import styled from "styled-components";
import Colors from "../../../assets/styles/colors";
import { StylesProps } from "../../../util/types";

const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding: 68px 30px 30px 30px; */
  overflow: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    overflow-x: hidden;
  }
`;

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ProposalCell = styled.div`
  width: 10px;
  margin-right: 15px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: ${(props) => props.color};
`;

const Overlay = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0;
`;

const TourContainer = styled.div` 
  padding: 59px 4% 0% 4%;
  /* display: flex; */
  flex-direction: column;
  text-align: left;
  /* justify-content: ${(props) =>
    props.first ? "center" : "space-between"}; */
  /* align-items: center; */
  max-width: 1000px;
  width: ${(props: StylesProps) => (props.first ? false : "100%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  background: ${Colors.white};
  border-radius: 10px;
  box-shadow: 0px 3px 6px ${Colors.black}29;
  transition: width 1s, heigth 1s;
  margin: 10px;
`;

const RowButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const TourTitle = styled.h2`
  color: ${Colors.blue6};
  font: Bold 1.5rem Eurostile;
  letter-spacing: 0;
  margin: 0 0 59px;
`;

const TourText = styled.p`
  font: Medium 14px/18px Roboto;
  letter-spacing: 0;
  font-size: 14px;
  color: ${Colors.gray5};
  text-align: left;
`;

const TextObservation = styled(TourText)`
  margin-top: 59px;
`;

const TourButton = styled.button`
  margin-top: 63px;
  margin-bottom: 38px;
  cursor: pointer;
  background: ${Colors.blue6};
  font-weight: bold;
  width: 134px;
  padding: 10px;
  color: ${Colors.white};
  border: none;
  border-radius: 2px;
  box-shadow: 0px 3px 6px ${Colors.black}29;
`;

export {
  RatingContainer,
  ScreenContainer,
  Loading,
  ProposalCell,
  Overlay,
  TourContainer,
  RowButton,
  TourTitle,
  TextObservation,
  TourText,
  TourButton,
};
