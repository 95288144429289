import React, { FC, useEffect, useState } from "react";
import { FavoriteButtonStyled } from "./styles";
import { connect } from "react-redux";
import {
  favoriteProposalonAPI,
  updateProposalList,
} from "../../../../dataflow/thunks/easy-proposal-search";
import { eventCategory, logEvent } from "../../../../util/analytics/index";

interface FavoriteButtonProps {
  proposal?: any;
  proposals?: any[];
  updateProposalList?: (value: any) => void;
  favoriteProposalonAPI?: (value: any, value2: any) => void;
};

const FavoriteButton: FC<FavoriteButtonProps> = ({ proposal, proposals, updateProposalList, favoriteProposalonAPI }) => {
  const [isFavorite, setIsFavorite] = useState(proposal?.isFavorite);

  const updateOpportunitiesList = () => {
    const proposalIndex: any = proposals?.findIndex(
      (_proposal) => _proposal?.id === proposal?.id
    );
    proposal.isFavorite = isFavorite;
    if (proposals?.length) proposals[proposalIndex] = proposal;
    updateProposalList && updateProposalList(proposals);
  };

  useEffect(() => {
    updateOpportunitiesList();
    //@TO-DO the function needs to be a callback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFavorite]);

  const requestToChangeFavoriteState = (event: any) => {
    logEvent(
      eventCategory.buttonClick,
      `click favoritar ${proposal?.description} ${!proposal?.isFavorite}`,
      "FavoriteButton"
    );
    event?.preventDefault();
    setIsFavorite(!isFavorite);
    event?.stopPropagation();
    event?.nativeEvent?.stopImmediatePropagation();
    favoriteProposalonAPI && favoriteProposalonAPI(proposal, proposal?.isFavorite);
  };

  return (
    <div>
      <FavoriteButtonStyled
        isFavorite={isFavorite}
        onClick={(event) => requestToChangeFavoriteState(event)}
      />
    </div>
  );
};

const mapSateToProps = (state: any) => ({
  proposals: state.easyProposals.proposals,
  // selectedProposal: state.easyProposals.selectedProposal,
});

const mapDispatchToProps = (dispatch: any) => ({
  favoriteProposalonAPI: (proposal: any, isFavorite: any) => {
    dispatch(favoriteProposalonAPI(proposal, isFavorite));
  },
  updateProposalList,
});

export default connect(mapSateToProps, mapDispatchToProps)(FavoriteButton);
