import styled from "styled-components";
import { ButtonProps } from ".";
import Colors from "../../../assets/styles/colors";

const ButtonSend = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props:ButtonProps) => props.width};
  height: ${(props:ButtonProps) => props.height};
  margin: ${(props:ButtonProps) => props.margin};
  border-radius: 4px;
  border: ${(props:ButtonProps) => props.border};
  font-size: 1rem;
  font-family: Eurostile;
  font-weight: ${(props:ButtonProps) => props.fontWeight};
  color: ${(props:ButtonProps) => props.color};
  background: ${(props:ButtonProps) => props.background};
  box-shadow: ${(props:ButtonProps) => props.shadow && `0px 3px 6px ${Colors.black}29`};
  text-decoration: ${(props:ButtonProps) => props.textDecoration};
  outline: 0;
  cursor: pointer;

  @media (max-width: 425px) {
    font-size: 0.8rem;
  }

  @media (max-width: 648px) {
    width: ${(props:ButtonProps) => props.widthMobile};
    margin: ${(props:ButtonProps) => props.marginMobile};
  }

  &:hover {
    font-weight: 600;
  }

  > img {
    margin-right: 0.25rem;
  }
`;
export { ButtonSend };
