import React, { FC } from "react";
import { TextDescription, Text } from "../../../components/common-components/Accordion/styles";
import { Image } from "../styles";
import Indicators01 from "../../../assets/img/faq/indicators1.png";
import Indicators02 from "../../../assets/img/faq/indicators2.png";
import Indicators03 from "../../../assets/img/faq/indicators3.png";

const Indicators: FC = () => {
    return (
        <>
            <TextDescription>
                <Text>
                    {`Temos disponível nos cards de indicadores a possibilidade do usuário realizar a pesquisa das oportunidades participadas a partir dos últimos meses (1, 3, 6, 9 e 12 meses). Também é possível alterar a visualização da moeda (R$ Real, U$ Dólar e € Euro).`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={Indicators01} alt="Imagem" />
            </TextDescription>
            <TextDescription>
                <Text>
                    {`Também é possível alterar a visualização do gráfico clicando nos cards de (Menor Preço, Maior Preço, Desclassificada, Declinadas e Oportunidades (Padrão)).`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={Indicators02} alt="Imagem" />
            </TextDescription>
            <TextDescription>
                <Text>
                    {`Na linha “Visualizar”, podemos consultar o gráfico de bolhas em “Oportunidades”. Também é possível consultar o resultado acumulado em “Acumulado”.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={Indicators03} alt="Imagem" />
            </TextDescription>
        </>
    )
}
export default Indicators;