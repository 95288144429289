import React from "react";
import { CarouselDiv } from "./styles";
import { Carousel } from "react-responsive-carousel";
import CardLives from "./cardLivers";
import CardTwo from "./CardTwo";
import CardThree from "./CardThree";
import CardFour from "./CardFour";

const CarouselLogin = () => {
  const carrouselSlides = [
    {
      slide: [<CardLives />]
    },
    {
      slide: [<CardTwo />],
    },
    {
      slide: [<CardThree />],
    },
    {
      slide: [<CardFour />],
    }
  ];
  return (
    <CarouselDiv>
      <Carousel
        autoFocus
        infiniteLoop
        interval={10000}
        autoPlay
        swipeable
        stopOnHover
        showThumbs={false}
        showStatus={false}
        transitionTime={1000}
        useKeyboardArrows={true}
      >
        {carrouselSlides.map((item: any, index: any) => (
          item.slide
        ))}
      </Carousel>
    </CarouselDiv>
  );
}

export default CarouselLogin;
