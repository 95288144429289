import styled from "styled-components";
import Colors from "../../../assets/styles/colors";

const Title = styled.h5`
    font-family: "Roboto", sans-serif;
`

const Description = styled.p`   
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    text-align: center;
`

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const ContainerButton = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const Button = styled.button`
    color: white;
    background-color: #dc3545;
    border: none;
    border-radius: 5px;
    padding: 6px 16px;
    font-weight: 600;
    cursor: pointer;
`;

const ButtonClose = styled(Button)``;
const ButtonConfirm = styled(Button)`
    background-color: ${Colors.blue4};
    margin-right: 10px;
`;

export {
    Title,
    Description,
    Container,
    ContainerButton,
    ButtonConfirm,
    ButtonClose
}