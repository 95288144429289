import axios from "axios";
import {
  changeCNPJAndTokenOnForm,
  addNewIntegrationProfile,
  getIntegrationProfiles,
  deleteIntegrationProfile,
  integrationStatusResponse,
  setCurrentProfile,
} from "../modules/integration-profiles-module";

const CURRENT_PROFILE_KEY = "current-profile";

export const getIntegrationListFromAPI = async () => {
  const { idToken } = JSON.parse(
      window.localStorage.getItem("petronect_credentials") || "{}"
  );

  const response = await axios.get(`${process.env.REACT_APP_API}/management/integrations`, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  })
  const {data} = response;

  return data

};


export const getIntegrationProfilesFromAPI = () => (dispatch: any) => {
  const { idToken } = JSON.parse(
    window?.localStorage?.getItem("petronect_credentials") || ""
  );

  try {
    axios
      .get(`${process.env.REACT_APP_API}/management/integrations`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        const { data } = response.data;

        const documents: any = [];
        const profiles = [];

        for (const profile of data) {
          if (!documents.includes(profile.document.number)) {
            profiles.push(profile);
            documents.push(profile.document.number);
          }
        }
        window?.localStorage?.setItem(
          "integration-profiles",
          JSON.stringify(profiles || '')
        );
        const currentProfileIndex: any = localStorage.getItem(CURRENT_PROFILE_KEY);
        if (profiles.length > 0) {
          if (currentProfileIndex && currentProfileIndex <= profiles.length) {
            dispatch(
              setCurrentIntegrationProfile(
                profiles[Number(currentProfileIndex)], null
              )
            );
          } else {
            localStorage.setItem(CURRENT_PROFILE_KEY, "0");
            dispatch(setCurrentIntegrationProfile(profiles[0], null));
          }
          dispatch(getIntegrationProfiles(profiles));
        } else {
          dispatch(getIntegrationProfiles([]));
          dispatch(clearCurrentIntegrationProfile());
        }
      });
  } catch (err) {
    dispatch(getIntegrationProfiles([]));
  }
};

export const clearCurrentIntegrationProfile = () => (dispatch: any) => {
  localStorage.removeItem(CURRENT_PROFILE_KEY);
  dispatch(setCurrentProfile(null));
};

export const setCurrentIntegrationProfile = (profile?: any, index?: any) => (dispatch: any) => {
  if (index) {
    localStorage.setItem(CURRENT_PROFILE_KEY, index);
  }
  dispatch(setCurrentProfile(profile));
};

export const addNewIntegrationProfileToAPI = (newProfile: any) => (dispatch: any) => { // aqui é o tratamento
  const { idToken } = JSON.parse(
    window?.localStorage?.getItem("petronect_credentials") || ""
  );

  try {
    axios
      .post(
        `${process.env.REACT_APP_API}/management/integrations`,
        {
          token: newProfile.token,
          document: {
            number: newProfile.cnpj,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        dispatch(addNewIntegrationProfile(response.data.data));
        dispatch(integrationStatusResponse("successed"));
        dispatch(changeCNPJAndTokenOnFormThunk({ cnpj: "", token: "" }));
      })
      .catch((err) => {
        dispatch(integrationStatusResponse("failed"));
      });
  } catch (err) {
    dispatch(integrationStatusResponse("failed"));
    // return responseHandler("Revise os campos preenchidos");
  }
};

export const delete_integration_profile_From_API = (integrationId: any) => (
  dispatch: any
) => {
  const { idToken } = JSON.parse(
    window?.localStorage?.getItem("petronect_credentials") || ""
  );
  try {
    axios
      .delete(
        `${process.env.REACT_APP_API}/management/integrations/${integrationId}`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .then((response) => {
        // update list
        dispatch(deleteIntegrationProfile(integrationId));
      });
  } catch (err) {
    // this.get_integration_profiles_from_api();
  }
};

export const new_delete_integration_profile_From_API = async (integrationId: any) => {
  const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
  );
  try {
    const response = await axios.delete(
        `${process.env.REACT_APP_API}/management/integrations/${integrationId}`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
    );
    // Atualize a lista aqui, ou retorne a resposta para que a lista seja atualizada no local onde a função é chamada
    return response.data; // ou qualquer outra manipulação necessária da resposta
  } catch (err) {
    console.error('Error deleting integration:', err);
    throw err; // Lança o erro para que seja tratado no local onde a função é chamada
  }
};


export const changeIntegrationStatus = (status: any) => (dispatch: any) => {
  dispatch(integrationStatusResponse(status));
};

export const changeCNPJAndTokenOnFormThunk = (status: any) => (dispatch: any) => {
  dispatch(changeCNPJAndTokenOnForm(status));
};

export default null;
