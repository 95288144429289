import React, { FC, useEffect, useState } from "react";
import {
  Texto,
  Form,
  Label,
  Input,
  RowButton,
  InputButton,
  Span
} from "./styles";
import {
  // addNewIntegrationProfileToAPI,
  changeIntegrationStatus,
  changeCNPJAndTokenOnFormThunk,
  getIntegrationProfilesFromAPI,
} from "../../../../dataflow/thunks/integration-profiles";
import { connect } from "react-redux";
import StatusModal from "../../../common-components/ModalForMessages/ModalForMessages";
import loading from "../../../../assets/animations/loading.svg";
import { eventCategory, logEvent } from "../../../../util/analytics/index";
import axios from "axios";
import { addNewIntegrationProfile, integrationStatusResponse } from "../../../../dataflow/modules/integration-profiles-module";

interface CpnjAndTokenForm {
  token: string;
  cnpj: string;
}

interface AddNewProfileProps {
  integrationStatus: string;
  getIntegrationProfilesFromAPI: () => void;
  changeIntegrationStatus: (e: string) => void;
  changeCNPJAndTokenOnFormThunk: ({ cnpj, token }: { cnpj: string, token: string }) => void;
  cpnjAndTokenForm: CpnjAndTokenForm;
  integrationProfiles: any
  // addNewIntegrationProfileToAPI: ({ cnpj, token }: {
  //   cnpj: string, token: string
  // }) => void;
  showDescriptionText: any;
}

const AddNewProfile: FC<AddNewProfileProps> = ({
  integrationStatus,
  getIntegrationProfilesFromAPI,
  changeIntegrationStatus,
  changeCNPJAndTokenOnFormThunk,
  cpnjAndTokenForm,
  integrationProfiles,
  showDescriptionText
}) => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getIntegrationProfilesFromAPI();
  }, []);

  useEffect(() => {
    if (integrationStatus === "successed") {
      getIntegrationProfilesFromAPI();
    }
    setIsLoading(false);
  }, [integrationStatus]);

  const closeModal = () => {
    changeIntegrationStatus("normal");
  };

  const messageModal = (infoReceived: any) => {
    const info = infoReceived;
    return (
      <StatusModal
        title={info.title}
        description={info.description}
        buttonTitle={"Ok"}
        closeModal={() => closeModal()}
      />
    );
  }

  const handleChangeCnpj = (event: any) => {
    changeCNPJAndTokenOnFormThunk({
      cnpj: event.target.value,
      token: cpnjAndTokenForm.token,
    });
  }

  const handleChangeToken = (event: any) => {
    changeCNPJAndTokenOnFormThunk({
      cnpj: cpnjAndTokenForm.cnpj,
      token: event.target.value,
    });
  }

  const addNewIntegrationProfileToAPI = (newProfile: any) => { // aqui é o tratamento voltar para lugar certo depois
    const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
    );

    try {
      axios
        .post(
          `${process.env.REACT_APP_API}/management/integrations`,
          {
            token: newProfile.token,
            document: {
              number: newProfile.cnpj,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        )
        .then((response) => {
          addNewIntegrationProfile(response.data.data);
          changeIntegrationStatus("successed");
          changeCNPJAndTokenOnFormThunk({ cnpj: "", token: "" });
        })
        .catch((err) => {
          changeIntegrationStatus("failed");
        });
    } catch (err) {
      changeIntegrationStatus("failed");
      // return responseHandler("Revise os campos preenchidos");
    }
  };

  const submitNewProfile = () => {
    logEvent(
      eventCategory.buttonClick,
      'click submeter formulário integrações',
      'Adicionar Perfil'
    );
    const { cnpj, token } = cpnjAndTokenForm;
    const cnpjDocument = cnpj.replace(/\D/g, "");

    if (token === "" || token === " ") {
      return;
    } else if (cnpj.length === 0) {
      changeIntegrationStatus("cnpjError");
      logEvent(
        eventCategory.modalOpen,
        'open modal cnpj Error',
        'CNPJ com erro'
      );
      return;
    } else if (token.length < 117) {
      changeIntegrationStatus("tokenError");
      logEvent(
        eventCategory.modalOpen,
        'open modal token Error',
        'Token com erro'
      );
      return;
    }
    const hasDocument = integrationProfiles.find(
      ({ document }: { document: any }) => document.number === cnpjDocument
    );
    if (hasDocument) {
      changeIntegrationStatus("registered");
      return;
    }
    setIsLoading(true);
    addNewIntegrationProfileToAPI({
      cnpj: cnpjDocument,
      token: token,
    });
  }

  const validateCNPJ = (docNumber:string)=>{
if (docNumber.length  === 14) return `CNPJ ${docNumber.substring(0,2)}.${docNumber.substring(2,5)}.${docNumber.substring(5,8)}/${docNumber.substring(8,12)}-${docNumber.substring(12,14)}`
else return `DOCUMENTO ${docNumber}`
  }

  const integrationStatusFeedback = () => {
    let modalInfo = { title: "", description: "" };
    switch (integrationStatus) {
      case "successed":
        // resetInputFields();
        modalInfo.title = "Perfil cadastrado";
        modalInfo.description = `
Olá!
Sua integração foi realizada com sucesso e o histórico das operações do ${validateCNPJ(cpnjAndTokenForm.cnpj)} estará disponível em até 24h.
Aproveite os benefícios utilizando o Meu Desempenho e o Match Analítico.
Em caso de dúvidas, estamos à disposição em nossos canais de atendimento.
Para instruções de uso da Minha Petronect, disponibilizamos vídeos em nosso canal no YouTube.
        `;
        return modalInfo;

      case "failed":
        modalInfo.title = "Erro ao cadastrar";
        modalInfo.description =
          "Ocorreu um erro ao cadastrar o perfil. Por favor, tente novamente.";
        return modalInfo;

      case "registered":
        modalInfo.title = "CNPJ já cadastrado";
        modalInfo.description = "Este CNPJ já está integrado em sua conta.";
        return modalInfo;

      case "cnpjError":
        modalInfo.title = "CNPJ com erro";
        modalInfo.description = "Verifique se o CNPJ foi digitado corretamente";
        return modalInfo;

      case "tokenError":
        modalInfo.title = "Token com erro";
        modalInfo.description =
          "Verifique se o token foi digitado corretamente";
        return modalInfo;

      default:
        modalInfo.title = "";
        modalInfo.description = "";
        return modalInfo;
    }
  }

  const { cnpj, token } = cpnjAndTokenForm;
  return (
    <div>
      <Form>
        {showDescriptionText ? (
          <Texto>
            <h5>
              <b>Como posso gerar o token?</b>
            </h5>{" "}
            Vá para o Portal Petronect/Integrações/Minha Petronect/Gerar Novo
            Token
          </Texto>
        ) : (
          <></>
        )}

        <Label>
          <Span>CNPJ</Span>
          <Input
            placeholder="Digite o CNPJ"
            type="text"
            value={cnpj}
            onChange={(event) => handleChangeCnpj(event)}
            required
          />

          <Span>Token</Span>
          <Input
            placeholder="Digite o Token"
            type="text"
            value={token}
            onChange={(event) => handleChangeToken(event)}
            required
          />

          <RowButton>
            {isLoading && (
              <img src={loading} height={50} alt="Loading..." />
            )}
            {!isLoading && (
              <InputButton
                type="button"
                value="Adicionar Perfil"
                onClick={() => submitNewProfile()}
              />
            )}
          </RowButton>
        </Label>

        {integrationStatus !== "normal"
          ? messageModal(integrationStatusFeedback())
          : null}
      </Form>
    </div>
  );
}


const mapStateToProps = (state: any) => ({
  integrationProfiles: state.integrationProfiles.integrationProfiles,
  integrationStatus: state.integrationProfiles.integrationStatus,
  cpnjAndTokenForm: state.integrationProfiles.cpnjAndTokenForm,
});

const mapDispatchToProps = (dispatch: any) => ({
  getIntegrationProfilesFromAPI: () => {
    dispatch(getIntegrationProfilesFromAPI());
  },
  changeIntegrationStatus: (status: any) =>
    dispatch(changeIntegrationStatus(status)),
  // addNewIntegrationProfileToAPI: (newProfile: any) => {
  //   dispatch(addNewIntegrationProfileToAPI(newProfile));
  // },
  changeCNPJAndTokenOnFormThunk: (newValues: any) =>
    dispatch(changeCNPJAndTokenOnFormThunk(newValues)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewProfile);
