/* eslint-disable array-callback-return */
import React, { FC, Fragment, ReactNode, useEffect, useState } from "react";
import * as d3 from "d3";
import styled from "styled-components";
import InfoModal from "./InfoModal";
import Colors from "../../../assets/styles/colors";

interface StylesProps {
  width?: any;
}

const Container = styled.div`
  position: relative;
  height: 100%;
  max-width: ${(props: StylesProps) => props.width}px;
  overflow-y: hidden;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const MonthsList = styled.ul`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: ${(props: StylesProps) => props.width}px;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Month = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 8.333%;
  height: 65px;

  // &:not(last-child) {
  //   border-right: 1px solid #e6e6e6;
  // }

  @media screen and (max-width: 480px) {
    width: 33.33%;
  }
`;

const Label = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25px;
  margin: 0 0.2rem;
  color: ${Colors.gray8};
  font: 400 0.85rem "Open Sans", sans-serif;
  background-color: ${Colors.white2};
`;

interface BubbleChartProps {
  type:any;
  timeline: any;
  bubblesRange: any;
  data: any;
  width: any;
  height: any;
  monthlyTransactions: any;
  dataSetRange: any;
  formatValue: (v?: any) => ReactNode;
  monthlyBubbles: any;
  setOpportunityDetails: (v: any) => void;
  selectedOportunity: any;
}

const BubbleChart: FC<BubbleChartProps> = ({
  timeline,
  bubblesRange,
  data,
  width,
  height,
  monthlyTransactions,
  dataSetRange,
  formatValue,
  monthlyBubbles,
  setOpportunityDetails,
  selectedOportunity
}) => {

  const [description, setDescription] = useState<any>({
    show: false,
    x: null,
    y: null,
  })
  const [hoveredBubbble, setHoveredBubbble] = useState<any>()
  const [bubbles, setBubbles] = useState([])
  const [bars, setBars] = useState([])
  let axisBottom = null;
  const paddingBottom = 65;
  const paddingTop = 35;
  const fontSize = 12;
  const fontFamily = "Open Sans";
  const [responsiveWidth, setResponsiveWidth] = useState<any>(width)

  useEffect(() => {
    updateResponsiveWidth();
    setupGraph();
  }, [])

  useEffect(() => { setupGraph() }, [timeline, bubblesRange, bubblesRange, data])


  const _formatValue = (num: any) => {
    if (num >= 1000000) {
      return `${Math.sign(num) * Number((Math.abs(num) / 1000000).toFixed(1))}m`;
    }
    if (num >= 1000 || num >= 1) {
      return `${Math.sign(num) * Number((Math.abs(num) / 1000).toFixed(1))}k`;
    }
    return num;
  };

  const updateResponsiveWidth = () => {
    // 433, 378, 328, 273
    switch (true) {
      // 480px - 433 with padding
      case width >= 379 && width <= 433:
        setResponsiveWidth(1740);
        break;
      // 425px - 378 with padding
      case width >= 329 && width <= 378:
        setResponsiveWidth(1520);
        break;
      // 375px - 328 with padding
      case width >= 274 && width <= 328:
        setResponsiveWidth(1320);
        break;
      // 320px - 273 with padding
      case width <= 273:
        setResponsiveWidth(1100);
        break;
      default:
        setResponsiveWidth(width);
    }
  }

  const setupGraph = () => {
    const _height = height - paddingBottom;
    var limiteIndex = monthlyTransactions?.length - timeline?.label;
    var monthlyFilter = monthlyTransactions?.filter(
      (item: any, index: any) => index >= limiteIndex
    );

    const xBarScale = d3
      .scaleBand()
      .domain(monthlyFilter.map((d: any) => d.month))
      .range([0, responsiveWidth]);

    const xScale = d3
      .scaleTime()
      .domain([
        new Date(`${dataSetRange?.rangeStart}`),
        new Date(`${dataSetRange?.rangeEnd}`),
      ])
      .range([0, responsiveWidth]);

    const yMax =
      Math.max(
        ...data
          .filter((d: any) => {
            return (
              d.total >= bubblesRange?.minValue &&
              d.total <= bubblesRange?.maxValue
            );
          })
          .map((d: any) => d.total)
      ) + 100;

    const yMin =
      Math.min(
        ...data
          .filter((d: any) => {
            return (
              d.total >= bubblesRange?.minValue &&
              d.total <= bubblesRange?.maxValue
            );
          })
          .map((d: any) => d.total)
      ) - 100;

    // Add Y axis
    const yScale = d3
      .scaleLinear()
      .domain([yMin, yMax])
      .range([_height, paddingTop]);

    // Add a scale for bubble size
    const zScale = d3
      .scaleLinear()
      .domain([yMin, yMax])
      .range([12, 35]);

    const _bars = monthlyFilter.map((d: any, index: any) => {
      return {
        id: index,
        x: Number(xBarScale(d.mo)) + xBarScale.bandwidth() * 0.1,
        width: xBarScale.bandwidth() * 0.8,
        finalX: xBarScale(d.month),
        month: d.month,

        revenueY: yScale(d.revenue),
        revenueFill: Colors.green5,
        revenueLabel: formatValue(d?.revenue),

        lossesY: yScale(d.losses),
        lossesFill: Colors.white2,
        lossesLabel: formatValue(d?.losses),
      };
    });

    const _bubbles: any = [];

    monthlyBubbles
      ?.map((d: any) => {
        d?.list
          .filter((d: any) => {
            return (
              d.total >= bubblesRange?.minValue &&
              d.total <= bubblesRange?.maxValue
            );
          })
          .map((item: any) => {
            let fill = Colors.gray8;
            if (item.highlightColor) {
              fill = item.highlightColor;
            } else {
              fill = item.winner ? Colors.green5 : Colors.gray8;
            }
            _bubbles.push({
              // cx: currentXScale(itemIndex),
              cx: xScale(new Date(item.openTime)) + zScale(item.total),
              cy: yScale(item.total),
              r: zScale(item.total),
              description: item.description,
              month: new Date(item.openTime).getMonth() + 1,
              date: new Date(item.openTime),
              fill: fill,
              won: item.winner,
              highlightColor: item.highlightColor,
              total: _formatValue(item?.total),
              competitor: _formatValue(item?.competitorPrice),
              myPrice: _formatValue(item?.myPrice),
              id: item.id,
              textSize: textSize(_formatValue(item?.total)),
              judgementType: item.judgementType,
              cnpj: item.cnpj,
              items: item.Items,
            });
          });
      })
      .sort((d: any) => {
        if (d.won) {
          return -1;
        }
        return 1;
      });

    const orderedBubbles = _bubbles.sort((a: any) => (a.won === true ? 1 : -1));
    setBubbles(orderedBubbles)
    setBars(_bars)
  };

  const textSize = (text: any) => {
    if (!d3) {
      return {
        width: null,
        height: null,
      };
    }

    const container = d3.select("body").append("svg");

    container
      .append("text")
      .text(text)
      .attr("font-size", fontSize)
      .attr("font-family", fontFamily)
      .attr("font-weight", 300)
      .attr("x", -99999)
      .attr("y", -99999);

    const size: any = container?.node()?.getBBox();
    container.remove();
    return {
      width: size.width,
      height: size.height,
    };
  }

  const handleMouseEnter = (e: any) => {
    const { id } = e.target;
    const opportunity: any = bubbles.find((d: any) => d.id === Number(id));
    let totalWon = 0;
    let totalLost = 0;

    if (
      opportunity.judgementType.toLowerCase() === "julgamento por item" ||
      opportunity.judgementType.toLowerCase() === "julgamento por lote"
    ) {
      totalWon = opportunity.items
        .filter((item: any) => item.winner)
        .reduce((totalItem: any, item: any) => {
          return totalItem + item.total;
        }, 0);

      totalLost = opportunity.items
        .filter((item: any) => !item.winner)
        .reduce((totalItem: any, item: any) => {
          return totalItem + item.total;
        }, 0);
    }

    const getCoordinate = e.target.getBoundingClientRect();
    const position = {
      left:
        opportunity.month > 10
          ? getCoordinate.left - 250
          : getCoordinate.right - getCoordinate.width / 2,
      top: getCoordinate.top + getCoordinate.height,
    };

    setDescription({
      show: true,
      ...position,
      text: opportunity.description,
      id: opportunity.id,
      total: opportunity.total,
      competitor: opportunity.competitor,
      myPrice: opportunity.myPrice,
      won: opportunity.winner,
      date: opportunity.date,
      cnpj: opportunity.cnpj,
      judgementType: opportunity.judgementType,
      totalWon: _formatValue(totalWon),
      totalLost: _formatValue(totalLost),
    })
    setHoveredBubbble((opportunity.judgementType.toLowerCase() === "julgamento por item" ||
      opportunity.judgementType.toLowerCase() === "julgamento por lote") &&
      opportunity.id)
  };

  const handleMouseLeave = () => {
    setDescription({
      show: false,
      left: null,
      top: null,
    })
    setHoveredBubbble("")
  };

  const handleSelectOpprtunity = (id: any) => {
    setOpportunityDetails(id);
  };

  const renderBars = () => {
    return bars.map((d: any, index: any) => (
      <Fragment>
        {index !== 0 && (
          <line
            x1={d.finalX}
            y1={0}
            x2={d.finalX}
            y2={height}
            width={1}
            height={height}
            strokeWidth={1}
            stroke={Colors.gray1}
          />
        )}
      </Fragment>
    ));
  };

  const renderBubbles = () => {
    return bubbles.map((d: any, index: any) => (
      <Fragment key={index}>
        <circle
          id={d.id}
          fill={
            selectedOportunity &&
              selectedOportunity.id === d.id
              ? Colors.blue8
              : hoveredBubbble === d.id
                ? Colors.green6
                : d.fill
          }
          cx={d.cx}
          cy={d.cy}
          r={d.r}
          style={{
            opacity:
              (d.won && !d.highlightColor) ||
                (selectedOportunity &&
                  selectedOportunity.id === d.id)
                ? 1
                : 0.8,
            cursor: "pointer",
          }}
          stroke="white"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleSelectOpprtunity(d.id)}
          onContextMenu={(ev) => {
            ev.preventDefault();
            handleMouseEnter(ev);
          }}
        />
        {2 * d.r > d.textSize.width && d.won && !d.highlightColor && (
          <text
            style={{ pointerEvents: "none" }}
            id={d.id}
            fill="white"
            fontSize={fontSize}
            fontWeight={400}
            fontFamily={fontFamily}
            dx={d.cx - d.textSize.width / 2}
            dy={d.cy + d.textSize.height / 2}
          >
            {d.total}
          </text>
        )}
      </Fragment>
    ));
  };

  const renderMonths = () => {
    const limiteIndex = monthlyTransactions?.length - timeline?.label;
    const monthlyFilter = monthlyTransactions?.filter(
      (item: any, index: any) => index >= limiteIndex
    );

    let inputStyle = {
      width: `${100 / timeline?.label}%`,
    };

    return monthlyFilter.map((item: any) => {
      return (
        <Month style={inputStyle}>
          {/* <Value>{props.formatValue(item.revenue)}</Value> */}
          <Label>{item.month}</Label>
        </Month>
      );
    });
  };

  return (
    <Fragment>
      <div
        id="bubble-chart"
        style={{
          position: "relative",
          minHeight: `${height}px`,
          maxHeight: `${height}px`,
          marginTop: "1rem",
          border: `1px solid ${Colors.gray1}`,
          borderRadius: "15px",
          overflow: "hidden",
          // overflowX: "auto"
        }}
      >
        <Container width={width}>
          <svg width={responsiveWidth} height={height}>
            <g
              className="axisBottom"
              ref={(el) => {
                axisBottom = el;
              }}
              transform={`translate(0, ${height})`}
            />
            <g>{renderBubbles()}</g>
            <g>{renderBars()}</g>
          </svg>
          {description.show && (
            <InfoModal description={description} />
          )}
          <MonthsList width={responsiveWidth}>
            {renderMonths()}
          </MonthsList>
        </Container>
      </div>
    </Fragment>
  );
}

export default BubbleChart;
