import React, { FC } from "react";
import {
  ContainerPage,
} from "./styles";
import Faq from "./Faq";

const FaqPage: FC = () => {
  return (
    <ContainerPage>
      <Faq />
    </ContainerPage>
  );
}

export default FaqPage;
