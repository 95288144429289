import React, { FC, Fragment, useEffect, useState } from "react";
import {
  TiArrowUnsorted,
  TiArrowSortedDown,
  TiArrowSortedUp,
} from "react-icons/ti";
import { connect } from "react-redux";
import crypto from "crypto";
import dropdown from "../../../../assets/icons/common/dropdown-cinza.svg";
import {
  formatDateWithoutTimezone,
  formatValue,
} from "../../../../util/format-value";
import exportData from "../../../../dataflow/thunks/export-data";
import { ReactComponent as DownloadSvg } from "../../../../assets/icons/common/download.svg";
import Colors from "../../../../assets/styles/colors";
import { eventCategory, logEvent } from "../../../../util/analytics/index";
import {
  Container,
  WrapHeader,
  Title,
  Label,
  WrapLine,
  LineContainer,
  Icon,
  Info,
  Dropdown,
  ItemsContainer,
  ButtonExportData,
  FlexDiv,
  Hr,
  PaginationContainer,
  ItemsPerPageSelect,
  ItemsPagination,
  PaginationWrapper
} from "./styles"
import { checkDeviceMobile } from "../../../../util/devUtilities";
import { Pagination, PageItem } from 'react-bootstrap';

interface OpportunityHistoryProps {
  opportunities: any;
  integrationProfile: any;
  setOpportunityDetails: (v: any) => void;
}

const OpportunityHistory: FC<OpportunityHistoryProps> = ({
  opportunities,
  integrationProfile,
  setOpportunityDetails
}) => {

  const [selectedOp, setSelectedOp] = useState("");
  const [order, setOrder] = useState({ key: "openTime", reversed: true });
  const [list, setList] = useState([]);
  const isMobile:boolean = checkDeviceMobile();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);

  useEffect(() => {
    if (opportunities.length > 0) {
      setList(opportunities.reverse());
    } else {
      setList([]);
    }
  }, [opportunities]);

  const handleSetSelectedOp = (ev: any, opportunity: any) => {
    ev.stopPropagation();
    setSelectedOp((prevState: any) =>
      opportunity === prevState.selectedOp ? undefined : opportunity
    );
  };

  const handleSelectOpprtunity = (ev: any, opportunity: any) => {
    ev.stopPropagation();
    setOpportunityDetails(opportunity);
    setSelectedOp((prevState: any) =>
      opportunity === prevState.selectedOp ? undefined : opportunity
    );
  };

  const renderIcon = (color: string) => (
    <Icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        {/* eslint-disable-next-line max-len */}
        <path
          fill={color}
          className="a"
          d="M10,5.983,12,7.2l-.532-2.3,1.8-1.538-2.365-.2L10,1,9.075,3.16l-2.348.2L8.511,4.9,7.955,7.2,10,5.983M12.455,19H7.545V8.364h4.909V19M1,14.091V19H5.909V14.091H1m3.273,3.273H2.636V15.727H4.273v1.636m9.818-6.545V19H19V10.818H14.091m3.273,6.545H15.727V12.455h1.636Z"
          transform="translate(-1 -1)"
        />
      </svg>
    </Icon>
  );

  const renderItem = (item: any, i: any, index: number, opportunity: any) => {
    let won = item.winner;

    return (
      <Label
        color={won ? Colors.green5 : Colors.gray8}
        key={`${item.id}-${index}`}
        background={index % 2 === 0}
        style={{
          borderTop: `0.5px solid ${Colors.gray1}`,
        }}
      >
        {renderIcon("transparent")}
        <Info width={"10%"} text="center" color={Colors.gray8}>
          {`${formatValue(item.competitorPrice)}` || "-"}
        </Info>
        <Info width={"10%"} text="center">
          {`${formatValue(item.myPrice)}` || "-"}
        </Info>
        <Info width={"10%"} text="center">
          {opportunity.priceDiff}
        </Info>
        <Info width={"10%"}>
          {i + 1 < 10
            ? `00${i + 1}`
            : i + 1 >= 10 && i + 1 < 100
              ? `0${i + 1}`
              : i + 1}
        </Info>
        <Info width={"35%"}>{item.description}</Info>
      </Label>
    );
  };

  const orderItemsBy = (key: any) => {
    if (order.key === key) {
      setOrder((state) => ({ key, reversed: !state.reversed }));
      return;
    }
    setOrder({
      key,
      reversed: false,
    });
  };

  const orderedItems = () => {
    const { key, reversed } = order;
    if (order.key === "description") {
      return list.sort((a: any, b: any) => {
        if (a[key].toLowerCase() > b[key].toLowerCase())
          return reversed ? 1 : -1;
        if (a[key].toLowerCase() < b[key].toLowerCase())
          return reversed ? -1 : 1;
        return 0;
      });
    } else if (key === "openTime") {
      return reversed
        ? list.sort((a: string, b: string) => (new Date(b[key as any]) as any) - (new Date(a[key as any]) as any))
        : list.sort((a: string, b: string) => (new Date(a[key as any]) as any) - (new Date(b[key as any]) as any));
    } else {
      return reversed
        ? list.sort((a, b) => a[key] - b[key])
        : list.sort((a, b) => b[key] - a[key]);
    }
  };

  const tableArrow = (keyValue: any) => {
    const { key, reversed } = order;
    let icon = (
      <span style={{ display: "inline-block", padding: "3px 2px" }}>
        <TiArrowUnsorted />
      </span>
    );
    if (keyValue === key) {
      if (reversed) {
        icon = (
          <span style={{ display: "inline-block", padding: "3px 2px" }}>
            <TiArrowSortedDown />
          </span>
        );
      } else {
        icon = (
          <span style={{ display: "inline-block", padding: "3px 2px" }}>
            <TiArrowSortedUp />
          </span>
        );
      }
    }
    return icon;
  };

  const renderOpportunities = () => {
    const currentPageItems = orderedItems().slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    
    return (
      <>
        {currentPageItems.map((opportunity: any, index) => (
          <Fragment key={index}>
            <Label
              color={opportunity.winner ? Colors.green5 : Colors.gray8}
              key={`${opportunity.ID}-${index}`}
              background={index % 2 === 0}
              style={{
                cursor: "pointer",
              }}
              weight
              onClick={(ev) => handleSelectOpprtunity(ev, opportunity.id)}
            >
              <WrapLine>
                {!isMobile && renderIcon(opportunity.winner ? Colors.green5 : Colors.red)}
                <LineContainer>
                  <Info
                    width={"11.2%"}
                    text="center"
                    color={Colors.gray8}
                    weight={selectedOp === opportunity}
                  >
                    {opportunity.competitorPrice
                      ? `${formatValue(opportunity.competitorPrice)}`
                      : "-"}
                  </Info>
                  <Info
                    width={"10%"}
                    text="center"
                    weight={selectedOp === opportunity}
                  >
                    {opportunity.myPrice
                      ? `${formatValue(opportunity.myPrice)}`
                      : "-"}
                  </Info>
                  {!isMobile && <Info
                    width={"10%"}
                    text="center"
                    weight={selectedOp === opportunity}
                  >
                    {opportunity.priceDiff}
                  </Info>}
                  <Info width={"10.3%"} weight={selectedOp === opportunity}>
                    {opportunity.id}
                  </Info>
                  {!isMobile && <Info
                    width={"36.2%"}
                    weight={selectedOp === opportunity}
                    // mobileFull
                  >
                    {opportunity.description}
                  </Info>}
                  <Info width={"10%"} weight={selectedOp === opportunity}>
                    {formatDateWithoutTimezone(
                      opportunity.openTime,
                      "dd/MM/yyyy"
                    )}
                  </Info>
                </LineContainer>
              </WrapLine>
              {opportunity.Items.length > 0 && (
                <Dropdown
                  onClick={(ev) => handleSetSelectedOp(ev, opportunity)}
                  rotate={opportunity === selectedOp}
                >
                  <img src={dropdown} alt="Dropdown" />
                </Dropdown>
              )}
            </Label>
            {selectedOp === opportunity && (
              <ItemsContainer>
                {opportunity.Items.map((item: any, i: any) =>
                  renderItem(item, i, index, opportunity)
                )}
              </ItemsContainer>
            )}
          </Fragment>
        ))}
      </>
    );
  };
  
  const handleDownloadFile = async (data: any, integrationProfile: any, fileType: any) => {
    const {
      integrationId,
      document: { number },
    } = integrationProfile;
    const fileName = `${crypto
      .createHash("md5")
      .update(integrationId + number)
      .digest("hex")}`;

    const dataMapped = data.map((item: any) => {
      const result = {
        Id: item.id.toString(),
        Vencedor: item.winner ? "Sim" : "Não",
        Concorrente: item.competitorPrice,
        "Meu preço": item.myPrice,
        "%": item.priceDiff,
        "Serviço / Item": item.description,
        Data: formatDateWithoutTimezone(item.openTime, "dd/MM/yyyy"),
      };

      return result;
    });

    await exportData(dataMapped, fileName, fileType);

    const anchor = document.createElement("a");
    document.body.appendChild(anchor);
    const file = `${process.env.REACT_APP_API}/export-data/download/${fileName}.${fileType}`;
    const { idToken } = JSON.parse(window.localStorage.getItem("petronect_credentials") || "");

    const headers = new Headers();
    headers.append("Authorization", `Barear ${idToken}`);

    await fetch(file, { headers })
      .then((response) => response.blob())
      .then((blobby) => {
        const objectUrl = window.URL.createObjectURL(blobby);

        anchor.href = objectUrl;
        anchor.download = fileName;
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      });
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); 
  };

  const totalPages = Math.ceil(list.length / itemsPerPage);

  const renderPagination = () => {
    const pagesToShow = 5;
    const halfPagesToShow = Math.floor(pagesToShow / 2);
    let startPage = currentPage - halfPagesToShow;
    let endPage = currentPage + halfPagesToShow;

    if (startPage <= 0) {
      startPage = 1;
      endPage = Math.min(totalPages, pagesToShow);
    } else if (endPage > totalPages) {
      startPage = totalPages - (pagesToShow - 1);
      endPage = totalPages;
    }

    const pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

    return (
      <>
      <PaginationContainer>
        <PaginationWrapper>
        <Pagination size="sm">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          {pages.map(page => (
            <Pagination.Item 
              key={page}
              active={page === currentPage}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
        </PaginationWrapper>

        <ItemsPagination>
          <p>Itens por página</p>
          <ItemsPerPageSelect onChange={handleItemsPerPageChange} value={itemsPerPage}>
            <option value={15}>15</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
          </ItemsPerPageSelect>
          <span>
            Página {currentPage} de {totalPages}
          </span>
        </ItemsPagination>
        
      </PaginationContainer>
      </>
    );
  };

  return (
    <Container>
      <Hr />
      <WrapHeader>
        <FlexDiv>
          <Title>Histórico de oportunidades</Title>
          <ButtonExportData
            onClick={() => {
              logEvent(
                eventCategory.buttonClick,
                "click Exportar CSV Histórico de oportunidades",
                "Exportar CSV"
              );
              handleDownloadFile(opportunities, integrationProfile, "csv");
            }}
          >
            <span>Exportar CSV</span>
            <DownloadSvg />
          </ButtonExportData>
          <ButtonExportData
            onClick={() => {
              logEvent(
                eventCategory.buttonClick,
                "click Exportar Excel Histórico de oportunidades",
                "Exportar Excel"
              );
              handleDownloadFile(opportunities, integrationProfile, "xlsx");
            }}
          >
            <span>Exportar Excel</span>
            <DownloadSvg />
          </ButtonExportData>
        </FlexDiv>
      </WrapHeader>
      <Label color={Colors.gray6} header>
      {!isMobile && renderIcon("transparent")}
       <Info
          width={"10%"}
          text="center"
          onClick={() => orderItemsBy("competitorPrice")}
          font
        >
          Concorrente {tableArrow("competitorPrice")}
        </Info>
        <Info
          width={"10%"}
          text="center"
          onClick={() => orderItemsBy("myPrice")}
          font
        >
          Meu preço {tableArrow("myPrice")}
        </Info>
        {!isMobile && <Info
          width={"10%"}
          text="center"
          onClick={() => orderItemsBy("priceDiff")}
          font
        >
          % {tableArrow("priceDiff")}
        </Info>}
        <Info
          width={"10%"}
          font
          onClick={() => orderItemsBy("id")}
          // hideOnMobile
        >
          Id {tableArrow("id")}
        </Info>
        {!isMobile && <Info
          width={"35%"}
          font
          onClick={() => orderItemsBy("description")}
          hideOnMobile
        >
          Serviço/ item {tableArrow("description")}
        </Info>}
        <Info
          width={"10%"}
          font
          onClick={() => orderItemsBy("openTime")}
          // hideOnMobile
        >
          Data {tableArrow("openTime")}
        </Info>
      </Label>
      <div>
      {list.length > 0 && renderOpportunities()}
      {list.length > 0 && renderPagination()}
    </div>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  currentCurrency: state.opportunities.currentCurrency,
  timeline: state.opportunities.timeline,
  userFilters: state.filter.userFilters,
  dataSetRange: state.opportunities.dataSetRange,
});

export default connect(mapStateToProps)(OpportunityHistory);
