import styled from "styled-components";
import Colors from "../../../assets/styles/colors";

const Overlay = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.black}50;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
`;

const Modal = styled.div`
  width: 30vw;
  background: ${Colors.white};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem 1rem;
  position: relative;
  border: 1px solid ${Colors.blue8};
  z-index: 99;

  @media (max-width: 768px) {
    width: 65vw;
  }

  @media (max-width: 590px) {
    width: 95%;
  }
`;

const ImageClose = styled.img`
  position: absolute;
  top: -1.5vh;
  right: -9px;
  cursor: pointer;
`;

const ImageSucess = styled.img`
  width: 25%;
`;

const TextSucess = styled.h2`
  text-align: center;
  color: ${Colors.blue6};
  font-family: Eurostile;
`;

export { Overlay, Modal, ImageClose, ImageSucess, TextSucess };
