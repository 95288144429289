import styled from "styled-components";
import { Link } from "react-router-dom";
import Colors from "../../../assets/styles/colors";
import { StylesProps } from "../../../util/types";

const OptionItem = styled(Link)`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.6rem 1rem;
  border-radius: 0 0 0 4px;
  text-decoration: none;
  color: ${Colors.blue6};
  background: ${Colors.gray6}15;
  cursor: pointer;
`;

const OptionIcon = styled.img`
  position: absolute;
  left: 1.1rem;
  width: 13px;
  font-size: 1rem;
`;

const OptionTitle = styled.p`
  width: 100%;
  margin: 0;
  margin-left: 1.5rem;
  font: 200 0.75rem Eurostile;
  color: ${Colors.blue6};

  &:hover {
    font-weight: 600;
  }
`;

const DropdownItem = styled(Link)`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.6rem 1rem;
  padding-right: 0.5rem;
  border-radius: ${(props:StylesProps) => (props.isOpen ? "4px 0 0 0" : "4px 0 0 4px")};
  color: ${(props:StylesProps) => (props.isActive ? Colors.blue6 : Colors.gray2)};
  background: ${(props:StylesProps) => {
    if (!props.isActive && props.isOpen) {
      return Colors.gray1;
    } else if (props.isOpen) {
      return `${Colors.blue6}20`;
    }
  }};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: ${Colors.blue6}20;
  }
`;

const DropdownIcon = styled.img`
  position: ${(props:StylesProps) => (props.arrow ? "relative" : "absolute")};
  left: ${(props:StylesProps) => !props.arrow && "1rem"};
  bottom: ${(props:StylesProps) => !props.arrow && ".85rem"};
  width: ${(props:StylesProps) => (props.arrow ? "auto" : ".95rem")};
  height: ${(props:StylesProps) => (props.arrow ? "auto" : ".95rem")};
  padding: ${(props:StylesProps) => props.arrow && ".5rem"};
  transform: ${(props:StylesProps) => props.rotate && "rotate(180deg)"};
  filter: ${(props:StylesProps) => (props.isActive ? "grayscale(0%)" : "grayscale(100%)")};
  opacity: ${(props:StylesProps) => (props.isActive ? "100%" : "30%")};
`;

const DropdownTitle = styled.p`
  width: 100%;
  margin: 0;
  font: 400 0.9rem Eurostile;
  font-weight: ${(props:StylesProps) => (props.isOpen ? "600" : "400")};
  margin-left: 1.5rem;
`;

const TagPRO = styled.span`
  background: ${Colors.blue6};
  border-radius: 10px;
  color: ${Colors.white};
  display: inline-block;
  font-size: 0.8rem;
  font-weight: bold;
  margin-left: 10px;
  line-height: 1;
  padding: 4px 8px 6px;
`;

export {
    OptionItem,
    OptionIcon,
    OptionTitle,
    DropdownItem,
    DropdownIcon,
    DropdownTitle,
    TagPRO
}