import styled from "styled-components";
import Colors from "../../assets/styles/colors";
import { StylesProps } from "../../util/types";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 16px;
  min-height: 95vh;
  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`;

export const BackScreenDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;

  > a {
    cursor: pointer;
    text-decoration: none;
    color: ${Colors.blue6};
    display: flex;
    span {
      font-weight: bold;
      font-size: 1.2rem;
      margin: auto 0px;
    }
  }
`;

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow-y: scroll;
`;

export const AddFilterButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 110px;
  padding: 2rem;
  border-radius: 10px;
  background: ${Colors.blue6};
  box-shadow: 0px 3px 6px ${Colors.black}29;
`;

export const Icon = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  width: 85px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${Colors.white};
  
  > h1 {
    text-align: center;
    font: 600 1rem Open Sans;
    
  }

  > p {   
    font: 200 1rem Open Sans;
    text-align: center
  }
`;

export const NotificationSidebar = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem 0;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const Title = styled.p`
  margin-bottom: 0.4rem;
  font: 600 1rem Eurostile;
  padding-left: 1rem;
  color: ${Colors.blue6};
  margin-right: 0.4rem;
`;

export const Description = styled.p`
  margin-top: 0;
  font: 400 0.7rem Open Sans;
  padding-left: 1rem;
  color: ${Colors.gray6};

  @media (max-width: 490px) {
    width: 63%;
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 490px) {
    margin-top: 1.5rem;
    width: 85%;
  }
`;

export const SwitchBox = styled.div`
  display: flex;
  font: 200 0.9rem Eurostile;
  color: ${Colors.blue6};
  margin-bottom: 0.25rem;
`;

export const Switch = styled.div`
  position: relative;
  width: 40px;
  height: 21px;
  margin-left: 0.5rem;
  border-radius: 13px;
  background: ${(props:StylesProps) => (props.change ? Colors.blue6 : Colors.white)};
  border: solid 0.5px ${Colors.blue6};
  cursor: pointer;
`;

export const Span = styled.span`
  position: absolute;
  top: 2px;
  left: ${(props:StylesProps) => (props.change ? "1.18rem" : "2px")};
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: ${(props:StylesProps) => (props.change ? Colors.white : Colors.blue6)};
  transition: 0.4s;
`;
