import React, { FC } from "react";
import { TagContainer } from "./styles";
import { ReactComponent as CloseIcon } from "../../../assets/icons/common/close-white.svg";

interface TagListItemProps {
  text:string;
  onClick:()=>void;
  onRemove?:()=>void;
  selected:boolean;
}

const TagListItem: FC<TagListItemProps> = ({ text, onClick, onRemove, selected }) => {
  return (
    <TagContainer hasRemoveIcon={!!onRemove} selected={selected}>
      <span onClick={onClick}>{text}</span>
      {onRemove && <CloseIcon onClick={onRemove} />}
    </TagContainer>
  );
};

export default TagListItem;
