import styled from "styled-components";
import Colors from "../../../assets/styles/colors";
import { StylesProps } from "../../../util/types";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 89vh;
  margin-top: ${(props: StylesProps) => props.marginTop && "2rem"};
  padding: 0 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    overflow-x: hidden;
    padding: 0;
  }
`;

const Title = styled.p`
  width: auto;
  font-size: 1.1rem;
  font-weight: 600;
  padding-left: 2rem;
  color: ${Colors.blue6};
`;
export { Content, Title };
