/* eslint-disable array-callback-return */
import axios from "axios";
import { subMonths, format, endOfMonth, startOfMonth } from "date-fns";
import { uniqBy, compact } from "lodash";

import {
  setLoading,
  saveOpportunities,
  setGraphTimeline,
  setCurrencyType,
  setNotfound,
  setSearchItems,
  setDataSetRange,
  setSearchTags,
  setMatchOpportunities,
  cleanOpportunitiesModule,
  setError,
} from "../modules/opportunities-module";

import {
  toggleSearchModal,
  saveFilterItems,
  toggleDropdownFilter,
  cleanSelectedFilters,
  cleanFilterModule,
} from "../modules/filter-module";

let ENV_SUFFIX_PERFORMANCE =
  process.env.NODE_ENV === "production"
    ? "/desempenho/odata"
    : "/desempenho/odata";
let ENV_SUFFIX_MATCH =
  process.env.NODE_ENV === "production"
    ? "/match-relevancia"
    : "/match-relevancia";

const checkLastOpportunity = (idToken: any, document: any) => {
  const headers: any = {
    Authorization: `Bearer ${idToken}`,
    "Content-Type": "application/json",
  };

  if (document) {
    headers["x-petronect-documents"] = document;
  }
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_API}${ENV_SUFFIX_PERFORMANCE}/Items?$orderby=openTime desc&$select=openTime&$top=1`,
    headers: headers,
  });
};

const getOpportunities = (
  currencyType: any,
  openTime: any,
  closeTime: any,
  filters: any,
  idToken: any,
  document: any
) => {
  const headers: any = {
    Authorization: `Bearer ${idToken}`,
    "Content-Type": "application/json",
  };

  if (document) {
    headers["x-petronect-documents"] = document;
  }
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_API}${ENV_SUFFIX_PERFORMANCE}/Dashboard?$filter=openTime ge '${openTime}' and openTime le '${closeTime}' and currency eq '${currencyType}'${filters}&$top=1000`,
    headers: headers,
  });
};

const getOpportunitiesItems = (query: any, idToken: any, document: any) => {
  const headers: any = {
    Authorization: `Bearer ${idToken}`,
    "Content-Type": "application/json",
  };

  if (document) {
    headers["x-petronect-documents"] = document;
  }
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_API}${ENV_SUFFIX_PERFORMANCE}/Items?$filter=${query}`,
    headers: headers,
  });
};

const getMatchOpportunities = (query: any, isExactTerm: any, idToken: any, document?: any) => {
  const headers: any = {
    Authorization: `Bearer ${idToken}`,
    "Content-Type": "application/json",
  };

  if (document) {
    headers["x-petronect-documents"] = document;
  }
  // console.log(
  //   "getMatchOpportunities: ",
  //   `${process.env.REACT_APP_API}${ENV_SUFFIX_MATCH}/biddings?query=${query}&isExactTerm=${isExactTerm}&onlyNew=true`
  // );
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_API}${ENV_SUFFIX_MATCH}/biddings?query=${query}&isExactTerm=${isExactTerm}&onlyNew=true`,
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
};

const handleExpiredToken = (err: any, dispatch: any) => {
  if (err.response.status === 401) {
    dispatch(cleanOpportunitiesModule());
    dispatch(cleanFilterModule());

    window.localStorage.removeItem("petronect_credentials");

    // this.setState({
    //   isAuthenticated: false,
    //   validated: true,
    // });

    window.location.reload();
  }
};

export const saveOpportunitiesThunk = (document: any, filter?: any, isLastYear?: any) => async (
  dispatch: any,
  getState: any
) => {
  try {
    const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
    );

    const { currentCurrency, timeline } = getState().opportunities;
    const { userFilters, selectedFilters } = getState().filter;

    dispatch(
      saveOpportunities({
        summary: {},
        opportunities: [],
      })
    );

    const lastOpportunity = await checkLastOpportunity(idToken, document);
    const currentYear = new Date(
      lastOpportunity.data.value[0].openTime
    ).getFullYear();
    const pastYear =
      new Date(lastOpportunity.data.value[0].openTime).getFullYear() - 1;

    let lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    const lastDate = isLastYear
      ? `${pastYear}-12-31`
      : format(endOfMonth(new Date(lastMonth)), "yyyy-MM-dd");

    const startOfLastMonth = new Date(startOfMonth(lastMonth));
    startOfLastMonth.setDate(startOfLastMonth.getDate() + 1);
    const openDate = isLastYear
      ? `${pastYear}-01-01`
      : format(subMonths(startOfLastMonth, timeline.label - 1), "yyyy-MM-dd");

    const checkedFilters = userFilters
      .filter((item: any) => item.isChecked)
      .map((item: any) => item.query.split(","))
      .flat();

    let query =
      checkedFilters.length === 0
        ? ""
        : ` and opportunityId in (${checkedFilters
          .map((item: any) => `"${item}"`)
          .join(",")})`;

    if (!query) {
      query =
        selectedFilters.length === 0
          ? ""
          : ` and opportunityId in (${selectedFilters
            .map((item: any) => `"${item}"`)
            .join(",")})`;
    }

    dispatch(setNotfound(false));
    dispatch(setLoading(true));

    const response = await getOpportunities(
      currentCurrency.type,
      openDate,
      lastDate,
      filter || query,
      idToken,
      document
    );

    const summary = {
      ...response.data.value[0],
    };

    delete summary.Opportunities;

    dispatch(
      setDataSetRange({
        date: lastDate,
        firstDate: openDate,
        rangeStart: format(
          subMonths(new Date(lastDate), timeline.label),
          "yyyy-MM-dd"
        ),
        // rangeStart: isLastYear
        //   ? `${pastYear}-01-01`
        //   : format(subMonths(new Date(lastDate), 12), "yyyy-MM-dd"),
        rangeEnd: isLastYear
          ? `${pastYear}-12-31`
          : format(endOfMonth(new Date(lastDate)), "yyyy-MM-dd"),
        year: isLastYear ? `${pastYear}` : `${currentYear}`,
        lastYearActive: isLastYear || false,
      })
    );
    dispatch(
      saveOpportunities({
        summary,
        opportunities: response.data.value[0].Opportunities,
      })
    );

    dispatch(setLoading(false));
  } catch (err) {
    dispatch(
      saveOpportunities({
        summary: {},
        opportunities: [],
      })
    );
    dispatch(setNotfound(true));
    dispatch(setLoading(false));
    handleExpiredToken(err, dispatch);
  }
};

export const applyFilterThunk = (document: any) => async (dispatch: any, getState: any) => {
  try {
    dispatch(
      toggleSearchModal({
        isOpen: false,
      })
    );
    dispatch(saveOpportunitiesThunk(document));
  } catch (err) { }
};

export const setLastYearThunk = (document: any, info: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    dispatch(setGraphTimeline(info));

    dispatch(saveOpportunitiesThunk(document, true));
  } catch (err) { }
};

export const setGraphTimelineThunk = (document: any, info: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    dispatch(setGraphTimeline(info));
    dispatch(
      setDataSetRange({
        lastYearActive: false,
      })
    );

    dispatch(saveOpportunitiesThunk(document, false));
  } catch (err) { }
};

export const setCurrencyTypeThunk = (document: any, info: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    dispatch(setCurrencyType(info));

    dispatch(applyFilterThunk(document));
  } catch (err) { }
};

export const getDescriptionThunk = (document: any, tags?: any) => async (dispatch: any) => {
  try {
    const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
    );
    const filters: any = [];
    const expresssions: any = [];
    const complexExpressions: any = [];

    let query = "";
    let complexQuery: any = [];
    let simpleItems;
    let multiItems;

    dispatch(saveFilterItems([]));
    dispatch(setSearchItems(true));

    if (tags.length === 0) {
      dispatch(
        toggleSearchModal({
          isOpen: false,
          tags,
        })
      );

      // clean selected filters
      dispatch(cleanSelectedFilters());

      dispatch(saveFilterItems(filters));
      dispatch(setSearchItems(false));

      return;
    }

    compact(tags)
      .filter((tag: any) => !/\s/g.test(tag))
      .map((expression: any) => {
        const terms = expression.split(" ");

        terms.map((term: any) => expresssions.push(term));
      });

    compact(tags)
      .filter((tag: any) => /\s/g.test(tag))
      .map((expression) => {
        complexExpressions.push(expression);
      });

    complexExpressions.map((term: any, index: any) => {
      const word = term.split(" ");
      let innerQuery = "";

      word.map((term: any, index: any) => {
        const isFirst = index === 0;
        const isLast = index + 1 === word.length;

        if (isFirst) {
          innerQuery += `(contains(description, '${term}') and `;
        }
        if (isLast) {
          innerQuery += `contains(description, '${term}'))`;
        }
        if (!isFirst && !isLast) {
          innerQuery += `contains(description, '${term}') and `;
        }
      });

      complexQuery.push(innerQuery);
    });

    if (expresssions.length === 1) {
      query += `contains(description, '${expresssions[0]}')`;
    } else {
      expresssions.map((term: any, index: any) => {
        const isFirst = index === 0;
        const isLast = index + 1 === expresssions.length;
        if (isFirst) {
          query += `contains(description, '${term}') or `;
        }
        if (isLast) {
          query += `contains(description, '${term}')`;
        }
        if (!isFirst && !isLast) {
          query += `contains(description, '${term}') or `;
        }
      });
    }

    if (expresssions.length > 0) {
      simpleItems = await getOpportunitiesItems(query, idToken, document);
    } else {
      simpleItems = {
        data: {
          value: [],
        },
      };
    }

    if (complexExpressions.length > 0) {
      multiItems = await getOpportunitiesItems(
        complexQuery.join(" or "),
        idToken,
        document
      );
    } else {
      multiItems = {
        data: {
          value: [],
        },
      };
    }

    const items = uniqBy(simpleItems.data.value, (o: any) => o.opportunityId).concat(
      uniqBy(multiItems.data.value, (o) => o.opportunityId)
    );
    const keys = uniqBy(items, (o) => o.description).map(
      (item) => item.description
    );

    keys.forEach((key) => {
      const matches = items.filter((item) => item.description === key);

      filters.push({
        ids: [...matches.map((match) => match.opportunityId)],
        description: key,
      });
    });

    dispatch(
      toggleSearchModal({
        isOpen: true,
        tags,
      })
    );

    // clean selected filters
    dispatch(cleanSelectedFilters());

    dispatch(saveFilterItems(filters));
    dispatch(setSearchItems(false));
  } catch (err) {
    dispatch(setSearchItems(false));
    handleExpiredToken(err, dispatch);
  }
};

export const applyUserFiltersThunk = (document: any, info: any) => async (
  dispatch: any,
  getState: any
) => {
  try {
    const { userFilters } = getState().filter;

    const selectedFilters = userFilters
      .filter((item: any) => item.isChecked)
      .map((item: any) => item.query.split(","))
      .flat();

    const query =
      selectedFilters.length === 0
        ? ""
        : ` and opportunityId in (${selectedFilters
          .map((item: any) => `"${item}"`)
          .join(",")})`;

    dispatch(setSearchTags([]));
    dispatch(saveOpportunitiesThunk(document, query));

    dispatch(
      toggleDropdownFilter({
        isOpen: false,
      })
    );
  } catch (err) { }
};

export const getMatchOpportunitiesThunk = (info: any, isExactTerm?: any) => async (
  dispatch: any,
  getState: any
) => {
  try {
    const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
    );
    // const { apiOpportunities } = getState().opportunities;
    const query = info;
    dispatch(setLoading(true));
    const opportunities = await getMatchOpportunities(query, isExactTerm, idToken);
    // const data = apiOpportunities.concat(opportunities.data.data); //this kept adding more duplicated itens if filters are toggled and didnt SET[]
    dispatch(setMatchOpportunities(opportunities.data.data));
    dispatch(setLoading(false));
  } catch (err) {
    //@TO-DO - ao remover um tag do meus filtros ele cai aqui, avaliar o por que
    dispatch(setLoading(false));
    dispatch(setMatchOpportunities([]));
    dispatch(setError("Nenhuma oportunidade foi encontrada"));
  }
};

export const clearMatchOpportunitiesThunk = () => async (dispatch: any) => {
  dispatch(setLoading(true));
  dispatch(setMatchOpportunities([]));
  dispatch(setLoading(false));
};
