import styled from "styled-components";
import Colors from "../../../assets/styles/colors";

interface TagContainerProps {
  selected:boolean;
  hasRemoveIcon:any;
}

export const TagContainer = styled.div`
  padding: 5px 0.5rem;
  margin: 0.2rem 0.25rem;
  border-radius: 5rem;
  cursor: pointer;
  background-color: ${(props:TagContainerProps) => (props.selected ? Colors.blue7 : `${Colors.blue7}55`)};
  color: ${(props:TagContainerProps) => (props.selected ? Colors.white : Colors.black)};
  span {
    margin-right: ${(props:TagContainerProps) => (props.hasRemoveIcon ? 10 : 0)}px;
  }
  svg,
  img {
    z-index: 5;
    width: 0.75rem;
    padding-bottom: 5px;
    path {
      fill: ${(props:TagContainerProps ) => (props.selected ? Colors.white : Colors.black)};
    }
  }
`;
