import React, { FC, useEffect, useState } from "react";
import {
  Container,
  NavBar,
  BoxLogo,
  ImgLogo,
  HeaderMobile,
  Username,
  WrapperTablet,
  ImageMenuTablet,
  MenuTabletText,
  WrapperLogoMobile,
  LogoMobile,
  BoxMenu,
  MenuList,
  WrapperInfo,
  Contentinfo,
  TextVersionNumber,
  BoxInfo,
  Representative,
  Button,
  Overlay,
  UserInfo,
  WelcomeText,
  CurrentProfile,
  ProfilesList,
  ProfilesListItem,
  RadioProfilesListItem,
} from "./styles";
import { connect } from "react-redux";
import LogoImg from "../../../assets/img/logos/LogoPNE.png";
import PerformanceIcon from "../../../assets/icons/common/icon_menu-Relevancia-blue.svg";
import Star from "../../../assets/icons/common/estrela-blue.svg";
import DollarIcon from "../../../assets/icons/common/dollar.svg";
import Download from "../../../assets/icons/common/download-blue.svg";
import easyProposalImg from "../../../assets/icons/common/easy-proposal-icon.svg";
import integrationImg from "../../../assets/icons/common/integration-menu-icon.svg";
import faqImg from "../../../assets/icons/common/faq.svg";
import MarketplaceIcon from "../../../assets/icons/common/marketplace.svg";
import DropdownOption from "../DropdownOption/DropdownOption";
import {
  IoMdRadioButtonOff,
  IoMdRadioButtonOn,
  IoIosArrowDown,
  IoIosArrowUp,
} from "react-icons/io";
import {
  cleanOpportunitiesModule,
  updateCurrentScreen,
  setMatchOpportunities,
} from "../../../dataflow/modules/opportunities-module";
import { saveModal } from "../../../dataflow/modules/modal-module";
import { cleanFilterModule } from "../../../dataflow/modules/filter-module";
import {
  clearCurrentIntegrationProfile,
  setCurrentIntegrationProfile,
} from "../../../dataflow/thunks/integration-profiles";
import { checkUploadPermission, decodedToken, isDesktop } from "../../../util/devUtilities";
import { eventCategory, logEvent } from "../../../util/analytics/index";
import suggestion from "../../../assets/icons/common/suggestion.svg";
import { verifyAuthorizationMarketplace } from "../../../dataflow/thunks/marketplace";

const appVersion = (window as any).appVersion;

interface SideBarProps {
  cleanOpportunitiesModule: () => void;
  cleanFilterModule: () => void;
  clearCurrentIntegrationProfile: () => void;
  history: any;
  updateCurrentScreen: (v: any) => void;
  setCurrentProfile: (v: any, v2: any) => void;
  integrations: any;
}

const SideBar: FC<SideBarProps> = ({
  cleanOpportunitiesModule,
  cleanFilterModule,
  clearCurrentIntegrationProfile,
  history,
  updateCurrentScreen,
  setCurrentProfile,
  integrations
}) => {
  const handleValidateUrl = (screen: any) => {
    return window.location.href.includes(screen);
  };

  const [state, setState] = useState<any>({
    selectedScreen: {
      "match-relevance": handleValidateUrl("match-relevancia"),
      performance: handleValidateUrl("meu-desempenho"),
      "easy-proposal": handleValidateUrl("match-analitico"),
      "access-fee": handleValidateUrl("access-fee"),
      "family-suggestion": handleValidateUrl("family-suggestion"),
      integrations: handleValidateUrl("integracoes"),
      faq: handleValidateUrl("faq"),
      "upload-membership": handleValidateUrl("upload-membership-list"),
      "upload-family": handleValidateUrl("upload-family-list"),
    },
    sidebarList: [
      {
        disable: true,
        iconSelected: DollarIcon,
        text: "Painel de Preços",
        route: "match-relevancia",
      },
    ],
    isOpen: false,
    isMatchFilter: false,
    isPerformanceFilter: false,
    isHovering: "",
    authInfo: undefined,
    currentProfileIndex: localStorage.getItem("current-profile") || "0",
    showProfilesList: false,
  })

  const handleLogout = () => {
    cleanOpportunitiesModule();
    cleanFilterModule();
    clearCurrentIntegrationProfile();

    window.localStorage.removeItem("petronect_credentials");

    setState({
      ...state,
      isAuthenticated: false,
      validated: true,
    });
    history.replace("/login");
  };

  const getUser = () => {
    // const info = window.localStorage.getItem("petronect_credentials");
    // if (info) {
    //   return jwt_decode(JSON.parse(info).idToken).name;
    // }
    // return null;
    return decodedToken('idToken').name
  };

  // const getUserData = () => {
  //   // const info = window.localStorage.getItem("petronect_credentials");
  //   // if (info) {
  //   //   return jwt_decode(JSON.parse(info).idToken);
  //   // }
  //   // return null;
  //   return decodedToken()
  // };

  const handleMenuOpen = () => {
    if (!isDesktop()) {
      setState((prevState: any) => ({
        ...state,
        isOpen: !prevState.isOpen,
      }));
    }
  };

  // const handleMatchDropdown = () => {
  //   setState({
  //     isMatchFilter: !state.isMatchFilter,
  //     isPerformanceFilter: false,
  //   });
  //   handleScreen("match-relevance");
  // };

  // const handlePerformanceDropdown = () => {
  //   setState({
  //     isPerformanceFilter: !state.isPerformanceFilter,
  //     isMatchFilter: false,
  //   });
  //   handleScreen("performance");
  // };

  // const handleScreen = (screen:any) => {
  //   updateCurrentScreen(screen);
  // };

  const handleHover = (item: any) => {
    setState({
      ...state,
      isHovering: item,
    });
  };

  const handlenoHover = () => {
    setState({
      ...state,
      isHovering: "",
    });
  };

  // const handleFilters = () => {
  //   props.setMatchOpportunities([]);
  //   handleScreen("match-filter");
  // };

  // const handleModalSupport = (ev) => {
  //   props.saveModal({
  //     isOpen: true,
  //     type: "support",
  //   });
  // };

  const handleIntegrationProfileClick = (profile: any, index: any) => {
    setState({
      ...state,
      currentProfileIndex: String(index),
      showProfilesList: !state.showProfilesList,
    });
    setCurrentProfile(profile, index);
  };

  const handleSelectedScreen = () => {
    setState({
      ...state,
      selectedScreen: {
        "match-relevance": handleValidateUrl("match-relevancia"),
        performance: handleValidateUrl("meu-desempenho"),
        "easy-proposal": handleValidateUrl("match-analitico"),
        "access-fee": handleValidateUrl("access-fee"),
        "family-suggestion": handleValidateUrl("family-suggestion"),
        integrations: handleValidateUrl("integracoes"),
        faq: handleValidateUrl("faq"),
        "upload-membership": handleValidateUrl("upload-membership-list"),
        "upload-family": handleValidateUrl("upload-family-list"),

      },
    });
  };

  const renderCurrentProfile = () => {
    const integrationProfilesQtd = integrations.integrationProfiles
      ? integrations.integrationProfiles.length
      : 0;

    return (
      <CurrentProfile
        style={{ cursor: integrationProfilesQtd > 1 ? "pointer" : "inherit" }}
        onClick={() => {
          if (integrationProfilesQtd > 1) {
            setState({
              ...state,
              showProfilesList: !state.showProfilesList,
            });
          }
        }}
      >
        {integrations.currentProfile.document.number.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        )}
        {integrationProfilesQtd > 1 && (
          <span style={{ position: "relative", top: 2, right: -2 }}>
            {state.showProfilesList ? (
              <IoIosArrowUp />
            ) : (
              <IoIosArrowDown />
            )}
          </span>
        )}
      </CurrentProfile>
    );
  };

  const renderIntegrationProfileList = () => {
    return (
      <ProfilesList>
        {integrations.integrationProfiles.map((item: any, index: any) => {
          return (
            <>
              <ProfilesListItem
                key={index}
                selected={state.currentProfileIndex === String(index)}
              >
                <RadioProfilesListItem
                  key={index + 1}
                  onClick={() => {
                    logEvent(
                      eventCategory.checkboxChange,
                      "click trocar CNPJ sidebar",
                      `${item.document.number.replace(
                        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                        "$1.$2.$3/$4-$5"
                      )}`
                    );
                    handleIntegrationProfileClick(item, index);
                  }}
                >
                  {state.currentProfileIndex === String(index) ? (
                    <IoMdRadioButtonOn />
                  ) : (
                    <IoMdRadioButtonOff />
                  )}
                  <span>
                    {" "}
                    {item.document.number.replace(
                      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                      "$1.$2.$3/$4-$5"
                    )}
                  </span>
                </RadioProfilesListItem>
              </ProfilesListItem>
            </>
          );
        })}
      </ProfilesList>
    );
  };

  // const renderDisableOptions = () => {
  //   const { sidebarList } = state;

  //   return sidebarList.map((option) => (
  //     <MenuItem
  //       key={option.text}
  //       disable={option.disable}
  //       onClick={() => setState({ isOpen: false })}
  //       to={`/${option.route}`}
  //     >
  //       <IconSideBar src={option.iconSelected} />
  //       {option.text}
  //     </MenuItem>
  //   ));
  // };

  const [showMarketplace, setShowMarketplace] = useState(false)
  const [integrationProfiles, setIntegrationProfiles] = useState([]);
  
  const hasAuthorizationToAccessPage = (cnpj: string) => {
    verifyAuthorizationMarketplace({ cnpj })
      .then(({ data }) => { 
        setShowMarketplace(data?.habilitado === 1 ? true : false);
      })
      .catch(err => console.error(err));
  }

  useEffect(() => {
    hasAuthorizationToAccessPage(integrations?.currentProfile?.document?.number);
  }, [integrations?.currentProfile?.document?.number])

  const { isOpen, isHovering } = state;

  // const userData = getUserData();

  return (
    <>
      <Overlay isOpen={isOpen} onClick={handleMenuOpen} />
      <HeaderMobile>
        <WrapperTablet isOpen={isOpen} onClick={handleMenuOpen}>
          <ImageMenuTablet isOpen={isOpen} />
          <MenuTabletText isOpen={isOpen}>MENU</MenuTabletText>
        </WrapperTablet>
        <WrapperLogoMobile>
          <LogoMobile
          // tablet={logoMobile} 
          // phone={LogoImg} 
          />
        </WrapperLogoMobile>
        <Username>{getUser()}</Username>
      </HeaderMobile>
      <Container isOpen={isOpen}>
        <NavBar>
          <BoxLogo isOpen={isOpen}>
            <ImgLogo src={LogoImg} alt="Logo" />
          </BoxLogo>
          <UserInfo>
            <WelcomeText>
              Bem vindo(a), <b>{getUser()}</b>
            </WelcomeText>
            {integrations.currentProfile && renderCurrentProfile()}
            {integrations && state.showProfilesList && (
              <div>{renderIntegrationProfileList()}</div>
            )}
          </UserInfo>
          <BoxMenu>
            <MenuList>
              <DropdownOption
                icon={Star}
                title={"Match Relevância"}
                isOpen={handleValidateUrl("match-relevancia")}
                isHovering={isHovering}
                handleDropdown={() => {
                  handleSelectedScreen();
                  handleMenuOpen();
                }}
                handleHover={() => handleHover("Match Relevância")}
                handlenoHover={handlenoHover}
                screen={"/match-relevancia"}
                // isModalSupport={state.isModalSupport}
                isActive={true}
              />
              <DropdownOption
                icon={PerformanceIcon}
                title={"Meu Desempenho"}
                isOpen={handleValidateUrl("meu-desempenho")}
                isHovering={isHovering}
                handleDropdown={() => {
                  handleSelectedScreen();
                  handleMenuOpen();
                }}
                handleHover={() => handleHover("Meu Desempenho")}
                handlenoHover={handlenoHover}
                screen={"/meu-desempenho"}
                commingSoon={false}
                feature={"meu-desempenho"}
                isActive={true}
                />
              <DropdownOption
                icon={easyProposalImg}
                title={"Match Analítico"}
                isOpen={handleValidateUrl("match-analitico")}
                isHovering={isHovering}
                handleDropdown={() => {
                  handleSelectedScreen();
                  handleMenuOpen();
                }}
                handleHover={() => handleHover("Match Analítico")}
                handlenoHover={handlenoHover}
                screen={"/match-analitico"}
                commingSoon={false}
                feature={"match-analitico"}
                isActive={true}
  
//            {
//                showMarketplace && (
//                  <DropdownOption
//                    icon={MarketplaceIcon}
//                    title={"Marketplace"}
//                    isOpen={handleValidateUrl("marketplace")}
//                    isHovering={isHovering}
//                    handleDropdown={() => {
//                      handleSelectedScreen();
//                      handleMenuOpen();
//                    }}
//                    handleHover={() => handleHover("Marketplace")}
//                    handlenoHover={handlenoHover}
//                    screen={"/marketplace"}
//                    commingSoon={false}
//                    feature={"marketplace"}
//                    isActive={true}
//                  />
//                )
//              }
              />
              <DropdownOption
                icon={DollarIcon}
                title={"Taxa de Acesso"}
                isOpen={handleValidateUrl("access-fee")}
                isHovering={isHovering}
                handleDropdown={() => {
                  handleSelectedScreen();
                  handleMenuOpen();
                }}
                handleHover={() => handleHover("Taxa de Acesso")}
                handlenoHover={handlenoHover}
                screen={"/access-fee"}
                commingSoon={false}
                feature={"access-fee"}
                isActive={true}
              />
              <DropdownOption
                icon={suggestion}
                title={"Sugestão de Famílias"}
                isOpen={handleValidateUrl("family-suggestion")}
                isHovering={isHovering}
                handleDropdown={() => {
                  handleSelectedScreen();
                  handleMenuOpen();
                }}
                handleHover={() => handleHover("Sugestão de Famílias")}
                handlenoHover={handlenoHover}
                screen={"/family-suggestion"}
                commingSoon={false}
                feature={"family-suggestion"}
                isActive={true}
              />
              <DropdownOption
                icon={integrationImg}
                title={"Integrações"}
                isOpen={handleValidateUrl("integracoes")}
                isHovering={isHovering}
                handleDropdown={() => {
                  handleSelectedScreen();
                  handleMenuOpen();
                }}
                handleHover={() => handleHover("Integrações")}
                handlenoHover={handlenoHover}
                screen={"/integracoes"}
                feature={"integracoes"}
                commingSoon={false}
                isActive={true}
              />
              <DropdownOption
                icon={faqImg}
                title={"Dúvidas"}
                isOpen={handleValidateUrl("faq")}
                isHovering={isHovering}
                handleDropdown={() => {
                  handleSelectedScreen();
                  handleMenuOpen();
                }}
                handleHover={() => handleHover("Dúvidas")}
                handlenoHover={handlenoHover}
                screen={"/private-faq"}
                feature={"faq"}
                isActive={true}
              />
              {checkUploadPermission() && (
                <DropdownOption
                  icon={Download}
                  title={"Upload associados"}
                  isOpen={handleValidateUrl("upload-membership-list")}
                  isHovering={isHovering}
                  handleDropdown={() => {
                    handleSelectedScreen();
                    handleMenuOpen();
                  }}
                  handleHover={() => handleHover("Upload associados")}
                  handlenoHover={handlenoHover}
                  screen={"/upload-membership-list"}
                  feature={"upload-membership-list"}
                  isActive={true}
                />
              )}
              {checkUploadPermission() && (
                <DropdownOption
                  icon={Download}
                  title={"Upload Familias"}
                  isOpen={handleValidateUrl("upload-family-list")}
                  isHovering={isHovering}
                  handleDropdown={() => {
                    handleSelectedScreen();
                    handleMenuOpen();
                  }}
                  handleHover={() => handleHover("Upload Familias")}
                  handlenoHover={handlenoHover}
                  screen={"/upload-family-list"}
                  feature={"upload-family-list"}
                  isActive={true}
                />
              )}
            </MenuList>
          </BoxMenu>
        </NavBar>
        <WrapperInfo>
          <Contentinfo>
            <BoxInfo>
              <Representative>{getUser()}</Representative>
            </BoxInfo>
            <span>
              <Button onClick={handleLogout}>Sair</Button>
            </span>
          </Contentinfo>
          <TextVersionNumber>{appVersion.number}</TextVersionNumber>
        </WrapperInfo>
      </Container>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  integrations: state.integrationProfiles,
});

const mapDispatchToProps = (dispatch: any) => ({
  setCurrentProfile: (profile: any, index: any) =>
    dispatch(setCurrentIntegrationProfile(profile, index)),
  cleanOpportunitiesModule: () => dispatch(cleanOpportunitiesModule()),
  cleanFilterModule: () => dispatch(cleanFilterModule()),
  updateCurrentScreen: (info: any) => dispatch(updateCurrentScreen(info)),
  setMatchOpportunities: (info: any) => dispatch(setMatchOpportunities(info)),
  saveModal: (info: any) => dispatch(saveModal(info)),
  clearCurrentIntegrationProfile: () =>
    dispatch(clearCurrentIntegrationProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
