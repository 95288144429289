import React, { FC, ReactNode } from "react";
import { Cell } from "./styles";

export interface ItemProps {
  bold?: boolean;
  background?: string;
  handleItem?: () => void;
  displayMobile?: string;
  children?: ReactNode;
}

const Item: FC<ItemProps> = ({ bold, background, handleItem, displayMobile, children }) => {
  return (
    <Cell
      bold={bold}
      background={background}
      onClick={handleItem}
      displayMobile={displayMobile}
    >
      {children}
    </Cell>
  );
};

export default Item;
