import styled from "styled-components";
import Colors from "../../../../assets/styles/colors";
import { StylesProps } from "../../../../util/types";

const ActionButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 999;
  @media only screen and (min-width: 700px) {
    position: absolute;
    right: 70px;
    bottom: 15px;
  }
`;
const ActionButtonStyle = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: ${Colors.blue8};
  border: none;
  box-shadow: 0px 2px 6px 1px ${Colors.black}99;
  cursor: pointer;
`;
const ButtonImg = styled.img`
  width: 30px;
  height: auto;
`;
const ActionButtonStyleDark = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: ${Colors.white};
  border: none;
  box-shadow: 0px 2px 6px 1px ${Colors.black}99;
  cursor: pointer;
  :focus {
    outline: ${(props:StylesProps) => (props.layout === "dark" ? "" : "none")};
  }
  :active {
    ${(props:StylesProps) => (props.layout === "dark" ? "" : "opacity: 0.5")};
  }
`;
const ButtonTitle = styled.p`
  margin-top: 5px;
  color: ${(props:StylesProps) => props.color};
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
`;

export { ActionButtonContainer,ActionButtonStyle, ButtonTitle, ButtonImg, ActionButtonStyleDark };
