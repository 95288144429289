import styled, { css } from "styled-components";
import { IndexProps } from ".";
import Colors from "../../../assets/styles/colors";

const Container = styled.div`
  display: flex;
  max-height: auto;
  width: 100%;

  .scrollarea {
    ${({ full }:{full:any}) =>
      full &&
      css`
        max-height: 100%;
        width: 100%;
      `};

    max-height: ${(props:IndexProps) => props.maxHeight && props.maxHeight};
  }

  .scrollarea .scrollbar-container {
    opacity: 0.12;
  }

  .scrollarea:hover .scrollbar-container {
    opacity: 0.22;
  }

  .scrollarea .scrollbar-container.active,
  .scrollarea .scrollbar-container:hover {
    opacity: 0.4 !important;
  }

  .scrollarea .scrollbar-container.vertical {
    width: ${(props:IndexProps) => (props.scrollWidth ? props.scrollWidth : "8px")};
    height: 100%;
    right: 2px;
    top: 0;
    border-radius: 6px;
    z-index: 3;
    background: ${Colors.gray1};
  }

  .scrollarea .scrollbar-container.vertical .scrollbar {
    width: ${(props:IndexProps) => (props.scrollWidth ? props.scrollWidth : "8px")};
    height: 20px;
    background: var(--strytegy-second-text);
    margin-left: 0;
    border-radius: 6px;
  }
`;

export {Container}