import React, { FC, useRef, useState } from "react";
import styled from "styled-components";
import { Form } from "@unform/web";
import Index from "../../../components/common-components/Button";
import Logo from "../../../components/layout-components/Logo/Logo";
import { confirmPassword } from "../Cognito";
import UnformInput from "../../../components/common-components/UnformInputs/UnformInput";
import Colors from "../../../assets/styles/colors";
import hidePasswordIcon from "../../../assets/icons/common/login-hide-password.svg";
import showPasswordIcon from "../../../assets/icons/common/login-show-password.svg";
import { toast } from "react-toastify";

interface StylesProps {
  width: any;
  last?: any;
}

const CreateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

const CreateBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  padding: 2.5rem 3rem;
  background: ${Colors.white};
  box-shadow: 0px 1px 2px ${Colors.black}1a;
  border-radius: 4px;
  transition: width 1s;
  @media (max-width: 1440px) {
    width: 50%;
  }
  @media (max-width: 960px) {
    margin: 1rem 0;
    width: 90%;
  }
  @media (max-width: 648px) {
    padding: 2rem;
    width: 100%;
  }
`;

const CreateTitle = styled.h1`
  align-self: flex-start;
  margin: 0 1rem 1rem 0;
  font: 700 1.5rem "Open Sans", sans-serif;
  letter-spacing: 0;
  color: ${Colors.blue6};
  @media (max-width: 960px) {
    font-size: 1.3rem;
  }
`;

const InstructionText = styled.p`
  ${""}
  margin: 1.25rem 0;
  font: 400 1rem "Open Sans", sans-serif;
  letter-spacing: 0;
  color: ${Colors.gray8};
  @media (max-width: 960px) {
    width: 100%;
  }
  @media (max-width: 648px) {
    margin-bottom: 1rem;
  }
`;

const SpamText = styled.p`
  ${""}
  margin: 1.25rem 0;
  font: 400 1rem "Open Sans", sans-serif;
  letter-spacing: 0;
  color: ${Colors.red};
  @media (max-width: 960px) {
    width: 100%;
  }
  @media (max-width: 648px) {
    margin-bottom: 1rem;
  }
`;

const InputBox = styled.span`
  position: relative;
  width: ${(props: StylesProps) => props.width};
  margin-top: ${(props: StylesProps) => props.last && ".5rem"};

  @media (max-width: 960px) {
    width: 100%;
  }
`;

const IconInputPassword = styled.img`
  position: absolute;
  bottom: 2%;
  right: 0;
  width: 1.25rem;
  margin-right: 1rem;
  cursor: pointer;
`;

const LoginMessageError = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
`;

const TextError = styled.p`
  font: 500 0.8rem "Eurostile", "Open Sans", sans serif;
  color: ${Colors.red2};
  @media (max-width: 960px) {
    margin: 0.1rem 0;
  }
`;

interface RecoverPasswordProps {
  resetEmail: any;
  setCurrentScreen: (v: string) => void;
  error?: any;
}

const RecoverPassword: FC<RecoverPasswordProps> = ({
  resetEmail,
  setCurrentScreen,
  error
}) => {
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [_error, set_Error] = useState<any>();
  const [isToastShowed, setisToastShowed] = useState(false);

  const buildToast = (phrase: any) => {
    toast.success(phrase, {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { background: `${Colors.green1}`, textAlign: 'center', fontSize: '20px' }
    });
    setisToastShowed(true);
    setCurrentScreen("login");
  };

  const handleResetPassword = async (data: any) => {
    const confirmationCode = data.confirmationCode.trim();
    const newPassword = data.newPassword.trim();

    try {
      await confirmPassword({
        username: resetEmail,
        confirmationCode,
        newPassword,
      });

      if (!isToastShowed) {
        buildToast(
          `Senha alterada com sucesso.`
        );
      }

    } catch (err: any) {
      if (err.response.data.__type === "LimitExceededException") {
        set_Error(
          "Você excedeu o número de tentativas. Por favor, tente novamente mais tarde."
        );
        setIsLoading(false);
      }
      if (err.response.data.__type === "InvalidParameterException") {
        set_Error("Usuário não confirmado");
        setIsLoading(false);
      }
      if (err.response.data.__type === "UserNotFoundException") {
        set_Error("Usuário não encontrado");
        setIsLoading(false);
      }
    }
  };

  const renderError = () => {
    if (_error && !isLoading) {
      return <TextError>{_error}</TextError>;
    }

    return null;
  };

  return (
    <CreateContainer>
      <CreateBox>
        <Logo width="20%" minWidth="220px" widthMobile="45%" />
        <Form  onSubmit={handleResetPassword} ref={formRef} placeholder="Insira o código">
          <CreateTitle>Resetar senha</CreateTitle>
          <InstructionText>
            Um <strong>código</strong> de confirmaçao foi{" "}
            <strong>enviado ao seu email</strong>, por favor digite-o abaixo:
          </InstructionText>
          <SpamText>
            Caso não encontre o e-mail na sua caixa de entrada verifique a sua
            caixa de SPAM.
          </SpamText>
          <InputBox width="100%">
            <UnformInput
              name={"confirmationCode"}
              required
              autoFocus
              placeholder={"Insira o código"}
              error={error || _error}
              labelText="Código de confirmação"
              autoComplete="none"
            />
          </InputBox>
          <InputBox last width="100%">
            <UnformInput
              name={"newPassword"}
              type={showPassword ? "password" : "text"}
              required
              placeholder={"Insira sua nova senha"}
              error={error || _error}
              labelText="Nova senha"
              autoComplete="none"
            />
            <IconInputPassword
              // loginScreen
              src={showPasswordIcon || hidePasswordIcon}
              onClick={() => setShowPassword((state) => !state)}
            />
          </InputBox>

          <LoginMessageError>{renderError()}</LoginMessageError>
          <Index
            margin="2.5rem 0 0 0"
            width="100%"
            height="3.5rem"
            color={Colors.white}
            background={Colors.blue8}
          >
            Enviar
          </Index>
        </Form>
        <Index
          margin="1rem 0 0 0"
          marginMobile=".5rem 0 0 0"
          width="100%"
          height="3rem"
          color={Colors.gray8}
          background={Colors.white}
          onClick={() => setCurrentScreen("login")}
          textDecoration="underline"
        >
          Voltar ao login
        </Index>
      </CreateBox>
    </CreateContainer>
  );
};

export default RecoverPassword;
