import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import ListComponent from "../ListItem/ListItem";
import Item from "../ListItem/Item";
import Colors from "../../../assets/styles/colors";
import { Status } from "..";
import { checkDeviceMobile, dateDefault, maskCNPJ } from "../../../util/devUtilities";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 0 1rem 3rem;
  @media (max-width: 768px) {
    flex-direction: column;
    overflow-x: hidden;
    padding: 0;
  }
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  height: 34vh;
  font: 400 1.35rem Eurostile;
  color: ${Colors.blue6};

  @media (max-width: 768px) {
    text-align: center;
    font-size: 1.1rem;
  }
`;

export interface AccessFeeItem {
    cnpj: string;
    razao_social: string;
    data_vencimento: string;
    status: string;
}

interface ListStatusMembersProps {
    list: AccessFeeItem[];
    listCNPJ: string[];
}

const ListStatusMembers: FC<ListStatusMembersProps> = ({ list, listCNPJ }) => {
    const [listMembers, setListMembers] = useState(list)
    const orderedList = list.sort((a: any, b: any) => {
        return Number(b?.data_vencimento?.replace(/-/g, "")?.slice(0, 8)) - Number(a?.data_vencimento?.replace(/-/g, "")?.slice(0, 8))
    })
    const isMobile: boolean = checkDeviceMobile();

    useEffect(() => {
        recentFilter()
    }, [])

    const renderListHeader = () => (
        <ListComponent>
            <Item bold>CNPJ</Item>
            {!isMobile && <Item bold>Razão Social</Item>}
            <Item bold>Data de Vencimento</Item>
            <Item bold>Status</Item>
        </ListComponent>
    );

    const recentFilter = () => {
        let auxListMembers: any[] = []
        let auxListNoMembers: AccessFeeItem[] = []
        for (let i = 0; i < listCNPJ.length; i++) {
            const firstElement = orderedList.find(e => e.cnpj === listCNPJ[i])
            if (firstElement && (firstElement.status !== Status.NAO_SOCIO && firstElement.status !== Status.INATIVO)) auxListMembers.push(firstElement)
            if (firstElement && (firstElement.status === Status.NAO_SOCIO || firstElement.status === Status.INATIVO)) auxListNoMembers.push(firstElement)
        }
        const orderedAuxList = auxListMembers.sort((a: any, b: any) => {
            return Number(b?.data_vencimento?.replace(/-/g, "")?.slice(0, 8)) - Number(a?.data_vencimento?.replace(/-/g, "")?.slice(0, 8))
        })
        return setListMembers(orderedAuxList.reverse())
    }

    return (
        <>
            {list?.length > 0 ?
                <Content>
                    {renderListHeader()}
                    {listMembers.map((item: any, index: any) => {
                        return (
                            <ListComponent status={item?.status} key={`${index}-${item?.cnpj}`}>
                                <Item >{maskCNPJ(item?.cnpj)}</Item>
                                {!isMobile && <Item>{item?.razao_social}</Item>}
                                <Item>{dateDefault(item?.data_vencimento,'')}</Item>
                                <Item
                                    bold
                                    background={
                                        item?.status === Status.VENCIDO ||
                                            item?.status === Status.NAO_SOCIO ||
                                                item?.status === Status.INATIVO ?
                                            Colors.red :
                                            Colors.green4
                                    }
                                >
                                    {item?.status}
                                </Item>
                            </ListComponent>
                        );
                    })}
                </Content>
                :
                <Message>
                    Ainda não há dados disponíveis para exibir
                </Message>
            }
        </>
    )
}

export default ListStatusMembers