// Action Types
export const SAVE_MODAL = "petronect/modals/SAVE_MODAL";
export const SAVE_LOADING = "petronect/modals/SAVE_LOADING";
export const SET_SELECTED_OPPORTUNITY =
  "petronect/modals/SET_SELECTED_OPPORTUNITY";
export const MODAL_SUPPORT = "petronect/modal/MODAL_SUPPORT";

// Initial State
const initialState = {
  modal: {
    type: "",
    isOpen: false
  },
  loading: "",
  selectedOppotunity: {},
  isModalSupport: {
    isOpen: false
  }
};

// Reducer
export default function(state = initialState, action:any) {
  switch (action.type) {
    case SAVE_MODAL:
      return Object.assign({}, state, {
        modal: action.info
      });
    case SAVE_LOADING:
      return Object.assign({}, state, {
        loading: action.info
      });
    case SET_SELECTED_OPPORTUNITY:
      return Object.assign({}, state, {
        selectedOppotunity: action.info
      });
    default:
      return state;
  }
}

// Action Creators
export const saveModal = (info:any) => ({
  type: SAVE_MODAL,
  info
});

export const saveLoading = (info:any) => ({
  type: SAVE_LOADING,
  info
});

export const setSelectedOpportunity = (info:any) => ({
  type: SET_SELECTED_OPPORTUNITY,
  info
});
