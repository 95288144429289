import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Form } from "@unform/web";
// import logo from '../../../assets/img/logos/logoBranca.svg';
import loader from "../../../assets/icons/common/loading.svg";
import Button from "../../../components/common-components/Button";
import Logo from "../../../components/layout-components/Logo/Logo";
import { forgotPassword } from "../Cognito";
import UnformInput from "../../../components/common-components/UnformInputs/UnformInput";
import Colors from "../../../assets/styles/colors";
import { eventCategory, logEvent } from "../../../util/analytics/index";

interface StylesProps{
  width:string;
  last:boolean;
}

const CreateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

// const LogoCreate = styled.img`
// 	width: 220px;
// 	@media(max-width: 960px) {
// 		padding: 2rem;
// 		width: 45%;
// 	}
// `;

const CreateBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  padding: 2.5rem 3rem;
  background: ${Colors.white};
  box-shadow: 0px 1px 2px ${Colors.black}1a;
  border-radius: 4px;
  transition: width 1s;
  margin-top: -10%;
  @media (max-width: 1440px) {
    width: 50%;
  }
  @media (max-width: 960px) {
    margin: 1rem 0;
    width: 90%;
  }
  @media (max-width: 648px) {
    padding: 2rem;
    width: 100%;
  }
`;

const CreateTitle = styled.h1`
  align-self: flex-start;
  margin: 0 1rem 1rem 0;
  font: 700 1.5rem "Eurostile", "Open Sans", sans-serif;
  letter-spacing: 0;
  color: ${Colors.blue6};
  @media (max-width: 960px) {
    font-size: 1.3rem;
  }
  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;

const ButtonsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 648px) {
    flex-wrap: column;
    margin-top: 2.5rem;
  }
`;

const InputBox = styled.span`
  position: relative;
  width: ${(props:StylesProps) => props.width};
  margin-top: ${(props:StylesProps) => props.last && ".5rem"};

  @media (max-width: 960px) {
    width: 100%;
  }
`;

const RecoveryPasswordForm = styled(Form)`
  width: 95%;
  @media (max-width: 648px) {
    width: 100%;
    flex-wrap: column;
    margin-top: 2.5rem;
  }
`;

const LoginMessageError = styled.span`
  position: absolute;
  right: 0;
  bottom: -3rem;
`;

const TextError = styled.p`
  font: 500 0.8rem "Eurostile", "Open Sans", sans serif;
  color: ${Colors.red2};
  @media (max-width: 960px) {
    margin: 0.1rem 0;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3rem;
`;

const Loader = styled.img`
  width: 30px;
  height: 30px;
`;

const RecoverPassword = (props:any) => {
  const formRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  const handleResetPassword = async (data:any) => {
    setIsLoading(true);
    logEvent( 
      eventCategory.buttonClick,
      'Submeter email para recuração de senha',
      'Enviar'
    );
    try {
      const username = data.email.trim();

      await forgotPassword({
        username,
      });

      setIsLoading(false);

      props.setResetEmail(username);
      props.setCurrentScreen("password");
    } catch (err:any) {
      if (
        err.response &&
        err.response.data.__type === "LimitExceededException"
      ) {
        setError("Você excedeu o número de tentativas. Por favor, tente novamente mais tarde.");
        setIsLoading(false);
      } else if (
        err.response &&
        err.response.data.__type === "InvalidParameterException"
      ) {
        setError("Usuário não confirmado");
        setIsLoading(false);
      } else if (
        err.response &&
        err.response.data.__type === "UserNotFoundException"
      ) {
        setError("Usuário nao encontrado");
        setIsLoading(false);
      }
    }
  };

  const renderError = () => {
    if (error && !isLoading) {
      return (
        <LoginMessageError>
          <TextError>{error}</TextError>
        </LoginMessageError>
      );
    }

    return null;
  };

  const renderButton = () => {
    if (isLoading) {
      return (
        <LoaderContainer>
          <Loader src={loader} />
        </LoaderContainer>
      );
    }

    return (
      <Button
        margin="2.5rem 0 0 0"
        marginTopMobile=".5rem"
        width="100%"
        height="3.5rem"
        color={Colors.white2}
        background={Colors.blue8}
      >
        Enviar
      </Button>
    );
  };

  return (
    <CreateContainer>
      <CreateBox>
        <Logo width="220px" widthMobile="30%" />
        <RecoveryPasswordForm onSubmit={handleResetPassword} ref={formRef} placeholder="Recuperar senha">
          <CreateTitle>Recuperar Senha</CreateTitle>
          <InputBox last width="100%">
            <UnformInput
              name={"email"}
              type="email"
              required
              error={error}
              placeholder={"nome@email.com"}
              labelText="Email"
            />
            {renderError()}
          </InputBox>
          <ButtonsBox>
            {renderButton()}
            {!isLoading && (
              <Button
                margin="0.2rem 0 0 0"
                marginMobile=".5rem 0 0 0"
                width="100%"
                height="3rem"
                color={Colors.gray8}
                backgroundColor={Colors.white}
                onClick={props.handleBackLogin}
                textDecoration="underline"
              >
                Voltar ao login
              </Button>
            )}
          </ButtonsBox>
        </RecoveryPasswordForm>
      </CreateBox>
    </CreateContainer>
  );
};

export default RecoverPassword;
