import React, { FC } from "react";
import enabledStar from "../../../assets/icons/common/enabledStar.svg";
import disabledStar from "../../../assets/icons/common/disabledStar.svg";
import { RatingContainer } from "./styles"

interface RatingProposalProps {
  rating: number;
}

const RatingProposal: FC<RatingProposalProps> = ({ rating }) => {
  const buildStars = () => {
    let elements = [];

    for (var cont = 0; cont < 5; cont++) {
      if (cont < rating) {
        elements.push(<img src={enabledStar} key={cont} alt="enabled star" />);
      } else {
        elements.push(<img src={disabledStar} key={cont} alt="disabled star" />);
      }
    }
    return elements;
  }
  return <RatingContainer>{buildStars()}</RatingContainer>;
}

export default RatingProposal;
