import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../assets/styles/colors";
import PageHeaderDescription from "../../components/common-components/PageHeaderDescription/PageHeaderDescription";
// import getFeesService from "../../dataflow/thunks/access-fee-data";
import { ItemProps } from "./ListItem/Item";
import { connect } from "react-redux";
import loader from "../../assets/icons/common/loading.svg";
import axios from "axios";
import { dateDefault } from "../../util/devUtilities";
import { getIntegrationProfilesFromAPI } from "../../dataflow/thunks/integration-profiles";
import Tooltip from "../../components/common-components/Tooltip";
import { Link } from "react-router-dom";
import { StylesProps } from "../../util/types";
import { eventCategory, logEvent } from "../../util/analytics";
import HelpImg from "../../assets/icons/common/help.svg";
import NoMembers from "./NoMembers";
import ListStatusMembers from "./ListStatusMembers";

const Container = styled.div`
overflow: auto;
`

const Title = styled.h2`
  letter-spacing: 0;
  color: ${Colors.blue6};
  font: 700 1rem "Eurostile", "Open sans", sans-serif;

  @media screen and (max-width: 480px) {
    margin-bottom: 0.8rem;
  }
`;

const Span = styled.span`
  margin-bottom: 3px;
  color: ${Colors.blue6};
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: ${(props: ItemProps) => (props.bold ? "600" : "350")};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

const RowUpdate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 16px;
  padding-right: 16px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

const RowTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  @media screen and (max-width: 480px) {
    justify-content: center;
  }
`;

const Loader = styled.img`
  width: 30px;
  height: 30px;
`;

const HelpButton = styled(Link)`
  width: 20px;
  height: 20px;
  background: none;
  background-image: url(${(props: StylesProps) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  cursor: pointer;
  margin-left: 10px;
`;

export enum Status {
    VENCIDO = 'Vencido',
    ATIVO = 'Ativo',
    NAO_SOCIO = 'Inativo',
    INATIVO = 'I',
}

interface AccessFeeProps {
    integrations: any;
    getIntegrationProfiles: () => void;
}

const AccessFee: FC<AccessFeeProps> = ({ integrations, getIntegrationProfiles }) => {
    const [listAccesFee, setListAccesFee] = useState<any>([])
    const { idToken } = JSON.parse(
        window?.localStorage?.getItem("petronect_credentials") || ""
    );
    const [isLoading, setIsLoading] = useState(false);
    const [lastUpdate, setLastUpdate] = useState('');
    const [isTooltipPartner, setIsTooltipPartner] = useState(false);
    const integrationProfiles = JSON.parse(window?.localStorage?.getItem("integration-profiles") || '[]');
    const listCNPJS: string[] = [];
    integrationProfiles?.map((item: any, index: any) => {
        listCNPJS.push(item?.document?.number)
    });

    useEffect(() => {
        getIntegrationProfiles();
        const payload = { listCNPJS, method: "GET" };
        if (listCNPJS.length > 0) {
            setIsLoading(true)
            axios.post(
                `${process.env.REACT_APP_API}/export-data/list-cnpjs`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`
                    }
                }
            )
                // getFeesService(idToken, listCNPJ)
                .then((response: any) => {
                    if (response && response !== null) {
                        setListAccesFee(response?.data?.body?.listCNPJ);
                        setLastUpdate(response?.data?.body?.last_update[0]?.created_at)
                        response?.data?.body?.listCNPJ.some((item: any) => item.status !== Status.NAO_SOCIO || item.status !== Status.INATIVO) ?
                            setIsTooltipPartner(true) :
                            setIsTooltipPartner(false);
                    }

                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                })
        }
    }, [])

    return (
        <Container>
            <PageHeaderDescription
                title={"Taxa de Acesso"}
                paragraphs={[
                    "A taxa de acesso é a assinatura que permite a participação do fornecedor em oportunidades públicas do Sistema Petrobras.",
                    "Confira aqui o status de sua assinatura.",
                    "Atenção: Para participação em oportunidades de dispensa por valor não é necessário o pagamento da taxa de acesso.",
                ]}
            />
            <RowUpdate>
                {listCNPJS.length > 0 && (
                    <Span>Última atualização:{' '}
                        {isLoading && (
                            <Loader src={loader} />
                        )}
                        {!isLoading && (
                            <Span bold>{dateDefault(lastUpdate,'')}</Span>
                        )}
                    </Span>
                )}
            </RowUpdate>

            {!isLoading && (
                <NoMembers list={listAccesFee} listCNPJ={listCNPJS} />
            )}
            <Row>
                <RowTitle>
                    { listAccesFee.length > 0 ? <Title>Taxa de acesso</Title> : '' }
                    {
                        listAccesFee.some((item: any) => item.status !== Status.NAO_SOCIO || item.status !== Status.INATIVO) && (
                            <HelpButton
                                onMouseOver={() => setIsTooltipPartner(true)}
                                onClick={() => {
                                    logEvent(
                                        eventCategory.iconClick,
                                        "click HelpImg",
                                        "Icone de ajuda sócio fornecedor"
                                    );
                                    setIsTooltipPartner(value => !value)
                                }}
                                image={HelpImg}
                                to={'/access-fee'}
                            />
                        )}
                </RowTitle>
            </Row>
            <Row>
                {isTooltipPartner && (
                    <Tooltip
                        onMouseLeave={() => { setIsTooltipPartner(false) }}
                        title="Prezado Fornecedor"
                        paragraphs={[
                            "Informamos que a partir de 01.10.2023, a taxa de acesso será cobrada como mensalidade com a opção de pagamento/vigência de 1 a 6 meses.",
                        ]}
                    // link={linkTooltip()}
                        width={'100%'}
                    />
                )}
            </Row>
            {!isLoading && (
                <ListStatusMembers list={listAccesFee} listCNPJ={listCNPJS} />
            )}
        </Container>
    )
}

const mapStateToProps = (state: any) => ({
    integrations: state.integrationProfiles,
});
const mapDispatchToProps = (dispatch: any) => ({
    getIntegrationProfiles: () => dispatch(getIntegrationProfilesFromAPI()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessFee);