import styled from "styled-components";
import Colors from "../../../../assets/styles/colors";

interface StylesProps {
    flexDirection?: any;
    gapColumn?: any;
    isDisabled?: any;
}

const StatusBar = styled.div`
  width: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: ${(props) => props.color};
`;

const Card = styled.div`
    display: flex;
    flex-direction: ${(props: StylesProps) => props.flexDirection || 'row'};
    flex-wrap: nowrap;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    overflow: auto;
`;

const CardContent = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    gap: 12px;
    padding: 20px;

    @media (max-width: 640px) {
        flex-wrap: wrap;
        gap: 4px;
        overflow: auto;
    }
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props: StylesProps) => props.gapColumn || 0};
`;

const Text = styled.p`
    padding: 0;
    margin: 0;
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 12px;

    @media (max-width: 640px) {
        flex-wrap: wrap;
        gap: 4px;
    }
`;

const Button = styled.button`
    height: max-content;
    background: none;
    border: none;
    font-size: 1.1rem;
    color: ${Colors.blue2};
    
    ${(props: StylesProps) => props.isDisabled && `
        opacity: .3;
        pointer-events: none;
    `}
`;

export {
    StatusBar,
    Card,
    CardContent,
    FlexColumn,
    Text,
    ButtonGroup,
    Button
}