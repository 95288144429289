import React, { FC, Fragment, ReactNode, useEffect, useState } from "react";
import * as d3 from "d3";
import styled from "styled-components";
import BarInfo from "./BarInfo";
import Colors from "../../../assets/styles/colors";

interface StylesProps {
  width?: any;
}

const Container = styled.div`
  position: relative;
  height: 100%;
  max-width: ${(props: StylesProps) => props.width}px;
  overflow-y: hidden;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const MonthsList = styled.ul`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: ${(props: StylesProps) => props.width}px;
  height: 25px;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Month = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8.333%;
  height: 100%;
  background-color: ${Colors.white2};

  &:not(last-child) {
    border-right: 1px solid ${Colors.gray9};
  }

  @media screen and (max-width: 480px) {
    width: 33.33%;
  }
`;

const Label = styled.span`
  display: inline-block;
  margin: 0 0.2rem;
  color: ${Colors.gray8};
  font: 400 0.85rem "Open Sans", sans-serif;
`;

interface BarChartProps {
  timeline?: any;
  bubblesRange?: any;
  data?: any;
  width?: any;
  height?: any;
  monthlyTransactions?: any;
  formatValue: (v?: any) => ReactNode;
  monthlyBubblesTest?:any;
  // shortMonths?: any;
  // setOpportunityDetails?: any;
  // opportunityDetails?: any;
  // selectedShortMonths?: any;
}

const BarChart: FC<BarChartProps> = ({
  timeline,
  bubblesRange,
  data,
  width,
  height,
  monthlyTransactions,
  formatValue,
}) => {
  const [description, setDescription] = useState<any>({
    show: false,
    x: null,
    y: null,
  })
  const [bars, setBars] = useState([])
  const [stacked, setStacked] = useState<any>()
  const paddingBottom = 25;
  const fontSize = 16;
  const fontFamily = "Open Sans";
  let axisLeft = null;
  let axisBottom = null;
  let axisTotalBottom = null;
  const [responsiveWidth, setResponsiveWidth] = useState<any>(width);


  useEffect(() => {
    updateResponsiveWidth();
    setupGraph();
  }, []);

  useEffect(() => {
    updateResponsiveWidth();
    setupGraph();
  }, [timeline, bubblesRange, data])

  const textSize = (text: any) => {
    if (!d3) {
      return {
        width: null,
        height: null,
      };
    }

    const container = d3?.select("body")?.append("svg");

    container
      .append("text")
      .text(text)
      .attr("font-size", fontSize)
      .attr("font-family", fontFamily)
      .attr("font-weight", 300)
      .attr("x", -99999)
      .attr("y", -99999);

    const size: any = container?.node()?.getBBox();
    container?.remove();

    return {
      width: size?.width,
      height: size?.height,
    };
  }

  const updateResponsiveWidth = () => {
    // 433, 378, 328, 273
    switch (true) {
      // 480px - 433 with padding
      case width >= 379 && width <= 433:
        setResponsiveWidth(1740);
        break;
      // 425px - 378 with padding
      case width >= 329 && width <= 378:
        setResponsiveWidth(1520);
        break;
      // 375px - 328 with padding
      case width >= 274 && width <= 328:
        setResponsiveWidth(1320);
        break;
      // 320px - 273 with padding
      case width <= 273:
        setResponsiveWidth(1100);
        break;
      default:
        setResponsiveWidth(width);
    }
  }

  const setupGraph = () => {

    const _height: any = height - paddingBottom;
    const limiteIndex: any = monthlyTransactions.length - timeline.label;
    const monthlyFilter: any = monthlyTransactions.filter(
      (item: any, index: any) => index >= limiteIndex
    );
  

    const xScale: any = d3
      .scaleBand()
      .domain(monthlyFilter.map((d: any) => d?.month))
      .range([0, responsiveWidth]);

    const yScale: any = d3
      .scaleLinear()
      .domain([0, d3?.max(monthlyFilter, (d: any) => d?.value)] as any)
      .range([_height, 0]);

    const stackedData = d3.stack().keys(["revenue", "biggestPrice","disqualified","declined"])(monthlyFilter);

    const revenues: any = stackedData[0]?.map((d: any) => {
      return {
        x: Number(xScale(d?.data?.month)) + xScale.bandwidth() * 0.1,
        y: yScale(d[1]),
        width: xScale.bandwidth() * 0.8,
        height: yScale(d[0]) - yScale(d[1]),
        fill: Colors.green5,
        label: d?.data?.revenue,
        revenueTextSize: textSize(formatValue(d?.data?.revenue)),
        modalInfo: {
          month: d.data.month,
          revenues: formatValue(d?.data?.revenue),        
          biggestPrice: formatValue(d?.data?.biggestPrice),
          disqualified: formatValue(d?.data?.disqualified),
          declined: formatValue(d?.data?.declined),
        },
      };
    });

    const biggestPrice: any = stackedData[1].map((d: any, index: any) => ({
      x: Number(xScale(d?.data?.month)) + xScale.bandwidth() * 0.1,
      y: yScale(d[1]),
      width: xScale.bandwidth() * 0.8,
      height: yScale(d[0]) - yScale(d[1]),
      fill: Colors.red,
      label: d.data.biggestPrice,
      biggestPriceTextSize: textSize(formatValue(d?.data?.biggestPrice)),
      modalInfo: {
        month: d.data.month,
        revenues: formatValue(d?.data?.revenue),        
        biggestPrice: formatValue(d?.data?.biggestPrice),
        disqualified: formatValue(d?.data?.disqualified),
        declined: formatValue(d?.data?.declined),
      },
    }));

    const disqualified = stackedData[2].map((d: any, index: any) => ({
      x: Number(xScale(d?.data?.month)) + xScale.bandwidth() * 0.1,
      y: yScale(d[1]),
      width: xScale.bandwidth() * 0.8,
      height: yScale(d[0]) - yScale(d[1]),
      fill: Colors.orange5,
      label: d.data.disqualified,
      disqualifiedTextSize: textSize(formatValue(d?.data?.disqualified)),
      modalInfo: {
        month: d.data.month,
        revenues: formatValue(d?.data?.revenue),        
        biggestPrice: formatValue(d?.data?.biggestPrice),
        disqualified: formatValue(d?.data?.disqualified),
        declined: formatValue(d?.data?.declined),
      },
    }));

    const declined = stackedData[3].map((d: any, index: any) => ({
      x: Number(xScale(d?.data?.month)) + xScale.bandwidth() * 0.1,
      y: yScale(d[1]),
      width: xScale.bandwidth() * 0.8,
      height: yScale(d[0]) - yScale(d[1]),
      fill: Colors.purple1,
      label: d.data.declined,
      declinedTextSize: textSize(formatValue(d?.data?.declined)),
      modalInfo: {
        month: d.data.month,
        revenues: formatValue(d?.data?.revenue),        
        biggestPrice: formatValue(d?.data?.biggestPrice),
        disqualified: formatValue(d?.data?.disqualified),
        declined: formatValue(d?.data?.declined),
      },
    }));

    const _bars = monthlyTransactions.map((d: any, index: any) => {
      return {
        id: index,
        x: Number(xScale(d?.month)) + xScale.bandwidth() * 0.1,
        width: xScale.bandwidth() * 0.8,
        finalX: xScale(d.month),
        month: d.month,
        revenueY: yScale(d.revenue),
        revenueFill: Colors.green5,
        revenueLabel: formatValue(d?.revenue),
        lossesY: yScale(d.losses),
        lossesFill: Colors.white2,
        lossesLabel: formatValue(d?.losses),
      };
    });
    setBars(_bars)
    setStacked({
      declined,
      disqualified,
      biggestPrice,
      revenues,      
    })
  };
  const handleMouseEnter = (e: any, index: any, modalInfo: any) => {
    const getCoordinate = e.target.getBoundingClientRect();
    const position = {
      top: getCoordinate.y,
      left: index + 1 > 10 ? getCoordinate.right - 150 : getCoordinate.x,
    };

    setDescription({
      ...description,
      show: true,
      ...position,
      modalInfo,
    });
  };

  const handleMouseLeave = () => {
    setDescription({
      ...description,
      show: false,
      left: null,
      top: null,
    });
  };

  const renderMonths = () => {
    const limiteIndex = monthlyTransactions?.length - timeline?.label;
    const monthlyFilter = monthlyTransactions.filter(
      (item: any, index: any) => index >= limiteIndex
    );

    let inputStyle = {
      width: `${100 / timeline.label}%`,
    };
    return monthlyFilter.map((item: any, index: any) => {
      return (
        <Month key={index} style={inputStyle}>
          <Label key={index+1}>
            {
              item.month /*(item.month.split("/")[0] === "Jan" || index === 0) &&
            item.revenue !== 0
              ? item.month
      : item.month.split("/")[0]*/
            }
          </Label>
        </Month>
      );
    });
  };

  const renderStack = (d: any) => {
    if (!d) {
      return null;
    }
    return (
      <Fragment>
      {renderStackDeclined(d)}
      {renderStackDisqualified(d)}
        {renderStackBiggestPrice(d)}
        {renderStackRevenues(d)}        
      </Fragment>
    );
  };

  const renderStackBiggestPrice = (d: any) => {
    return (
      <Fragment>
        {d.biggestPrice.map((d: any, index: any) => (
          <rect
            x={d.x}
            y={d.y}
            width={d.width}
            height={d.height}
            fill={d.fill}
          />
        ))}

        {d.biggestPrice.map((d: any) => {
          if (d.height > 20)
            return renderLabel(d, Colors.white, d.biggestPriceTextSize);

          return null;
        })}

        {/* Overlay Bar to onMouseEnter */}
        {d.biggestPrice.map((item: any, index: any) => (
          <rect
            x={item.x}
            y={item.y}
            width={item.width}
            height={item.height}
            fill={"#FFFFFF00"}
            onMouseOver={(ev) =>
              handleMouseEnter(ev, index, item.modalInfo)
            }
            onMouseLeave={handleMouseLeave}
            onContextMenu={(ev) => {
              ev.preventDefault();
              handleMouseEnter(ev, index, item.modalInfo);
            }}
          />
        ))}
      </Fragment>
    );
  };

  const renderStackDeclined = (d: any) => {
    return (
      <Fragment>
        {d.declined.map((d: any, index: any) => (
          <rect
            x={d.x}
            y={d.y}
            width={d.width}
            height={d.height}
            fill={d.fill}
          />
        ))}

        {d.declined.map((d: any) => {
          if (d.height > 20)
            return renderLabel(d, Colors.white, d.declinedTextSize);

          return null;
        })}

        {/* Overlay Bar to onMouseEnter */}
        {d.declined.map((item: any, index: any) => (
          <rect
            x={item.x}
            y={item.y}
            width={item.width}
            height={item.height}
            fill={"#FFFFFF00"}
            onMouseOver={(ev) =>
              handleMouseEnter(ev, index, item.modalInfo)
            }
            onMouseLeave={handleMouseLeave}
            onContextMenu={(ev) => {
              ev.preventDefault();
              handleMouseEnter(ev, index, item.modalInfo);
            }}
          />
        ))}
      </Fragment>
    );
  };

  const renderStackDisqualified = (d: any) => {
    return (
      <Fragment>
        {d.disqualified.map((d: any, index: any) => (
          <rect
            x={d.x}
            y={d.y}
            width={d.width}
            height={d.height}
            fill={d.fill}
          />
        ))}

        {d.disqualified.map((d: any) => {
          if (d.height > 20)
            return renderLabel(d, Colors.white, d.disqualifiedTextSize);

          return null;
        })}

        {/* Overlay Bar to onMouseEnter */}
        {d.disqualified.map((item: any, index: any) => (
          <rect
            x={item.x}
            y={item.y}
            width={item.width}
            height={item.height}
            fill={"#FFFFFF00"}
            onMouseOver={(ev) =>
              handleMouseEnter(ev, index, item.modalInfo)
            }
            onMouseLeave={handleMouseLeave}
            onContextMenu={(ev) => {
              ev.preventDefault();
              handleMouseEnter(ev, index, item.modalInfo);
            }}
          />
        ))}
      </Fragment>
    );
  };

  const renderStackRevenues = (d: any) => {
    return (
      <Fragment>
        {d.revenues.map((d: any, index: any) => {
          return (
            <rect
              x={d?.x}
              y={d?.y}
              width={d?.width}
              height={d?.height}
              fill={d?.fill}
              style={{
                pointerEvents: "none",
              }}
            />
          );
        })}

        {d.revenues.map((d: any) => {
          if (d.height > 20)
            return renderLabel(d, Colors.white, d.revenueTextSize);

          return null;
        })}
      </Fragment>
    );
  };

  const renderLabel = (d: any, color: any, textSize: any) => {
    return (
      <text
        fill={color}
        fontSize={fontSize}
        fontWeight={300}
        fontFamily={fontFamily}
        width={d.width}
        height={d.height}
        x={d.x + d.width / 2 - textSize.width / 2}
        y={d.y + d.height / 2 + textSize.height / 2}
      >
        {formatValue(d?.label)}
      </text>
    );
  };

  const renderBars = () => {
    return (
      <Fragment>
        {renderStack(stacked)}
        {bars?.map((d: any, index: any) => (
          <Fragment key={index}>
            {index !== 0 && (
              <line
                x1={d?.finalX}
                y1={0}
                x2={d?.finalX}
                y2={height}
                width={1}
                height={height}
                strokeWidth={1}
                stroke={Colors.gray1}
              />
            )}
          </Fragment>
        ))}
      </Fragment>
    );
  };

  return (
    <div
      id="bubble-chart"
      style={{
        position: "relative",
        margin: "1rem auto 0",
        minHeight: `${height}px`,
        maxHeight: `${height}px`,
        border: `2px solid ${Colors.gray9}`,
        borderRadius: "15px",
        overflow: "hidden",
      }}
    >
      <Container width={width}>
        <svg width={responsiveWidth} height={height}>
          <g
            className="axisBottom"
            ref={(el) => {
              axisBottom = el;
            }}
            transform={`translate(0, ${height})`}
          ></g>
          <g>{renderBars()}</g>
        </svg>
        {description.show && (
          <BarInfo description={description} />
        )}
        <MonthsList width={responsiveWidth}>
          {renderMonths()}
        </MonthsList>
      </Container>
    </div>
  );
}

export default BarChart;
