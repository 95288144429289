//actions
export const SENDSUPPORTMESSAGE = "Petronect/Support/SENDSUPPORTMESSAGE";
export const CHANGESUPPORTMESSAGE = "Petronect/Support/CHANGESUPPORTMESSAGE";

export const initialState = {
  supportResponse: { status: "writing", statusMessage: "" },
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SENDSUPPORTMESSAGE:
      return { ...state, supportResponse: action.info };

    case CHANGESUPPORTMESSAGE:
      return { ...state, message: action.info };
    default:
      return state;
  }
};

//action creators
export const sendSupportMessage = (info: any) => ({
  type: SENDSUPPORTMESSAGE,
  info,
});

export const changeSupportMessage = (info: any) => ({
  type: CHANGESUPPORTMESSAGE,
  info,
});
