import React, { useState } from "react";
import "./FamilyDescription.css";
import { Link } from "react-router-dom";
import backButtonIcon from "../../assets/icons/common/back-button.svg";
import { eventCategory, logEvent } from "../../util/analytics/index";
import FamilyItem from "./FamilyItem";
import Colors from "../../assets/styles/colors";
import { Button } from "../../components";
import styled from "styled-components";

const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: -30px 20px 10px 20px;

  @media (max-width: 660px) {
    flex-direction: column;
    margin: 0px 20px 10px 20px;
  }
`;

const FamilyDescription = (props: any) => {
  const card = props.history.location.state;
  const [sorted, setSorted] = useState(0);

  const orderBy = (array: any, type: Number) => {
    if (type === 0) return array.sugestao.sort((a: any, b: any) => b.oport_dispensa - a.oport_dispensa);
    if (type === 1) return array.sugestao.sort((a: any, b: any) => b.oport_licitacao - a.oport_licitacao);
  }

  return (
    <div className="proposalDescriptionContainer">
      <div className="proposalDescriptionMenu">
        <Link
          className="backButton"
          to={{ pathname: "/family-suggestion/" }}
          onClick={() => {
            logEvent(
              eventCategory.buttonClick,
              'click voltar para Sugestão de Famílias',
              'Voltar'
            );
          }}
        >
          <img src={backButtonIcon} alt="back button" />
          <span className="pageTitleText">Voltar</span>
        </Link>
      </div>
      <div className="proposalDescription">
        <div className="descriptionHeader">
        </div>

        <div className="descriptionHeader">
          <div className="headerTopics">
            <div className="descriptionTextTitle">Filtro salvo</div>
            <div className="descriptiontext">{card?.nome}</div>
          </div>
        </div>
        <p className="descriptionTextTitle">Sugestão de Famílias:</p>
        <ContainerButton onClick={(ev) => ev.stopPropagation()}>
          <Button
            color={sorted === 0 ? Colors.white : Colors.blue8}
            background={sorted === 0 ? Colors.blue8 : Colors.white}
            border={`1px solid ${Colors.blue6}`}
            width="170px"
            height="1.5rem"
            margin=".20rem 10px"
            onClick={() => {
              setSorted(0)
              logEvent(
                eventCategory.checkboxChange,
                `Ordena por dispensas`,
                'Ordena por dispensas'
              );
            }}
          >
            Ordenar por Dispensa
          </Button>
          <Button
            color={sorted === 1 ? Colors.white : Colors.blue8}
            background={sorted === 1 ? Colors.blue8 : Colors.white}
            border={`1px solid ${Colors.blue6}`}
            width="170px"
            height="1.5rem"
            margin=".20rem 0"
            onClick={() => {
              setSorted(1)
              logEvent(
                eventCategory.checkboxChange,
                `Ordena por licitação`,
                'Ordena por licitação'
              );
            }}
          >
            Ordenar por Licitação
          </Button>
        </ContainerButton>
        {orderBy(card, sorted).map((item: any, index: any) => <FamilyItem key={index} item={item} />)}
      </div>
    </div>
  );

}

export default FamilyDescription;
