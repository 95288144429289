import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import Colors from "../../../assets/styles/colors";

const Cell = styled.li`
  position: relative;
  padding: 0.6rem 0.5rem;
  font-size: 0.75rem;
  font-weight: ${(props: ItemProps) => (props.bold ? "600" : "400")};
  font-family: Open Sans;
  color: ${(props: ItemProps) =>
    props.bold ? Colors.gray6 : `${Colors.gray8}95`};
  list-style: none;

  &:nth-child(1) {
    width: 20%;
    padding-left: 1rem;
    color: ${(props: ItemProps) => props.background};
  }
  &:nth-child(2) {
    width: 55%;
    color: ${(props: ItemProps) => props.background};
  }
  &:nth-child(3) {
    width: 15%;
    color: ${(props: ItemProps) => props.background};
  }
  &:nth-child(4) {
    width: 10%;
    color: ${(props: ItemProps) => props.background};
  }

  @media (max-width: 590px) {
    &:nth-child(1) {
      width: auto;
    }
    &:nth-child(2) {
      width: auto;
          padding-left: 75px;
    }
    &:nth-child(3) {
      width: auto;
    }
    &:nth-child(4) {
      width: auto;
    }
    display: ${(props: ItemProps) => props.displayMobile};
  }
`;

export interface ItemProps {
  bold?: boolean;
  background?: string;
  displayMobile?: string;
  children?: ReactNode;
}

const Item: FC<ItemProps> = ({ bold, background, displayMobile, children }) => {
  return (
    <Cell
      bold={bold}
      background={background}
      displayMobile={displayMobile}
    >
      {children}
    </Cell>
  );
};

export default Item;
