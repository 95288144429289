import React, { FC } from "react";
import maintenance_small from "../../assets/img/maintenance/maintenance_small.png";
import {
  Overlay,
  TourContainer,
  TourTitlePrimary,
  TourTitleSecundary,
  ImageMaintenance,
  TourText,
} from "./styles"

const Maintenance: FC = () => {

  return (
    <Overlay>
      <TourContainer>
        <TourTitlePrimary>Página em manutenção</TourTitlePrimary>
        <ImageMaintenance src={maintenance_small} alt="Maintenance Page" />
        <TourTitleSecundary>Desculpe o transtorno</TourTitleSecundary>
        <TourText>
          Estamos trabalhando para melhorar sua experiência em nossa
          plataforma.
        </TourText>
      </TourContainer>
    </Overlay>
  );
}

export default Maintenance;
