import React, { FC, ReactNode } from "react";
import { ButtonSend } from "./styles";
import Colors from "../../../assets/styles/colors";

export interface ButtonProps {
  background?: string;
  onClick?: () => void;
  width?: string;
  height?: string;
  margin?: string;
  color?: string;
  widthMobile?: string;
  marginMobile?: string;
  textDecoration?: string;
  shadow?: any;
  fontWeight?: string;
  border?: string;
  children?: ReactNode;
  id?: any;
  marginTopMobile?: any;
  backgroundColor?: any;
  text?:any;
  handleClick?:any;
  disabled?: boolean;
}

const Button: FC<ButtonProps> = ({
  background,
  onClick,
  width,
  height,
  margin,
  color,
  widthMobile,
  marginMobile,
  textDecoration,
  shadow,
  fontWeight,
  border,
  children,
  id,
  marginTopMobile,
  backgroundColor,
  disabled
}) => {
  return (
    <ButtonSend
      id={id}
      background={background || "transparent"}
      onClick={onClick}
      width={width}
      height={height}
      margin={margin}
      color={color || Colors.white}
      widthMobile={widthMobile}
      marginMobile={marginMobile}
      textDecoration={textDecoration}
      shadow={shadow}
      fontWeight={fontWeight}
      border={border || "none"}
      marginTopMobile={marginTopMobile}
      backgroundColor={backgroundColor}
      disabled={disabled}
    >
      {children}
    </ButtonSend>
  );
};
export default Button;
