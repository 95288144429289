import styled from "styled-components";
import Colors from "../../assets/styles/colors";

interface StylesProps {
  flexDirection?: any;
  gap?: any;
  wrap?: any;
  justifyContent?: any;
  margin?: any;
}

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props: StylesProps) => props.flexDirection || 'row'};
  gap: ${(props: StylesProps) => props.gap || 0};
  flex-wrap: ${(props: StylesProps) => props.wrap || 'nowrap'};
  justify-content: ${(props: StylesProps) => props.justifyContent || 'center'};
`;

const ContainerWrapper = styled.div`
  margin: ${(props: StylesProps) => props.margin || 0};
`;

const Text = styled.p`
  margin: 0px;
  
  @media (max-width: 350px) {
    font-size: 0.8888rem;
  }
`;

const TutorialPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 0px;
  height: 100%;
`;

const TutorialStepsDiv = styled.div`
  margin-bottom: 40px;
`;

const TutorialWelcomeTitle = styled.div`
  font-size: 20px;
  color: ${Colors.blue6};
  margin-bottom: 10px;
`;

const TutorialTitle = styled.p`
  font-size: 16px;
  color: ${Colors.blue6};
  margin-bottom: 10px;
`;

const TutorialParagraph = styled.p`
  font-size: 14px;
  color: ${Colors.blue3};
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 0px;
`;

const TutorialImg = styled.img`
  width: 100%;
  height: auto;
`;

const ScreenContainer = styled.p`
  overflow-y: auto;
`;

const ProfileContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 60px;

  @media (min-width: 768px) {
    grid-template-columns: auto auto;
  }
`;

const CellTitle = styled.p`
  font-size: 12px;
  color: ${Colors.gray4};
  margin: 0px;
  margin-bottom: 10px;
  padding: 0px;
`;

const Card = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    padding: 20px 15px 20px 15px;
    border-radius: 4px;
    box-shadow: 0px 3px 6px ${Colors.black}29; 
`;

const DeleteButton = styled.button`
  background: ${Colors.orange2};
  color: ${Colors.orange3};
  width: 134px;
  height: 32px;
  border: none;
  :active {
    opacity: 0.5;
  }
`;

const SubContainerTitle = styled.p`
  color: ${Colors.gray7};
  font-size: 16px;
`;

export {
  FlexContainer,
  ContainerWrapper,
  Text,
  TutorialPage,
  TutorialStepsDiv,
  TutorialWelcomeTitle,
  TutorialTitle,
  TutorialParagraph,
  TutorialImg,
  ScreenContainer,
  ProfileContainer,
  CellTitle,
  Card,
  DeleteButton,
  SubContainerTitle
};
