import React, { FC } from "react";
import { InputForm, Label, TextError, BoxError } from "./style";

/**
 * Default HTML input, with project styling
 * @param {*} props HTML props + {error:string, label:string}
 * @returns
 */

interface InputProps {
  error?: string;
  label?: string;
  rest?: any;
  required?: any;
  autoFocus?: any;
  placeholder?: any;
  onBlur?: () => void;
  onChange?: any;
  type?: any;
  labelText?: string;
}

const Input: FC<InputProps> = ({ error, label, required, autoFocus, placeholder, onBlur, onChange, type, labelText, ...rest }) => {
  return (
    <>
      <Label>{label}</Label>
      <InputForm
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        autoFocus={autoFocus}
        placeholder={placeholder}
        {...rest}
      />
      {error && (
        <BoxError>
          <TextError>{error}</TextError>
        </BoxError>
      )}
    </>
  );
};

export default Input;
