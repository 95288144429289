import styled from "styled-components";
import Colors from "../../assets/styles/colors";
import { StylesProps } from "../../util/types";

const Overlay = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TourContainer = styled.div`
  padding: 20px;
  flex-direction: column;
  text-align: center;
  max-width: 1200px;
  width: ${(props:StylesProps) => (props.first ? false : "90%")};
  min-height: 500px;
  height: ${(props:StylesProps) => (props.height ? props.height : "auto")};
  background-size: auto 100%;
  border-radius: 10px;
  transition: width 1s, heigth 1s;
`;

const TourTitlePrimary = styled.h1`
  color: ${Colors.blue6};
  font: 48px Eurostile;
  letter-spacing: 0;
  margin: 0;
`;

const ComingSoonImage = styled.img`
  margin: 20px 0;
  height: 160px;
`;

const TourText = styled.p`
  font: Medium 14px/18px Roboto;
  max-width: 500px;
  margin: 20px auto;
  line-height: 1.4;
  letter-spacing: 0;
  font-size: 18px;
  color: ${Colors.gray7};
`;

export { Overlay, TourContainer, TourTitlePrimary, ComingSoonImage, TourText };
