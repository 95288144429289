import React, { FC } from "react";
import styled from "styled-components";
import Button from "../../../components/common-components/Button";
import Logo from "../../../components/layout-components/Logo/Logo";
import Colors from "../../../assets/styles/colors";
import { StylesProps } from "../../../util/types";

const Overlay = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, ${Colors.blue8}, ${Colors.blue6});
`;

const Container = styled.div`
  min-width: 320px;
  width: 60%;
  padding: 2.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${Colors.white};
  border-radius: 4px;

  @media (max-width: 768px) {
    width: 55vw;
  }

  @media (max-width: 590px) {
    width: 95%;
  }
`;

const BoxTitle = styled.span`
  width: 90%;
`;

const Title = styled.h1`
  color: ${Colors.blue6};
  font-size: 1.3rem;
  margin: 1rem 0 3rem 0;
`;

const BoxText = styled.span`
  width: 90%;
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  color: ${(props: StylesProps) => props.color}; 
  font-size: 1rem;
  width: 90%;

  @media (max-width: 590px) {
    width: 100%;
  }
`;

const BoxButtons = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

const Strong = styled.strong`
  word-wrap: break-word;
`;

interface ResendEmailProps {
  createdEmail?: any;
  handleBackLogin?: () => void;
  resend?: boolean;
}

const ResendEmail: FC<ResendEmailProps> = ({ createdEmail, handleBackLogin, resend }) => {
  return (
    <Overlay>
      <Container>
        <Logo width="220px" margin="0 0 3rem 0" />
        <BoxTitle>
          <Title>{resend ? 'Verificar Email' : 'Criar Conta'}</Title>
        </BoxTitle>
        <BoxText>
          <Text color={Colors.gray8}>
            {resend ? 'Reenviamos' : 'Enviamos'} um e-mail de confirmação para{" "}
            <Strong>
              {createdEmail ? createdEmail : "name@email.com"}
            </Strong>
            . Verifique sua caixa de entrada para prosseguir.
          </Text>
          <Text color={Colors.blue8}>
            Caso não tenha recebido a confirmação, verifique sua caixa de{" "}
            <Strong>SPAM</Strong> ou entre em contato com nosso suporte através
            do número
            <Strong> 4020-9876</Strong> para capitais, regiões metropolitanas e
            ligações originadas de telefone celular ou
            <Strong> 0800 282 8484</Strong> para demais regiões.
          </Text>
        </BoxText>
        <BoxButtons>
          <Button
            color={Colors.gray8}
            margin=".5rem 0"
            textDecoration="underline"
            onClick={handleBackLogin}
          >
            Voltar ao login
          </Button>
        </BoxButtons>
      </Container>
    </Overlay>
  );
}

export default ResendEmail