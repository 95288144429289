import React, { FC } from "react";
import { TextDescription, TextBold, Text, Link } from "../../../components/common-components/Accordion/styles";
import { Image } from "../styles";
import HowDoRegister01 from "../../../assets/img/faq/howDoRegister1.png";
import HowDoRegister02 from "../../../assets/img/faq/howDoRegister2.png";
import HowDoRegister03 from "../../../assets/img/faq/howDoRegister3.png";

const HowDoRegister: FC = () => {
    return (
        <>
            <TextDescription>
                <Text>{`Acesse `}</Text>
                <Link
                    href="https://minhapetronect.com.br"
                    target="_blank"
                >
                    www.minhapetronect.com.br
                </Link>
                <Text>{`, e crie a sua conta no botão “Criar Conta”.`}</Text>
            </TextDescription>
            <TextDescription>
                <Image src={HowDoRegister01} alt="Imagem" />
            </TextDescription>
            <TextDescription>
                <Text>{`Após clicar no botão em destaque, preencha os dados indicados (Nome, Email, Confirme o Email, Criação de Senha, Aceite dos Termos de Uso) e logo após clique em “Criar Conta”.`}</Text>
            </TextDescription>
            <TextDescription>
                <Image src={HowDoRegister02} alt="Imagem" />
            </TextDescription>
            <TextDescription>
                <Text>{`Após a realização do processo de cadastro, você receberá um e-mail de confirmação. Caso não encontre em sua `}</Text>
                <TextBold>{`caixa de entrada`}</TextBold>
                <Text>{` ou `}</Text>
                <TextBold>{`lixo eletrônico`}</TextBold>
                <Text>{`, deverá clicar no botão “Verificar Email” para ativar a sua conta.`}</Text>
            </TextDescription>
            <TextDescription>
                <Image src={HowDoRegister03} alt="Imagem" />
            </TextDescription>
        </>
    )
}
export default HowDoRegister;