import React, { createContext, useContext, useState } from "react";
import {
  Container,
  Content,
  ContentDash,
  LoadingOverlay,
  LoadingContainer,
  LoadingIcon,
  LoadingText,
} from "./styles";
import loading from "../../assets/icons/common/loading-dash.svg";
import SideBar from "../../components/layout-components/Sidebar";
import Modal from "../../components/modules-components/match-relevance/ListItem/Modal";
import ModalFilter from "../../components/modules-components/match-relevance/ModalFilter/ModalFilter";
import DeleteFilterModal from "../../components/modules-components/match-relevance/DeleteModal";
import Support from "../../components/common-components/Support/Support";
import ModalSucess from "../../components/common-components/ModalSucess/ModalSucess";
import { useDispatch, useSelector } from "react-redux";
import {
  saveModal,
  setSelectedOpportunity,
} from "../../dataflow/modules/modal-module";
import {
  deleteFilterThunk,
  deleteMatchFilterThunk,
} from "../../dataflow/thunks/filters-thunk";
import { useHistory } from "react-router-dom";
import { eventCategory, logEvent } from "../../util/analytics/index";

const LayoutContext = createContext({});

export const LayoutProvider = (props:any) => {
  const [containerClicked, setContainerClicked] = useState(false);
  const { isLoading } = useSelector((state:any) => state.opportunities);
  const { modal, selectedOppotunity } = useSelector((state:any) => state.modals);
  const { selectedFilter } = modal;
  const history = useHistory();
  const dipatch = useDispatch();

  const closeModal = () => {
    // dipatch(setSelectedOpportunity());
    dipatch(saveModal({}));
  };

  const handleDeleteFilter = () => {
    logEvent(eventCategory.buttonClick, "click excluir filtros", "Confirmar");
    const { screen } = modal;
    if (screen) {
      dipatch(deleteFilterThunk(selectedFilter.id));
    } else dipatch(deleteMatchFilterThunk(selectedFilter.id));
    closeModal();
  };

  const renderLoading = () => (
    <LoadingOverlay>
      <LoadingContainer>
        <LoadingIcon src={loading} alt="" />
        <LoadingText>Carregando...</LoadingText>
      </LoadingContainer>
    </LoadingOverlay>
  );

  return (
    <LayoutContext.Provider value={{ containerClicked, setContainerClicked }}>
      <Container onClick={() => setContainerClicked(true)}>
        <Content>
          <SideBar history={history} />
          <ContentDash id="content-dash">{props.children}</ContentDash>
          {isLoading && renderLoading()}
          {modal.isOpen && modal.type === "open-opportunity" && (
            <Modal
              closeModal={closeModal}
              selectedOppotunity={selectedOppotunity}
            />
          )}
          {modal.isOpen && modal.type === "filter-modal" && (
            <ModalFilter closeModal={closeModal} />
          )}
          {modal.isOpen && modal.type === "filter-edit" && (
            <ModalFilter type="filter-edit" closeModal={closeModal} />
          )}
          {modal.isOpen && modal.type === "filter-delete" && (
            <DeleteFilterModal
              selectedFilter={selectedFilter}
              handleConfirm={handleDeleteFilter}
              closeModal={closeModal}
            />
          )}
          {modal.isOpen && modal.type === "support" && <Support />}
          {modal.isOpen && modal.type === "sucess" && (
            <ModalSucess closeModal={closeModal} />
          )}
        </Content>
      </Container>
    </LayoutContext.Provider>
  );
};

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error("useLayout must be used within a LayoutProvider");
  }
  return context;
};
