import React, { FC, forwardRef, ReactNode, useImperativeHandle, useState } from "react";
import TagListItem from "../TagListItem/TagListItem";
import { ListContainer, TitleList, ChildrenContainer } from "./styles";
import { useTagList } from "./useTagList";
import { StringArray } from "./useTagListUtils";

interface TagListProps {
  defaultList:StringArray[];
  onToggle:(v:any,v2:any)=>void;
  onRemove:(v:any,v2:any)=>void;
  editEnabled?:boolean;
  title?:string;
  children?:ReactNode;
  ref?:any;
}

const TagList: FC<TagListProps> = forwardRef(
  (
    {
      defaultList,
      onToggle,
      onRemove,
      editEnabled: editProps,
      title,
      children,
    },
    ref
  ) => {
    const {
      onRemoveTag,
      onToggleTag,
      tagsListArray,
      setTagList,
      activeTags,
    } = useTagList({
      defaultList,
      onRemove,
      onToggle,
    });
    const [editEnabled, setEditEnabled] = useState(editProps || false);

    useImperativeHandle(ref, () => ({
      setTagList,
      setEditEnabled,
      tagsListArray,
      activeTags,
    }));

    return (
      <ListContainer>
        <TitleList>{title}</TitleList>
        {tagsListArray.map((item:any, index:any) => (
          <TagListItem
            onClick={() => onToggleTag(index)}
            onRemove={
              editEnabled
                ? () => {
                    onRemoveTag(index);
                  }
                : undefined
            }
            selected={item.selected}
            text={item.label}
            key={`${item.label}-${index}`}
          />
        ))}
        <ChildrenContainer>{children}</ChildrenContainer>
      </ListContainer>
    );
  }
);

export default TagList;
