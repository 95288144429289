import React, { FC } from "react";
import { TextDescription, Text } from "../../../components/common-components/Accordion/styles";
import { Image } from "../styles";
import FamilySuggestion1 from "../../../assets/img/faq/familySuggestion1.png";

const FamilySuggestion: FC = () => {
    return (
        <>
            <TextDescription>
                <Text>
                    {`O objetivo é apresentar sugestões de famílias relacionadas as palavras salvas na gestão de filtro do Match Relevância (Busca de oportunidades da Minha Petronect).`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Text>
                    {`Quanto mais palavras o usuário salvar em seu filtro, maior a chance de verificar novas famílias para cadastro e aumentar as oportunidades para concorrer.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Text>
                    {`Para visualizar as famílias sugeridas é necessário clicar no card apresentado com a palavra salva no filtro.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={FamilySuggestion1} alt="Imagem" />
            </TextDescription>
 
        </>
    )
}
export default FamilySuggestion;