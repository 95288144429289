import styled from "styled-components";
import { Link } from "react-router-dom";
import Colors from "../../../../assets/styles/colors";

const StatusBar = styled.div`
  width: 10px;
  margin-right: 15px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: ${(props) => props.color};
`;

const CardWrapper = styled(Link)`
    color: ${Colors.black};
    width: calc(50% - 14px);
    min-height: 192px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: #fff 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px rgba(0,0,0,.1607843137254902);
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
    overflow-x: hidden;

    @media(max-width: 900px) {
        width: 100%;
    }

    &:hover,
    &:focus {
        color: ${Colors.black};
    }
`;

const CardContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 10px 0px;
`;

const CardFlex = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Text = styled.p`
    margin: 0;
`;

const Image = styled.img`
    width: 32px;
    height: 32px;
`;

export {
    StatusBar,
    CardWrapper,
    CardContent,
    Text,
    CardFlex,
    Image
}