import React, { FC } from "react";
import Logo from "../../../../components/layout-components/Logo/Logo";
import styled from "styled-components";
import background from "./20220726_cardLives.png"
import Colors from "../../../../assets/styles/colors";
import {
    YoutubeLogo,
    LinkedinLogo
} from "../../../../assets/img/src/pages/Auth/Slides/index";

const SlideCardLiveContainer = styled.div`
width: 90%;
height: 100vh;
margin: auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-image: url(${background});
background-position: center;
background-repeat: no-repeat;
background-size: 100%;
flex-wrap: nowrap;
`;

const LogoContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
width:30%;
min-width: 30%;
padding-bottom: 0px;
`

const BodyContainer = styled.div`
width: 90%;
 border:solid 0px;
 box-shadow: 0px 0px 15px -5px  ${Colors.black};
 background-color: ${Colors.white};
 padding-top:20px;
 padding-bottom:20px;
`
const Text = styled.div`
margin: 5px;
text-align: center;
font-family: "Roboto", sans-serif;
font-size: 14px;
letter-spacing: 0px;
color: ${Colors.blue10};
`;
const TextGrenn = styled.div`
margin: 5px;
text-align: center;
font-family: "Roboto", sans-serif;
font-size: 14px;
letter-spacing: 0px;
color: ${Colors.green9};
`;

const TextSpan = styled.span`
text-align: center;
color: ${Colors.blue10};
letter-spacing: 0px;
font: 700 14px  sans-serif;

@media (max-width: 1200px) {
  font-size: 1rem;
}
`;
const TextSpanGreen = styled.span`
text-align: center;
color: ${Colors.green9};
letter-spacing: 0px;
font: 700 18px  sans-serif;

@media (max-width: 1200px) {
  font-size: 1rem;
}
`;

const TextSpanBlue = styled.span`
text-align: center;
color: ${Colors.blue10};
letter-spacing: 0px;
font: 700 18px  sans-serif;

@media (max-width: 1200px) {
  font-size: 1rem;
}
`;

const LinkContainer = styled.div`
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 35%;
  padding-right: 35%;
  padding-bottom: 2%;
`

const LogoLink = styled.a`
z-index: 99;
`

const CardLives: FC = () => {
    return (
        <SlideCardLiveContainer >
            <LogoContainer>
                <Logo width="30%" widthMobile="20%" />
            </LogoContainer>
            <BodyContainer>
                <Text><TextSpanBlue>Venha conhecer a Minha Petronect!</TextSpanBlue></Text>
                <Text>
                    {`Mergulhe no universo da Minha Petronect e conheça o nosso canal oficial do Youtube! Através dos nossos conteúdos, apresentamos as funcionalidades do site para você fornecedor da Petrobras! Venha conferir esses conteúdos e muito mais através dos canais oficiais da Petronect!`}
                </Text>
                <TextGrenn>
                <TextSpanGreen>{`Fique por dentro das novidades e conteúdos da Petronect em nossos Canais Oficiais!`}</TextSpanGreen>
                </TextGrenn>
                <LinkContainer>
                    <LogoLink
                        href="https://www.youtube.com/c/PetronectOficial"
                        target="_blank"
                        rel="noopener noreferrer"
                    ><img
                            src={YoutubeLogo}
                            alt="YouTube"
                            height="40"
                            width="40"
                        /></LogoLink>
                    <LogoLink
                        href="http://www.linkedin.com/company/petronect/"
                        target="_blank"
                        rel="noopener noreferrer"
                    ><img
                            src={LinkedinLogo}
                            alt="LinkedIn"
                            height="40"
                            width="40"
                        /></LogoLink>
                </LinkContainer>
            </BodyContainer>
        </SlideCardLiveContainer>
    )
}

export default CardLives;