import React, { FC, ReactNode } from "react";
import { connect } from "react-redux";
import CloseModalIcon from "../../../assets/icons/common/close-icon.svg";
import { saveModal } from "../../../dataflow/modules/modal-module";
import { Overlay, ModalContainer, ImageClose } from "./styles";

const mapDispatchToProps = (dispatch: any) => ({
  saveModal: (info: any) => dispatch(saveModal(info))
});

interface ModalProps {
  onBlur: () => void;
  closeModal: () => void;
  children: ReactNode;
  width?: any;
  overlay?: boolean;
}

export const Modal: FC<ModalProps> = ({ onBlur, closeModal, children, width, overlay }) => {
  return (
    <Overlay onBlur={() => onBlur()} onClick={!overlay ? () => closeModal() : () => null}>
      <ModalContainer width={width} >
        <ImageClose src={CloseModalIcon} onClick={closeModal} />
        {children}
      </ModalContainer>
    </Overlay>
  );
};

export default connect(null, mapDispatchToProps)(Modal);