import styled from "styled-components";
import Colors from "../../../assets/styles/colors";

const Overlay = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.black}50;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
`;

const Modal = styled.div`
  width: 40vw;
  background: ${Colors.white};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1rem;
  position: relative;
  border: 1px solid ${Colors.blue8};
  z-index: 99;

  @media (max-width: 768px) {
    width: 65vw;
  }

  @media (max-width: 590px) {
    width: 95%;
  }
`;

const ImageClose = styled.img`
  align-self: flex-end;
  position: absolute;
  top: -1.5vh;
  right: -9px;
  /* left: 0; */
  cursor: pointer;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
`;

const TitleSupport = styled.h3`
  font-size: 0.9rem;
  color: ${Colors.blue8};
  font-family: Eurostile, Bold;
  margin: 0;
`;

const Text = styled.p`
  color: ${Colors.gray8};
  font-size: 0.8rem;

  > strong {
    display: flex;
    flex-direction: column;
  }
`;

const Form = styled.form`
  width: 100%;
`;

const BoxButton = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0 0 0;
`;

const MessageError = styled.p`
  width: 100%;
  margin-bottom: 0;
  text-align: center;
  font-size: 0.85rem;
  color: red;
`;

export {
  Overlay,
  Modal,
  ImageClose,
  Box,
  TitleSupport,
  Text,
  Form,
  BoxButton,
  MessageError,
};
