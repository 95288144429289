import React, { FC, Fragment } from "react";
import styled from "styled-components";
import { formatDateWithoutTimezone } from "../../../util/format-value";
import Colors from "../../../assets/styles/colors";

interface ContainerStylesProps {
  top?: any;
  left?: any;
}

interface StylesProps {
  color?: any;
}

const Container = styled.div`
  position: fixed;
  top: ${(props: ContainerStylesProps) => props.top}px;
  left: ${(props: ContainerStylesProps) => props.left}px;
  width: 240px;
  padding: 1rem;
  background: ${Colors.white2} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px ${Colors.black}29;
  border: 0.5px solid ${Colors.gray1};
  border-radius: 8px;
  z-index: 1;
  transform: translateX(-120px);

  @media screen and (max-width: 480px) {
    ${(props: ContainerStylesProps) => {
    if (props.left < 120) {
      return "transform: translateX(0);";
    }
    if (props.left + 120 > 480) {
      return "transform: translateX(-240px);";
    }
  }}
  }

  @media screen and (max-width: 425px) {
    ${(props: ContainerStylesProps) => {
    if (props.left < 120) {
      return "transform: translateX(0);";
    }
    if (props.left + 120 > 425) {
      return "transform: translateX(-240px);";
    }
  }}
  }

  @media screen and (max-width: 375px) {
    ${(props: ContainerStylesProps) => {
    if (props.left < 120) {
      return "transform: translateX(0);";
    }
    if (props.left + 120 > 375) {
      return "transform: translateX(-240px);";
    }
  }}
  }

  @media screen and (max-width: 320px) {
    ${(props: ContainerStylesProps) => {
    if (props.left < 120) {
      return "transform: translateX(0);";
    }
    if (props.left + 120 > 320) {
      return "transform: translateX(-240px);";
    }
  }}
  }
`;

const Title = styled.p`
  margin: 0;
  letter-spacing: 0;
  color: ${(props: StylesProps) => props.color};
  cursor: pointer;
  font: 700 0.625rem "Open Sans";
`;

const Id = styled.p`
  margin: 0.15rem 0 0;
  color: ${Colors.gray8};
  font: 400 0.625rem "Eurostile";
`;

const JudgementType = styled.p`
  margin: 0.15rem 0 0;
  color: ${Colors.gray8};
  font: 300 0.625rem "Eurostile";
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

const Box = styled.span`
  width: 33%;
  margin-top: 0.25rem;
`;

const InfoLabel = styled.p`
  margin: 0.25rem 0;
  color: ${Colors.gray8};
  font: 500 0.625rem "Eurostile";
`;

const InfoContent = styled.p`
  margin: 0.25rem 0;
  color: ${(props: StylesProps) => props.color};
  font: 700 0.625rem "Open Sans";
  opacity: 1;
`;

interface InfoModalProps{
  description:any;
}

const InfoModal: FC<InfoModalProps> = ({description}) => {
  const renderDescription = () => {
    const { competitor, myPrice, won } = description;

    // if (judgementType.toLowerCase() === "julgamento por total") {
    return (
      <Fragment>
        <Box>
          <InfoLabel>Concorrente</InfoLabel>
          <InfoContent color={Colors.gray8}>{competitor}</InfoContent>
        </Box>
        <Box>
          <InfoLabel>Meu preço</InfoLabel>
          <InfoContent color={won ? Colors.green5 : Colors.blue6}>
            {myPrice}
          </InfoContent>
        </Box>
      </Fragment>
    );
    // } else {
    //   return (
    //     <Fragment>
    //       <Box>
    //         <InfoLabel>Vencido</InfoLabel>
    //         <InfoContent color={"Colors.green5"}>{totalWon}</InfoContent>
    //       </Box>
    //       <Box>
    //         <InfoLabel>Perdido</InfoLabel>
    //         <InfoContent color={"#404040"}>{totalLost}</InfoContent>
    //       </Box>
    //       <Box>
    //         <InfoLabel>Data</InfoLabel>
    //         <InfoContent color={"#404040"}>
    //           {formatDateWithoutTimezone(date, "dd/MM/yyyy")}
    //         </InfoContent>
    //       </Box>
    //     </Fragment>
    //   );
    // }
  };

  const {
    top,
    left,
    id,
    text,
    won,
    judgementType,
    date,
    cnpj,
  } = description;
  return (
    <Container top={top} left={left}>
      <Title color={won ? Colors.green5 : Colors.blue6}>{text}</Title>
      <Id>{id}</Id>
      <JudgementType>{judgementType}</JudgementType>
      <JudgementType>{cnpj}</JudgementType>
      <JudgementType>
        {" "}
        {formatDateWithoutTimezone(date, "dd/MM/yyyy")}
      </JudgementType>
      <Content>{renderDescription()}</Content>
    </Container>
  );
}

export default InfoModal;
