import React, { useEffect, useState } from 'react';
import PageHeaderDescription from '../../components/common-components/PageHeaderDescription/PageHeaderDescription';
import LoadingList from '../../components/modules-components/match-analytical/LoadingList';
import { Button, FilterSearch } from '../../components';
import ProposalCardsContainer from './components/ProposalCardsContainer';
import { BlockFilter, BlockFilterOpportunities, BoxSavedFilters } from '../MatchRelevance/RelevancyMatchFilter/styles';
import { ContainerCheckboxDropDown, ContainerDropDown, FlexRow, FlexWrapper, InputTitle, ResetFilterButton, ResetFilterIcon, ReverseIcon, SectionContainer, SectionContent } from './styles';
import DropdownIcon from "../../assets/icons/common/dropdown-azul.svg";
import MoveDown from "../../assets/icons/common/move-down-icon.svg";
import MoveUp from "../../assets/icons/common/move-up-icon.svg";
import search from "../../assets/icons/common/search-icon.svg";
import Colors from "../../assets/styles/colors";
import CloseIcon from "../../assets/icons/common/close-white.svg";
import { getAllCatalogs } from '../../dataflow/thunks/marketplace';
import { connect } from 'react-redux';
import { getIntegrationProfilesFromAPI } from '../../dataflow/thunks/integration-profiles';
import { replaceAll, sortByDateAscending, sortByDateDescending } from '../../util/devUtilities';
import { DescriptionMarketplace } from './components/DescriptionMarketplace';

const Marketplace = ({
    integrations,
    getIntegrationProfiles
}: any) => {
    const { currentProfile } = integrations;
    const [items, setItems]: Array<any> = useState([]);
    const [sortedItems, setSortedItems]: Array<any> = useState([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [labelRegionDropdown, setLabelRegionDropdown] = useState('');
    const [isBoxSavedFilterOpen, setIsBoxSavedFilterOpen] = useState(false);
    const [showBoxRegion, setShowBoxRegion] = useState(false);
    const [isReverse, setIsReverse] = useState(false);
    const [isSearching, setIsSearching] = useState(true);
    const [hasMounted, setHasMounted] = useState(false);
    const [labelDropDown, setLabelDropDown] = useState('');
    const [regions, setRegions]: Array<any> = useState([]);

    useEffect(() => {
        if (!currentProfile) {
          getIntegrationProfiles();
        }
    }, []);

    useEffect(() => {
        const CNPJ = integrations?.currentProfile?.document?.number || '';
        // console.log('maketplace', CNPJ);
        setIsSearching(true);

        if (CNPJ) {
            getAllCatalogs({
                "method": "GET",
                "id": null,
                "cnpj": CNPJ
            })
            .then(res => res.data.body.listaCatalogo)
            .then(data => {
                const treatedData = data.map((currentData: any) => {
                                            let { ValorPropostaFornecedor, ValorPropostaVencedora, PropostaVencedora } = currentData;

                                            currentData.PropostaVencedora = PropostaVencedora.toFixed(2);
                                            currentData.ValorPropostaFornecedor = ValorPropostaFornecedor.toFixed(2);
                                            currentData.ValorPropostaVencedora = ValorPropostaVencedora.toFixed(2);

                                            return currentData;
                                        })
                                        .map((currentData: any) => handleStatusProposals(currentData))
                                        .map((currentData: any) => calculateDaysLeftProposals(currentData));
                                        

                // console.log('treatedData', treatedData)
                setItems(treatedData);
                setSortedItems(treatedData);
                setRegions(['TODOS', ...new Set(treatedData?.map(({ LocalEntrega }: any) => LocalEntrega))]);
            })
            .catch(console.error)
            .finally(() => setIsSearching(false));
        }
    }, [currentProfile]);
    
    const toggleBoxFilter = () => {
        setIsBoxSavedFilterOpen((state) => !state);
    }

    const toggleBoxRegion = () => {
        setShowBoxRegion((state) => !state);
    }
    
    const reverseArray = () => {
        // console.log(isReverse)
        // const reverse = [...sortedItems].reverse();
        let reverse;

        if (isReverse) {
            reverse = sortByDateDescending([...sortedItems], 'VencimentoRequisicao');
        } else {
            reverse = sortByDateAscending([...sortedItems], 'VencimentoRequisicao');
        }

        setSortedItems(reverse);
        setIsReverse((state) => !state);
    };

    const handleStatusProposals = (currentItem: any) => {
        const { red, green1, white, purple } = Colors;
        const { ValorPropostaFornecedor, ValorPropostaVencedora, Declinio, IdProposta, IdPropostaVencedora} = currentItem;
        const { treatedCurrentQuotation, treatedBestPrice } = {
            treatedCurrentQuotation: (ValorPropostaFornecedor || null),
            treatedBestPrice: (ValorPropostaVencedora || 0)
        }
        
        if (Declinio) {
            currentItem.statusProposals = 'Declínio';
            currentItem.statusColor = purple;
            return currentItem;
        }
        else{
            if (!treatedCurrentQuotation || treatedCurrentQuotation == 0.00 ) {
                currentItem.statusProposals = 'Não cotadas';
                currentItem.statusColor = white;
                return currentItem;
            }
            else{
                if (treatedBestPrice < treatedCurrentQuotation) {
                    currentItem.statusProposals = 'Cotadas com maior valor';
                    currentItem.statusColor = red;
                    return currentItem;
                }
                else if (treatedBestPrice >= treatedCurrentQuotation) {
                    if(IdProposta == IdPropostaVencedora)
                    {
                        currentItem.statusProposals = 'Cotadas com menor valor';
                        currentItem.statusColor = green1;
                        return currentItem;
                    }
                    else
                    {
                        currentItem.statusProposals = 'Cotadas com maior valor';
                        currentItem.statusColor = red;
                        return currentItem;
                    }
                }   
            }
        }
    }

    const calculateDaysLeftProposals = (currentItem: any) => {
        const currentDate: any = new Date();
        const treatedDueDate: any = new Date(currentItem.VencimentoRequisicao);
    
        const differenceInMilliseconds =  treatedDueDate - currentDate;

        const daysRemaining = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    
        currentItem.daysLeft = daysRemaining;

        if (daysRemaining >= 7) {
            currentItem.clockType = 'green';
            return currentItem;
        }
        
        if (daysRemaining >= 2 && daysRemaining <= 6) {
            currentItem.clockType = 'yellow';
            return currentItem;
        }

        if (daysRemaining <= 1) {
            currentItem.clockType = 'red';
            return currentItem;
        }
    }
    
    const groupFiltering = () => {
        function handleFilterRegion(eachItem: any) {
            if (labelRegionDropdown === 'TODOS') return eachItem;

            return eachItem.LocalEntrega.toLowerCase().includes(labelRegionDropdown.toLowerCase());
        }

        function handleFilterStatus(eachItem: any) {
            if (labelDropDown === 'TODOS') return eachItem;

            return eachItem.statusProposals.toLowerCase().includes(labelDropDown.toLowerCase());
        }

        function handleDescriptionSearch(eachItem: any) {
            if (!eachItem) return eachItem;
            
            return eachItem.Descricao.toLowerCase().includes(searchFilter.toLowerCase());
        }

        const filteredItems = items
                                .filter(handleFilterRegion)
                                .filter(handleFilterStatus)
                                .filter(handleDescriptionSearch);

        setSortedItems(filteredItems);
    }

    const resetFilterings = () => {
        setSearchFilter('');
        setLabelDropDown('');
        setLabelRegionDropdown('');
    }

    useEffect(() => {
        if (hasMounted) { 
            groupFiltering();
        }

        setHasMounted(true);
    }, [searchFilter, labelDropDown, labelRegionDropdown, hasMounted]);

    return (
        <>
            <PageHeaderDescription
                title={"Marketplace"}
                paragraphs={DescriptionMarketplace()}
            />
            <SectionContainer>
                {
                    !isSearching ?
                    <>
                        <FlexWrapper>
                            <BlockFilterOpportunities>
                                <FilterSearch
                                    placeholder={`Local de entrega: ${labelRegionDropdown}`}
                                    width={"380px"}
                                    height={showBoxRegion ? "40px" : "100%"}
                                    onClick={toggleBoxRegion}
                                    handleAddTag={toggleBoxRegion}
                                    type="button"
                                    borderTopRightRadius="16px"
                                    borderTopLeftRadius="16px"
                                    borderBottomRightRadius={showBoxRegion ? 0 : "16px"}
                                    borderBotttomLeftRadius={showBoxRegion ? 0 : "16px"}
                                    icon={DropdownIcon}
                                    search
                                />
                                {showBoxRegion && (
                                <ContainerDropDown>
                                    <BoxSavedFilters>
                                        {
                                            regions.map((currentItem: string, index: number) => (
                                                <Button
                                                    key={index}
                                                    color={Colors.blue8}
                                                    background={Colors.white}
                                                    border={`1px solid ${Colors.blue6}`}
                                                    width="200px"
                                                    height="2.5rem"
                                                    margin=".20rem 0"
                                                    onClick={() => {
                                                        setLabelRegionDropdown(currentItem);
                                                        toggleBoxRegion();
                                                    }}
                                                >{currentItem}</Button>
                                            ))
                                        }
                                    </BoxSavedFilters>
                                </ContainerDropDown>)}
                            </BlockFilterOpportunities>

                            <ContainerCheckboxDropDown>
                                <InputTitle>Buscar:</InputTitle>
                                <BlockFilter>
                                    <FilterSearch
                                        placeholder={"Digite palavras-chave"}
                                        width={"100%"}
                                        value={searchFilter}
                                        onChange={(ev) => setSearchFilter(ev.target.value)}
                                        borderTopRightRadius="16px"
                                        borderTopLeftRadius="16px"
                                        borderBotttomLeftRadius={"16px"}
                                        borderBottomRightRadius={"16px"}
                                        search
                                        icon={search}
                                    />
                                </BlockFilter>
                                <BlockFilterOpportunities>
                                    <FilterSearch
                                        placeholder={`Filtrar por Status: ${labelDropDown}`}
                                        width={"250px"}
                                        onClick={toggleBoxFilter}
                                        handleAddTag={toggleBoxFilter}
                                        type="button"
                                        borderTopRightRadius="16px"
                                        borderTopLeftRadius="16px"
                                        borderBottomRightRadius={isBoxSavedFilterOpen ? 0 : "16px"}
                                        borderBotttomLeftRadius={isBoxSavedFilterOpen ? 0 : "16px"}
                                        icon={DropdownIcon}
                                        search
                                    />
                                    {isBoxSavedFilterOpen && (
                                    <ContainerDropDown>
                                        <BoxSavedFilters>
                                            {
                                                ['TODOS',
                                                'Não cotadas', 
                                                'Cotadas com menor valor', 
                                                'Cotadas com maior valor',
                                                'Declínio'].map((currentItem: string, index: number) => (
                                                    <Button
                                                        key={index}
                                                        color={Colors.blue8}
                                                        background={Colors.white}
                                                        border={`1px solid ${Colors.blue6}`}
                                                        width="200px"
                                                        height="2.5rem"
                                                        margin=".20rem 0"
                                                        onClick={() => {
                                                            setLabelDropDown(currentItem);
                                                            toggleBoxFilter();
                                                        }}
                                                    >{currentItem}</Button>
                                                ))
                                            }
                                        </BoxSavedFilters>
                                    </ContainerDropDown>)}
                                </BlockFilterOpportunities>
                                <FlexRow>
                                    <Button
                                        color={Colors.blue8}
                                        background={Colors.white}
                                        border={`1px solid ${Colors.blue6}`}
                                        width="200px"
                                        height="2rem"
                                        margin="0"
                                        onClick={reverseArray}
                                    >
                                        Ordenar por data: <ReverseIcon src={isReverse ? MoveDown : MoveUp} alt={"icon"} />
                                    </Button>
                                    {
                                        (searchFilter || labelDropDown || labelRegionDropdown) &&
                                        <ResetFilterButton onClick={resetFilterings}>
                                            <ResetFilterIcon src={CloseIcon} alt={"icon"} />
                                        </ResetFilterButton>
                                    }
                                </FlexRow>
                            </ContainerCheckboxDropDown>
                        </FlexWrapper>
                        <SectionContent>
                            {
                                (sortedItems && sortedItems.length > 0) ? 
                                <ProposalCardsContainer items={sortedItems} /> : 
                                <p>Nenhum item foi encontrada.</p>
                            }
                        </SectionContent>
                    </> : <LoadingList animationText={"Procurando..."} />
                }
            </SectionContainer>
        </>
    )
}

const mapSateToProps = (state: any) => ({
    integrations: state.integrationProfiles,
});

const mapDispatchToProps = (dispatch: any) => ({
  getIntegrationProfiles: () => dispatch(getIntegrationProfilesFromAPI()),
});

export default connect(mapSateToProps, mapDispatchToProps)(Marketplace);