import React from "react";
import { BrowserRouter } from "react-router-dom";
import store from "./dataflow/store";
import { Provider } from "react-redux";
import Routes from "./routes";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import GlobalStyle from "./assets/styles/global";
import ReactGA from "react-ga4";
import {
  REACT_GA_TRACKING_CODE
} from "./util/analytics/index"

const App = () => {
  ReactGA.initialize(REACT_GA_TRACKING_CODE);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes />
        <GlobalStyle />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
