import styled from "styled-components";

const CardWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 12px 6px;

    @media(max-width: 900px) {
        flex-direction: column;
    }
`;

export {
    CardWrapper
}