import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Loading,
  Container,
  ScreenContainer,
  RangeContainer,
  CoefficientContainer,
} from "./styles";
// import { truncate } from "lodash";
import "react-tagsinput/react-tagsinput.css";
import "./SearchStyle.css";
import CoefficientChart from "../../components/modules-components/my-performance/CoefficientChart";
import OpportunityHistory from "../../components/modules-components/my-performance/OpportunityHistory";
import OpportunityDetails from "../../components/modules-components/my-performance/OpportunityDetails";
import {
  toggleFilter,
  checkAllFilters,
  uncheckAllFilters,
} from "../../dataflow/modules/filter-module";
import {
  setFilterRange,
  setBubblesRange,
  setSearchTags,
  cleanOpportunitiesModule,
  saveOpportunities,
  // setDataSetRange,
} from "../../dataflow/modules/opportunities-module";
import {
  getMonthTransactions,
  getMonthBubbles,
} from "../../dataflow/selectors/opportunities-selector";
import {
  getFiltersThunk,
  applyTemporaryFilterThunk,
} from "../../dataflow/thunks/filters-thunk";
import {
  saveOpportunitiesThunk,
  setGraphTimelineThunk,
  setCurrencyTypeThunk,
  getDescriptionThunk,
} from "../../dataflow/thunks/opportunities-thunk";
import Summary from "../../components/modules-components/my-performance/Summary";
import GraphSwitch from "../../components/modules-components/my-performance/GraphSwitch";
import Range from "../../components/modules-components/my-performance/Range";
import Graph from "../../components/modules-components/my-performance/Graph";
import {
  getIntegrationProfilesFromAPI,
  setCurrentIntegrationProfile,
} from "../../dataflow/thunks/integration-profiles";
import loading from "../../assets/animations/loading.svg";
import WelcomeEasyProposal from "../../components/modules-components/match-analytical/WelcomeEasyProposal";
import PageHeaderDescription from "../../components/common-components/PageHeaderDescription/PageHeaderDescription";
import Modal from "../../components/common-components/Modal/Modal";
import Colors from "../../assets/styles/colors";
import { eventCategory, logEvent } from "../../util/analytics/index";

interface DashboardProps {
  integrations: any;
  saveOpportunitiesThunk: (v: any) => void;
  getIntegrationProfiles: () => void;
  cleanOpportunitiesModule: () => void;
  opportunities: any;
  setGraphTimelineThunk: (v: any, v2: any) => void;
  setCurrencyTypeThunk: (v: any, v2: any) => void;
  filterRange: any;
  min: any;
  max: any;
  setBubblesRange: (v: any) => void;
  setFilterRange: (v: any) => void;
  originalOpportunities: any;
  saveOpportunities: (v: any) => void;
  summary: any;
  setSearchTags: (v: any) => void;
  timeline: any;
  currentCurrency: any;
  dataSetRange: any;
  notFound: any;
  props: any;
  monthlyTransactions: any;
  monthlyBubbles: any;
}

const Dashboard: FC<DashboardProps> = ({
  integrations,
  saveOpportunitiesThunk,
  getIntegrationProfiles,
  cleanOpportunitiesModule,
  opportunities,
  setGraphTimelineThunk,
  filterRange,
  min,
  max,
  setBubblesRange,
  setFilterRange,
  originalOpportunities,
  saveOpportunities,
  summary,
  setSearchTags,
  timeline,
  currentCurrency,
  dataSetRange,
  notFound,
  props,
  monthlyTransactions,
  monthlyBubbles
}) => {
  const [state, setState] = useState<any>({
    // opportunityDetails: undefined,
    currentOpenMenu: undefined,
    // tags: [],
    graphTypes: [
      {
        type: "bubbles",
        label: "Oportunidades",
      },
      {
        type: "bars",
        label: "Acumulado",
      },
    ],
    selectedGraph: "bubbles",
    filterRangeErrors: {
      minValue: false,
      maxValue: false,
    },
    filterApplied: false,
    mediaMatch: false,
    lastSelected: undefined,
    currentCurrency: currentCurrency,
  })
  const [contentDash, setContentDash] = useState<any>();


  useEffect(() => {
    if (!integrations?.currentProfile) {
      getIntegrationProfiles();
    } else {
      const { document } = integrations?.currentProfile;
      saveOpportunitiesThunk(document.number);
    }
    setContentDash(document?.getElementById("content-dash"));
    const mediaMatch = window?.matchMedia("(max-width: 480px)");
    setState({
      ...state,
      mediaMatch,

    });
    // document.addEventListener('click', () => {console.log('montou e add o listener');cleanOpportunitiesModule()});
    // document.removeEventListener('click', () => {console.log('dexecutou ao desmontar');cleanOpportunitiesModule()})
  }, [])

  useEffect(() => {
    const currentProfile = integrations?.currentProfile;
    saveOpportunitiesThunk(currentProfile?.document?.number);
  }, [integrations])

  const setOpportunityDetails = (id: any) => {
    setState({
      ...state,
      selectedOportunity: opportunities.filter(
        (item: any) => item.id === id
      )[0],

    });
  };
  const handleSetMonth = (item: any) => {
    const { document } = integrations?.currentProfile;
    setState({
      ...state,
      currentMonths: item,
      currentOpenMenu: undefined,

    });
    setGraphTimelineThunk(document?.number, item);
  };

  const handleSetCurrency = (item: any) => {
    const { document } = integrations?.currentProfile;
    setState({
      ...state,
      currentCurrency: item,
      currentOpenMenu: undefined,


    });
    setCurrencyTypeThunk(document?.number, item);
  };

  // const handleSelectFilterItem = (ev: any, item: any) => {
  //   if (!ev.target.checked && checkAll.checked) {
  //     checkAll.checked = false;
  //   }
  //   props.toggleFilter(item.ids);
  // };

  const handleApplyFilter = () => {
    if (filterRange?.minValue > filterRange?.maxValue) {
      setState({
        ...state,
        filterRangeErrors: {
          minValue: true,
          maxValue: true,
        },

      });
      return;
    }
    setBubblesRange({
      ...filterRange,
    });
    setState({
      ...state,
      filterApplied: !(
        filterRange?.minValue === min && filterRange?.maxValue === max
      ),

    });
  };
  const handleCleanFilter = () => {
    setFilterRange({
      minValue: min,
      maxValue: max,
    });
    setBubblesRange({
      minValue: min,
      maxValue: max,
    });
    setState({
      ...state,
      filterRangeErrors: {
        minValue: false,
        maxValue: false,
      },
      filterApplied: false,

    });
  };

  const selectOpportunities = (type: any) => {
    const types: any = {
      won: originalOpportunities.filter((item: any) => item.winner),
      lost: originalOpportunities.reduce((filtered: any, item: any) => {
        if (
          item.statusProposal === "Cotação" &&
          item.statusQualification === "Classificado" &&
          !item.winner
        ) {
          const nItem = {
            highlightColor: Colors.red,
            ...item,
          };
          filtered = [...filtered, nItem];
        }
        return filtered;
      }, []),
      disqualified: originalOpportunities.reduce(
        (filtered: any, item: any) => {
          if (
            item.statusProposal === "Cotação" &&
            item.statusQualification === "Desclassificado"
          ) {
            const nItem = {
              highlightColor: Colors.orange5,
              ...item,
            };
            filtered = [...filtered, nItem];
          }
          return filtered;
        },
        []
      ),
      declined: originalOpportunities.reduce((filtered: any, item: any) => {
        if (item.statusProposal === "Declínio") {
          const nItem = {
            highlightColor: Colors.purple1,
            ...item,
          };
          filtered = [...filtered, nItem];
        }
        return filtered;
      }, []),
    };
    const data: any = types[type];

    if (data.length > 0) {
    //   window?.localStorage?.setItem(
    //     "opportunities-my-performance",
    //     JSON.stringify(types || '')
    // );
      saveOpportunities({
        summary: summary,
        opportunities: data,
        originalOpportunities: originalOpportunities,
      });
    }
  };
  const handleSelectOpportunities = (ev: any, type: any) => {
    // ev?.preventDefault();
    if (state?.lastSelected === type || type === undefined) {
      saveOpportunities({
        summary: summary,
        opportunities: originalOpportunities,
      });
      setState({
        ...state,
        lastSelected: undefined,

      });
      return;
    }
    selectOpportunities(type);
    setState({
      ...state,
      lastSelected: type,

    });
  };

  setSearchTags = (tags: any) => {
    setSearchTags(tags);
    const { document } = integrations.currentProfile;
    if (tags.length <= 0) {
      props.uncheckAllFilters();
      return saveOpportunitiesThunk(document.number);
    }
    props.getDescriptionThunk(document.number, tags);
  };

  const handleOpenMenu = (type: any) => {
    logEvent(
      eventCategory?.dropdownChange,
      `click dropdown ${type === "months" ? "Meses" : "Moeda"}`,
      `${type === "months" ? "Meses" : "Moeda"}`
    );
    setState(() => ({
      ...state,
      currentOpenMenu: state?.currentOpenMenu === type ? undefined : type,

    }));
  };

  const handleChangeSelectedType = (type: any) => {
    logEvent(
      eventCategory.buttonClick,
      `click  ${type === "bars" ? "Acumulado" : "Oportunidades"}`,
      `${type === "bars" ? "Acumulado" : "Oportunidades"}`
    );
    setState({ ...state, selectedGraph: type, });
  };

  // truncate = (string: any) =>
  //   truncate(string, {
  //     length: 8,
  //   });

  const renderNotFoundMessage = () => (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "1rem auto 0",
        height: "320px",
        border: "1px solid E6E6E6",
        borderRadius: "12px",
        fontWeight: "500",
        color: Colors.gray6,
      }}
    >
      <span>
        Olá, para começar a usar o Desempenho é necessário habilitar seu CNPJ em
        Integrações.
      </span>
    </div>
  );

  const { integrationProfiles } = integrations;
  if (!integrationProfiles) {
    return (
      <Loading>
        <img src={loading} height={50} alt="Loading..." />
      </Loading>
    );
  }
  return integrationProfiles.length > 0 ? (
    <>
      <Container>
        <PageHeaderDescription
          title={"Meu Desempenho"}
          paragraphs={[
            "É sua análise de concorrência através de oportunidades encerradas pelo comprador e disponibilizadas para visualização. No painel ficam disponíveis as oportunidades vencedoras, perdidas, desqualificadas e declinadas. No Coeficiente de Competitividade de Preços é verificado em porcentagem a diferença do valor ofertado pela sua empresa e do seu concorrente.",
          ]}
        />
        <Summary
          currentCurrency={state.currentCurrency}
          dataSetRange={dataSetRange}
          currentOpenMenu={state.currentOpenMenu}
          opportunities={opportunities}
          summary={summary}
          timeline={timeline}
          lastSelected={state.lastSelected}
          // {...props}
          handleSelectOpportunities={handleSelectOpportunities}
          handleSetMonth={handleSetMonth}
          handleSetCurrency={handleSetCurrency}
          handleOpenMenu={handleOpenMenu}
          // originalOpportunities={originalOpportunities}
        />
        <GraphSwitch
          {...props}
          {...state}
          handleChangeSelectedType={handleChangeSelectedType}
        />
        {opportunities.length > 0 && (
          <Graph
            dataSetRange={dataSetRange}
            monthlyBubbles={monthlyBubbles}
            selectedGraph={state.selectedGraph}
            opportunities={opportunities}
            selectedOportunity={state.selectedOportunity}
            bubblesRange={filterRange}
            monthlyTransactions={monthlyTransactions}
            timeline={timeline}
            setOpportunityDetails={setOpportunityDetails}
            contentDash={contentDash}
          />
        )}
        <RangeContainer>
          <Range
            currentCurrency={state.currentCurrency}
            filterRange={filterRange}
            filterRangeErrors={state.filterRangeErrors}
            mediaMatch={state.mediaMatch}
            selectedGraph={state.selectedGraph}
            max={max}
            min={min}
            {...props}
            setFilterRange={setFilterRange}
            handleApplyFilter={handleApplyFilter}
            handleCleanFilter={handleCleanFilter}
          />
        </RangeContainer>
        {notFound && opportunities.length === 0 && renderNotFoundMessage()}
        {opportunities.length > 0 && (
          <CoefficientContainer>
            <CoefficientChart
              height={320}
              width={contentDash ? contentDash.offsetWidth - 34 : 0}
              {...props}
              data={opportunities}
              // timeline={timeline}
            />
          </CoefficientContainer>
        )}
        {state.selectedOportunity ? (
          <Modal onBlur={() => {
            logEvent(
              eventCategory.modalOpen,
              'click modal de detalhes',
              `${state.selectedOportunity.description}`
            );
          }} closeModal={(v?: any) => setOpportunityDetails(v)}>
            <OpportunityDetails
              selectedOportunity={state.selectedOportunity}
            // setOpportunityDetails={(id?:any)=>setOpportunityDetails(id)}
            />
          </Modal>
        ) : (
          <OpportunityHistory
            setOpportunityDetails={setOpportunityDetails}
            // opportunityDetails={state.opportunityDetails}
            opportunities={opportunities}
            integrationProfile={integrations.currentProfile}
          />
        )}
      </Container>
    </>
  ) : (
    <ScreenContainer>
      <WelcomeEasyProposal feature={"my-performance"} />
    </ScreenContainer>
  );
}
const mapStateToProps = (state: any) => ({
  integrations: state.integrationProfiles,
  summary: state.opportunities.summary,
  opportunities: state.opportunities.opportunities,
  monthlyTransactions: getMonthTransactions(
    state.opportunities.opportunities,
    state.opportunities.timeline,
    state.opportunities.dataSetRange
  ),
  monthlyBubbles: getMonthBubbles(
    state.opportunities.opportunities,
    state.opportunities.timeline,
    state.opportunities.dataSetRange
  ),
  keywords: state.filter.cardFilter.keywords,
  isLoading: state.opportunities.isLoading,
  notFound: state.opportunities.notFound,
  currentCurrency: state.opportunities.currentCurrency,
  timeline: state.opportunities.timeline,
  filters: state.filter.filters,
  selectedFilters: state.filter.selectedFilters,
  searchModal: state.filter.searchModal,
  filterModal: state.filter.filterModal,
  filterDropdown: state.filter.filterDropdown,
  userFilters: state.filter.userFilters,
  min: state.opportunities.min,
  max: state.opportunities.max,
  filterRange: state.opportunities.filterRange,
  bubblesRange: state.opportunities.bubblesRange,
  setupId: state.opportunities.setupId,
  isSearchingItems: state.opportunities.isSearchingItems,
  searchTags: state.opportunities.searchTags,
  dataSetRange: state.opportunities.dataSetRange,
  originalOpportunities: state.opportunities.originalOpportunities,
});
const mapDispatchToProps = (dispatch: any) => ({
  setFilterRange: (info: any) => dispatch(setFilterRange(info)),
  setBubblesRange: (info: any) => dispatch(setBubblesRange(info)),
  saveOpportunitiesThunk: (document: any, info: any) =>
    dispatch(saveOpportunitiesThunk(document, info)),
  setGraphTimelineThunk: (document: any, info: any) =>
    dispatch(setGraphTimelineThunk(document, info)),
  setCurrencyTypeThunk: (document: any, info: any) =>
    dispatch(setCurrencyTypeThunk(document, info)),
  checkAllFilters: () => dispatch(checkAllFilters()),
  uncheckAllFilters: () => dispatch(uncheckAllFilters()),
  toggleFilter: (info: any) => dispatch(toggleFilter(info)),
  getFiltersThunk: (info: any) => dispatch(getFiltersThunk(info)),
  applyTemporaryFilterThunk: (info: any) =>
    dispatch(applyTemporaryFilterThunk(info)),
  getDescriptionThunk: (info: any) => dispatch(getDescriptionThunk(info)),
  setSearchTags: (info: any) => dispatch(setSearchTags(info)),
  cleanOpportunitiesModule: () => dispatch(cleanOpportunitiesModule()),
  saveOpportunities: (info: any) => dispatch(saveOpportunities(info)),
  getIntegrationProfiles: () => dispatch(getIntegrationProfilesFromAPI()),
  setCurrentIntegrationProfile: () => dispatch(setCurrentIntegrationProfile()),
});
export default connect<any>(mapStateToProps, mapDispatchToProps)(Dashboard);