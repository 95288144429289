import styled from "styled-components";
import Colors from "../../../../assets/styles/colors";

const Texto = styled.p`
  line-height: 1.4;
  letter-spacing: 0px;
  font-size: 16px;
  color: ${Colors.gray7};
`;

const Form = styled.form`
  // min-height: 446px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: 768px) {
    width: 100%;
    max-width: 600px;
    height: auto;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
`;

 const Input = styled.input`
  border: 1px solid ${Colors.gray3};
  margin-bottom: 15px;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  color: ${Colors.blue6};
  padding-left: 10px;
  font-size: 16px;
  width: 100%;
  :focus {
    outline: 2px solid ${Colors.blue6};
  }
  ::placeholder {
    color: ${Colors.gray5};
  }
`;

 const RowButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

 const InputButton = styled.input`
  width: auto;
  height: auto;
  padding: 0.5rem 1rem 0.5rem 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  background: ${Colors.blue6};
  color: ${Colors.white};
  :active {
    opacity: 0.5;
  }
  :focus {
    outline: none;
  }
`;

 const Span = styled.span`
  margin-bottom: 3px;
  color: ${Colors.blue6};
  font-size: 16px;
  margin-bottom: 5px;
`;

export {
  Texto,
  Form,
  Label,
  Input,
  RowButton,
  InputButton,
  Span
}
