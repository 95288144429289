import React, { FC } from "react";
import { TextDescription, Text, Link } from "../../../components/common-components/Accordion/styles";
import { Image } from "../styles";
import Integrations01 from "../../../assets/img/faq/integrations1.png";
import Integrations02 from "../../../assets/img/faq/integrations2.png";
import Integrations03 from "../../../assets/img/faq/integrations3.png";
import Integrations04 from "../../../assets/img/faq/integrations4.png";
import Integrations05 from "../../../assets/img/faq/integrations5.png";
import Integrations06 from "../../../assets/img/faq/integrations6.png";

const Integrations: FC = () => {
    return (
        <>
            <TextDescription>
                <Text>
                    {`Acesse o site `}
                </Text>
                <Link
                    href="https://www.petronect.com.br"
                    target="_blank"
                >
                    www.petronect.com.br
                </Link>
                <Text>
                    {` e faça o seu login com seu usuário e senha do Portal Petronect.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={Integrations01} alt="Imagem" />
            </TextDescription>
            <TextDescription>
                <Text>
                    {`Dentro do Portal Petronect, na pasta “A Petronect” clique em Integrações.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={Integrations02} alt="Imagem" />
            </TextDescription>
            <TextDescription>
                <Text>
                    {`Será necessário aceitar os termos para que o Proposta Fácil tenha acesso as oportunidades do CNPJ. A qualquer momento o fornecedor poderá retirar esse acesso.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={Integrations03} alt="Imagem" />
            </TextDescription>
            <TextDescription>
                <Image src={Integrations04} alt="Imagem" />
            </TextDescription>
            <TextDescription>
                <Text>
                    {`Após aceitar os termos, a tela seguirá com a mensagem “Token criado com sucesso”. `}
                </Text>
            </TextDescription>
            <TextDescription>
                <Text>
                    {`O mesmo será apresentado no quadro logo abaixo.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={Integrations05} alt="Imagem" />
            </TextDescription>
            <TextDescription>
                <Text>
                    {`Logo após coletar o token, retorne ao site da Minha Petronect vá em “Integrações” e preencha os campos com os dados solicitados (CNPJ e Token). `}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={Integrations06} alt="Imagem" />
            </TextDescription>
        </>
    )
}
export default Integrations;