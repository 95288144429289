import styled from "styled-components";
import Colors from "../../../assets/styles/colors";

const PageDefaultHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  color: ${Colors.blue6};

  h2 {
    font-weight: bold;
    white-space: nowrap;
    margin-right: 1.5rem;
  }

  > .textHeader {
    @media (min-width: 601px) {
      margin-left: 1.5rem;
    border: 0px solid ${Colors.gray2};
    border-left-width: 2px;
    }

    ul,
    p {
      padding: 0px 2rem;
      margin-right: 2rem;
      text-align: justify;
    }

    li {
      padding-left: 0px;
      margin-left: 2rem;
    }
    .doubleParagraph {
      display: flex;

      div {
        width: 50%;
        text-align: justify;
      }
    }
  }
  @media (max-width: 1450px) {
    h2 {
      /* white-space: normal; */
      margin: 0px;
    }
    p {
      margin: 0px;
      padding-right: 0px !important;
    }
  }

  @media (max-width:600px) {
    flex-direction: column;
    h2 {
      /* white-space: normal; */
      margin: 10px;
    }
  }
`;

const TitleContainer = styled.div`
display: flex;
flex-direction: row;
`

const InfoImage = styled.img`
  width: 24px;
`;

export { PageDefaultHeader,TitleContainer,InfoImage };
