import React, { FC } from "react";
import {
  DropdownItem,
  DropdownIcon,
  DropdownTitle,
  TagPRO
} from "./styles";

interface DropdownOptionProps {
  isHovering: string;
  title: string;
  commingSoon?: boolean;
  feature?: string;
  screen: string;
  isOpen: boolean;
  handleDropdown: () => void;
  handleHover: () => void;
  handlenoHover: () => void;
  isActive: boolean;
  icon: string;
  showPro?: boolean;
}

const DropdownOption: FC<DropdownOptionProps> = ({
  isHovering,
  title,
  commingSoon,
  feature,
  screen,
  isOpen,
  handleDropdown,
  handleHover,
  handlenoHover,
  isActive,
  icon,
  showPro
}) => {
  const hover = isHovering === title;
  return (
    <DropdownItem
      to={commingSoon ? `/em-breve/${feature}` : screen}
      isOpen={isOpen || hover}
      onClick={handleDropdown}
      onMouseEnter={handleHover}
      onMouseLeave={handlenoHover}
      isActive={isActive}
    >
      <DropdownIcon src={icon} isActive={isActive} />
      <DropdownTitle isOpen={isOpen || hover}>
        {title}
        {showPro && <TagPRO>PRO</TagPRO>}
        {commingSoon && <TagPRO>Em Breve</TagPRO>}
      </DropdownTitle>
    </DropdownItem>
  );
};

export default DropdownOption;
