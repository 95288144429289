import React, { FC } from "react";
import { TextDescription, Text, } from "../../../components/common-components/Accordion/styles";
import { Image } from "../styles";
import CheckEmail01 from "../../../assets/img/faq/checkEmail1.png";

const CheckEmail: FC = () => {
    return (
        <>
            <TextDescription>
                <Text>
                    {`Caso você não tenha realizado a verificação do e-mail dentro do prazo de 24 horas, você deverá utilizar o botão “Verificar Email” dentro do site da Minha Petronect para reenviar um novo email de verificação.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={CheckEmail01} alt="Imagem" />
            </TextDescription>
        </>
    )
}
export default CheckEmail;