import { useState } from 'react';

const useConfirmationModal = () => {
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [integrationIdToDelete, setIntegrationIdToDelete] = useState<string | null>(null);
    const [cnpjToDelete, setCnpjToDelete] = useState<string | null>(null);

    const openConfirmModal = (integrationId: string, cnpj: string) => {
        setIntegrationIdToDelete(integrationId);
        setCnpjToDelete(cnpj);
        setShowConfirmModal(true);
    };

    const confirmUnlinking = (handleDeleteIntegration: (integrationId: string) => void) => {
        if (integrationIdToDelete) {
            handleDeleteIntegration(integrationIdToDelete);
            setIntegrationIdToDelete(null);
        }
        setShowConfirmModal(false);
    };

    return { showConfirmModal, setShowConfirmModal, integrationIdToDelete, cnpjToDelete, openConfirmModal, confirmUnlinking };
};

export default useConfirmationModal;
