import React, { FC, Fragment } from "react";
import FilterImg from "../../assets/img/src/pages/Help/Filter.png";
import ControlImg from "../../assets/img/src/pages/Help/control.png";
import GraphImg from "../../assets/img/src/pages/Help/graph.png";
import {
  Container,
  WrapHeader,
  Title,
  WrapTopic,
  SubTitle,
  WrapperImg,
  Text
} from "./styles";

const Help: FC = () => {
  const tutorial = [
    {
      subtitle: "Filtros",
      img: FilterImg,
      description:
        'Com os filtros você agilizar a busca de informações de oportunidades e itens. Você pode fazer uma busca rápida digitando palavras-chave no campo "Filtrar" na parte superior da tela. Essa busca pode ser genérica ou você pode selecionar apenas alguns itens. Os filtros podem ser salvos para serem usados em outras ocasiões.',
      descriptionother:
        'No canto superior direito você vai encontrar os filtros que salvou anteriormente. Você aplicar mais um filtro para gerar uma busca mais completa. Em "Gerenciar filtros" estão todos os filtros salvos, podendo ser excluídos ou editados.',
    },
    {
      subtitle: "Painel de controle",
      img: ControlImg,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut blandit sapien sodales erat vulputate commodo. Sed at hendrerit elit. Nam in libero id dui efficitur vulputate. Nullam nisi quam, dignissim eget nunc id, sagittis scelerisque massa. In erat ante, finibus sed sem at, dapibus aliquam urna. Sed rhoncus, odio quis ultricies malesuada, justo metus viverra augue, sit amet congue nisl eros quis ipsum. Ut ut finibus felis, sit amet accumsan dolor. Donec varius justo vel scelerisque pellentesque. Proin quis consectetur libero. Phasellus lobortis lectus auctor, blandit ligula vel, imperdiet metus. Quisque aliquam dui quis risus tincidunt sodales. Curabitur posuere tellus sit amet tincidunt bibendum. Sed risus magna, pharetra et tincidunt tincidunt, porta sit amet arcu.",
    },
    {
      subtitle: "Gráficos",
      img: GraphImg,
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut blandit sapien sodales erat vulputate commodo. Sed at hendrerit elit. Nam in libero id dui efficitur vulputate. Nullam nisi quam, dignissim eget nunc id, sagittis scelerisque massa. In erat ante, finibus sed sem at, dapibus aliquam urna. Sed rhoncus, odio quis ultricies malesuada, justo metus viverra augue, sit amet congue nisl eros quis ipsum. Ut ut finibus felis, sit amet accumsan dolor. Donec varius justo vel scelerisque pellentesque. Proin quis consectetur libero. Phasellus lobortis lectus auctor, blandit ligula vel, imperdiet metus. Quisque aliquam dui quis risus tincidunt sodales. Curabitur posuere tellus sit amet tincidunt bibendum. Sed risus magna, pharetra et tincidunt tincidunt, porta sit amet arcu.",
    },
  ];

  return (
    <Fragment>
      <Container>
        <WrapHeader>
          <Title>Guia do usuário</Title>
          <Text height={"6.5rem"}>
            Petronect é um sistema para que fornecedores possam visualizar seu
            desempenho ao longo do tempo a fim de aumentar sua inteligência
            financeira de precificação. Neste guia rápido você vai encontrar
            dicas de como usar e a descrição das principais funcionalidades do
            sistema.
          </Text>
        </WrapHeader>
        {tutorial.map((item,index) => (
          <WrapTopic key={index}>
            <SubTitle>{item.subtitle}</SubTitle>
            <WrapperImg>
              <img src={item.img} alt={"icon"} />
            </WrapperImg>
            <Text>{item.description}</Text>
            <Text>{item.descriptionother}</Text>
          </WrapTopic>
        ))}
      </Container>
    </Fragment >
  );
}


export default Help;
