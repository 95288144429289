import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FilterIcon from "../../assets/icons/common/filtrar.svg";
import search from "../../assets/icons/common/search-icon.svg";
import Filter from "../../components/modules-components/match-relevance/NotificationFilter/NotificationFilter";
import FilterSearch from "../../components/modules-components/match-relevance/FilterSearch";
import { saveModal } from "../../dataflow/modules/modal-module";
import {
  getMatchFilterThunk,
  updateNotificationBellThunk,
  getNotificationThunk,
} from "../../dataflow/thunks/filters-thunk";
import {
  setNotification,
  toggleDropdownFilter,
} from "../../dataflow/modules/filter-module";
import { getMatchOpportunitiesThunk } from "../../dataflow/thunks/opportunities-thunk";

import {
  AddFilterButton,
  Box,
  Container,
  Description,
  Icon,
  NotificationSidebar,
  Span,
  Switch,
  SwitchBox,
  SwitchWrapper,
  Title,
  Wrapper,
  BackScreenDiv,
  ContentDiv,
} from "./styles";
import { eventCategory, logEvent } from "../../util/analytics/index";

interface MatchFiltersProps {
  getMatchFilterThunk: () => void;
  getNotificationThunk: () => void;
  saveModal: ({ type, isOpen, selectedFilter }: { type: string, isOpen: boolean, selectedFilter: any }) => void;
  getMatchOpportunitiesThunk: (v: any) => void;
  matchFilter: any;
  isNotification: any;
  setNotification: (v: any) => void;
  updateNotificationBellThunk: () => void;
}

const MatchFilters: FC<MatchFiltersProps> = ({
  getMatchFilterThunk,
  getNotificationThunk,
  saveModal,
  getMatchOpportunitiesThunk,
  matchFilter,
  isNotification,
  setNotification,
  updateNotificationBellThunk
}) => {

  const [valuetoSearchFilter, setValuetoSearchFilter] = useState("");
  const [currentList, setCurrentList] = useState([]);

  useEffect(() => {
    getMatchFilterThunk();
    getNotificationThunk();
  },[])

  const handleEditFilter = (filter: any) => {
    saveModal({
      type: "filter-edit",
      isOpen: true,
      selectedFilter: filter,
    });
  };

  const toggleDeleteFilter = (filter: any) => {
    saveModal({
      type: "filter-delete",
      isOpen: true,
      selectedFilter: filter,
    });
  };

  const handleSearch = (tags: any) => {
    getMatchOpportunitiesThunk(tags);
  };

  const handleChange = (ev: any) => {
    handleFilterSearch();
    setValuetoSearchFilter(ev?.target?.value)

  };

  const handleFilterSearch = () => {

    const filteredList = matchFilter?.filter((item: any) => {
      return (
        item?.name?.search(valuetoSearchFilter) !== -1 ||
        item?.tags?.join(",").search(valuetoSearchFilter) !== -1
      );
    });
    setCurrentList(filteredList)

  };

  const updateId = () => {
    getMatchFilterThunk();
  };

  const handleNotification = () => {
    const on = 1;
    const off = 0;

    logEvent(
      eventCategory.buttonClick,
      "click receber notificações diárias por email",
      "Notificações"
    );

    if (isNotification === 0) {
      setNotification(on);
    } else {
      setNotification(off);
    }
    updateNotificationBellThunk();
  };

  const renderFilters = () => {
    const list = valuetoSearchFilter !== "" ? currentList : matchFilter;

    return list.map((filter: any) => {
      return (
        <Filter
          key={filter?.id}
          handleDelete={() => {
            toggleDeleteFilter(filter);
          }}
          handleEdit={() => {
            handleEditFilter(filter);
          }}
          handleSearch={() => handleSearch(filter?.tags)}
          name={filter?.name}
          updateId={updateId}
          background={filter?.color}
          tags={filter?.tags}
          screen={"/match-filter"}
          filter={filter}
        />
      );
    });
  };

  const renderSwitchToggle = () => {
    return (
      <Switch change={isNotification === 1} onClick={handleNotification}>
        <Span change={isNotification === 1}></Span>
      </Switch>
    );
  };

  const renderNotificationSidebar = () => {
    const Switch = isNotification === 1 ? "On" : "Off";
    return (
      <>
        <SwitchWrapper>
          <Title>Notificações</Title>
          <SwitchBox>   
            {Switch}         
            {renderSwitchToggle()}
          </SwitchBox>          
        </SwitchWrapper>
        </>
    );
  };

  return (
    <Container>
      <ContentDiv>
        <BackScreenDiv>
        <Link to={"/match-relevancia"}>
            <span>Voltar</span>
          </Link>
        <AddFilterButton>
            <Icon src={FilterIcon} />
            <Box>
              <h1>Adicionar Notificação</h1>          
              <p>
                Selecione palavras-chave para encontrar contratos relevantes
              </p>
            </Box>  
          </AddFilterButton>
        </BackScreenDiv>

        <NotificationSidebar>
        <Title>Pesquisar filtro</Title>
        <FilterSearch
          icon={search}
          width={"100%"}
          placeholder={"Digite aqui"}
          onChange={handleChange}
        />
        {renderNotificationSidebar()}
         </NotificationSidebar>

        <Wrapper>  
        {renderFilters()}     
       </Wrapper>
      </ContentDiv>
    </Container>
  );
}

const mapStateToProps = (state: any) => ({
  matchFilter: state.filter.matchFilter,
  matchSelectedFilter: state.filter.matchSelectedFilter,
  modal: state.modals.modal,
  isNotification: state.filter.isNotification,
});

const mapDispatchToProps = (dispatch: any) => ({
  getMatchFilterThunk: () => dispatch(getMatchFilterThunk()),
  saveModal: (info: any) => dispatch(saveModal(info)),
  getMatchOpportunitiesThunk: (info: any) =>
    dispatch(getMatchOpportunitiesThunk(info)),
  updateNotificationBellThunk: () =>
    dispatch(updateNotificationBellThunk()),
  getNotificationThunk: () => dispatch(getNotificationThunk()),
  setNotification: (info: any) => dispatch(setNotification(info)),
  toggleDropdownFilter: (info: any) => dispatch(toggleDropdownFilter(info)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchFilters);
