import React from "react";
import { Modal } from "react-bootstrap";
import { Container, ContainerButton, Description, ButtonConfirm, ButtonClose } from "./styles";

const CommonModal = ({ 
  title, 
  children, 
  callbackCloseModal, 
  closeText = "Fechar",
  callbackConfirm,
  confirmText
}: any) => {
  return (
    <Modal show={true} onHide={callbackCloseModal} animation={true} centered >

      <Modal.Body> 
        <Container> 
          <Description>{children}</Description>

          <ContainerButton>
            {confirmText && <ButtonConfirm onClick={callbackConfirm}>{confirmText}</ButtonConfirm>}
          <ButtonClose onClick={callbackCloseModal}>{closeText}</ButtonClose>
          </ContainerButton>

        </Container>  
      </Modal.Body>

      
    </Modal>
  );
};

export default CommonModal;
