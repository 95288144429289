import React, { useEffect, useState } from "react";
import Marketplace from "../../../pages/Marketplace";
import { Loading, ScreenContainer } from "./styles";
import loading from "../../../assets/animations/loading.svg";

const MarketplaceScreenManager = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);

    return (
        <>
            {!isLoading ? (
                <ScreenContainer>
                    <Marketplace />
                </ScreenContainer>
            ) : (
                <Loading>
                    <img src={loading} height={50} alt="Loading..." />
                </Loading>
            )}
        </>
    );
};

export default MarketplaceScreenManager;
