import styled from "styled-components";
import Colors from "../../../../assets/styles/colors";
import { StylesProps } from "../../../../util/types";
import { ItemProps } from "./Item";

const Cell = styled.li`
  position: relative;
  padding: 0.6rem 0.5rem;
  font-size: 0.75rem;
  font-weight: ${(props: ItemProps) => (props.bold ? "600" : "400")};
  font-family: Open Sans;
  color: ${(props: ItemProps) =>
    props.bold ? Colors.gray6 : `${Colors.gray8}95`};
  list-style: none;

  &:nth-child(1) {
    width: 10%;
    padding-left: 1rem;
    color: ${(props: ItemProps) => props.background};
  }
  &:nth-child(2) {
    width: 20%;
  }
  &:nth-child(3) {
    width: 15%;
  }
  &:nth-child(4) {
    width: 40%;
  }
  &:nth-child(5) {
    width: 15%;
  }
  &:nth-child(6) {
    width: 15%;
  }

  @media (max-width: 590px) {
    &:nth-child(1) {
      width: auto;
    }
    &:nth-child(2) {
      width: auto;
    }
    &:nth-child(3) {
      width: auto;
    }
    &:nth-child(4) {
      width: auto;
    }
    &:nth-child(5) {
      width: auto;
    }
    &:nth-child(6) {
      width: auto;
    }
    display: ${(props: ItemProps) => props.displayMobile};
  }
`;

const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0 0.4rem;
  border-radius: 4px;
  cursor: pointer;

  &:nth-child(even) {
    background: ${Colors.white};
  }
  &:nth-child(odd) {
    background: ${Colors.white2};
  }

  @media (max-width: 590px) {
    /* &:nth-child(1) {
      display: none;
    } */
    flex-wrap: wrap;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.21);
  z-index: 8;
`;

const InfoWrap = styled.span`
  width: 95%;
  padding-bottom: 0.7rem;
`;

const Download = styled.a`
  top: 0.75rem;
  font: 100 0.85rem Open Sans;
  font-weight: ${(props: StylesProps) => (props.light ? "100" : "300")};
  text-decoration: none;
`;

const InputButton = styled.input`
  width: auto;
  height: auto;
  padding: 0.5rem 1rem 0.5rem 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  background: ${Colors.blue6};
  color: ${Colors.white};

  :active {
    opacity: 0.5;
  }

  :focus {
    outline: none;
  }
`;

const AcordeonItem = styled.div`
  margin: 0 0 2px 0;
  padding: 6px 0px 6px 10px;
  background-color: ${Colors.white};
  cursor: pointer;
  :hover {
    font-weight: bold;
    ${Download} {
      font-weight: 600;
    }
  }
`;

const ModalList = styled.ul`
  padding: 6px 0px 6px 10px;
  &:nth-child(even) {
    background: ${Colors.white};
  }
  &:nth-child(odd) {
    background: ${Colors.white2};
  }

  @media (max-width: 590px) {
    &:nth-child(1) {
      display: none;
    }
    flex-wrap: wrap;
  }
`;

const TextHighlight = styled.p`
  padding: ${(props: StylesProps) =>
    props.padding ? props.padding : "0 0 0.15rem 0"};
  color: ${(props: StylesProps) => (props.title ? Colors.gray6 : Colors.gray8)};
  font-weight: ${(props: StylesProps) => (props.bold ? 500 : 400)};
  font-size: ${(props: StylesProps) =>
    props.fontSize ? "0.90rem" : "0.85rem"};
  margin-top: ${(props: StylesProps) => props.marginTop && "0.2rem"};
  @media (max-width: 648px) {
    padding: ${(props: StylesProps) =>
      props.padding ? props.padding : "0 0 0 0"};
  }

  em {
    font-weight: 700;
    background-color: yellow;
    font-style: normal;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  padding-top: 0.75rem;
  padding-bottom: 1.5rem;
  border: solid 0.5px ${Colors.blue8}95;
  border-radius: 8px;
  background: ${Colors.white};

  @media (max-width: 490px) {
    width: 95%;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  }
`;

const Icon = styled.img`
  position: absolute;
  top: 0.75rem;
  left: 0.85rem;
  width: 23px;

  @media (max-width: 490px) {
    top: 0.5rem;
  }
`;

const Dropdown = styled.img`
  width: 14px;
  transform: rotate(${(props: StylesProps) => (props.open ? 180 : 0)}deg);
`;

const CloseButton = styled.button`
  position: absolute;
  top: -0.85rem;
  right: 0.85rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  font: 600 0.85rem Open Sans;
  border: none;
  border-radius: 50%;
  background: ${Colors.blue1};
  cursor: pointer;
  outline: none;
  z-index: 99;

  @media (max-width: 490px) {
    top: 0.5rem;
    right: 0.5rem;
    width: 38px;
    height: 38px;
    border: solid 0.5px ${Colors.blue6};
    background: ${Colors.white};
  }
`;

const CloseIcon = styled.img`
  width: 78%;

  @media (max-width: 490px) {
    width: 64%;
  }
`;

const Title = styled.p`
  position: ${(props: StylesProps) => !props.blue && "absolute"};
  top: ${(props: StylesProps) => !props.blue && ".9rem"};
  left: ${(props: StylesProps) => !props.blue && "3rem"};
  margin-top: 0;
  font: 600 0.85rem Open Sans;
  font-size: ${(props: StylesProps) => (props.blue ? ".95rem" : ".85rem")};
  color: ${(props: StylesProps) => (props.blue ? Colors.blue6 : Colors.green4)};

  @media (max-width: 490px) {
    top: ${(props: StylesProps) => !props.blue && ".7rem"};
  }
`;

const Box = styled.div`
  display: ${(props: StylesProps) => props.row && "flex"};
  width: ${(props: StylesProps) => props.second ? "30%" : "62%"};
  margin-left: ${(props: StylesProps) => props.second ? "20px" : "0px"};
  @media (max-width: 490px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 1rem;
  padding-left: 3rem;
  margin-bottom: 1rem;

  @media (max-width: 490px) {
    flex-direction: column;
    padding-left: 1.3rem;
  }
`;

const SmallTitle = styled.p`
  margin-bottom: 0;
  font: 400 0.85rem Eurostile;
  color: ${Colors.gray6}95;
`;

const SmallAccordionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${Colors.white2};
  padding: 0 10px 0 10px;
  margin-bottom: 0;
  margin-top: 5px;
  font: 400 0.85rem Eurostile;
  font-weight: bold;
  color: ${Colors.gray8}95;
  cursor: pointer;
`;

const Text = styled.p`
  margin-top: 3px;
  font: 600 0.85rem Open Sans;
  font-weight: ${(props: StylesProps) => (props.light ? "200" : "600")};
  color: ${Colors.gray8};
`;

const TextItem = styled.p`
  margin-top: 3px;
  font: 200 0.7rem Open Sans;
  font-weight: ${(props: StylesProps) => (props.light ? "200" : "480")};
  color: ${Colors.gray8};
`;

export {
  Cell,
  List,
  Box,
  Overlay,
  InfoWrap,
  AcordeonItem,
  Download,
  ModalList,
  TextHighlight,
  Content,
  Icon,
  Dropdown,
  CloseButton,
  CloseIcon,
  Title,
  Wrapper,
  SmallTitle,
  SmallAccordionTitle,
  Text,
  TextItem,
  InputButton
};
