import React, { FC } from "react";
import BubbleChart from "../BubbleChart";
import BarChart from "../BarChart";
import { formatValue } from "../../../../util/format-value";

interface GraphProps {
  selectedGraph?: any;
  opportunities?: any;
  contentDash?: any;
  setOpportunityDetails?: any;
  selectedOportunity?: any;
  monthlyTransactions?: any;
  timeline?: any;
  bubblesRange?: any;
  monthlyBubbles?: any;
  dataSetRange?: any;
  opportunityDetails?: any;
  shortMonths?: any;
  selectedShortMonths?: any
  props?: any;
}

const Graph: FC<GraphProps> = ({
  selectedGraph,
  opportunities,
  contentDash,
  setOpportunityDetails,
  selectedOportunity,
  monthlyTransactions,
  timeline,
  bubblesRange,
  monthlyBubbles,
  dataSetRange,
  opportunityDetails,
  shortMonths,
  selectedShortMonths,
  props
}) => {

  switch (selectedGraph) {
    case "bubbles":
    return (
      <BubbleChart
        type="major"
        height={320}
        width={contentDash ? contentDash.offsetWidth - 34 : 0}
        data={opportunities}
        setOpportunityDetails={setOpportunityDetails}
        selectedOportunity={selectedOportunity}
        monthlyTransactions={monthlyTransactions}
        formatValue={formatValue}
        timeline={timeline}
        bubblesRange={bubblesRange}
        monthlyBubbles={monthlyBubbles}
        dataSetRange={dataSetRange}
      />
    );
    case "bars":
      return (
        <BarChart
          height={320}
          width={contentDash ? contentDash.offsetWidth - 34 : 0}
          data={opportunities}
          formatValue={formatValue}
          monthlyTransactions={monthlyTransactions}
          timeline={timeline}
        />
      );
    default:
      return null;
  }
};

export default Graph;
