import React, { FC } from "react";
import { TextDescription, Text } from "../../../components/common-components/Accordion/styles";
import { Image } from "../styles";
import ExactTerms01 from "../../../assets/img/faq/exactTerms1.png";



const ExactTerms: FC = () => {
    return (
        <>
            <TextDescription>
                <Text>
                    {`Você pode escolher “busca por termos exatos”, e o resultado será restrito ao conjunto de algoritmos registrado.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={ExactTerms01} alt="Imagem" />
            </TextDescription>
        </>
    )
}
export default ExactTerms;