import { useState } from 'react';
import { getIntegrationListFromAPI, new_delete_integration_profile_From_API } from '../../../dataflow/thunks/integration-profiles';

interface Integration {
    integrationId: string;
    tokenLastCharacters: string;
    document: {
        number: string;
    };
}

const useIntegrationProfiles = () => {
    const [cnpjList, setCnpjList] = useState<Integration[]>([]);
    const [error, setError] = useState<string | null>(null);

    const fetchData = async () => {
        try {
            const response = await getIntegrationListFromAPI();
            setCnpjList(response.data);
        } catch (error) {
            console.error('Error fetching integration list:', error);
        }
    };

    const handleDeleteIntegration = async (integrationId: string) => {
    try {
      const deleteResponse = await new_delete_integration_profile_From_API(integrationId);
      setCnpjList((prevList) => prevList.filter((item) => item.integrationId !== integrationId));
      window.location.reload();
    } catch (error) {
      console.error("Error deleting integration:", error);
    }
  };

    return { cnpjList, error, handleDeleteIntegration, fetchData };
};

export default useIntegrationProfiles;
