import styled from "styled-components";
import Colors from "../../../assets/styles/colors";
import { StylesProps } from "../../../util/types";

const Container = styled.div`
  background: ${Colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px ${Colors.black}29;
  border-radius: 10px;
  padding: 16px 16px 0px 16px;
`;

const AccordionTrigger = styled.button`
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: top;
  border: none;
  background: white;
  cursor: pointer;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  :focus {
    outline: none;
  }
`;
const TitleText = styled.p`
  margin: 0px;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  letter-spacing: 0px;
  color: ${Colors.blue6};
  font-weight: 800;
`;

const ExpandAccordionTriggerContainer = styled.div`
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 15px;
`;

const HorizontalLine = styled.div`
  height: 3px;
  width: 15px;
  background: ${Colors.blue6};
  transform: rotateZ(0deg);
  border-radius: 5px;
  margin: auto;
  position: absolute;
  top: 4.5px;
`;

const VerticalLine = styled.div`
  height: 3px;
  width: 15px;
  background: ${Colors.blue6};
  transition: transform 0.5s ease-in-out;
  border-radius: 5px;
  margin: auto;
  position: absolute;
  top: 4.5px;
  transform: ${(props:StylesProps) => (props.isOpen ? "rotateZ(-90deg)" : null)};
`;

const TextDescription = styled.p`
  display: block;
  transform: ${(props:StylesProps) => (props.isOpen ? "rotateX(-90deg)" : "rotateX(0deg)")};
  transition: ${(props:StylesProps) =>
    props.isOpen ? "transform 0s ease-in-out" : "transform 0.4s ease-in-out"};
  height: ${(props:StylesProps) => (props.isOpen ? "0px" : "auto")};
  padding: ${(props:StylesProps) =>
    props.isOpen ? "10px 0px 8px 30px" : "7px 0px 20px 30px"};
  margin: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: ${Colors.gray8};
  overflow: ${(props:StylesProps) => (props.isOpen ? "hidden" : null)};
  transform-origin: ${(props:StylesProps) => (!props.isOpen ? "top" : null)};
`;

const TextBold = styled.text`
  margin: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: ${Colors.gray6};
  font-weight: 600;
`
const Text = styled.text`
  margin: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: ${Colors.gray8};
`
const Link = styled.a`
  font-size: 14px;
  text-decoration: underline;
  color: ${Colors.blue6};
  cursor: pointer;
`;

export {
  Container,
  AccordionTrigger,
  TitleText,
  ExpandAccordionTriggerContainer,
  HorizontalLine,
  VerticalLine,
  TextDescription,
  TextBold,
  Text,
  Link
}