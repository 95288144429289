/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
// import { useHistory } from "react-router-dom";
import crypto from "crypto";
import { TitleCount, ButtonExportData, FlexDiv, FilterByIsFavorite, ContainerCheckbox, ContainerDropDown, ContainerCheckboxDropDown, InputTitle } from "./styles";

import CellDescription from "../../components/modules-components/match-analytical/CellDescription";
import "../../components/modules-components/match-analytical/cellDescription.css";
import LoadingList from "../../components/modules-components/match-analytical/LoadingList";

import { getProposals } from "../../dataflow/thunks/easy-proposal-search";
import { getIntegrationProfilesFromAPI } from "../../dataflow/thunks/integration-profiles";
import exportData from "../../dataflow/thunks/export-data";

import { ReactComponent as DownloadSvg } from "../../assets/icons/common/download.svg";
import PageHeaderDescription from "../../components/common-components/PageHeaderDescription/PageHeaderDescription";
import { formatDateWithoutTimezone } from "../../util/format-value";
import { eventCategory, logEvent } from "../../util/analytics/index";
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import { dateDefault } from "../../util/devUtilities";
import { Button, FilterSearch } from "../../components";
import DropdownIcon from "../../assets/icons/common/dropdown-azul.svg";
import { BlockFilterOpportunities, BoxSavedFilters, BlockFilter} from "../MatchRelevance/RelevancyMatchFilter/styles";
import Colors from "../../assets/styles/colors";
import MoveDown from "../../assets/icons/common/move-down-icon.svg";
import MoveUp from "../../assets/icons/common/move-up-icon.svg";
import search from "../../assets/icons/common/search-icon.svg";
import ProposalsComponent from "./ProposalsComponent";


interface MatchAnalyticalProps {
  proposals: any;
  isSearching: any;
  integrations: any;
  getIntegrationProfiles: () => void;
  getProposals: (v: any, v2: any) => void;
  isBoxFilter?: any;
  saveModal?: ({ type, isOpen, tagsSearch }: { type: string, isOpen: boolean, tagsSearch: any }) => void;
  addOneFilter?: (v: any) => void;
}

const MatchAnalytical: FC<MatchAnalyticalProps> = ({
  proposals,
  isSearching,
  integrations,
  getIntegrationProfiles,
  getProposals,
  isBoxFilter,
}) => {
  // const history = useHistory();
  const { currentProfile } = integrations;
  const [profile, setProfile] = useState();
  const [labelDropDown, setLabelDropDown] = useState('');
  const [isFavoriteChecked, setIsFavoriteChecked] = useState(false);
  const [isBoxSavedFilterOpen, setIsBoxSavedFilterOpen] = useState(false);
  const [isReverse, setIsReverse] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [searchFilterClean, setSearchFilterClean] = useState("");
  
  useEffect(() => {
    if (!currentProfile) {
      getIntegrationProfiles();
    }
  }, []);

  useEffect(() => {
    if (!profile && currentProfile) {
      setProfile(currentProfile);
    }
    if (
      (proposals?.length === 0 && currentProfile) ||
      (profile && profile !== currentProfile)
    ) {
      setProfile(currentProfile);
      const { document } = integrations?.currentProfile;
      getProposals({ keyword: "", filter_option: "ALL" }, document?.number);
    }
  }, [currentProfile]);

  // Filtro

  const openBoxFilter = (ev?: any) => {
    ev?.stopPropagation();
    logEvent(
      eventCategory.dropdownChange,
      'Click Buscador Match Analítico',
      'Buscador Match Analítico'
    );
  };

  const handleChange = (ev: any) => {
    setSearchFilter(ev.target.value);
    setSearchFilterClean(ev.target.value.toLowerCase().normalize("NFD").replace(/[^a-z0-9-\s]+/, ''));
  };

  const orderByFilter = (array:any) => {
    var arrayFilter = array.filter((value: any) => value.description.toLowerCase().normalize("NFD").replace(/[^a-z0-9-\s]+/, '').includes(searchFilterClean))
    return arrayFilter;
  }

 // Fim do filtro

  const handleItems = (items: any) => {
    let itemToBigString = items?.map((item: any, index: any) => {
      const deliveryDate = new Date(item.deliveryDate);

      return `Item ${index + 1} -> Descrição: ${item.description
        }, Quantidade: ${item.quantity
        }, Data de entrega: ${formatDateWithoutTimezone(
          deliveryDate,
          "dd/MM/yyyy"
        )}`;
    });

    return itemToBigString.join(" | ");
  };

  const handleDownloadFile = async (listAPI: any, fileName: any, fileType: any) => {
    const data = listAPI.map((item: any) => {
      const reversedDate = item.closingDate
        .split("/")
        .reverse()
        .join("-");
      const endDate = new Date(reversedDate);

      const result = {
        Oportunidade: item.opportunityNumber,
        Favorito: item.isFavorite === true ? "Sim" : "Não",
        "Avaliação da Oportunidade": item.rating,
        Descrição: item.description,
        "Data Fim": formatDateWithoutTimezone(endDate, "dd/MM/yyyy"),
        "Quantidade de Itens": item.items.length,
        Itens: handleItems(item.items),
      };

      return result;
    });

    await exportData(data, fileName, fileType);
    const anchor = document.createElement("a");
    document.body.appendChild(anchor);
    const file = `${process.env.REACT_APP_API}/export-data/download/${fileName}.${fileType}`;
    const { idToken } = JSON.parse(window.localStorage.getItem("petronect_credentials") || '');

    const headers = new Headers();
    headers.append("Authorization", `Barear ${idToken}`);

    await fetch(file, { headers })
      .then((response) => response.blob())
      .then((blobby) => {
        const objectUrl = window.URL.createObjectURL(blobby);

        anchor.href = objectUrl;
        anchor.download = fileName;
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      });
  };

  const {
    document: { number },
    integrationId,
  } = currentProfile;
  const fileName = `${crypto
    .createHash("md5")
    .update(integrationId + number)
    .digest("hex")}`;

  const filterIsFavorite = proposals.filter((value: any) => value.isFavorite)
  const filterSearch = orderByFilter(proposals)
  let sortedProposals = proposals;

  const orderByRating = (array: any) => {
    return array.sort((a: any, b: any) => b.rating - a.rating);
  }

  const orderByItems = (array: any) => {
    return array.sort((a: any, b: any) => b.items.length - a.items.length);
  }

  const orderByDate = (array: any) => {
    const sortedTest = array.sort((a: any, b: any) => {
      if (new Date(dateDefault(a.closingDate, 'MM/DD/YYYY')) > new Date(dateDefault(b.closingDate, 'MM/DD/YYYY'))) return -1;
      if (new Date(dateDefault(a.closingDate, 'MM/DD/YYYY')) < new Date(dateDefault(b.closingDate, 'MM/DD/YYYY'))) return 1;
      return 0;
    })
    return sortedTest
  }

  const openBox = (ev?: any) => {
    ev?.stopPropagation();
    // setIsBoxFilterOpen(false);
    setIsBoxSavedFilterOpen((state) => !state);
    logEvent(
      eventCategory.dropdownChange,
      'Click Dropdown Filtros Match Analítico',
      'Filtros Match Analítico'
    );
  };

  const reverseArray = (ev?: any) => {
    ev?.stopPropagation();
    const reverse = sortedProposals.reverse();
    sortedProposals = reverse;
    setIsReverse((state) => !state);
    logEvent(
      eventCategory.dropdownChange,
      'Click Botão Ordenar',
      'Ordenar'
    );
  };
  
  const placeholder = "Digite palavras-chave";
  
  return (
    <>
      <PageHeaderDescription
        title={"Match Analítico"}
        paragraphs={[
          "Acesso de forma ágil às oportunidades do tipo Dispensa que a empresa foi convidada. Aqui é possível verificar na avaliação da oportunidade, com base na quantidade de estrelas preenchidas, quais são as oportunidades com maior potencial de relevância para o seu negócio.",
        ]}
      />
      <div className="easyProposalContainer">
        {sortedProposals?.length > 0 && (
          <>
            <ContainerCheckboxDropDown>
              <FilterByIsFavorite>
                <ContainerCheckbox
                  onClick={(e) => {
                    e.preventDefault();
                    setIsFavoriteChecked((state) => !state);
                    logEvent(
                      eventCategory.checkboxChange,
                      `Exibe somente oportunidades favoritadas ${!isFavoriteChecked}`,
                      'Exibe somente oportunidades favoritadas'
                    );
                  }}
                >
                  {isFavoriteChecked ? (
                    <MdCheckBox />
                  ) : (
                    <MdCheckBoxOutlineBlank />
                  )}
                  <input
                    type={"checkbox"}
                    id={"isFavorite"}
                    checked={isFavoriteChecked}
                  />
                  <label htmlFor={"isFavorite"}>Exibir somente favoritos</label>
                </ContainerCheckbox>
              </FilterByIsFavorite>
              <InputTitle>Buscar:</InputTitle>
              <BlockFilter isBoxFilter={isBoxFilter}>
                <FilterSearch
                  placeholder={placeholder}
                  width={"100%"}
                  value={searchFilter}
                  onChange={handleChange}
                  onClick={(ev) => {
                    sortedProposals = orderByFilter(proposals)
                    logEvent(
                      eventCategory.checkboxChange,
                      `Buscando pela palavra:` + searchFilter,
                      'Buscando pela palavra:' + searchFilter,
                    );
                    openBoxFilter(ev)
                  }}
                  borderTopRightRadius="16px"
                  borderTopLeftRadius="16px"
                  borderBotttomLeftRadius={"16px"}
                  borderBottomRightRadius={"16px"}
                  isBoxFilter={isBoxFilter}
                  search
                  icon={search}
                />
              </BlockFilter>
              <BlockFilterOpportunities>
                <FilterSearch
                  placeholder={`Filtrar por: ${labelDropDown}`}
                  width={"250px"}
                  onClick={openBox}
                  handleAddTag={openBox}
                  type="button"
                  borderTopRightRadius="16px"
                  borderTopLeftRadius="16px"
                  borderBottomRightRadius={isBoxSavedFilterOpen ? 0 : "16px"}
                  borderBotttomLeftRadius={isBoxSavedFilterOpen ? 0 : "16px"}
                  icon={DropdownIcon}
                  search
                // displayMobile={isBoxFilterOpen ? "none" : "flex"}
                />
                {isBoxSavedFilterOpen && (
                  <ContainerDropDown onClick={(ev) => ev.stopPropagation()}>
                    <BoxSavedFilters>
                      <Button
                        color={Colors.blue8}
                        background={Colors.white}
                        border={`1px solid ${Colors.blue6}`}
                        width="200px"
                        height="2.5rem"
                        margin=".20rem 0"
                        onClick={() => {
                          sortedProposals = orderByDate(proposals);
                          setLabelDropDown('Data Fim')
                          logEvent(
                            eventCategory.checkboxChange,
                            `Ordena as oportunidades por Data Fim`,
                            'Ordena as oportunidades por Data Fim'
                          );
                          openBox();
                        }}
                      >
                        Data Fim
                      </Button>
                      <Button
                        color={Colors.blue8}
                        background={Colors.white}
                        border={`1px solid ${Colors.blue6}`}
                        width="200px"
                        height="2.5rem"
                        margin=".20rem 0"
                        onClick={() => {
                          sortedProposals = orderByRating(proposals);
                          setLabelDropDown('Avaliação')
                          logEvent(
                            eventCategory.checkboxChange,
                            `Ordena as oportunidades por Avaliação`,
                            'Ordena as oportunidades por Avaliação'
                          );
                          openBox();
                        }}
                      >
                        Avaliação
                      </Button>
                      <Button
                        color={Colors.blue8}
                        background={Colors.white}
                        border={`1px solid ${Colors.blue6}`}
                        width="200px"
                        height="2.5rem"
                        margin=".20rem 0"
                        onClick={() => {
                          sortedProposals = orderByItems(proposals);
                          setLabelDropDown('Quantidade de Itens')
                          logEvent(
                            eventCategory.checkboxChange,
                            `Ordena as oportunidades por Quantidade de Itens`,
                            'Ordena as oportunidades por Quantidade de Itens'
                          );
                          openBox();
                        }}
                      >
                        Quantidade de Itens
                      </Button>
                    </BoxSavedFilters>
                  </ContainerDropDown>
                )}
              </BlockFilterOpportunities>
              <Button
                color={Colors.blue8}
                background={Colors.white}
                border={`1px solid ${Colors.blue6}`}
                width="150px"
                height="2rem"
                margin="0"
                onClick={() => {
                  reverseArray();
                  logEvent(
                    eventCategory.checkboxChange,
                    `Ordena as oportunidades por Avaliação`,
                    'Ordena as oportunidades por Avaliação'
                  );
                }}
              >
                Ordenar: 
                {isReverse && <img
                  src={MoveDown}
                  style={{ width: "17px", marginTop: "0px",marginLeft: "0.25rem" }}
                  alt={"icon"}
                />}
                {!isReverse && <img
                  src={MoveUp}
                  style={{ width: "17px", marginTop: "0px",marginLeft: "0.25rem"  }}
                  alt={"icon"}
                />}
              </Button>
            </ContainerCheckboxDropDown>
            <FlexDiv>
              <TitleCount>
                {isFavoriteChecked ? filterIsFavorite.length : (searchFilterClean ? filterSearch.length :sortedProposals?.length)} oportunidades encontrada(s).
              </TitleCount>
              <ButtonExportData
                onClick={() => {
                  logEvent(
                    eventCategory.buttonClick,
                    "click Exportar CSV oportunidades encontrada(s)",
                    "Exportar CSV"
                  );
                  handleDownloadFile(sortedProposals, fileName, "csv");
                }}
              >
                <span>Exportar CSV</span>
                <DownloadSvg />
              </ButtonExportData>
              <ButtonExportData
                onClick={() => {
                  logEvent(
                    eventCategory.buttonClick,
                    "click Exportar Excel oportunidades encontrada(s)",
                    "Exportar Excel"
                  );
                  handleDownloadFile(sortedProposals, fileName, "xlsx");
                }}
              >
                <span>Exportar Excel</span>
                <DownloadSvg />
              </ButtonExportData>
            </FlexDiv>
          </>
        )}
        <ProposalsComponent
          sortedProposals={sortedProposals}
          isSearching={isSearching}
          isFavoriteChecked={isFavoriteChecked}
          searchFilter={searchFilter}
          searchFilterClean={searchFilterClean}
        />
      </div>
    </>
  );
};

const mapSateToProps = (state: any) => ({
  integrations: state.integrationProfiles,
  proposals: state.easyProposals.proposals,
  isSearching: state.easyProposals.isSearchingForProposals,
});

const mapDispatchToProps = (dispatch: any) => ({
  getIntegrationProfiles: () => dispatch(getIntegrationProfilesFromAPI()),
  getProposals: (info: any, document: any) => {
    dispatch(getProposals(info, document))    
  },
});

export default connect(mapSateToProps, mapDispatchToProps)(MatchAnalytical);
