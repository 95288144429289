import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { Status } from "..";
import Colors from "../../../assets/styles/colors";
interface ListStylesProps {
  status?: string
}

const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0 0.4rem;
  border-radius: 4px;
  cursor: ${(props: ListStylesProps) => props?.status === Status.NAO_SOCIO ? "pointer" : null}; 

  &:nth-child(even) {
    background: ${Colors.white};
  }
  &:nth-child(odd) {
    background: ${Colors.white2};
  }

  @media (max-width: 590px) {
    /* &:nth-child(1) {
      display: none;
    } */
    flex-wrap: wrap;
  }
`;

interface ListItemProps {
  children: ReactNode;
  status?: string;
}

const ListItem: FC<ListItemProps> = ({ children, status }) => {
  return (
      <List       
        status={status}>
        {children}
      </List>
  );
};

export default ListItem;
