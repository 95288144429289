import React, { FC } from "react";
import styled from "styled-components";
import Colors from "../../../assets/styles/colors";

interface StylesProps {
   width?:any;
}

const Text = styled.p`
  color: ${Colors.white};
  padding-left: 10px;
  padding-right: 10px;
`;

const TooltipText = styled.div`
  background: ${Colors.blue8};
  color: ${Colors.white};
  width: ${(props: StylesProps) => props.width ? props.width : '80%'};
  text-align: center;
  line-height: 44px;
  border-radius: 4px;
  cursor: pointer;
`;
const TooltipBox = styled.div`
   color: ${Colors.white};
   background-color: ${Colors.blue8};
   width: ${(props: StylesProps) => props.width ? props.width : '80%'};
   padding: 8px 8px;
   border-radius: 4px;
`;
const TooltipCard = styled.div`
   margin: 16px;
   width: 100%;
`;

interface TooltipProps {
   title?: string;
   paragraphs: string[];
   onMouseLeave: () => void;
   link?: any;
   onMouseOver?: () => void;
   width?: any;
}

const Tooltip: FC<TooltipProps> = ({ title, paragraphs, onMouseLeave, link, onMouseOver,width }) => {

   return (
      <TooltipCard onMouseLeave={onMouseLeave} onMouseOver={onMouseOver}>
         {title && (
            <TooltipText width={width}>
               <h3>{title}</h3>
            </TooltipText>
         )}

         <TooltipBox width={width}>
            {paragraphs.map((p, index) => <Text key={index}>{p}</Text>)}
            {link}
         </TooltipBox>
      </TooltipCard>
   );
};

export default Tooltip;