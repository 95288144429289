import styled from "styled-components";
import Colors from "../../assets/styles/colors";
import { StylesProps } from "../../util/types";

const TitleCount = styled.p`
  width: ${(props:StylesProps) => !props.name && "auto"};
  margin: 0.8rem 1.3rem;
  font-size: ${(props:StylesProps) => (props.name ? ".75rem" : "0.85rem")};
  padding: ${(props:StylesProps) => props.name && ".2rem 0"};
  color: ${Colors.blue6};
  @media (max-width: 500px) {
    margin: 0.8rem 0rem;
  }
`;

const InputTitle = styled.p`
  margin: 0.5rem;
  font: 400 1rem Eurostile;
  letter-spacing: 0.5px;
  color: ${Colors.blue6};

  @media (max-width: 590px) {
    display: none;
  }
`;

const ButtonExportData = styled.button`
  display: flex;
  justify-content: center;
  color: white;
  background-color: ${Colors.blue6};
  box-shadow: 0px 3px 6px ${Colors.black}29;
  border: none;
  border-radius: 5px;
  text-align: center;
  height: fit-content;
  padding: 5px 2px;
  font-size: 12px;
  cursor: pointer;
  transition: 0.1s linear;
  text-decoration: none;

  &:hover {
    font-weight: 600;
    background-color: ${Colors.blue6}cc;
    box-shadow: 0 3px 15px -10px ${Colors.black};
  }

  span {
    font-weight: bold;
  }

  svg {
    margin: auto 0px;
    margin-left: 5px;
    * {
      fill: white;
    }
  }
`;

const FlexDiv = styled.div`
  display: grid;
  grid-template-columns: 65% 15% 15%;
  grid-column-gap: 10px;
  align-items: baseline;
  @media (max-width: 500px) {
    grid-template-columns: 45% 25% 25%;
  }
`;

const FilterByIsFavorite = styled.div`
  color: ${Colors.blue8}; 
  width: 100%;
  display: flex;
  color: ${Colors.blue6};

  input:checked {
    background-color: ${Colors.blue6};
  }

  label {
    cursor: pointer;
    font-size: 14px;
  }

  input[type="checkbox"] {
    display: none;
  }
  @media (max-width: 435px) {
    margin-bottom: 10px;
  }
`;

const ContainerCheckbox = styled.div`
display: flex;
flex-direction: row;
align-content: center;
align-items: center;
justify-content: center;
flex-wrap: nowrap;
`

const ContainerDropDown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 250px;
  padding: 0.5rem;
  border: 0.5px solid ${Colors.blue6};
  border-top: none;
  border-radius: 0 0 4px 4px;
  background: ${Colors.white};
  overflow: hidden;
  z-index: 5;

  @media (max-width: 768px) {
    left: 0.25rem;
    width: 250px;
  }

  @media (max-width: 590px) {
    right: 0;
  }
`;

const ContainerCheckboxDropDown = styled.div`
display: flex;
flex-direction: row;
align-content: center;
align-items: center;
justify-content: center;
flex-wrap: nowrap;
@media (max-width: 435px) {
  flex-direction: column;
  align-items: flex-start;
  }
`

export {
  TitleCount,
  ButtonExportData,
  FlexDiv,
  FilterByIsFavorite,
  ContainerCheckbox,
  ContainerDropDown,
  ContainerCheckboxDropDown,
  InputTitle
}
