import React, { FC } from "react";
import "./FamilyDescription.css";

interface FamilyItemProps {
  item: any;
}

const FamilyItem: FC<FamilyItemProps> = ({ item }) => {

  return (
    <div className="itemContainer">      
      <div className="descriptionTextTitle"> {item.familia} - <label className="descriptionTextBold">{item.descricao}</label></div>    
      <div className="descriptionTextTitleGray"> {`Quantidade de Oportunidades Dispensa`} - <label className="descriptionTextBold">{item.oport_dispensa}</label></div>    
      <div className="descriptionTextTitleGray"> {`Quantidade de Oportunidades Licitação Pública`} - <label className="descriptionTextBold">{item.oport_licitacao}</label></div>    
    </div>
  );
};

export default FamilyItem;
