import React, { FC } from "react";
import { TextDescription, Text } from "../../../components/common-components/Accordion/styles";
import { Image } from "../styles";
import OpportunityHistory01 from "../../../assets/img/faq/opportunityHistory1.png";

const OpportunityHistory: FC = () => {
    return (
        <>
            <TextDescription>
                <Text>
                    {`Apresenta as informações das oportunidades no qual o CNPJ em questão participou. Dentro dessa área, você conseguirá visualizar o preço do mercado, o seu preço, o coeficiente de competitividade, o ID da oportunidade, o serviço/item e a data fim. É possível extrair o relatório em CSV e/ou Excel.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={OpportunityHistory01} alt="Imagem" />
            </TextDescription>
        </>
    )
}
export default OpportunityHistory;