import React, { useState, useEffect } from 'react';
import LoadingList from '../../components/modules-components/match-analytical/LoadingList';
import CellDescription from "../../components/modules-components/match-analytical/CellDescription";
import PaginationComponent from '../../components/common-components/Pagination/Pagination';

interface Proposal {
  id: string;
  isFavorite: boolean;
}

interface ProposalsComponentProps {
  sortedProposals: Proposal[];
  isSearching: boolean;
  isFavoriteChecked: boolean;
  searchFilter: string;
  searchFilterClean: string;
}

const ProposalsComponent: React.FC<ProposalsComponentProps> = ({ 
  sortedProposals, 
  isSearching, 
  isFavoriteChecked, 
  searchFilter, 
  searchFilterClean 
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [proposalsPerPage, setProposalsPerPage] = useState(15);

  useEffect(() => {
    setCurrentPage(1); // Reset current page when changing proposals per page
  }, [proposalsPerPage]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setProposalsPerPage(Number(event.target.value));
  };

  const indexOfLastProposal = currentPage * proposalsPerPage;
  const indexOfFirstProposal = indexOfLastProposal - proposalsPerPage;
  const currentProposals = sortedProposals.slice(indexOfFirstProposal, indexOfLastProposal);

  const totalPages = Math.ceil(sortedProposals.length / proposalsPerPage);

  return (
    <div>
      {isSearching ? (
        <LoadingList animationText={"Procurando oportunidades..."} />
      ) : (
        <>
          <div className="proposalContainerList">
            {currentProposals.map((proposal: Proposal) => (
              (!isFavoriteChecked || proposal.isFavorite) && (
                <CellDescription
                  proposal={proposal}
                  key={proposal.id}
                />
              )
            ))}
          </div>
          {currentProposals.length > 0 && !isFavoriteChecked && (
            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              itemsPerPage={proposalsPerPage}
              handlePageChange={handlePageChange}
              handleItemsPerPageChange={handleItemsPerPageChange}
              onPageClick={handlePageChange}
            />
          )}
          {currentProposals.length === 0 && <p>Nenhuma oportunidade foi encontrada.</p>}
        </>
      )}
    </div>
  );
};

export default ProposalsComponent;
