import React, { FC, useState } from "react";
import { values } from "lodash";
import YellowStar from "../../../../assets/icons/common/estrela.svg";
import close from "../../../../assets/icons/common/close-blue.svg";
import dropdown from "../../../../assets/icons/common/dropdown-cinza-claro.svg";
import Scrollbar from "../../../common-components/Scrollbar";
import { formatDateWithoutTimezone } from "../../../../util/format-value";
import {
  Overlay,
  InfoWrap,
  AcordeonItem,
  ModalList,
  TextHighlight,
  Content,
  Icon,
  Dropdown,
  CloseButton,
  CloseIcon,
  Title,
  Wrapper,
  SmallTitle,
  SmallAccordionTitle,
  Text,
  TextItem,
  Download,
  Box,
  InputButton
} from "./styles";


interface ModalProps {
  selectedOppotunity: any;
  closeModal: () => void;
}

const Modal: FC<ModalProps> = ({ selectedOppotunity, closeModal }) => {
  const [accordionAnexos, setAccordionAnexos] = useState(false);
  const [accordionTermos, setAccordionTermos] = useState(true);
  const [accordionItens, setAccordionItens] = useState(false);
  const dateStart = new Date(selectedOppotunity.START_DATE);
  const dateEnd = new Date(selectedOppotunity.END_DATE);

  const normalizeScore = (score: any) => {
    if (score <= 1) return 1 + "%";
    else if (score < 100) return Math.round(100 - 100 / score) + "%";
    else return 100 + "%";
  };

  const formatItemNotes = (notes: any) => {
    var res = "";

    for (let note_index in notes) {
      for (let line_index in notes[note_index].NOTAS) {
        res = res + notes[note_index].NOTAS[line_index].TDLINE;
      }
    }
    return res;
  };

  // dateStart.setDate(dateStart.getDate() + 1);
  // dateEnd.setDate(dateEnd.getDate() + 1);

  return (
    <Overlay onClick={closeModal}>
      <Content onClick={(ev) => ev.stopPropagation()}>
        <Icon src={YellowStar} />
        <CloseButton onClick={closeModal}>
          <CloseIcon src={close} />
        </CloseButton>
        <Title>
          {normalizeScore(selectedOppotunity.score)} Relevante
        </Title>
        <Scrollbar full scrollWidth={"2px"} maxHeight={"85vh"}>
          <Wrapper>
            <Box>
              <div>
                <SmallTitle>Título e Descrição</SmallTitle>
                <Title blue>{selectedOppotunity.DESC_OBJ_CONTRAT}</Title>
              </div>
              <div>
                <SmallTitle>Empresa</SmallTitle>
                <Text>{selectedOppotunity.COMPANY_DESC}</Text>
              </div>

              <div>
                <SmallAccordionTitle
                  onClick={() =>
                    setAccordionItens(!accordionItens)
                  }
                >
                  <p>Quantidade de Itens</p>
                  <Dropdown
                    open={accordionItens}
                    src={dropdown}
                  ></Dropdown>
                </SmallAccordionTitle>

                {accordionItens ? (
                  <AcordeonItem>
                    {selectedOppotunity.ITEMS.map((item: any,index:any) => {
                      return (
                        <div key={index}>
                          <ModalList key={item.ITEM_NUM}>
                            <TextItem>
                              {item.ITEM_DESC} - {item.ITEM_NUM}{" "}
                            </TextItem>
                            <TextItem>Quantidade: {item.QUANTITY} </TextItem>
                            <TextItem>Unit: {item.UNIT} </TextItem>
                            <TextItem>
                              Nota: {formatItemNotes(item.ITEM_NOTES)}{" "}
                            </TextItem>
                            <TextItem>Família: {item.FAMILY} </TextItem>
                            <TextItem>
                              Nº Material: {item.NUM_MATERIAL}{" "}
                            </TextItem>
                          </ModalList>
                        </div>
                      );
                    })}
                  </AcordeonItem>
                ) : (
                  <div></div>
                )}
              </div>

              <SmallAccordionTitle
                onClick={() =>
                  setAccordionAnexos(!accordionAnexos)
                }
              >
                <p>Anexos</p>
                <Dropdown
                  open={accordionAnexos}
                  src={dropdown}
                ></Dropdown>
              </SmallAccordionTitle>
              {accordionAnexos ? (
                selectedOppotunity.ANEXOS.map((anexos: any) => {
                  let urlDownload = `https://www.petronect.com.br/sap/opu/odata/SAP/YPCON_PUB_ATTACHMENT_DOWNLOAD_SRV/attachmentSet('${anexos.PHIO_OBJID}')/$value`;

                  return (
                    <AcordeonItem>
                      <Download
                        href={urlDownload}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        {anexos.DESCRIPTION}
                      </Download>
                    </AcordeonItem>
                  );
                })
              ) : (
                <div></div>
              )}

              <SmallAccordionTitle
                onClick={() => {
                  setAccordionTermos(!accordionTermos);
                }}
              >
                <p>Termos encontrados</p>
                <Dropdown
                  open={accordionTermos}
                  src={dropdown}
                ></Dropdown>
              </SmallAccordionTitle>
              {accordionTermos ? (
                selectedOppotunity.highlight &&
                values(selectedOppotunity.highlight).map((item) =>
                  item.map((highlight: any, index: any) => (
                    <AcordeonItem>
                      <InfoWrap key={index}>
                        <TextHighlight
                          dangerouslySetInnerHTML={{ __html: highlight }}
                        />
                      </InfoWrap>
                    </AcordeonItem>
                  ))
                )
              ) : (
                <div></div>
              )}

              <InputButton
                type="button"
                value="Tenho interesse"
                onClick={() => { window.open("https://www.petronect.com.br/irj/go/km/docs/pccshrcontent/Site%20Content%20(Legacy)/Portal2018/pt/busca_intr.html?search="+selectedOppotunity.OPPORT_NUM, '_blank'); }}
              />
            </Box>
            <Box second>
              <div>
                <SmallTitle>Id</SmallTitle>
                <Text>{selectedOppotunity.OPPORT_NUM}</Text>
              </div>
              <div>
                <SmallTitle>Data de Início</SmallTitle>
                <Text>
                  {formatDateWithoutTimezone(dateStart, "dd/MM/yyyy")}
                </Text>
              </div>
              <div>
                <SmallTitle>Data Final</SmallTitle>
                <Text>
                  {formatDateWithoutTimezone(dateEnd, "dd/MM/yyyy")}{" "}
                </Text>
              </div>
            </Box>
          </Wrapper>
        </Scrollbar>
      </Content>
    </Overlay>
  );
}

export default Modal;
