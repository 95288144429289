import React, { FC } from "react";
import styled from "styled-components";
import Colors from "../../../assets/styles/colors";
import Button from "../../../components/common-components/Button";
import Modal from "../../../components/common-components/Modal/Modal";
import { eventCategory, logEvent } from "../../../util/analytics";

const Title = styled.h1`
  font: 700 2rem Eurostile;
  color: ${Colors.blue8};
`;
// const SubTitle = styled.h2`
//   font: 700 1.2rem Eurostile;
//   color: ${Colors.blue8};
//   padding-left: 10px;
//   padding-right: 10px;
// `;
const Text = styled.p`
  color: ${Colors.gray8};
  padding-left: 10px;
  padding-right: 10px;
`;
// const Box = styled.div`
//   display: flex;
//   flex-direction: column;

// `;

interface ModalErrorServiceProps {
    closeModal: () => void;
}

const ModalErrorService: FC<ModalErrorServiceProps> = ({ closeModal }) => {

    return (
        <Modal
            width={'40%'}
            onBlur={() => {
                logEvent(
                    eventCategory.modalOpen,
                    'click modal erro Upload arquico CSV',
                    `click modal erro Upload arquico CSV`
                );
            }} 
            closeModal={() => closeModal()}
            overlay
        >
            <Title>Ocorreu um ao fazer o Upload</Title>
            <Text>Ocorreu um erro na chamada do serviço de upload de arquivos, tente novamente caso erro persista entre em contato com suporte.</Text>
            <Button
                background={Colors.blue6}
                width={"25%"}
                widthMobile={"100%"}
                height={"56px"}
                fontWeight={"400"}
                margin={'10px'}
                onClick={() => { closeModal() }}
            >
                OK
            </Button>
        </Modal>
    );
};

export default ModalErrorService;