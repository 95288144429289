import React, { FC, useState } from "react";
import "./cardSuggestion.css";
import { Link } from "react-router-dom";
import Colors from "../../../assets/styles/colors";
import { eventCategory, logEvent } from "../../../util/analytics/index";
import { ProposalCell } from "./styles"

const setProposalStatusColorOnCss = (proposalStatus: string) => {
  switch (proposalStatus) {
    case "OVER":
      return Colors.yellow1;
    case "LEAVE":
    default:
      return Colors.green3;
  }
};

interface CardSuggestionProps {
  card?: any;
}

const CardSuggestion: FC<CardSuggestionProps> = ({ card }) => {
  const [color, setColor] = useState('LEAVE')

  const initialDispensa = 0;
  const sumDispensa = card.sugestao.reduce(
    (accumulator: any, currentValue: any) => accumulator + Number(currentValue?.oport_dispensa),
    initialDispensa
  );

  const initialLicitacao = 0;
  const sumLicitacao = card.sugestao.reduce(
    (accumulator: any, currentValue: any) => accumulator + Number(currentValue?.oport_licitacao),
    initialLicitacao
  );

  return (
    <Link
      onMouseLeave={() => { setColor('LEAVE'); }}
      onMouseOver={() => { setColor('OVER') }}
      onClick={() => {
        logEvent(
          eventCategory.modalOpen,
          `click detalhes de sugestão de famílias`,
          `${card?.filter}`
        );
      }}

      className="proposalCard"
      to={{
        pathname: `/family-suggestion-description`,
        state: card,
      }}
    >
      <ProposalCell
        color={setProposalStatusColorOnCss(color)}
      ></ProposalCell>
      <div className="proposalInfoContainer">
        <div>
          <p className="textCenter">{card?.nome}</p>
        </div>
        <div>
          <p className="textCenterGray">{'Clique para mais detalhes'}</p>
        </div>
        <div className="cellFooterInfoContainerCenter">
          <div className="cellInfoParagraphs">
          </div>
          <div className="cellInfoItens">
            <p className="greyCellItemText infoCellItens">
              {card?.sugestao?.length}
            </p>
            <p className="greyCellText infoCellItens">Famílias Sugeridas</p>
          </div>






          <div className="cellInfoItens">
            <p className="greyCellItemText infoCellItens">
              {sumLicitacao}
            </p>
            <p className="greyCellText infoCellItens">Licitação Pública</p>
          </div>

          <div className="cellInfoItens">
            <p className="greyCellItemText infoCellItens">
              {sumDispensa}
            </p>
            <p className="greyCellText infoCellItens">Oportunidades de Dispensa</p>
          </div>

          {/* <div className="cellInfoItens">
            <p className="greyCellItemText infoCellItens">
              {sumDispensa + sumLicitacao}
            </p>
            <p className="greyCellText infoCellItens">Licitação Pública</p>
          </div> */}

        </div>
      </div>
    </Link>
  );
};

export default CardSuggestion;
