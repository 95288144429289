
export interface StringArray {
  label: string;
  fullObj: string;
  selected: boolean;
}

export const mapStringArrayToTagItems = (
  stringArray:StringArray[],
  setAllToSelected = false
) => {
  return stringArray.map(
    (item) =>
      (typeof item === "string" && {
        label: item,
        fullObj: item,
        selected: setAllToSelected,
      }) ||
      item
  );
};

export const mapTagItemsToString = (tagArray:StringArray[]) => {
  return tagArray.map(
    (item) => (typeof item === "object" && item.fullObj) || item
  );
};

export const setThisItemsToSelected = (items:StringArray[], tagArray:StringArray[]) => {
  const selectArray = mapStringArrayToTagItems(items);
  const tagListArray = mapStringArrayToTagItems(tagArray);
  selectArray.forEach((element:StringArray) => {
    for (let i = 0; i < tagListArray.length; i++) {
      if (tagListArray[i].fullObj === element.fullObj) {
        tagListArray[i].selected = true;
        break;
      }
    }
  });

  return tagListArray;
};

export const removeFromTagList = (removeItems:StringArray[], tagList:StringArray[]) => {
  const removeArray = mapStringArrayToTagItems(removeItems);
  const tagListArray = mapStringArrayToTagItems(tagList);
  const indexes:number[] = [];
  removeArray.forEach((element, index) => {
    for (let i = 0; i < tagListArray.length; i++) {
      const tag:StringArray = tagListArray[i];
      if (tag.fullObj === element.fullObj) {
        indexes.push(i);
      }
    }
  });

  return tagListArray.filter((_, index) => !indexes.includes(index));
};

/**
 * @returns obj: {
 mapStringArrayToTagItems (stringArray) => {label: item,fullObj: item,selected: false,}
 }
 */
export const useTagListUtils = () => {
  return {
    mapStringArrayToTagItems,
    mapTagItemsToString,
    removeFromTagList,
    setThisItemsToSelected,
  };
};
