// Action Types
export const SAVE_OPPORTUNITIES = "petronect/opportunities/SAVE_OPPORTUNITIES";
export const SET_GRAPH_TIMELINE = "petronect/opportunities/SET_GRAPH_TIMELINE";
export const SET_CURRENCY_TYPE = "petronect/opportunities/SET_CURRENCY_TYPE";
export const SET_LOADING = "petronect/opportunities/SET_LOADING";
export const SET_NOTFOUND = "petronect/opportunities/SET_NOT_FOUND";
export const SET_FILTER_RANGE = "petronect/opportunities/SET_FILTER_RANGE";
export const SET_BUBBLES_RANGE = "petronect/opportunities/SET_BUBBLES_RANGE";
export const SET_SEARCH_ITEMS = "petronect/opportunities/SET_SEARCH_ITEMS";
export const SET_DATA_SET_RANGE = "petronect/opportunities/SET_DATA_SET_RANGE";
export const SET_SEARCH_TAGS = "petronect-/opportunities/SET_SEARCH_TAGS";
export const CLEAN_OPPRTUNITIES_MODULE =
  "petornect/opportunities/CLEAN_OPPRTUNITIES_MODULE";
export const UPDATE_CURRENT_SCREEN =
  "petronect/oportunities/UPDATE_CURRENT_SCREEN";
export const SET_MATCH_OPPORTUNITIES =
  "petronect/oportunities/SET_MATCH_OPPORTUNITIES";
// export const PUT_FAVORITE = 'petronect/favorite/PUT_FAVORITE';
// export const REMOVE_FAVORITE = 'petronect/oportunities/REMOVE_FAVORITE';
export const ADD_ONE_FILTER = "petronect/oportunities/ADD_ONE_FILTER";
export const SET_TAGS_FILTER = "petronect/oportunities/SET_TAGS_FILTER";
export const CLEAN_TAGS_FILTER = "petronect/oportunities/CLEAN_TAGS_FILTER";
export const SET_ERROR = "petronect/oportunities/SET_ERROR";

export const initialState = {
  summary: {},
  opportunities: [],
  timeline: {
    type: "third",
    label: 3,
  },
  shortMonths: {
    third: ["Out", "Nov", "Dez"],
    half: ["Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    ninety: ["Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    full: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
  },
  currentCurrency: {
    cipher: "R$",
    name: "Real",
    type: "BRL",
  },
  isLoading: false,
  notFound: false,
  min: null,
  max: null,
  filterRange: {
    minValue: 0,
    maxValue: 0,
  },
  bubblesRange: {
    minValue: 0,
    maxValue: 0,
  },
  setupId: null,
  isSearchingItems: false,
  dataSetRange: {
    firstDate: "00-00-00",
    date: "00-00-00",
    rangeStart: "00-00-00",
    rangeEnd: "00-00-00",
    year: "",
    lastYearActive: false,
  },
  searchTags: [],
  originalOpportunities: [],
  currentScreen: "performance",
  tagsFilter: [],
  apiOpportunities: [],
  error: "",
};

// Reducer
export default function(state = initialState, action:any) {
  // console.log("REDUX:", action, { state });
  switch (action.type) {
    case SAVE_OPPORTUNITIES:
      const mappedValues =
        action.info.opportunities.length === 0
          ? [0]
          : action.info.opportunities.map((d:any) => d.total);

      const minValue = Math.min(...mappedValues);
      const maxValue = Math.max(...mappedValues);
      return {
        ...state,
        summary: action.info.summary,
        opportunities: action.info.opportunities,
        ...(action.info.opportunities.length > 0
          ? {
              min: minValue,
              max: maxValue,
              filterRange: {
                minValue: minValue,
                maxValue: maxValue,
              },
              bubblesRange: {
                minValue: minValue,
                maxValue: maxValue,
              },
            }
          : {
              min: 0,
              max: 0,
              filterRange: {
                minValue: 0,
                maxValue: 0,
              },
              bubblesRange: {
                minValue: 0,
                maxValue: 0,
              },
            }),
        originalOpportunities:
          action.info.originalOpportunities || action.info.opportunities,
        // greater: action.info.opportunities.filter(d => d.id === 9515000065)
      };
    case SET_FILTER_RANGE:
      return {
        ...state,
        filterRange: {
          ...action.info,
        },
      };
    case SET_BUBBLES_RANGE:
      return {
        ...state,
        bubblesRange: {
          ...action.info,
        },
      };
    case SET_GRAPH_TIMELINE: {
      return {
        ...state,
        timeline: action.info,
      };
    }
    case SET_CURRENCY_TYPE: {
      return {
        ...state,
        currentCurrency: action.info,
      };
    }
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.info,
      };
    case SET_NOTFOUND:
      return {
        ...state,
        notFound: action.info,
      };
    case SET_SEARCH_ITEMS:
      return {
        ...state,
        isSearchingItems: action.info,
      };
    case SET_DATA_SET_RANGE:
      return {
        ...state,
        dataSetRange: {
          ...state.dataSetRange,
          ...action.info,
        },
      };
    case SET_SEARCH_TAGS:
      return {
        ...state,
        searchTags: action.info,
      };
    case SET_MATCH_OPPORTUNITIES:
      return {
        ...state,
        apiOpportunities: action.info,
      };
    case CLEAN_OPPRTUNITIES_MODULE:
      return {
        ...initialState,
      };
    case UPDATE_CURRENT_SCREEN:
      return {
        ...state,
        currentScreen: action.info,
      };
    case SET_TAGS_FILTER:
      return {
        ...state,
        tagsFilter: action.info,
      };
    case ADD_ONE_FILTER:
      return {
        ...state,
        tagsFilter: [action.info, ...state.tagsFilter],
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.info,
      };
    case CLEAN_TAGS_FILTER:
      return {
        ...state,
        tagsFilter: [],
      };
    default:
      return state;
  }
}

// Action Creators
export const saveOpportunities = (info:any) => ({
  type: SAVE_OPPORTUNITIES,
  info,
});

export const setGraphTimeline = (info:any) => ({
  type: SET_GRAPH_TIMELINE,
  info,
});

export const setFilterRange = (info:any) => ({
  type: SET_FILTER_RANGE,
  info,
});

export const setBubblesRange = (info:any) => ({
  type: SET_BUBBLES_RANGE,
  info,
});

export const setCurrencyType = (info:any) => ({
  type: SET_CURRENCY_TYPE,
  info,
});

export const setLoading = (info:any) => ({
  type: SET_LOADING,
  info,
});

export const setNotfound = (info:any) => ({
  type: SET_NOTFOUND,
  info,
});

export const setSearchItems = (info:any) => ({
  type: SET_SEARCH_ITEMS,
  info,
});

export const setDataSetRange = (info:any) => ({
  type: SET_DATA_SET_RANGE,
  info,
});

export const setSearchTags = (info:any) => ({
  type: SET_SEARCH_TAGS,
  info,
});

export const setError = (info:any) => ({
  type: SET_ERROR,
  info,
});

export const cleanOpportunitiesModule = () => ({
  type: CLEAN_OPPRTUNITIES_MODULE,
});

export const updateCurrentScreen = (info:any) => ({
  type: UPDATE_CURRENT_SCREEN,
  info,
});

export const setMatchOpportunities = (info:any) => ({
  type: SET_MATCH_OPPORTUNITIES,
  info,
});
export const setTagsFilter = (info:any) => ({
  type: SET_TAGS_FILTER,
  info,
});
export const addOneFilter = (info:any) => ({
  type: ADD_ONE_FILTER,
  info,
});
export const cleanTagsFilter = (info:any) => ({
  type: CLEAN_TAGS_FILTER,
  info,
});
