import axios from "axios";
import { sendSupportMessage } from "../modules/support-module";
import jwt_decode from "jwt-decode";

// let ENV_SUFFIX_PERFORMANCE =
//   process.env.NODE_ENV === "production" ? "/desempenho/odata" : "/odata";
let ENV_SUFFIX_MATCH =
  process.env.NODE_ENV === "production"
    ? "/match-relevancia"
    : "/match-relevancia";

export const sendMessage = (info:any) => async (dispatch:any) => {
  let help:any = {};
  help.message = info;

  const credentials = window?.localStorage?.getItem("petronect_credentials") || "";

  const { idToken } = JSON.parse(credentials);

  if (credentials) {
    const decodedToken: any = jwt_decode(idToken)
    help.email = decodedToken.email;
    help.name = decodedToken.name;
  } else {
    dispatch(
      sendSupportMessage({ status: "failed", message: "Erro no envio" })
    );
  }

  try {
    axios
      .post(`${process.env.REACT_APP_API}${ENV_SUFFIX_MATCH}/contact`, help, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .then((response) => {
        dispatch(
          sendSupportMessage({
            status: "succeed",
            message: response.data.message,
          })
        );
      })
      .catch((err) => {
        dispatch(
          sendSupportMessage({ status: "failed", message: err.response.data })
        );
      });
  } catch (err:any) {
    dispatch(
      sendSupportMessage({ status: "failed", message: err.response.data })
    );
  }
};

export const changeStatus = (info:any) => async (dispatch:any) => {
  dispatch(sendSupportMessage({ status: "writing", statusMessage: "" }));
};

export default null;
