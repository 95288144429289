import React, { FC } from "react";
import { TextDescription, Text, Link } from "../../../components/common-components/Accordion/styles";

const Doubts: FC = () => {
    return (
        <>
            <TextDescription>
                <Text>
                    {`Caso ainda assim precise do nosso suporte, ficamos felizes em poder ajudá-los!`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Text>
                    {`Te esperaremos através do e-mail `}
                </Text>
                <Link
                    href="mailto:minhapetronect@petronect.com.br"
                    target="_blank"
                >
                    minhapetronect@petronect.com.br
                </Link>
                <Text>
                    {`.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Text>
                    {`Pode enviar a sua dúvida, sugestão ou elogio. Queremos ouvi-lo!`}
                </Text>
            </TextDescription>
        </>
    )
}
export default Doubts;