import React, { FC, useEffect } from "react";
import lottie from "lottie-web";
import Colors from "../../../assets/styles/colors"
import searchForProposalAnimation from "../../../assets/animations/searchForProposal.json";
//@TO-DO review this variable declaration, is it the best practice?
// eslint-disable-next-line no-unused-vars
let animObj = null;

interface LoadingListProps{
  animationText:string;
}

const LoadingList: FC<LoadingListProps> = ({animationText}) => {
  let animBox: any = null;

  useEffect(() => {
    animObj = lottie.loadAnimation({
      container: animBox, // the dom element that will contain the animation
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: searchForProposalAnimation, // the path to the animation json
    });
  }, [])


  const styleAlignContainer = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const paragraphStyle = {
    color: Colors.blue6,
    fontSize: "20px",
    fontWeight: "500",
    marginTop: "-30px",
  };

  return (
    <div style={{...styleAlignContainer,flexDirection: 'column'}}>
      <div style={{ width: 400 }} ref={(ref) => (animBox = ref)}></div>
      <p style={paragraphStyle}>{animationText}</p>
    </div>
  );
}

export default LoadingList;
