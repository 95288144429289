import axios from "axios";

const exportData = async (data: any, fileName: any, fileType: any) => {
  const { idToken } = JSON.parse(
    window?.localStorage?.getItem("petronect_credentials") || ""
  );

  try {
    const payload = { data, fileName, fileType };

    await axios.post(
      `${process.env.REACT_APP_API}/export-data/download`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      }
    );
  } catch (err) {
    return { message: "Não foi possivel realizar o download." };
  }
};

export default exportData;
