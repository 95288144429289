import React, { FC } from "react";
import {
  Title,
  Description,
  ContainerOpacity,
  ModalBox,
  ActionButton,
} from "./styles";

interface ModalForMessagesProps {
  title: string;
  description: string;
  closeModal: () => void;
  buttonTitle: string;
}

const ModalForMessages: FC<ModalForMessagesProps> = ({ title, description, closeModal, buttonTitle }) => {
  const descriptions = description.split("\n");

  return (
    <ContainerOpacity>
      <ModalBox>
        <Title>{title}</Title>
        {descriptions.map((item: string,index:any) => (
          <Description key={index}>{item}</Description>
        ))}
        <ActionButton type={"button"} onClick={() => closeModal()}>
          {buttonTitle}
        </ActionButton>
      </ModalBox>
    </ContainerOpacity>
  );
};

export default ModalForMessages;
