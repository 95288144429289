import styled from "styled-components";
import Colors from "../../../../assets/styles/colors";
import { StylesProps } from "../../../../util/types";

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow-y: scroll;
  ${"" /* padding: 0.5rem .5rem 0.25rem; */}
`;

const WapperDetaisl = styled.div`
  position: relative;
  max-height: 85vh;
  border: 0.5px solid ${Colors.gray9};
  border-radius: 8px;
  background: ${Colors.white};
  z-index: 2;
  margin-bottom: 0.625rem;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${Colors.white};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: transparent
      linear-gradient(180deg, ${Colors.blue8} 0%, ${Colors.blue6} 100%);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${Colors.black};
  }
`;

const WapperIdentification = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0.5rem 0 1.5rem;
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
`;

const CloseImage = styled.img`
  margin: 0 0.5rem;
  width: 15px;
`;

const DetailsOportuny = styled.div``;

const InfoContainer = styled.div`
  padding: 1.5rem 0 1rem 1.5rem;
  margin-top: 0.25rem;
  background: ${Colors.white};
  border-radius: 6px 6px 0 0;

  @media screen and (max-width: 480px) {
    padding: 0.75rem 0 0.5rem 0.75rem;
  }
`;

const Infowrapper = styled.span`
  display: flex;
  flex-flow: row wrap;
`;

const InfoWrap = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${(props:StylesProps) => (props.width ? "30%" : "auto")};
  height: 50%;

  @media screen and (max-width: 480px) {
    width: ${(props:StylesProps) => (props.mobile === "full" ? "100%" : "50%")};
    margin: 5% 0;
  }
`;

const Description = styled.p`
  letter-spacing: 0;
  color: ${Colors.blue6};
  font: 700 1rem "Open Sans", sans-serif;
`;

const Text = styled.p`
  color: ${(props:StylesProps) => props.color};
  font-weight: ${(props:StylesProps) => (props.bold ? 600 : 400)};
  font-size: ${(props:StylesProps) => (props.fontSize ? "1rem" : ".95rem")};
  font-family: Open Sans, sans-serif;
  margin: 0.15rem;
`;

const InfoContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem 0 1rem 1.5rem;
  border-radius: 0 0 6px 6px;
  background: ${Colors.white2};
  overflow: "auto";
`;

const BoxTitle = styled.div`
  width: 100%;
`;

const BoxInfo = styled.div`
  width: 50%;
  padding-bottom: 1.5rem;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export {
  Container,
  WapperDetaisl,
  WapperIdentification,
  CloseContainer,
  CloseImage,
  DetailsOportuny,
  InfoContainer,
  Infowrapper,
  InfoWrap,
  Description,
  Text,
  InfoContent,
  BoxTitle,
  BoxInfo,
};
