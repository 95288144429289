export const ADD_ITEM = "petronect/filter/ADD_ITEM";
export const UPDATE_CARD = "petronect/filter/UPDATE_CARD";
export const TOGGLE_FILTER = "petronect/filter/TOGGLE_FILTER";
export const TOGGLE_SEARCH = "petronect/filter/TOGGLE_SEARCH";
export const SAVE_FILTER_ITEMS = "petronect/filter/SAVE_FILTER_ITEMS";
export const CHECK_ALL_FILTERS = "petronect/filter/CHECK_ALL_FILTERS";
export const UNCHECK_ALL_FILTERS = "petronect/filter/UNCHECK_ALL_FILTERS";
export const TOGGLE_MODAL_FILTER = "petronect/filter/TOGGLE_MODAL_FILTER";
export const TOGGLE_DROPDOWN_FILTER = "petronect/filter/TOGGLE_DROPDOWN_FILTER";
export const SAVE_FILTER_INFO = "petronect/filter/SAVE_FILTER_INFO";
export const UPDATE_SEARCH_KEYWORD = "petronect/filter/UPDATE_SEARCH_KEYWORD";
export const SAVE_USER_FILTERS = "petronect/filter/SAVE_USER_FILTERS";
export const UPDATE_USER_FILTER = "petronect/filter/UPDATE_USER_FILTER";
export const DELETE_USER_FILTER = "petronect/filter/DELETE_USER_FILTER";
export const TOOGLE_USER_FILTER = "petronect/filter/TOOGLE_USER_FILTER";
export const CLEAN_SELECTED_FILTERS = "petronect/filter/CLEAN_SELECTED_FILTERS";
export const CLEAN_USER_FILTERS = "petronct/filter/CLEAN_USER_FILTERS";
export const CLEAN_FILTER_MODULE = "petronect/filter/CLEAN_FILTER_MODULE";
export const UPDATE_MATCH_FILTERS = "petronect/filter/UPDATE_MATCH_FILTERS";
export const CREATE_MATCH_FILTER = "petronect/filter/CREATE_MATCH_FILTER";
export const EDIT_MATCH_FILTER = "petronect/filter/EDIT_MATCH_FILTER";
export const DELETE_MATCH_FILTER = "petronect/filter/DELETE_MATCH_FILTER";
export const SET_SELECTED_FILTER = "petronect/filter/SET_SELECTED_FILTER";
export const SET_SUPPORT = "petronect/support/SET_SUPPORT";
export const SET_NOTIFICATION = "petronect/filter/SET_NOTIFICATION";

const initialState = {
  cardFilter: {
    keywords: []
  },
  filters: [],
  selectedFilters: [],
  searchModal: {
    isOpen: false
  },
  filterModal: {
    isOpen: false
  },
  filterDropdown: {
    isOpen: false
  },
  filterInfo: {},
  userFilters: [],
  matchFilter: [],
  matchSelectedFilter: undefined,
  support: {},
  isNotification: ""
};

export default function(state = initialState, action:any) {
  switch (action.type) {
    case ADD_ITEM:
      return {
        ...state,
        cardFilter: {
          keywords: action.info
        }
      };
    case UPDATE_CARD:
      return {
        ...state,
        cardFilter: action.info
      };
    case TOGGLE_FILTER: {
      const isChecked = state.selectedFilters.find(
        item => item === action.info[0]
      );
      return {
        ...state,
        selectedFilters: isChecked
          ? state.selectedFilters.filter(f => !action.info.includes(f))
          : state.selectedFilters.concat(action.info)
      };
    }
    case CHECK_ALL_FILTERS: {
      const ids:any = [];

      state.filters.map((item:any) => item.ids.map((id:any) => ids.push(id)));

      return {
        ...state,
        selectedFilters: ids
      };
    }
    case UNCHECK_ALL_FILTERS:
      return {
        ...state,
        selectedFilters: []
      };
    case CLEAN_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: []
      };
    case TOGGLE_SEARCH:
      return {
        ...state,
        searchModal: action.info
      };
    case TOGGLE_MODAL_FILTER:
      return {
        ...state,
        filterModal: action.info
      };
    case TOGGLE_DROPDOWN_FILTER:
      return {
        ...state,
        filterDropdown: action.info
      };
    case SAVE_FILTER_ITEMS:
      return {
        ...state,
        filters: action.info
      };
    case SAVE_FILTER_INFO:
      return {
        ...state,
        filterInfo: {
          ...state.filterInfo,
          ...action.info
        }
      };
    case UPDATE_SEARCH_KEYWORD:
      return {
        ...state,
        keyword: action.info
      };
    case SAVE_USER_FILTERS:
      return {
        ...state,
        userFilters: action.info
      };
    case UPDATE_USER_FILTER: {
      const remainningFilters = state.userFilters.filter(
        (item:any) => item.id !== action.info.id
      );

      return {
        ...state,
        userFilters: remainningFilters.concat(action.info)
      };
    }
    case DELETE_USER_FILTER:
      return {
        ...state,
        userFilters: state.userFilters.filter(
          (item:any) => item.id !== action.info.id
        )
      };
    case TOOGLE_USER_FILTER: {
      return {
        ...state,
        userFilters: state.userFilters.map((item:any) =>
          item.id === action.info.id
            ? { ...item, isChecked: !item.isChecked }
            : item
        )
      };
    }
    case CLEAN_USER_FILTERS: {
      return {
        ...state,
        userFilters: state.userFilters.map((item:any) => ({
          ...item,
          isChecked: false
        }))
      };
    }
    case CLEAN_FILTER_MODULE:
      return {
        ...initialState
      };
    case UPDATE_MATCH_FILTERS: {
      return {
        ...state,
        matchFilter: action.info
      };
    }
    case EDIT_MATCH_FILTER: {
      const updateFilter = state.matchFilter.map((filter:any) => {
        if (filter.id === action.info.id) {
          return action.info;
        } else return filter;
      });
      return {
        ...state,
        matchFilter: updateFilter
      };
    }
    case CREATE_MATCH_FILTER: {
      return {
        ...state,
        matchFilter: [...state.matchFilter, action.info]
      };
    }
    case SET_SELECTED_FILTER: {
      return {
        ...state,
        matchSelectedFilter: action.info
      };
    }
    case DELETE_MATCH_FILTER:
      return {
        ...state,
        matchFilter: state.matchFilter.filter((item:any) => item.id !== action.info)
      };
    case SET_NOTIFICATION: {
      return {
        ...state,
        isNotification: action.info
      };
    }
    default:
      return state;
    case SET_SUPPORT: {
      return Object.assign({}, state, {
        support: {
          ...state.support,
          ...action.info
        }
      });
    }
  }
}

export const addItem = (info:any) => ({
  type: ADD_ITEM,
  info
});

export const updateCard = (info:any)  => ({
  type: UPDATE_CARD,
  info
});

export const toggleFilter = (info:any)  => ({
  type: TOGGLE_FILTER,
  info
});

export const cleanSelectedFilters = () => ({
  type: CLEAN_SELECTED_FILTERS
});

export const toggleSearchModal = (info:any)  => ({
  type: TOGGLE_SEARCH,
  info
});

export const toggleModalFilter = (info:any)  => ({
  type: TOGGLE_MODAL_FILTER,
  info
});

export const toggleDropdownFilter = (info:any)  => ({
  type: TOGGLE_DROPDOWN_FILTER,
  info
});

export const saveFilterItems = (info:any)  => ({
  type: SAVE_FILTER_ITEMS,
  info
});

export const updateUserFilter = (info:any)  => ({
  type: UPDATE_USER_FILTER,
  info
});

export const deleteUserFilter = (info:any)  => ({
  type: DELETE_USER_FILTER,
  info
});

export const saveFilterInfo = (info:any)  => ({
  type: SAVE_FILTER_INFO,
  info
});

export const upateSearchKeyword = (info:any)  => ({
  type: UPDATE_SEARCH_KEYWORD,
  info
});

export const checkAllFilters = () => ({
  type: CHECK_ALL_FILTERS
});

export const uncheckAllFilters = () => ({
  type: UNCHECK_ALL_FILTERS
});

export const saveUserFilters = (info:any)  => ({
  type: SAVE_USER_FILTERS,
  info
});

export const toggleUserFilter = (info:any)  => ({
  type: TOOGLE_USER_FILTER,
  info
});

export const cleanUserFilters = () => ({
  type: CLEAN_USER_FILTERS
});

export const cleanFilterModule = () => ({
  type: CLEAN_FILTER_MODULE
});

export const saveMatchFilters = (info:any)  => ({
  // get
  type: UPDATE_MATCH_FILTERS,
  info
});

export const createMatchFilter = (info:any)  => ({
  // criar um filtro
  type: CREATE_MATCH_FILTER,
  info
});

export const editMatchFilter = (info:any)  => ({
  type: EDIT_MATCH_FILTER,
  info
});

export const deleteMatchFilter = (info:any)  => ({
  type: DELETE_MATCH_FILTER,
  info
});

export const setSelectedFilter = (info:any)  => ({
  type: SET_SELECTED_FILTER,
  info
});
export const setSupport = (info:any)  => ({
  type: SET_SUPPORT,
  info
});

export const setNotification = (info:any)  => ({
  type: SET_NOTIFICATION,
  info
});
