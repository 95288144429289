/* eslint-disable max-len */
import React, { FC } from "react";
import styled from "styled-components";
import PDF from "./PDFTerms";
import Colors from "../../../assets/styles/colors";

const OverlayTerms = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.gray8}40;
`;

const ContainerTerms = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  // width: 600px;
  height: 650px;
  border-radius: 4px;
  background-color: ${Colors.white};
  overflow-y: scroll;
`;

const ContainerButtonClose = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ButtonCloseTerms = styled.button`
  width: 100%;
  height: 2.3rem;
  color: ${Colors.white};
  font-size: 0.95rem;
  font-weight: 600;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  background-color: ${Colors.blue6};
  font: Bold 1rem "Eurostile";
`;

interface TermsProps {
  handleModalTerms?: any;
}

const Terms: FC<TermsProps> = ({ handleModalTerms }) => {

  return (
    <OverlayTerms>
      <ContainerTerms>
        <PDF />
        <ContainerButtonClose onClick={handleModalTerms}>
          <ButtonCloseTerms>Fechar</ButtonCloseTerms>
        </ContainerButtonClose>
      </ContainerTerms>
    </OverlayTerms>
  );
}

export default Terms;
