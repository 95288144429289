import React, { FC, useEffect } from "react";
import "./ProposalDescription.css";
import { Link } from "react-router-dom";
import backButtonIcon from "../../assets/icons/common/back-button.svg";
import { connect } from "react-redux";
import {
  declineProposal,
  getProposals,
  getSpecificProposal,
} from "../../dataflow/thunks/easy-proposal-search";
import { resetSelectedProposal } from "../../dataflow/modules/easy-proposals-module";
import ProposalItem from "./proposalItem";
import ProposalAttachments from "./ProposalAttachments";
import FavoriteButton from "../../components/modules-components/match-analytical/FavoriteButton/index";
import RatingProposal from "../../components/modules-components/match-analytical/RatingProposal";
import { getIntegrationProfilesFromAPI } from "../../dataflow/thunks/integration-profiles";
import { eventCategory, logEvent } from "../../util/analytics/index";

interface ProposalDescriptionProps {
  proposals: any[];
  integrations: any;
  getIntegrationProfiles: () => void;
  getProposals: (v: any, v2: any) => void;
  match: any;
}

const ProposalDescription: FC<ProposalDescriptionProps> = ({
  proposals,
  integrations,
  getIntegrationProfiles,
  getProposals,
  match
}) => {

  useEffect(() => {
    if (proposals?.length === 0) {
      const { currentProfile } = integrations;
      if (!currentProfile) {
        getIntegrationProfiles();
      } else {
        handleGetProposals();
      }
    }
  }, []);

  const handleGetProposals = () => {
    const { document } = integrations?.currentProfile;
    getProposals(
      { keyword: "", filter_option: "ALL" },
      document?.number
    );
  }

  const getProposalStatus = (proposal: any) => {
    const proposalStatus = proposal?.proposalStatus;
    let status = "";

    if (proposalStatus === "NOT_DONE") {
      status = "Oportunidade ainda não realizada";
    } else if (proposalStatus === "DONE") {
      status = "Oportunidade realizada";
    } else if (proposalStatus === "DONE") {
      status = "Oportunidade declinada";
    }

    return <div className="descriptiontext">{status}</div>;
  }

  const id = match?.params?.proposalId;

  let proposal = null;
  if (proposals?.length > 0) {
    proposal = proposals?.find((item: any) => item?.id === id);
  }

  if (proposal) {
    return (
      <div className="proposalDescriptionContainer">
        <div className="proposalDescriptionMenu">
          <Link
            className="backButton"
            to={{ pathname: "/match-analitico/" }}
            onClick={() => {
              logEvent(
                eventCategory.buttonClick,
                'click voltar para match analitico',
                'Voltar'
              );
              handleGetProposals();
            }}
          >
            <img src={backButtonIcon} alt="back button" />
            <span className="pageTitleText">Voltar</span>
          </Link>
        </div>
        <div className="proposalDescription">
          <div className="descriptionHeader">
            <div className="headerTopics">
              <div className="descriptionTextTitle">
                {" "}
                Número da Oportunidade
              </div>
              <div className="descriptiontext">
                {proposal?.opportunityNumber}
              </div>
            </div>

            <div className="headerTopics">
              <div className="descriptionTextTitle">CNPJ</div>
              <div className="descriptiontext">
                {proposal?.companyDocumentNumber}
              </div>
            </div>

            <div className="headerTopics ">
              <p className="descriptionTextTitle">
                Avaliação da Oportunidade
              </p>
              <div className="descriptiontext">
                <RatingProposal rating={proposal?.rating} />
              </div>
            </div>

            <div className="headerTopics ">
              <p className="descriptionTextTitle">Favoritar</p>
              <div className="descriptiontext">
                <FavoriteButton
                  proposal={proposal}
                // proposals={proposals}
                />
              </div>
            </div>

            <div className="headerTopics">
              <div className="descriptionTextTitle"> Status</div>
              {getProposalStatus(proposal)}
            </div>

            <div className="headerTopics">
              <div className="descriptionTextTitle">Prazo de submissão</div>
              <div className="descriptiontext">{proposal?.closingDate}</div>
            </div>
          </div>

          <div className="descriptionHeader">
            <div className="headerTopics">
              <div className="descriptionTextTitle"> Descrição</div>
              <div className="descriptiontext">{proposal?.description}</div>
            </div>
          </div>

          <p className="descriptionTextTitle">Itens</p>
          {proposal?.items && proposal?.items?.length > 0
            ? proposal?.items?.map((item: any,index:any) => <ProposalItem key={index} item={item} />)
            : false}
          <p className="descriptionTextTitle">Anexos</p>
          {proposal?.attachments && proposal?.attachments?.length > 0
            ? proposal?.attachments?.map((attachments: any,index:any) => (
              <ProposalAttachments key={index} attachments={attachments} />
            ))
            : false}
        </div>
      </div>
    );
  } else {
    return null;
  }
}

const mapSateToProps = (state: any) => ({
  selectedProposal: state.easyProposals.selectedProposal,
  integrations: state.integrationProfiles,
  proposals: state.easyProposals.proposals,
});

const mapDispatchToProps = (dispatch: any) => ({
  declineProposal: (info: any) => {
    dispatch(declineProposal(info));
  },
  getSpecificProposal: (id: any) => {
    dispatch(getSpecificProposal(id));
  },
  getProposals: (info: any, document: any) => {
    dispatch(getProposals(info, document));
  },
  getIntegrationProfiles: () => dispatch(getIntegrationProfilesFromAPI()),
  resetProposal: () => {
    dispatch(resetSelectedProposal());
  },
});

export default connect(mapSateToProps, mapDispatchToProps)(ProposalDescription);
