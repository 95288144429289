import React, { FC } from "react";
import { TextDescription, Text } from "../../../components/common-components/Accordion/styles";
import { Image } from "../styles";
import PassRecovery01 from "../../../assets/img/faq/passRecovery1.png";
import PassRecovery02 from "../../../assets/img/faq/passRecovery2.png";
import PassRecovery03 from "../../../assets/img/faq/passRecovery3.png";

const PassRecovery: FC = () => {
    return (
        <>
            <TextDescription>
                <Text>
                    {`Caso você tenha esquecido ou perdido a sua senha, temos disponível em nosso site o botão “Recuperar Senha”. Após clicar e informar o seu e-mail, você receberá um código de confirmação na sua caixa de entrada ou lixo eletrônico para inserir juntamente a nova senha.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={PassRecovery01} alt="Imagem" />
            </TextDescription>
            <TextDescription>
                <Image src={PassRecovery02} alt="Imagem" />
            </TextDescription>
            <TextDescription>
                <Text>
                    {`Após a realização de todo o processo, você será redirecionado para a página de login com a seguinte mensagem no topo: “alteração de senha realizada com sucesso”.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={PassRecovery03} alt="Imagem" />
            </TextDescription>
        </>
    )
}
export default PassRecovery;