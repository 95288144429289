/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import {
  NoSavedFilters,
  SearchByExactTerm,
  ButtonBox,
  BoxSavedFilters,
  Container,
  SearchBarRow,
  HeaderTitle,
  Box,
  InputTitle,
  BlockFilter,
  BlockFilterOpportunities,
  TagListContainer,
  TagListChildrenContainer,
  ContainerCheckbox,
} from "./styles";
import SaveFilter from "../../../assets/icons/common/save-dark-blue.svg";
import SettingsFilter from "../../../assets/icons/common/settings-filter.svg";
import search from "../../../assets/icons/common/search-icon.svg";
import DropdownIcon from "../../../assets/icons/common/dropdown-azul.svg";
import { Button, FilterSearch } from "../../../components";
import SavedFilter from "../../../components/modules-components/match-relevance/NotificationFilter/MinFilter";
import {
  clearMatchOpportunitiesThunk,
  getMatchOpportunitiesThunk,
} from "../../../dataflow/thunks/opportunities-thunk";
import { getMatchFilterThunk } from "../../../dataflow/thunks/filters-thunk";
import { saveModal } from "../../../dataflow/modules/modal-module";
import {
  addOneFilter,
  setMatchOpportunities,
  setTagsFilter,
} from "../../../dataflow/modules/opportunities-module";
import TagList from "../../../components/common-components/TagList/TagList";
import { useTagListUtils } from "../../../components/common-components/TagList/useTagListUtils";
import Colors from "../../../assets/styles/colors";
import {
  eventCategory,
  logEvent,
} from "../../../util/analytics/index"
import { checkDeviceMobile } from "../../../util/devUtilities";

interface RelevancyMatchFilterProps {
  getMatchFilterThunk?: () => void;
  filterTags?: any;
  matchFilter?: any;
  getMatchOpportunitiesThunk?: (v: any, v2: any) => void;
  saveModal?: ({ type, isOpen, tagsSearch }: { type: string, isOpen: boolean, tagsSearch: any }) => void;
  setTagsFilter?: (v: any) => void;
  addOneFilter?: (v: any) => void;
  isBoxFilter?: any;
}

const RelevancyMatchFilter: FC<RelevancyMatchFilterProps> = ({
  getMatchFilterThunk,
  filterTags,
  matchFilter,
  getMatchOpportunitiesThunk,
  saveModal,
  setTagsFilter,
  addOneFilter,
  isBoxFilter
}) => {
  const [searchFilter, setSearchFilter] = useState("");
  const [defaultTagList, setDefaultTagList] = useState([]);
  const [selectedFromMyFilters, setSelectedFromMyFilters] = useState([{}]);
  const [isExactTermChecked, setIsExactTermChecked] = useState(false);
  const [isBoxFilterOpen, setIsBoxFilterOpen] = useState(false);
  const [isBoxSavedFilterOpen, setIsBoxSavedFilterOpen] = useState(false);
  const isMobile:boolean = checkDeviceMobile();
  const [searchText, setSearchText] = useState('');

  const {
    mapStringArrayToTagItems,
    mapTagItemsToString,
    removeFromTagList,
    setThisItemsToSelected,
  } = useTagListUtils();
  const tagListRef: any = useRef();

  useEffect(() => {
    if (getMatchFilterThunk) { getMatchFilterThunk() };
    const activeTags: any = mapStringArrayToTagItems(filterTags).map((item) => ({ ...item, selected: true }));
    searchOpportunities(activeTags);
    setDefaultTagList(activeTags);
    updateSelectedFilterFromTagsList(filterTags);
  }, []);

  useEffect(() => {
    const selectedFilters: any = [];
    matchFilter.forEach((filter: any) => {
      if (filter.id) selectedFilters[filter.id] = false;
    });
    setSelectedFromMyFilters(selectedFilters);
    updateSelectedFilterFromTagsList(defaultTagList || filterTags);
  }, [matchFilter]);

  /**
   * @param {*} activeTags :  Array<{label:string,fullObj:T,selected:boolean}>
   */
  const searchOpportunities = (activeTags: any) => {
    if (getMatchOpportunitiesThunk) {
      getMatchOpportunitiesThunk(
        mapTagItemsToString(activeTags),
        isExactTermChecked
      );
    }

  };

  /**
   * @param {*} _toggledItem : T
   * @param {*} activeTags :  Array<{label:string,fullObj:T,selected:boolean}>
   */
  const handleTagToggle = (_toggledItem: any, activeTags: any) => {
    searchOpportunities(activeTags);
  };

  const handleExactTermsToggle = (value: any) => {
    const filters: any = tagListRef?.current?.activeTags?.map(
      (selectedItem: any) => selectedItem.fullObj
    );
    if (getMatchOpportunitiesThunk) { getMatchOpportunitiesThunk(filters, value); }

  };

  const openBox = (ev: any) => {
    ev.stopPropagation();
    setIsBoxFilterOpen(false);
    setIsBoxSavedFilterOpen((state) => !state);
    logEvent(
      eventCategory.dropdownChange,
      'Click Dropdown Meus Filtros',
      'Meus Filtros'
    );
  };

  const openFilterBox = (ev: any) => {
    ev.stopPropagation();
    setIsBoxFilterOpen(true);
    setIsBoxSavedFilterOpen(false);
  };

  const handleChange = (ev: any) => {
    setSearchFilter(ev.target.value);
  };

  const handleChangeFilters = (event: any) => {
    const text = event.target.value.toLowerCase();
    setSearchText(text);
  };

  const filteredMatches = matchFilter.filter((filter: any) =>
    filter.name.toLowerCase().includes(searchText) ||
    (filter.tags && filter.tags.some((tag: string) => tag.toLowerCase().includes(searchText)))
  );

  const handleFilterModal = () => {
    if (saveModal) {
      saveModal({
        type: "filter-modal",
        isOpen: true,
        tagsSearch: defaultTagList,
      });
    }

    logEvent(
      eventCategory.buttonClick,
      'click abrir modal salvar filtros',
      'Salvar filtros'
    );
  };

  const toggleFilter = (filter: any, index: any) => {
    const newSelected: any = { ...selectedFromMyFilters };
    const newValue = !newSelected[filter.id];
    newSelected[filter.id] = newValue;
    setSelectedFromMyFilters(newSelected);

    let newTags: any = [];
    if (newValue) {
      const uniqueTags = [
        ...mapStringArrayToTagItems(filter.tags),
        ...removeFromTagList(filter.tags, defaultTagList),
      ];
      newTags = setThisItemsToSelected(filter.tags, uniqueTags);
      setTagsFilter && setTagsFilter(uniqueTags);
    } else {
      // newTags = removeFromTagList(filter.tags, props.filterTags);
      newTags = removeFromTagList(filter.tags, defaultTagList);
      setTagsFilter && setTagsFilter(mapTagItemsToString(newTags));
    }

    setDefaultTagList(newTags);
    updateSelectedFilterFromTagsList(newTags);
    searchOpportunities(newTags.filter((item: any) => item.selected));
  };

  /**
   * If the user removes one tag from a selected filter, it will automatically be set to unselected
   * @param {*} tags current filterTags
   */
  const updateSelectedFiltersFromRemovedTag = (removedItem: any) => {
    const newSelected: any = { ...selectedFromMyFilters };
    for (let index = 0; index < matchFilter.length; index++) {
      const filter = matchFilter[index];
      const isCurrentlyActive = selectedFromMyFilters[filter.id];

      if (isCurrentlyActive) {
        if (removedItem) {
          if (filter.tags.includes(removedItem.fullObj || removedItem)) {
            newSelected[filter.id] = !newSelected[filter.id];
          }
        }
      }
    }
    setSelectedFromMyFilters(newSelected);
  };

  /**
   * If the user adds all tags from a selected filter, it will automatically be set to selected
   * @param {*} tags current filterTags
   */
  const updateSelectedFilterFromTagsList = (tags: any) => {
    const newSelected: any = { ...selectedFromMyFilters };
    const strings = mapTagItemsToString(tags);

    for (let index = 0; index < matchFilter.length; index++) {
      const filter = matchFilter[index];
      let shouldBeChecked = true;
      for (let index = 0; index < filter.tags.length; index++) {
        const tag = filter.tags[index];
        if (!strings.includes(tag)) {
          shouldBeChecked = false;
          break;
        }
      }
      newSelected[filter.id] = shouldBeChecked;
    }
    setSelectedFromMyFilters(newSelected);
  };

  const handleAddTag = () => {
    logEvent(
      eventCategory.iconClick,
      'Click criou filtro',
      'Filtrar'
    );
    const tagList: any = searchFilter
      .toLowerCase()
      .trim()
      .replace(",", "");
    const alreadyExists = defaultTagList.find(
      (itemTag: any) => tagList === itemTag.fullObj
    );

    if (alreadyExists) {
      return null;
    } else {
      const listSearchFilter: any[] = [searchFilter]
      addOneFilter && addOneFilter(tagList);
      const newList: any = [
        ...mapStringArrayToTagItems(listSearchFilter, true),
        ...defaultTagList,
      ];
      setDefaultTagList(newList);
      searchOpportunities(newList.filter((item: any) => item.selected));
      updateSelectedFilterFromTagsList(newList);
    }

    setSearchFilter("");
  };

  /**
   * @param {*} _removedItem : T
   * @param {*} newTagListArray :  Array<{label:string,fullObj:T,selected:boolean}>
   */
  const handleRemoveTagAndSearch = (removedItem: any, newTagListArray: any) => {
    setTagsFilter && setTagsFilter(mapTagItemsToString(newTagListArray));

    setDefaultTagList(newTagListArray);
    updateSelectedFiltersFromRemovedTag(removedItem);
    if (removedItem.selected) {
      searchOpportunities(newTagListArray.filter((item: any) => item.selected));
    }
  };

  const handleKeyAddTag = (ev: any) => {
    ev.preventDefault();
    ev.stopPropagation();

    if ((ev.key === "," || ev.key === "Enter") && searchFilter !== "") {
      handleAddTag();
    } else {
      return null;
    }
  };

  const handleClickAddTag = (ev: any) => {
    ev.stopPropagation();
    if (searchFilter !== "") {
      handleAddTag();
    } else {
      return null;
    }
  };

  const title = isMobile ? "Filtros de oportunidades" : "Licitações públicas selecionadas";
  const placeholder =
    !isBoxFilterOpen && isMobile ? "" : "Digite palavras-chave";

  return (
    <>
      <SearchBarRow>
        <HeaderTitle>{title}</HeaderTitle>
        <Box>
          <InputTitle>Filtrar</InputTitle>
          <BlockFilter isBoxFilter={isBoxFilter}>
            <FilterSearch
              placeholder={placeholder}
              width={"100%"}
              value={searchFilter}
              onChange={handleChange}
              onClick={openFilterBox}
              handleKey={handleKeyAddTag}
              handleAddTag={handleClickAddTag}
              borderTopRightRadius="16px"
              borderTopLeftRadius="16px"
              borderBotttomLeftRadius={"16px"}
              borderBottomRightRadius={"16px"}
              isBoxFilter={isBoxFilter}
              search
              icon={search}
            />
          </BlockFilter>
          <BlockFilterOpportunities>
            <FilterSearch
              placeholder={"Meus Filtros"}
              width={"180px"}
              onClick={openBox}
              handleAddTag={openBox}
              type="button"
              borderTopRightRadius="16px"
              borderTopLeftRadius="16px"
              borderBottomRightRadius={isBoxSavedFilterOpen ? 0 : "16px"}
              borderBotttomLeftRadius={isBoxSavedFilterOpen ? 0 : "16px"}
              icon={DropdownIcon}
              onChange={handleChangeFilters}
              search
            />
            {isBoxSavedFilterOpen && (
              <Container onClick={(ev) => ev.stopPropagation()}>
                <BoxSavedFilters>
                  <ButtonBox onClick={() => {
                    logEvent(
                      eventCategory.buttonClick,
                      'Click Gerenciar Filtros',
                      'Gerenciar Filtros'
                    );
                  }}
                    to={"/match-filter"}>
                    <Button
                      color={Colors.blue8}
                      background={Colors.white}
                      border={`1px solid ${Colors.blue6}`}
                      width="100%"
                      height="2.5rem"
                      margin=".25rem 0"
                    >
                      <img
                        src={SettingsFilter}
                        style={{ width: "17px", marginTop: "2px" }}
                        alt={"icon"}
                      />
                      Gerenciar Filtros
                    </Button>
                  </ButtonBox>
                  {filteredMatches.length === 0 ? (
                    <NoSavedFilters>Nenhum filtro encontrado</NoSavedFilters>
                  ) : (
                    filteredMatches.map((filter: any, index: number) => (
                      <SavedFilter
                        key={filter.id}
                        isSelected={selectedFromMyFilters[filter.id]}
                        name={filter.name}
                        background={filter.color}
                        addFilter={() => toggleFilter(filter, index)}
                      />
                    ))
                  )}
                </BoxSavedFilters>
              </Container>
            )}
          </BlockFilterOpportunities>
        </Box>
      </SearchBarRow>
      
      <TagListContainer>
        <TagList
          ref={tagListRef}
          title={"Filtros :"}
          defaultList={defaultTagList}
          onToggle={handleTagToggle}
          onRemove={handleRemoveTagAndSearch}
          editEnabled={true}
        >
          <TagListChildrenContainer>
            <SearchByExactTerm>
              <ContainerCheckbox
                onClick={(e) => {
                  e.preventDefault();
                  handleExactTermsToggle(!isExactTermChecked);
                  setIsExactTermChecked((state) => !state);
                  logEvent(
                    eventCategory.checkboxChange,
                    `Buscar por termos exatos ${!isExactTermChecked}`,
                    'Buscar por termos exatos'
                  );
                }}
              >
                {isExactTermChecked ? (
                  <MdCheckBox />
                ) : (
                  <MdCheckBoxOutlineBlank />
                )}
                <input
                  type={"checkbox"}
                  id={"exactTerm"}
                  checked={isExactTermChecked}
                />
                <label htmlFor={"exactTerm"}>Buscar por termos exatos</label>
              </ContainerCheckbox>
            </SearchByExactTerm>
            <label htmlFor={"notificationSave"}></label>
            <Button
              id={"notificationSave"}
              color={Colors.blue8}
              background={Colors.white}
              border={`1px solid ${Colors.blue6}`}
              height="2rem"
              onClick={handleFilterModal}
              disabled={defaultTagList.length === 0}
            >
              <span style={{ whiteSpace: "nowrap" }}>Salvar filtros</span>
              <img
                src={SaveFilter}
                style={{
                  width: "1.5rem",
                  marginTop: "2px",
                  marginLeft: ".25rem",
                }}
                alt={"icon"}
              />
            </Button>
          </TagListChildrenContainer>
        </TagList>
      </TagListContainer>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  opportunities: state.opportunities,
  matchFilter: state.filter.matchFilter,
  filterTags: state.opportunities.tagsFilter,
  apiOpportunities: state.opportunities.apiOpportunities,
});

const mapDispatchToProps = (dispatch: any) => ({
  addOneFilter: (info: any) => dispatch(addOneFilter(info)),
  setTagsFilter: (info: any) => dispatch(setTagsFilter(info)),
  clearMatchOpportunitiesThunk: () => dispatch(clearMatchOpportunitiesThunk()),
  getMatchOpportunitiesThunk: (info: any, isExactTerm: any) =>
    dispatch(getMatchOpportunitiesThunk(info, isExactTerm)),
  setMatchOpportunities: (info: any) => dispatch(setMatchOpportunities(info)),
  saveModal: (info: any) => dispatch(saveModal(info)),
  // cleanTagsFilter: (info) => dispatch(cleanTagsFilter(info)),
  getMatchFilterThunk: () => dispatch(getMatchFilterThunk()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RelevancyMatchFilter);
