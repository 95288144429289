import React, { useRef, useState, useEffect, FC } from "react";
import loginHidePassword from "../../../assets/icons/common/login-hide-password.svg";
import loginShowPassword from "../../../assets/icons/common/login-show-password.svg";
import { createAccount } from "../Cognito";
import { connect } from "react-redux";
import Terms from "../components/Terms";
import Button from "../../../components/common-components/Button";
import Logo from "../../../components/layout-components/Logo/Logo";
import Input from "../../../components/common-components/Input/Input";
import {
  CreateContainer,
  CreateBox,
  RegisterForm,
  TermsText,
  ButtonTerms,
  CreateTitle,
  InputBox,
  IconInputPassword,
  LoaderContainer,
  Loader,
  BoxCheck,
  TextConfirm,
  ButtonCloseTerms,
} from "./styles";
import loader from "../../../assets/icons/common/loading.svg";
import Colors from "../../../assets/styles/colors";
import { eventCategory, logEvent } from "../../../util/analytics/index";
const nameRegex = new RegExp(/[A-Za-z\u00C0-\u00FF]{3,}[\w ]*/);
const emailRegex = new RegExp(/\S+@\S+\.\S+/);
interface CreateAccountProps {
  handleResendEmail: (v: string) => void;
  handleBackLogin: () => void;
  handleResend: (v: boolean) => void;
}
interface Form {
  name: string;
  email: string;
  confirmEmail: string;
  password: string;
}
const CreateAccount: FC<CreateAccountProps> = ({
  handleResendEmail,
  handleBackLogin,
  handleResend
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenTerms, setIsOpenTerms] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const formRef = useRef(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [formValues, setFormValues] = useState<Form>({
    name: "",
    email: "",
    confirmEmail: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState<Form>({
    name: "",
    email: "",
    confirmEmail: "",
    password: "",
  });
  useEffect(() => {
    if (isLoading || !isTermsAccepted) {
      setIsSubmitDisabled(true);
      return;
    }
    let disabled = false;
    for (const key in formErrors) {
      if (Object.hasOwnProperty.call(formErrors, key)) {
        const element: string = formErrors[key as keyof Form];
        if (element !== "") {
          disabled = true;
          break;
        }
      }
    }
    setIsSubmitDisabled(disabled);
  }, [formErrors, isLoading, isTermsAccepted]);
  const onChangeField = (e: any, field: any) => {
    e.persist();
    setFieldError(field, "");
    if (e.target?.value && field) {
      setFormValues((previous) => {
        let newValue: any = { ...previous };
        newValue[field] = e.target?.value;
        return newValue;
      });
    }
    if (e.target.placeholder === "Senha") {
    } else {
      return null;
    }
  };
  const setFieldError = (field: any, message: any) => {
    setFormErrors((previous) => {
      const newValue: any = { ...previous };
      newValue[field] = message;
      return newValue;
    });
  };
  const handleCreateAccount = async () => {
    setIsLoading(true);
    logEvent(
      eventCategory.buttonClick,
      'Submeter formulário de criação de conta',
      'Concordar e criar conta'
    );
    const { name, email, confirmEmail: confirm, password } = formValues;
    try {
      const username = name.trim();
      const registeredEmail = email.trim();
      const confirmEmail = confirm.trim();
      const registeredPassword = password.trim();
      debugger;
      if (!username) {
        setFieldError("name", "O campo Nome é obrigatório!");
        setIsLoading(false);
        return;
      }
      if (confirmEmail.trim() !== registeredEmail) {
        setFieldError(
          "email",
          "Os campos email e confirmação de email devem ser iguais!"
        );
        setIsLoading(false);
        return;
      }
      await createAccount({
        email: registeredEmail,
        password: registeredPassword,
        username,
        terms_accepted: isTermsAccepted.toString(),
      });
      setIsLoading(false);
      handleResend(false)
      handleResendEmail(email);
    } catch (err: any) {
      if (err.response.data.__type === "LimitExceededException") {
        setFieldError(
          "password",
          "Você excedeu o número de tentativas. Por favor, tente novamente mais tarde."
        );
        setIsLoading(false);
      }
      if (err.response.data.__type === "InvalidParameterException" && err.response.data.message === "Username should be an email.") {
        setFieldError("email", "Deve ser um e-mail válido");
        setIsLoading(false);
      }
      if (err.response.data.__type === "UsernameExistsException") {
        setFieldError("email", "Usuário ja existe");
        setIsLoading(false);
      }
      if (err.response.data.__type === "UserLambdaValidationException") {
        setFieldError("email", "Não é possível criar este usuário");
        setIsLoading(false);
      }
    }
  };
  const handleModalTerms = () => {
    setIsOpenTerms((currentState) => !currentState);
    logEvent(
      eventCategory.modalOpen,
      `abrir modal de termos de uso ${!isOpenTerms}`,
      'Termos de Uso'
    );
  };
  const validateEmail = (field: string) => {
    if (
      formValues.confirmEmail &&
      formValues.email &&
      formValues.confirmEmail !== formValues.email
    ) {
      setFieldError("confirmEmail", "Emails devem ser iguais!");
    } else {
      const isEmailValid: any = emailRegex.test(formValues[field as keyof Form]);
      if (!isEmailValid) {
        setFieldError(
          field,
          `Email incorreto, use um endereço de e-mail válido`
          // `Email incorreto, um email válido deve conter somente "letras", "números", "@" e "."!`

        );
      } else {
        setFieldError("confirmEmail", ``);
        setFieldError("email", ``);
      }
    }
  };

  const onBlurField = (field: any) => {
    switch (field) {
      case "name": {
        if (!nameRegex.test(formValues.name)) {
          setFieldError(field, "Digite um nome válido!");
        } else {
          setFieldError(field, "");
        }
        break;
      }
      case "email": {
        validateEmail("email");
        break;
      }
      case "confirmEmail": {
        validateEmail("confirmEmail");
        break;
      }
      case "password": {
        const lower = /([a-z])/;
        const upper = /([A-Z])/;
        const numbers = /([0-9])/;
        const special = /([^a-zA-Z0-9])/;
        if (
          !formValues.password.match(lower) ||
          !formValues.password.match(upper) ||
          !formValues.password.match(numbers) ||
          !formValues.password.match(special)
        ) {
          setFieldError(
            "password",
            "A senha precisa conter letras minúsculas, maiúsculas, números e caracteres especiais"
          );
        }
        if (formValues.password.length < 6) {
          setFieldError("password", "Senha precisa de ao menos 6 caracteres");
        }
        break;
      }
      default:
        break;
    }
  };
  const handlePassword = () => {
    setShowPassword((currentState) => !currentState);
  };
  const toggleTerms = (e: any) => {
    setIsTermsAccepted(!isTermsAccepted);
    logEvent(
      eventCategory.checkboxChange,
      `aceitar os termos de uso na criação de conta ${!isTermsAccepted}`,
      'Li e aceito os termos de uso'
    );
  };
  return (
    <CreateContainer>
      <CreateBox>
        <Logo width="220px" widthMobile="45%" />
        <RegisterForm ref={formRef} onSubmit={handleCreateAccount} placeholder="Criar conta">
          <CreateTitle>Criar Conta</CreateTitle>
          <InputBox width="100%">
            <Input
              required
              autoFocus
              placeholder={"Nome"}
              // error={errorType === "name" && props.error}
              error={formErrors.name}
              onBlur={() => {
                onBlurField("name");
              }}
              onChange={(e: any) => onChangeField(e, "name")}
              label="Nome"
            />
          </InputBox>
          <InputBox last width="100%">
            <Input
              type={"email"}
              required
              placeholder={"nome@email.com"}
              // error={errorType === "email" && props.error}
              error={formErrors.email}
              onBlur={() => {
                onBlurField("email");
              }}
              onChange={(e: any) => onChangeField(e, "email")}
              label="Email"
            />
          </InputBox>
          <InputBox last width="100%">
            <Input
              type={"email"}
              required
              placeholder={"nome@email.com"}
              // error={errorType === "email" && props.error}
              error={formErrors.confirmEmail}
              onBlur={() => {
                onBlurField("confirmEmail");
              }}
              onChange={(e: any) => onChangeField(e, "confirmEmail")}
              label="Confirme seu email"
            />
          </InputBox>
          <InputBox last width="100%">
          
            <Input
              type={showPassword ? "text" : "password"}
              required
              placeholder={"Senha"}
              // error={errorType === "password" && props.error}
              error={formErrors.password}
              onBlur={() => {
                onBlurField("password");
              }}
              onChange={(e: any) => onChangeField(e, "password")}
              label="Senha"
            />
           
            <IconInputPassword
              src={showPassword ? loginHidePassword : loginShowPassword}
              onClick={handlePassword}
              errorType={formErrors.password}
            />
           
          </InputBox>
          <TermsText>
            Clique abaixo para concordar com os
            <ButtonTerms onClick={handleModalTerms}>
              <strong style={{ textDecoration: "underline" }}>
                Termos de Uso
              </strong>
            </ButtonTerms>
            e registrar
          </TermsText>
          <BoxCheck>
            <input onClick={(e) => toggleTerms(e)} type="checkbox"></input>
            <TextConfirm>Li e aceito os termos de uso</TextConfirm>
          </BoxCheck>
          {isOpenTerms && <Terms handleModalTerms={handleModalTerms} />}
          {isLoading ? (
            <LoaderContainer>
              <Loader src={loader} />
            </LoaderContainer>
          ) : (
            <>
              <ButtonCloseTerms type={"submit"} disabled={isSubmitDisabled}>
                Concordar e criar conta
              </ButtonCloseTerms>
              <Button
                margin="0.2 0 0 0"
                marginMobile=".5rem 0 0 0"
                width="100%"
                height="3rem"
                color={Colors.gray8}
                background={Colors.white}
                onClick={handleBackLogin}
                textDecoration="underline"
              >
                Voltar ao login
              </Button>
            </>
          )}
        </RegisterForm>
      </CreateBox>
    </CreateContainer>
  );
};
const mapStateToProps = (state: any) => ({
  error: state.auth.error,
  createSuccess: state.auth.createSuccess,
});
export default connect(mapStateToProps, null)(CreateAccount);
