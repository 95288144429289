import React, { FC } from "react";
import styled from "styled-components";
import Colors from "../../../assets/styles/colors";
import Button from "../../../components/common-components/Button";
import Modal from "../../../components/common-components/Modal/Modal";
import { eventCategory, logEvent } from "../../../util/analytics";
import Feedback01 from "../../../assets/img/faq/feedback1.png";

const Title = styled.h1`
  font: 700 2rem Eurostile;
  color: ${Colors.blue8};
`;
const SubTitle = styled.h2`
  font: 700 1.2rem Eurostile;
  color: ${Colors.blue8};
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
`;
const Text = styled.p`
  color: ${Colors.gray8};
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
`;

const Image = styled.img`
  width: 50%;
`;

interface ModalFeedbackProps {
    closeModal: () => void;
}

const ModalFeedback: FC<ModalFeedbackProps> = ({ closeModal }) => {

    const handleModalFeedback = () => {
        window?.localStorage?.setItem(
            "feedback_modal",
            JSON.stringify({read:true})
        );
        logEvent(
            eventCategory.modalOpen,
            'click botão OK modal Feedback',
            `Sugestão para feedbacks mensais`
        );
    }

    return (
        <Modal
            width={'60%'}
            onBlur={() => {
                logEvent(
                    eventCategory.modalOpen,
                    'click modal feedbacks mensais',
                    `click modal feedbacks mensais`
                );
            }}
            closeModal={() => closeModal()}
            overlay
        >
            <Title>Queremos ouvir você!</Title>
            <Text>É extremamente importante o seu feedback para a Minha Petronect, assim aprimoraremos sua experiência na plataforma.</Text>
            <Text>Segue o exemplo abaixo:</Text>
            <Image src={Feedback01} alt="Imagem" />
            <SubTitle>O Time Minha Petronect agradece seu feedback, em caso de dúvidas consulte nosso FAQ.</SubTitle>
            <Button
                background={Colors.blue6}
                width={"25%"}
                widthMobile={"100%"}
                height={"56px"}
                fontWeight={"400"}
                margin={'10px'}
                onClick={() => { handleModalFeedback(); closeModal() }}
            >
                OK
            </Button>
        </Modal>
    );
};

export default ModalFeedback;