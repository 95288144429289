import React, { FC, useState } from "react";
import { InfoImage, PageDefaultHeader, TitleContainer } from "./styles";
import infoIcon from "../../../assets/icons/common/info-blue.svg";
import { checkDeviceMobile } from "../../../util/devUtilities";

interface Paragraph {
  p1: string[];
  p2: string[];
}

interface PageHeaderDescriptionProps {
  title: String;
  paragraphs: string[];
  doubleParagraph?: Paragraph;

}

const PageHeaderDescription: FC<PageHeaderDescriptionProps> = ({ title, paragraphs, doubleParagraph }) => {
  const isMobile: boolean = checkDeviceMobile();
  const [isClick, setIsClick] = useState(false);
  const renderParagraphItem = (item: any, index: any) => {
    if (typeof item === "string") {
      return <p key={index} dangerouslySetInnerHTML={{ __html: item }}></p>;
    }

    if (Array.isArray(item)) {
      return (
        <ul key={index}>
          {item.map((itemList, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: itemList }}></li>
          ))}
        </ul>
      );
    }
  };




  return (
    <PageDefaultHeader>
      <TitleContainer>
        <h2>{title}</h2>
        {isMobile && (<InfoImage src={infoIcon} onClick={() => setIsClick(!isClick)} />)}

      </TitleContainer>
      {(!isMobile || isClick) && (
        <div className="textHeader">
          {paragraphs.map(renderParagraphItem)}

          <div className="doubleParagraph">
            {doubleParagraph && (
              <>
                <div>{doubleParagraph.p1.map(renderParagraphItem)}</div>
                <div>{doubleParagraph.p2.map(renderParagraphItem)}</div>
              </>
            )}
          </div>
        </div>
      )}


    </PageDefaultHeader>
  );
};

export default PageHeaderDescription;
