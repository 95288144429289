import styled from "styled-components";
import maintenance_background from "../../assets/img/maintenance/maintenance_background.png";
import Colors from "../../assets/styles/colors";
import { StylesProps } from "../../util/types";

const Overlay = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TourContainer = styled.div`
  padding: 20px;
  flex-direction: column;
  text-align: center;
  max-width: 1200px;
  width: ${(props: StylesProps) => (props.first ? false : "90%")};
  min-height: 500px;
  height: ${(props: StylesProps) => (props.height ? props.height : "auto")};
  background: ${Colors.white} url(${maintenance_background}) no-repeat center
    center;
  background-size: auto 100%;
  border-radius: 10px;
  box-shadow: 0px 3px 6px ${Colors.black}29;
  transition: width 1s, heigth 1s;
`;

const TourTitlePrimary = styled.h1`
  color: ${Colors.blue6};
  font: 48px Eurostile;
  letter-spacing: 0;
  margin: 0;
`;

const TourTitleSecundary = styled.h2`
  color: ${Colors.blue6};
  font: 36px Eurostile;
  letter-spacing: 0;
  margin: 0;
`;

const ImageMaintenance = styled.img`
  margin: 20px 0;
`;

const TourText = styled.p`
  font: Medium 14px/18px Roboto;
  max-width: 500px;
  margin: auto;
  letter-spacing: 0;
  font-size: 24px;
  color: ${Colors.blue6};
`;

export {
  Overlay,
  TourContainer,
  TourTitlePrimary,
  TourTitleSecundary,
  ImageMaintenance,
  TourText,
};
