import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../assets/styles/colors";
import PageHeaderDescription from "../../components/common-components/PageHeaderDescription/PageHeaderDescription";
import loader from "../../assets/icons/common/loading.svg";
import axios from "axios";
import { dateDefault, decodedToken } from "../../util/devUtilities";
import Tooltip from "../../components/common-components/Tooltip";
import { Link } from "react-router-dom";
import { StylesProps } from "../../util/types";
import { eventCategory, logEvent } from "../../util/analytics";
import HelpImg from "../../assets/icons/common/help.svg";
import { ItemProps } from "../AccessFee/ListItem/Item";
import CardSuggestion from "./CardSuggestion/CardSuggestion";

const Container = styled.div`
overflow: auto;
`

const Title = styled.h2`
  letter-spacing: 0;
  color: ${Colors.blue6};
  font: 700 1rem "Eurostile", "Open sans", sans-serif;

  @media screen and (max-width: 480px) {
    margin-bottom: 0.8rem;
  }
`;

const Span = styled.span`
  margin-bottom: 3px;
  color: ${Colors.blue6};
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: ${(props: ItemProps) => (props.bold ? "600" : "350")};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

const RowUpdate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 16px;
  padding-right: 16px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

const RowTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  @media screen and (max-width: 480px) {
    justify-content: center;
  }
`;

const RowNoSuggestion = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-top:50px;
  @media screen and (max-width: 480px) {
    
  }
`;

const Loader = styled.img`
  width: 30px;
  height: 30px;
`;

const HelpButton = styled(Link)`
  width: 20px;
  height: 20px;
  background: none;
  background-image: url(${(props: StylesProps) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  cursor: pointer;
  margin-left: 10px;
`;

export enum Status {
    VENCIDO = 'Vencido',
    ATIVO = 'Ativo',
    NAO_SOCIO = 'Não Sócio'
}

interface FamilySuggestionProps {
    integrations: any;
    getIntegrationProfiles: () => void;
}

const FamilySuggestion: FC<FamilySuggestionProps> = () => {
    const [listFamily, setListFamily] = useState<any>([])
    const { idToken } = JSON.parse(
        window?.localStorage?.getItem("petronect_credentials") || ""
    );
    const [isLoading, setIsLoading] = useState(false);
    const [lastUpdate, setLastUpdate] = useState('');
    const [isTooltipPartner, setIsTooltipPartner] = useState(false);

    useEffect(() => {
        getListFamily();
    }, []);

    const getListFamily = () => {
        const payload = {
            userId: decodedToken('accessToken').username,
            method: "GET"
        };
        setIsLoading(true)
        axios.post(
            `${process.env.REACT_APP_API}/export-data/list-familys`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${idToken}`
                }
            }
        )
            .then((response: any) => {
                if (response && response !== null) {
                    const orderByLength = (array: any) => {
                        return array.sort((a: any, b: any) => b.sugestao.length - a.sugestao.length);
                    }
                    setListFamily(orderByLength(response?.data?.body?.listaFamilias));
                    setLastUpdate(response?.data?.body?.last_update[0]?.created_at)
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            })
    }

    return (
        <Container>
            <PageHeaderDescription
                title={"Sugestão de Famílias"}
                paragraphs={[
                    `É um painel de divulgação de sugestões de grupos de família Petrobras, relacionadas as palavras salvas no filtro de busca no Match Relevância, e que não estão cadastradas para o CNPJ selecionado.

                    Quanto mais filtros o usuário salvar na aba Match Relevância, mais sugestões de famílias ele visualizará no painel Sugestão de Famílias relacionado a cada palavra.
                    
                    Para visualizar a lista de famílias sugeridas, basta clicar no card da palavra salva no filtro e aproveitar a dica!`,
                ]}
            />
            <RowUpdate>
                {listFamily.length > 0 && (
                    <Span>Última atualização:{' '}
                        {isLoading && (
                            <Loader src={loader} />
                        )}
                        {!isLoading && (
                            <Span bold>{dateDefault(lastUpdate, '')}</Span>
                        )}
                    </Span>
                )}
            </RowUpdate>
            <Row>
                <RowTitle>
                    <Title>Sugestão de Famílias</Title>
                    {
                        listFamily?.some((item: any) => item.status !== Status.NAO_SOCIO) && (
                            <HelpButton
                                onMouseOver={() => setIsTooltipPartner(true)}
                                onClick={() => {
                                    logEvent(
                                        eventCategory.iconClick,
                                        "click HelpImg",
                                        "Icone de ajuda como usar"
                                    );
                                    setIsTooltipPartner(value => !value)
                                }}
                                image={HelpImg}
                                to={'/family-suggestion'}
                            />
                        )}
                </RowTitle>
            </Row>
            <Row>
                {isTooltipPartner && (
                    <Tooltip
                        width={'100%'}
                        onMouseLeave={() => { setIsTooltipPartner(false) }}
                        title="Como Usar"
                        paragraphs={[
                            `Abaixo, apresentamos os cards segregados pela palavra salva no filtro do Match Relevância:

                            Para visualizar as famílias sugeridas é necessário clicar no card apresentado com a palavra salva no filtro.
                            
                            Pronto! Agora, siga para o Portal de Cadastro da Petrobras e realize o cadastro das famílias para ampliar as oportunidades!`,
                        ]}
                    />
                )}
            </Row>
            {(listFamily?.length > 0 && !isLoading) && (
                <div className="proposalContainerList">
                    {listFamily?.map((card: any) => (<CardSuggestion card={card} />))}
                </div>
            )}
            {(listFamily?.length === 0 && !isLoading) && (
                <RowNoSuggestion>
                    <p>Ainda não temos Sugestão de Familias para o CNPJ selecionado!</p>
                </RowNoSuggestion>
            )}
        </Container>
    )
}

export default FamilySuggestion;