import styled from "styled-components";
import Colors from "../../assets/styles/colors";
import { StylesProps } from "../../util/types";

const OnboardingContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: transparent
    linear-gradient(180deg, ${Colors.blue8} 0%, ${Colors.blue6} 100%) 0% 0%
    no-repeat padding-box;
  padding: 0 4rem;
  @media (max-width: 1200px) {
    
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 960px) {
    flex-direction: ${(props:StylesProps) =>
      !(props.screen === "create") && "column-reverse"};
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 650px) {
    padding: 0 2rem;
  }

  @media (max-width: 648px) {
    padding: 1rem;
  }
`;
export { OnboardingContainer };
