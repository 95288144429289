import React from 'react';
import { Pagination } from 'react-bootstrap';
import {
  PaginationContainer,
  ItemsPerPageSelect,
  ItemsPagination,
  PaginationWrapper,
} from './styles'; 

interface PaginationComponentProps {
  currentPage: number;
  totalPages: number;
  itemsPerPage: number;
  handlePageChange: (pageNumber: number) => void;
  handleItemsPerPageChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onPageClick: (pageNumber: number) => void; // Adicionado
}

const PaginationComponent: React.FC<PaginationComponentProps> = ({
  currentPage,
  totalPages,
  itemsPerPage,
  handlePageChange,
  handleItemsPerPageChange,
  onPageClick,
}) => {
  const pagesToShow = 3;
  const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
  const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

  const pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

  return (
    <PaginationContainer>
      <PaginationWrapper>
        <Pagination>
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          {pages.map(page => (
            <Pagination.Item
              key={page}
              active={page === currentPage}
              onClick={() => onPageClick(page)}
            >
              {page}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      </PaginationWrapper>
      <ItemsPagination>
        <p>Itens por página</p>
        <ItemsPerPageSelect onChange={handleItemsPerPageChange} value={itemsPerPage}>
          <option value={15}>15</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </ItemsPerPageSelect>
        <span>Página {currentPage} de {totalPages}</span>
      </ItemsPagination>
    </PaginationContainer>
  );
};

export default PaginationComponent;
