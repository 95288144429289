//actions
export const UPDATELOCALPROPOSALS =
  "Petronect/MatchAnalytical/UPDATELOCALPROPOSALS";

export const ISSEARCHINGFORPROPOSALS =
  "Petronect/MatchAnalytical/ISSEARCHINGFORPROPOSALS";

export const SELECTEDPROPOSAL = "Petronect/MatchAnalytical/SELECTEDPROPOSAL";

export const FAVORITEPROPOSAL = "Petronect/MatchAnalytical/FAVORITEPROPOSAL";

export const RESETSELECTEDPROPOSAL =
  "Petronect/MatchAnalytical/RESETSELECTEDPROPOSAL";

export const initialState = {
  proposals: [],
  isSearchingForProposals: true,
  selectedProposal: {
    id: "",
    description: "",
    partnumber: "",
    deliveryAddress: "",
    closingDate: "",
    opportunityNumber: "",
    proposalStatus: "",
    items: [],
    attachments: [],
    isFavorite: false,
    rating: 1,
  },
};

export default (state = initialState, action:any) => {
  // console.log("REDUX: ", action.type, action.info);
  switch (action.type) {
    case UPDATELOCALPROPOSALS: {
      return { ...state, proposals: [...action.info] };
    }

    case ISSEARCHINGFORPROPOSALS: {
      return { ...state, isSearchingForProposals: action.info };
    }

    case SELECTEDPROPOSAL: {
      return { ...state, selectedProposal: action.info };
    }

    case RESETSELECTEDPROPOSAL: {
      return { ...state, selectedProposal: initialState.selectedProposal };
    }

    case FAVORITEPROPOSAL: {
      let proposals:any = [...state.proposals];

      if (proposals.length === 0 && state.selectedProposal !== undefined) {
        proposals.push(action.info);
        return { ...state, proposals: proposals };
      }

      proposals.forEach((proposal:any) => {
        if (proposal.id === action.info.id) {
          proposal.isFavorite = action.info.isFavorite;
        }
      });
      return { ...state, proposals: proposals };
    }

    default: {
      return state;
    }
  }
};

//action creators
export const updateLocalProposals = (info:any) => ({
  type: UPDATELOCALPROPOSALS,
  info,
});

export const isSearchingForProposals = (info:any) => ({
  type: ISSEARCHINGFORPROPOSALS,
  info,
});

export const selectedProposal = (info:any) => ({
  type: SELECTEDPROPOSAL,
  info,
});

export const favoriteProposal = (info:any) => ({
  type: FAVORITEPROPOSAL,
  info,
});

export const resetSelectedProposal = () => ({
  type: RESETSELECTEDPROPOSAL,
});
