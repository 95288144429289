// Libs
import { compose, createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";

// Reducers
import Auth from "./modules/auth-module";
import Opportunities from "./modules/opportunities-module";
import Filter from "./modules/filter-module";
import Modal from "./modules/modal-module";
import EasyProposals from "./modules/easy-proposals-module";
import IntegrationProfiles from "./modules/integration-profiles-module";
import Support from "./modules/support-module";

const reducers:any = combineReducers({
  auth: Auth,
  opportunities: Opportunities,
  filter: Filter,
  modals: Modal,
  easyProposals: EasyProposals,
  integrationProfiles: IntegrationProfiles,
  support: Support,
});

const configureStore = (initialState:any) => {
  const bundle = compose(applyMiddleware(thunkMiddleware));
  const createStoreWithMiddleware:any = bundle(createStore);
  const store:any = createStoreWithMiddleware(
    reducers,
    initialState,
    // window?.devToolsExtension ? window?.devToolsExtension() : (f:any) => f
  );

  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module.hot.accept(reducers, () => {
        store.replaceReducer(reducers);
      });
    }
  }

  return store;
};

export default configureStore({});
