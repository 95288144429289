import React from 'react';
import { CardWrapper, CardContent, StatusBar, Text, CardFlex, Image } from './styles';
// import { ICurrentItem } from './interfaces';
import { handleDate } from '../../../../util/format-value';
import RedClock from '../../../../assets/icons/common/clock-red.svg';
import YellowClock from '../../../../assets/icons/common/clock-yellow.svg';
import GreenClock from '../../../../assets/icons/common/clock-green.svg';
import { handleMoneyWhenReceiving } from '../../util/functions';

const ProposalCard = ({ currentItem }: { currentItem: any }) => {
    const handleClock = (clockType: 'red' | 'yellow' | 'green') => {
        if (clockType === 'red') return RedClock;
        if (clockType === 'yellow') return YellowClock;
        if (clockType === 'green') return GreenClock;
    }

    return (
        <CardWrapper to={{
            pathname: `/marketplace/${currentItem?.IdRequisicaoCompra}`,
            state: currentItem,
        }}>
            <StatusBar color={currentItem.statusColor} />
            <CardContent>
                <CardFlex>
                    <div>
                        <Text>Nº da requisição: {currentItem.NumRequisicao}</Text>
                        <Text>Descrição: {currentItem.Descricao}</Text>
                        <Text>Local de entrega: {currentItem.LocalEntrega}</Text>
                        <Text>Fabricante: {currentItem.Fabricante}</Text>
                        <Text>Data limite para apresentação de proposta: {handleDate(currentItem.VencimentoRequisicao)}</Text>
                    </div>
                    <div>
                        <Text>Cotação atual: {
                            currentItem.ValorPropostaFornecedor ?
                            `R$ ${handleMoneyWhenReceiving(String(currentItem.ValorPropostaFornecedor))}` : `R$ 0,00`
                        }</Text>
                        <Text>Melhor preço: {
                            currentItem.ValorPropostaVencedora ?
                            `R$ ${handleMoneyWhenReceiving(String(currentItem.ValorPropostaVencedora))}` : `R$ 0,00`
                        }</Text>
                    </div>
                </CardFlex>
                <Image src={handleClock(currentItem.clockType)} alt="Clock" />
            </CardContent>
        </CardWrapper>
    )
}

export default ProposalCard;