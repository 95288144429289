import React, { FC, useEffect } from "react";
// import illustration from '../../../assets';
import {
  getIntegrationProfilesFromAPI,
  delete_integration_profile_From_API,
} from "../../dataflow/thunks/integration-profiles";
import { connect } from "react-redux";
import {
  ProfileContainer,
  CellTitle,
  Card,
  DeleteButton,
  SubContainerTitle
} from "./styles";

const mapStateToProps = (state: any) => ({
  integrationProfiles: state.integrationProfiles.integrationProfiles,
});

const mapDispatchToProps = (dispatch: any) => ({
  // favoriteProposgetalonAPI: (proposal, isFavorite) => { dispatch(favoriteProposalonAPI(proposal, isFavorite)) }
  getIntegrationProfilesFromAPI: () => {
    dispatch(getIntegrationProfilesFromAPI());
  },
  delete_integration_profile_From_API: (integrationId: any) => {
    dispatch(delete_integration_profile_From_API(integrationId));
  },
});

interface ManageProfileProps{
  getIntegrationProfilesFromAPI:()=>void;
  delete_integration_profile_From_API:(v:any)=>void;
  integrationProfiles:any;
}

const ManageProfile: FC<ManageProfileProps> = ({
  getIntegrationProfilesFromAPI,
  delete_integration_profile_From_API,
  integrationProfiles
}) => {

  useEffect(()=>{
    get_integration_profiles();
  },[])

  // const isLoadingInfo=(response:any)=> {
  //   setState({ isLoadingActive: response });
  // }

  const get_integration_profiles = () => {
    getIntegrationProfilesFromAPI();
  }

  const delete_integration_profile=(integrationId:any)=> {
    delete_integration_profile_From_API(integrationId);
  }

  const infoThatWillDisplay=()=> {
    return integrationProfiles.length === 0 ? (
      <div> Não existem perfis cadastrados </div>
    ) : (
      integrationProfiles.map((profile:any,index:any) => {
        return (
          <Card key={index}>
            <CellTitle>{`CNPJ: ${profile.document.number}`}</CellTitle>
            <CellTitle>{`TOKEN: XXXXXXXXXXXXXXXXXXXXXXXXXX${profile.tokenLastCharacters}`}</CellTitle>
            <DeleteButton
              onClick={() =>
                delete_integration_profile(profile.integrationId)
              }
            >
              Excluir
            </DeleteButton>
          </Card>
        );
      })
    );
  }

  return (
    <div>
      <SubContainerTitle>Perfis Cadastrados</SubContainerTitle>
      <ProfileContainer>{infoThatWillDisplay()}</ProfileContainer>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageProfile);
