//actions
export const GETINTEGRATIONPROFILES =
  "Petronect/IntegrationProfiles/GETINTEGRATIONPROFILES";

export const ADDNEWINTEGRATIONPROFILE =
  "Petronect/IntegrationProfiles/ADDNEWINTEGRATIONPROFILE";

export const CURRENTINTEGRATIONPROFILE =
  "Petronect/IntegrationProfiles/CURRENTINTEGRATIONPROFILE";

export const DELETEINTEGRATIONPROFILE =
  "Petronect/IntegrationProfiles/DELETEINTEGRATIONPROFILE";

export const INTEGRATIONSTATUSRESPONSE =
  "Petronect/IntegrationProfiles/INTEGRATIONSTATUSRESPONSE";

export const CHANGECNPJANDTOKENONFORM =
  "Petronect/IntegrationProfiles/CHANGECNPJANDTOKENONFORM";

export const initialState = {
  integrationProfiles: null,
  integrationStatus: "normal",
  cpnjAndTokenForm: { cnpj: "", token: "" },
  currentProfile: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GETINTEGRATIONPROFILES:
      return { ...state, integrationProfiles: action.info };

    case CURRENTINTEGRATIONPROFILE:
      return { ...state, currentProfile: action.info };

    case ADDNEWINTEGRATIONPROFILE:
      let profiles: any = [...(state?.integrationProfiles || "")];
      profiles.push(action.info);
      return { ...state, integrationProfiles: profiles };

    case DELETEINTEGRATIONPROFILE:
      let oldProfiles: any = [...(state?.integrationProfiles || "")];
      let newProfiles = oldProfiles.filter(
        (element: any) => element.integrationId !== action.info
      );
      return { ...state, integrationProfiles: newProfiles };

    case INTEGRATIONSTATUSRESPONSE:
      return { ...state, integrationStatus: action.info };

    case CHANGECNPJANDTOKENONFORM:
      return { ...state, cpnjAndTokenForm: action.info };

    default:
      return state;
  }
};

//action creators
export const getIntegrationProfiles = (info: any) => ({
  type: GETINTEGRATIONPROFILES,
  info,
});

export const setCurrentProfile = (info: any) => ({
  type: CURRENTINTEGRATIONPROFILE,
  info,
});

export const addNewIntegrationProfile = (info: any) => ({
  type: ADDNEWINTEGRATIONPROFILE,
  info,
});

export const deleteIntegrationProfile = (info: any) => ({
  type: DELETEINTEGRATIONPROFILE,
  info,
});

export const integrationStatusResponse = (info: any) => ({
  type: INTEGRATIONSTATUSRESPONSE,
  info,
});

export const changeCNPJAndTokenOnForm = (info: any) => ({
  type: CHANGECNPJANDTOKENONFORM,
  info,
});
