import styled, { keyframes } from "styled-components";
import Colors from "../../assets/styles/colors";
import { StylesProps } from "../../util/types";

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  background: transparent
    linear-gradient(180deg, ${Colors.blue8} 0%, ${Colors.blue6} 100%);
  overflow-x: hidden;
`;

const Content = styled.div`
  display: flex;
  width: 92%;
  height: 95vh;

  @media (max-width: 1200px) {
    flex-direction: column;
    height: 95vh;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1rem 0;
    overflow-y: hidden;
  }

  @media screen and (max-width: 480px) {
    padding: 0 0.5rem 0 0.3rem;
  }
`;

const ContentDash = styled.div`
  display: flex;
  flex-direction: column;
  width: 81%;
  height: 100%;
  margin: 0 auto;
  background: ${Colors.white};
  border-right: rgba(0, 0, 0, 0.1) solid 1px;
  border-radius: 0 4px 0 0;

  @media (max-width: 1200px) {
    width: 100%;
    min-height: 100%;
    border-radius: 4px 4px 0 0;
  }

  @media screen and (max-width: 490px) {
    padding: 0;
    margin-top: 1rem;
  }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: ${Colors.black}50;
  z-index: 5;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto 0;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled.img`
  animation: ${rotate} 2s linear infinite;
  margin: 2rem;
`;

const LoadingText = styled.p`
  margin: 0;
  font: 700 2.5rem Eurostile;
  letter-spacing: 0;
  color: ${Colors.white2};
`;

const TourOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: ${Colors.black}50;
  z-index: 10;
`;

const TourContainer = styled.div`
  padding: 2% 4% 2% 4%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props: StylesProps) =>
    props.first ? "center" : "space-between"};
  align-items: center;
  max-width: 1000px;
  width: ${(props: StylesProps) => (props.first ? false : "100%")};
  height: ${(props: StylesProps) => (props.first ? false : "auto")};
  background: ${Colors.white};
  border-radius: 4px;
  transition: width 1s, heigth 1s;
  margin: 10px;
`;

const TourLayout = styled.div`
  /* padding: 4%; */
  margin-bottom: 5vh;
  width: auto;
  height: auto;
`;

const TourTitle = styled.h2`
  font: Bold 1.5rem Eurostile;
  letter-spacing: 0;
  color: ${Colors.blue6};
`;

const TourText = styled.p`
  font: Regular 1rem Open Sans;
  letter-spacing: 0;
  color: ${Colors.gray8};
`;

const TourImaage = styled.img`
  margin-bottom: 4%;
  width: 95%;
`;

const TourButtons = styled.div`
  width: 100%;
  border: none;
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
`;

const TourButton = styled.button`
  cursor: pointer;
  // background: ${Colors.blue6};
  font-weight: bold;
  width: 100px;
  padding: 10px;
  background: ${Colors.blue6};
  color: ${Colors.white};
  border: none;
  border-radius: 10px;
`;

export {
  Container,
  Content,
  ContentDash,
  LoadingOverlay,
  LoadingContainer,
  LoadingIcon,
  LoadingText,
  TourOverlay,
  TourContainer,
  TourLayout,
  TourTitle,
  TourText,
  TourImaage,
  TourButtons,
  TourButton
}
