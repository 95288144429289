import styled, { css } from "styled-components";
import Colors from "../../../../assets/styles/colors";
import { StylesProps } from "../../../../util/types";
import imagem from "./20220726_cardLives.png"

const CarouselDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 1rem;
`;

const SlideItemContainer = styled.div`
  width: 90%;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${imagem});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
`;

const SlideImage = styled.img`
  ${(props: StylesProps) =>
    props.hasText
      ? css`
          max-height: 100%;
        `
      : css`
          margin: 3rem 0px;
        `};
`;

const SlideMessage = styled.span`
  margin: 0.25rem 0;
  margin-top: 3rem;
  color: ${Colors.white};
  font: 700 1.125rem "Eurostile", "Open Sans", sans serif;
  font-weight: bold;
  bottom: 16vh;
  letter-spacing: 0.18px;
  line-height: 2rem;

  @media (max-width: 768px) {
    width: 35%;
    bottom: 13vh;
    left: 5vh;
    font-size: 1rem;
  }
`;



export {
  CarouselDiv,
  SlideItemContainer,
  SlideImage,
  SlideMessage,
}
