import styled from "styled-components";
import { Link } from "react-router-dom";
import Colors from "../../../assets/styles/colors";
import { StylesProps } from "../../../util/types";

const SearchBarRow = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;
  padding-left: 2rem;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0.75rem;
    flex-direction: column;
  align-items: flex-start;
  }

  /* @media (max-width: 490px) {
    align-items: center;
    position: relative;
  } */
`;

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 75%;

    @media (max-width: 500px) {
   flex-direction: column;
   align-items: flex-start;
   width:100%;
  }


`;

const HeaderTitle = styled.span`
  width: 30%;
  font-weight: 600;
  font-size: 1.1rem;
  color: ${Colors.blue6};

  @media (max-width: 768px) {
    font-size: 0.95rem;
    width: 33%;
    margin-right: 1.5rem;
    margin-left: -1rem;
  }

  @media (max-width: 500px) {
    width: 50%;
    margin-left: 0rem;
  }
`;

const BlockFilter = styled.span`
  position: relative;
  width: 70%;
  display: flex;
  align-items: center;
  @media (max-width: 500px) {
    width: 100%;
  };

  /* @media (max-width: 590px) {
    position: absolute;
    width: ${(props: StylesProps) => (props.isBoxFilter ? "91vw" : "33px")};
    left: ${(props: StylesProps) => (props.isBoxFilter ? "" : "9rem")};
  } */
`;

const InputTitle = styled.p`
  margin: 0.5rem;
  font: 400 1rem Eurostile;
  letter-spacing: 0.5px;
  color: ${Colors.blue6};

  @media (max-width: 590px) {
    display: none;
  }
`;

// export const BoxFilter = styled.div`
//   position: absolute;
//   top: 100%;
//   right: 1rem;
//   display: flex;
//   align-self: center;
//   flex-direction: column;
//   width: calc(100% - 1rem);
//   padding: 0.5rem;
//   border: 0.5px solid ${Colors.blue6};
//   border-top: none;
//   background: ${Colors.white};
//   z-index: 5;

//   @media (max-width: 768px) {
//     right: 0.25rem;
//     width: calc(100% - 0.5rem);
//   }
// `;

// export const BoxCell = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   margin: 0.5rem 0 0.8rem 0;
// `;

// export const BoxButtons = styled.span`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

const BlockFilterOpportunities = styled.div`
  width: auto;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 92%;
  padding: 0.5rem;
  border: 0.5px solid ${Colors.blue6};
  border-top: none;
  border-radius: 0 0 4px 4px;
  background: ${Colors.white};
  overflow: hidden;
  z-index: 5;

  @media (max-width: 768px) {
    left: 0.25rem;
    width: 96%;
  }

  @media (max-width: 590px) {
    right: 0;
  }
`;

const BoxSavedFilters = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
`;

const NoSavedFilters = styled.p`
  width: 100%;
  text-align: center;
  font-size: 0.85rem;
  color: ${Colors.gray6};
`;

const ButtonBox = styled(Link)`
  width: 100%;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
`;

// export const ClearButton = styled.button`
//   background: transparent;
//   color: #1c5781;
//   border: none;
//   cursor: pointer;

//   svg {
//     width: 0.75rem;
//     margin: auto 0px;
//     margin-right: 0.25rem;
//   }
// `;

const SearchByExactTerm = styled.div`
  color: ${Colors.blue8};
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  color: ${Colors.blue6};

  input:checked {
    background-color: ${Colors.blue6};
  }

  label {
    cursor: pointer;
    font-size: 14px;
  }

  input[type="checkbox"] {
    display: none;
  }
`;

const TagListContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5rem 2rem;
`;
const TagListChildrenContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5rem 0.2rem;
`;

const ContainerCheckbox = styled.div`
display: flex;
flex-direction: row;
align-content: center;
align-items: center;
justify-content: center;
flex-wrap: nowrap;
`

export {
  SearchBarRow,
  Box,
  HeaderTitle,
  BlockFilter,
  InputTitle,
  BlockFilterOpportunities,
  Container,
  BoxSavedFilters,
  NoSavedFilters,
  ButtonBox,
  SearchByExactTerm,
  TagListContainer,
  TagListChildrenContainer,
  ContainerCheckbox
};
