import React, { FC } from "react";
import {
  GraphSwitch as Graph,
  SwitchContainer,
  Label,
  Type,
  HelpContainer,
  HelpButton
} from "../../../../pages/MyPerformance/styles";
import HelpImg from "../../../../assets/icons/common/help.svg";
import { eventCategory, logEvent } from "../../../../util/analytics/index";

interface GraphSwitchProps {
  graphTypes?: any[];
  handleChangeSelectedType: (value: any) => void;
  selectedGraph?: any;
}

const GraphSwitch: FC<GraphSwitchProps> = ({ graphTypes, handleChangeSelectedType, selectedGraph }) => {
  return (
    <Graph>
      <SwitchContainer>
        <Label>Visualizar:</Label>
        {graphTypes?.map((graphType, index) => {          
          const handleClick = () =>
            handleChangeSelectedType(graphType.type);
          return (
            <Type
            key={index}
              onClick={handleClick}
              isSelected={selectedGraph === graphType.type}
            >
              {graphType.label}
            </Type>
          );
        })}
        {/* <HelpContainer>
          <HelpButton
            onClick={() => {
              logEvent(
                eventCategory.iconClick,
                "click HelpImg",
                "Icone de ajuda"
              );
            }}
            image={HelpImg}
            to={"/private-faq"}
          />
        </HelpContainer> */}
      </SwitchContainer>
    </Graph>
  );
};

export default GraphSwitch;
