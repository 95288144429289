import axios from "axios";

interface IMarketplacePropotals {
    method: string;
    id: number | null;
    cnpj: string;
}

function idToken() {
    const { idToken } = JSON.parse(localStorage.getItem("petronect_credentials") || "");

    return idToken;
}

const headers = (body: any) => ({ headers: body });

/**
 * @param payload id: null - getAll; id: number - getById
 */
const getCatalogs = async (payload: IMarketplacePropotals) => (
    await axios.post(
        `${process.env.REACT_APP_API}/export-data/catalogo`, 
        payload,
        headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken()}`
        })
    )
)

const getAllCatalogs = async (payload: IMarketplacePropotals) => await getCatalogs(payload);
const getCatalogsById = async (payload: IMarketplacePropotals) => await getCatalogs(payload);
const getPropotalsById = async (payload: any) => (
    await axios.post(
        `${process.env.REACT_APP_API}/export-data/proposta`, 
        payload,
        headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken()}`
        })
    )
)
const verifyAuthorizationMarketplace = async (payload: { cnpj: string }) => (
    await axios.post(
        `${process.env.REACT_APP_API}/export-data/autenticacaomk`, 
        payload,
        headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken()}`
        })
    )
)


export {
    getAllCatalogs,
    getCatalogsById,
    getPropotalsById,
    verifyAuthorizationMarketplace
}