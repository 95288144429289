import styled from "styled-components";
import { Form } from "@unform/web";
import Colors from "../../../assets/styles/colors";
import { Link as LinkReact } from "react-router-dom";

interface StylesProps{
  width?:string;
  last?:boolean;
  color?:any;
  lastResponsive?:boolean;
}

export const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;
  background: ${Colors.white};
  border-radius: 0 0 6px 6px;

  @media (max-width: 960px) {
    margin: 1rem 0;
    padding: 2rem;
    width: 85%;
    height: min-content;
    border-radius: 6px;
  }

  @media (max-width: 648px) {
    margin: 0;
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-evenly;
    border-radius: 6px;
  }
`;

export const InputBox = styled.span`
  position: relative;
  width: ${(props:StylesProps) => props.width};
  margin-top: ${(props:StylesProps) => props.last && ".5rem"};

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const IconInputPassword = styled.img`
  position: absolute;
  bottom: 27%;
  right: 0;
  width: 1.25rem;
  margin-right: 1rem;
  cursor: pointer;
`;

export const AltBox = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 64%;
  margin-top: 1rem;
  @media (max-width: 960px) {
    margin-top: 0;
    margin: 1.5rem 0;
    width: 94%;
  }
  @media (max-width: 648px) {
    width: 90%;
  }
`;

export const Link = styled.p`
  font: 400 1rem "Eurostile", "Open Sans", sans serif;
  letter-spacing: 0;
  color: ${(props:StylesProps) => props.color || Colors.gray8};
  text-decoration: ${(props:StylesProps) => (props.color ? "underline" : "none")};
  cursor: pointer;
  :last lastResponsive-child {
    text-align: right;
  }
  &:hover {
    color: ${Colors.blue8};
    border-radius: 5px;
    font-weight: 700;
  }
  @media (max-width: 425px) {
    font-size: 0.8rem;
  }
`;

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
`;

export const LoginForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 10px;
`;

export const LoginMessageError = styled.span`
  position: relative;
  width: 100%;
  margin-top: 6px;
`;

export const TextError = styled.p`
  font: 500 0.9rem "Eurostile", "Open Sans", sans serif;
  color: ${Colors.red2};
  text-align: center;
  

  @media (max-width: 960px) {
    margin: 0.1rem 0;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3rem;
`;

export const Loader = styled.img`
  width: 30px;
  height: 30px;
`;

export const LoginRightSide = styled.div`
  position: sticky;
  top: 0px;
  right: 0px;
  margin-right: -4rem; //Needed to counter default screen padding and center slider
  width: 60%;
  height: 100vh;

 
  /* @media (max-width: 960px) {
    width: 95%;
    height: 40vh;
    justify-content: space-evenly;
    flex-direction: row-reverse;
  } */
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const ContainerOpacity = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalBox = styled.div`
  width: 28%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.red2};
  border-radius: 5px;
`;

export const Close = styled.p`
  width: 95%;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  cursor: pointer;
  font: 500 1rem "Eurostile", "Open Sans", sans-serif;
`;

export const TextWarning = styled.p`
  width: 95%;
  display: flex;
  align-items: center;
  margin: 0 0 0 0;
  color: ${Colors.red2};
  font: 500 1rem "Eurostile", "Open Sans", sans-serif;
`;

export const TextVersion = styled.p`
  font: 400 .8rem "Eurostile", "Open Sans", sans serif;
  color: ${Colors.gray8};
  @media (max-width: 425px) {
    font-size: 0.6rem;
  }
`;

export const TourText = styled.p`
  font: Medium 14px/18px Roboto;
  text-align: center;
  max-width: 500px;
  margin: 10px auto;
  letter-spacing: 0;
  font-size: 20px;
  color: ${Colors.blue6};
`;

export const TourTextLink = styled(LinkReact)`
  font: Medium 14px/18px Roboto;
  text-align: center;
  max-width: 500px;
  margin: 10px auto;
  letter-spacing: 0;
  font-size: 16px;
  color: ${Colors.blue6};
  text-decoration: none;
  @media (max-width: 425px) {
    font-size: 13px;
  }
`;

export const BannerWarning = styled.img`
  height: 100vh;
`;

export const NewUserDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  h5 {
    padding: 0px;
    margin: auto 0px;
    @media (max-width: 425px) {
    font-size: 0.8rem;
  }
  }
  > button {
    padding: 0.5rem 1.5rem;
    margin-top: 0px;
    margin-left: 1rem;
    @media (max-width: 425px) {
    font-size: 0.8rem;
  }
  }
`;

export const HighlightText = styled.h4`
  color: ${Colors.blue10};
  text-align: center;
  // width: 50%;
  margin-bottom: 15px;
  font-size: 1.2rem;
  @media (max-width: 425px) {
    font-size: 1rem;
  }
`