import React, { FC } from "react";
import styled from "styled-components";
import Colors from "../../../assets/styles/colors";
import Button from "../../../components/common-components/Button";
import Modal from "../../../components/common-components/Modal/Modal";
import { eventCategory, logEvent } from "../../../util/analytics";

const Title = styled.h1`
  font: 700 2rem Eurostile;
  color: ${Colors.blue8};
`;
const SubTitle = styled.h2`
  font: 700 1.2rem Eurostile;
  color: ${Colors.blue8};
  padding-left: 10px;
  padding-right: 10px;
`;
const Text = styled.p`
  color: ${Colors.gray8};
  padding-left: 10px;
  padding-right: 10px;
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;

`;

interface ModalNewVersionProps {
    closeModal: () => void;
}

const ModalNewVersion: FC<ModalNewVersionProps> = ({ closeModal }) => {
    const appVersion = (window as any).appVersion;

    const handleModalNewVersion = () => {
        window?.localStorage?.setItem(
            "app_version",
            JSON.stringify(appVersion || '')
        );
        logEvent(
            eventCategory.modalOpen,
            'click botão OK modal nova versão App',
            `nova versão App ${appVersion.number}`
        );
    }

    return (
        <Modal
            width={'50%'}
            onBlur={() => {
                logEvent(
                    eventCategory.modalOpen,
                    'click modal nova versão App',
                    `click modal nova versão App`
                );
            }}
            closeModal={() => closeModal()}
            overlay
        >
            <Title>Novidades</Title>
            <Text>Descubra o que está incluído nessa nova atualização.</Text>
            <Box>
                <SubTitle>{`versão ${appVersion?.number}`}</SubTitle>
                <ul>
                    <li><Text><strong>Novos Recursos</strong></Text></li>
                    <ul>
                    {/* <li><Text> Novo layout na visualização do gráfico de Oportunidades em Meu Desempenho;</Text></li>
                    <li><Text> Mais informações e detalhes das oportunidades perdidas, no campo Acumulado em Meu Desempenho;</Text></li>
                    <li><Text> Novos filtros de seleção para Oportunidades encontradas em Match Analítico;</Text></li> */}
                    <li><Text> Nova funcionalidade 'Sugestão de Famílias' o objetivo é apresentar sugestões de famílias relacionadas as palavras salvas na gestão de filtro do Match Relevância (Busca de oportunidades da Minha Petronect).;</Text></li>
                    <li><Text> Quanto mais palavras o usuário salvar em seu filtro, maior a chance de verificar novas famílias para cadastro e aumentar as oportunidades para concorrer.;</Text></li>
                   
                    {/* <li><Text>Validação de e-mail - Botão adicional para verificar o e-mail do usuario caso não tenha recebido no momento do cadastro.</Text></li> */}
                    </ul>
                    <li><Text><strong>Recursos aprimorados</strong></Text></li>
                    <ul>
                        {/* <li><Text> Adicionar perfil na tela de Integrações;</Text></li> */}
                        {/* <li><Text> Correção nas oportunidades exibidas na funcionalidade "Meu desempenho".</Text></li>
                        <li><Text> Melhorias e correções de responsividade no layout para dispositivos mobile e tablet.</Text></li> */}
                        <li><Text> Correções de bugs e melhorias no layout.</Text></li>
                        {/* <li><Text> Novo layout da tela de FAQ respondendo as dúvidas mais frequentes com exemplos práticos e print de telas.</Text></li> */}
                    </ul>
                </ul>
            </Box>
            <Button
                background={Colors.blue6}
                width={"25%"}
                widthMobile={"100%"}
                height={"56px"}
                fontWeight={"400"}
                margin={'10px'}
                onClick={() => { handleModalNewVersion(); closeModal() }}
            >
                OK
            </Button>
        </Modal>
    );
};

export default ModalNewVersion;