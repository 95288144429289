// Action Types
export const SET_USER_AUTH = "petronect/auth/SET_USER_AUTH";
export const UPDATE_ERROR = "petronect/auth/ADD_ITEM";
export const UPDATE_CREATE_SUCCESS = "petronect/auth/UPDATE_CREATE_SUCCESS";
export const UPDATE_RECOVER_SUCCESS = "petronect/auth/UPDATE_RECOVER_SUCCESS";
export const VERIFY_EMAIL_EXISTING = "petronect/auth/VERIFY_EMAIL_EXISTING";
export const GET_NAME_USER = "petronect/auth/GET_NAME_USER";

export const initialState = {
  user: {
    isAuthenticated: false,
    data: {}
  },
  error: "",
  createSuccess: null,
  recoverSuccess: null,
  verifyEmailExisting: true,
  nameUser: ""
};

// Reducer
export default function(state = initialState, action:any) {
  switch (action.type) {
    case SET_USER_AUTH:
      return Object.assign({}, state, {
        user: action.info
      });
    case UPDATE_ERROR:
      return {
        ...state,
        error: action.info
      };
    case UPDATE_CREATE_SUCCESS:
      return {
        ...state,
        createSuccess: action.info
      };
    case UPDATE_RECOVER_SUCCESS:
      return {
        ...state,
        recoverSuccess: action.info
      };
    case VERIFY_EMAIL_EXISTING:
      return {
        ...state,
        verifyEmailExisting: action.info
      };
    case GET_NAME_USER:
      return {
        ...state,
        nameUser: action.info
      };
    default:
      return state;
  }
}

// Action Creators
export const setUserAuth = (info:any) => ({
  type: SET_USER_AUTH,
  info
});
export const updateError = (info:any) => ({
  type: UPDATE_ERROR,
  info
});

export const updateCreateSuccess = (info:any) => ({
  type: UPDATE_CREATE_SUCCESS,
  info
});

export const updateRecoverSuccess = (info:any) => ({
  type: UPDATE_RECOVER_SUCCESS,
  info
});

export const verifyEmailExisting = (info:any) => ({
  type: VERIFY_EMAIL_EXISTING,
  info
});

export const getNameUser = (info:any) => ({
  type: GET_NAME_USER,
  info
});
