import styled from "styled-components";
import { StylesProps } from "../../../util/types";

export const ImageLogo = styled.img`
  height: ${(props:StylesProps) => props.height};
  width: ${(props:StylesProps) => props.width};
  margin: ${(props:StylesProps) => props.margin};
  min-width: ${(props:StylesProps) => props.minWidth};

  @media (max-width: 648px) {
    height: ${(props:StylesProps) => props.height};
    width: ${(props:StylesProps) => props.widthMobile};
    margin: ${(props:StylesProps) => props.marginMobile};
    min-width: ${(props:StylesProps) => props.minWidthMobile};
    padding: ${(props:StylesProps) => props.paddingMobile};
  }
`;