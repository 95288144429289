import React, { FC } from "react";
import { Link } from "react-router-dom";
import {
  Overlay,
  TourContainer,
  RowButton,
  TourTitle,
  TextObservation,
  TourText,
  TourButton
} from "./styles";

interface WelcomeEasyProposalProps{
  feature?:string;
}

const WelcomeEasyProposal:FC<WelcomeEasyProposalProps> = ({feature})=>{
 const  renderMatchAnalytical = (title:string) => {
    return (
      <TourContainer>
        <TourTitle>Bem Vindo ao {title}</TourTitle>

        <TourText>
          Aqui é possível acessar de forma mais ágil as dispensas* que a empresa
          foi convidada.
        </TourText>

        <TourText>
          Após a integração através do Token criado no portal Petronect, as
          oportunidades ficam disponíveis na Minha Petronect para melhor
          controle e análise. Toda ação executada no portal Petronect é
          refletida na Minha Petronect. (Ex: Se uma oportunidade for declinada,
          o card muda a cor de amarelo para vermelho.)
        </TourText>

        <TourText>
          Além disso, é possível verificar a Avaliação da Oportunidade, onde em
          conjunto com a funcionalidade Desempenho fazemos uma leitura de todas
          as oportunidades que a empresa ganhou e listamos em quantidades de
          estrelas preenchidas qual é mais relevante para o seu processo.
        </TourText>

        <TextObservation>
          *Oportunidades de compras de itens até R$80 mil"
        </TextObservation>

        <RowButton>
          <Link to="/integracoes">
            <TourButton>Fazer Integração</TourButton>
          </Link>
        </RowButton>
      </TourContainer>
    );
  };

  const renderMyPerformance = (title:string) => {
    return (
      <>
        <TourContainer>
          <TourTitle>Bem Vindo ao {title}</TourTitle>

          <TourText>
            Faça a integração e tenha acesso ao seu histórico de participação em
            oportunidades, com análise dos preços ofertados por você versus os
            menores preços ofertados por outros participantes, simplificando a
            análise de precificação e concorrência.
          </TourText>

          <RowButton>
            <Link to="/integracoes">
              <TourButton>Fazer Integração</TourButton>
            </Link>
          </RowButton>
        </TourContainer>
      </>
    );
  };


    return (
      <Overlay>
        {feature === "my-performance"
          ? renderMyPerformance("Meu Desempenho")
          : renderMatchAnalytical("Match Analítico")}
      </Overlay>
    );
  }

export default WelcomeEasyProposal;
