import axios from "axios";
import jwt_decode from "jwt-decode";

export const getUserTermsAcceptedByEmail = async () => {
  try {
    console.log(">>> [LOG] - getUserTermsAcceptedByEmail");
    const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
    );
    const decodedToken: any = jwt_decode(idToken)
    const email:any = decodedToken.email;

    const {
      data: { termsAccepted },
    } = await axios.get(`${process.env.REACT_APP_API}/users/${email}`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    return true ? termsAccepted === "true" : false;
  } catch (e) {
    console.log(">>> [LOG] - Catch");
    console.error(e);
  }
};

export const updateUserTermsAccepted = async (isTermsAccepted:any) => {
  try {
    const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
    );
    const decodedToken: any = jwt_decode(idToken)
    const email = decodedToken.email;
    const { status } = await axios.patch(
      `${process.env.REACT_APP_API}/users/${email}`,
      { isTermsAccepted },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    if (status === 400 || status === 403 || status === 404 || status === 500)
      return "Parece que algo saiu errado. Entre em contato com nossa equipe.";

    return "Termos de uso aceitos.";
  } catch (err) {
    console.error(err);
  }
};

export default null;
