import React, { FC } from "react";
import styled from "styled-components";
import background from "./card3.png"
import Colors from "../../../../assets/styles/colors";

const SlideCardLiveContainer = styled.div`
width: 90%;
height: 100vh;
margin: auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-image: url(${background});
background-position: center;
background-repeat: no-repeat;
background-size: 100%;
flex-wrap: nowrap;
`;

const BodyContainer = styled.div`
width: 90%;
 border:solid 0px;
 box-shadow: 0px 0px 15px -5px  ${Colors.black};
 background-color: ${Colors.white};
 margin-bottom:20px;
 padding-left:10px;
 padding-right:10px;
`
const Text = styled.div`
margin: 5px;
text-align: start;
font-family: "Roboto", sans-serif;
font-size: 14px;
letter-spacing: 0px;
color: ${Colors.blue10};
`;

const TextSpanGreen = styled.span`
text-align: center;
color: ${Colors.green9};
letter-spacing: 0px;
font: 700 18px  sans-serif;

@media (max-width: 1200px) {
  font-size: 1rem;
}
`;

const HighlightText = styled.h3`
color: ${Colors.green9};
font-weight: 800;
margin-bottom:20px;
margin-top:20px;
`

const CardThree: FC = () => {
    return (
        <SlideCardLiveContainer >
            <HighlightText>{`DEFINIÇÕES DAS FUNÇÕES:`}</HighlightText>
            <BodyContainer>
                <Text>
                    <TextSpanGreen>{`Match Relevância - `}</TextSpanGreen>{`É uma aplicação para facilitar a busca e a gestão das oportunidades em Licitações Públicas. A busca é realizada através de palavras-chave e o sistema faz uma leitura em toda descrição, itens e anexos da licitação.`}
                </Text>
            </BodyContainer>
            <BodyContainer>
                <Text>
                    <TextSpanGreen>{`Meu Desempenho - `}</TextSpanGreen>{`É o campo de análise de concorrência através das oportunidades encerradas pelo comprador e disponibilizadas para visualização. No painel ficam disponíveis as oportunidades vencedoras, perdidas, desqualificadas e declinadas.`}
                </Text>
            </BodyContainer>
            <BodyContainer>
                <Text>
                    <TextSpanGreen>{`Match Analítico - `}</TextSpanGreen>{`Ferramenta para acesso de forma ágil às oportunidades de Dispensa no qual a empresa foi convidada. Aqui é possível verificar, na avaliação da oportunidade, uma leitura do fit que a oportunidade tem com o histórico de oportunidades da sua empresa. São listadas em quantidades de estrelas preenchidas que indicam qual pode ser mais relevante para o seu processo.`}
                </Text>
            </BodyContainer>
            <BodyContainer>
                <Text>
                    <TextSpanGreen>{`Taxa de Acesso - `}</TextSpanGreen>{`Você consegue consultar a situação de seu(s) CNPJ(s). Se ele estiver como “Ativo”, significa que o processo de pagamento foi realizado corretamente e você poderá concorrer as licitações públicas.`}
                </Text>
            </BodyContainer>
            <BodyContainer>
                <Text>
                    <TextSpanGreen>{`Sugestão de Famílias - `}</TextSpanGreen>{`É um painel de divulgação de sugestões de grupos de famílias Petrobras (Lista de Fornecimento) relacionado as palavras salvas no filtro de busca no Match Relevância. `}
                </Text>
            </BodyContainer>
        </SlideCardLiveContainer>
    )
}

export default CardThree;