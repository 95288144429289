import styled from "styled-components";
import Colors from "../../../../assets/styles/colors";

interface StylesProps {
    flexDirection?: any;
    gapColumn?: any;
    width?: any;
}

const SectionContainer = styled.section`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 20px 36px 20px;
    height: 90%;
    overflow-y: auto;
`;

const GroupCards = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 16px;
`;

const Card = styled.div`
    display: flex;
    flex-direction: ${(props: StylesProps) => props.flexDirection || 'row'};
    flex-wrap: nowrap;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    overflow: auto;
`;

const CardContent = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    gap: 12px;
    padding: 20px;

    @media (max-width: 640px) {
        flex-wrap: wrap;
        gap: 4px;
        overflow: auto;
    }
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props: StylesProps) => props.gapColumn || 0};
`;

const Text = styled.p`
    padding: 0;
    margin: 0;
`;

const ErrorText = styled(Text)`
    color: ${Colors.red}
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 12px;

    @media (max-width: 640px) {
        flex-wrap: wrap;
        gap: 4px;
    }
`;

const Button = styled.button`
    height: max-content;
    background: none;
    border: none;
    font-size: 1.1rem;
    // font-weight: 500;
    color: ${Colors.blue2}
`;

const Input = styled.input`
    border: none;
    border-bottom: 1px solid black;
    padding: 0 6px;
    border-color: ${Colors.gray3};
    background-color: ${Colors.yellow1};
    border-radius: 11px;
    opacity: .8;

    &:focus {
        opacity: 1;
    }
`;

const JustifyInput = styled.textarea`
    width: ${(props: StylesProps) => props.width || 'auto'};
    max-height: 250px;
    border: 1px solid black;
    padding: 6px;
    border-color: #B2B0B0;
    border-radius: 0.375rem;
    opacity: .8;
`;

const FlexRow = styled.div`
    display: flex;
    gap: 6px;
`;

const GridRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 640px) {
        display: flex;
        flex-direction: column;
    }
`;


const ButtonModal = styled.button`
    color: white;
    background-color: #dc3545;
    border: none;
    border-radius: 5px;
    padding: 6px 16px;
    font-weight: 600;
    cursor: pointer;
`;

export {
    SectionContainer,
    GroupCards,
    Card,
    CardContent,
    Text,
    ErrorText,
    Button,
    ButtonGroup,
    Input,
    JustifyInput,
    FlexRow,
    FlexColumn,
    ButtonModal,
    GridRow
}