import React, { FC } from "react";
import styled from "styled-components";
import Colors from "../../../../assets/styles/colors";

interface StylesProps {
  background?: any;
  filterSelected?: any;
}

const BoxOption = styled.div`
  width: 100%;
  background: ${(props: StylesProps) => props.background};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 28px;
  padding: 0 0.5rem;
  margin: 0.15rem 0;
  border-radius: 8px;

  > p {
    margin: 0;
    color: ${Colors.white};
    white-space: nowrap;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Checkbox = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 3px;
  padding: 0.1rem;
  background: ${(props: StylesProps) =>
    props.filterSelected ? Colors.blue6 : Colors.white};
  border: solid 2px ${Colors.white};
  cursor: pointer;
`;

interface MinFilterProps {
  background: any;
  name: any;
  addFilter: () => void;
  isSelected: any;
}

const MinFilter: FC<MinFilterProps> = ({
  background,
  name,
  addFilter,
  isSelected
}) => {
  return (
    <BoxOption background={background}>
      <p>{name}</p>
      <Checkbox
        id="check"
        onClick={addFilter}
        filterSelected={isSelected}
      />
    </BoxOption>
  );
};

export default MinFilter;
