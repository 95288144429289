import React, { FC } from "react";
import "./ProposalDescription.css";

interface ProposalAttachmentsProps {
  attachments: any;
}

const ProposalAttachments: FC<ProposalAttachmentsProps> = ({ attachments }) => {
  return (
    <div className="itemContainer">
      <div>
        <div className="descriptionTextTitle"> Descrição</div>
        <div className="descriptiontext">{attachments?.description}</div>

        <div className="descriptionTextTitle">Conteúdo</div>
        <div className="descriptiontext">{attachments?.content}</div>
      </div>
    </div>
  );
};

export default ProposalAttachments;
