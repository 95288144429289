import { useEffect, useRef, useState } from "react";
import { StringArray } from "./useTagListUtils";

export const useTagList = (
  { defaultList, onToggle, onRemove }:
    { defaultList: StringArray[], onToggle: (arg1: StringArray, arg2: any) => void, onRemove: (arg1: StringArray, arg2: any) => void }
) => {
  const [tagsListArray, setTagsListArray] = useState(defaultList);

  const activeTags: any = useRef([]);
  const previousDefaultValue: any = useRef([]);

  useEffect(() => {
    const findItemOnPreviousArray = (tagItem: StringArray) => {
      return previousDefaultValue.current.find(
        (item: StringArray) =>
          item.label === tagItem.label && item.fullObj === tagItem.fullObj
      );
    };
    const newArray: any = defaultList.map((item: StringArray) => {
      return findItemOnPreviousArray(item) || item;
    });
    previousDefaultValue.current = newArray;
    activeTags.current = newArray.filter((item: StringArray) => item.selected);
    setTagList(newArray);
  }, [defaultList]);

  const onToggleTag = (index: any) => {
    const newTagsListArray: StringArray[] = [...tagsListArray];
    newTagsListArray[index].selected = !newTagsListArray[index].selected;
    setTagsListArray(newTagsListArray);
    activeTags.current = newTagsListArray.filter((item) => item.selected);

    onToggle(tagsListArray[index], activeTags.current);
  };

  const onRemoveTag = (index: any) => {
    const newTagsListArray = [...tagsListArray];
    newTagsListArray.splice(index, 1);
    setTagsListArray(newTagsListArray);
    previousDefaultValue.current = newTagsListArray;

    onRemove(tagsListArray[index], newTagsListArray);
  };

  const setTagList = (list: StringArray[]) => {
    setTagsListArray(list);
    previousDefaultValue.current = list;
  };

  return {
    tagsListArray,
    activeTags: activeTags.current,
    onToggleTag,
    onRemoveTag,
    setTagList,
  };
};
