import styled from "styled-components";
import Colors from "../../../../assets/styles/colors";

interface StylesProps {
  padding?: any;
  opacity?: any;
  cursor?: any;
}

const Title = styled.h5`
    line-height: 1.4;
    letter-spacing: 0px;
    color: ${Colors.gray7};
    font-weight: bold;
    margin-bottom: 0.3rem;
`;

const Description = styled.p`
    line-height: 1.4;
    letter-spacing: 0px;
    font-size: 1rem;
    color: ${Colors.gray7};
`

const Text = styled.p`
  margin: 0px;
  
  @media (max-width: 350px) {
    font-size: 0.8888rem;
  }
`;

const List = styled.ul`
  height: 250px;
  overflow-y: scroll;
  list-style-type: none;
  padding: 16px;
  border-radius: 12px;
  color: ${Colors.blue6};
  font-weight: 500;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  -o-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);

  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.gray3};
    border-radius: 12px;
  }

  &::-webkit-scrollbar-track {
    background-color: none;
    border-radius: 12px;
  }

  @media (max-width: 640px) {
    min-height: 200px;
    margin-bottom: 96px;
  }
`;

const ListItem = styled.li`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 6px;
    border-radius: 10px;

    &:hover,
    &:focus {
        background-color: ${Colors.gray1};
    }

    @media (max-width: 350px) {
      gap: 6px;
      justify-content: center;
    }
`;

const ResetFilterButton = styled.button`
  color: ${Colors.red1};
  background: ${Colors.red1};
  border: 1px solid ${Colors.red};
  border-radius: 25px;
  margin: 0;
  padding: ${(props: StylesProps) => props.padding || '2px'};
  opacity: ${(props: StylesProps) => props.opacity || '.8'};

  &:hover,
  &:focus {
    opacity: 1;
    cursor: ${(props: StylesProps) => props.cursor || 'pointer'};
  }
`;

const ResetFilterIcon = styled.img`
  width: 15px;
  margin: 0.4rem;
`;

export {
    Title,
    Description,
    Text,
    List,
    ListItem,
    ResetFilterButton,
    ResetFilterIcon
}