import styled from "styled-components";
import Colors from "../../../assets/styles/colors"
import { StylesProps } from "../../../util/types";

const TextArea = styled.textarea`
  width: 100%;
  height: 6rem;
  outline: none;
  margin: ${(props:StylesProps) => props.margin};
  font: 400 1rem "Open Sans";
  border: 1px solid ${Colors.green1};
  padding: 0.5rem 1.25rem 1.25rem 0.9rem;
  color: ${Colors.gray8};
  background: ${Colors.white} 0% 0% no-repeat padding-box;
  border-radius: 4px;
  resize: none;

  ::placeholder {
    font: 400 1rem "Open Sans", sans-serif;
    letter-spacing: 0;
    color: ${Colors.gray5};
    align-self: flex-start;
  }
`;

const Label = styled.label`
  font: 500 0.75rem "Eurostile", "Open Sans", sans - serif;
  margin: 0 0 0.25rem 1rem;
  letter-spacing: 0;
  color: ${Colors.green1};

  p {
    color: ${Colors.green1};
    margin-left: 0.5rem;
    font-family: "Open Sans", sans - serif;
    font-size: 13px;
  }
`;

export {TextArea,Label}