import React, { FC, useState } from "react";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import Index from "../Button";
import Textarea from "../Textarea/Textarea";
import CloseModalIcon from "../../../assets/icons/common/close-icon.svg";
// import { setSupport } from '../../dataflow/modules/filter-module';
import { saveModal } from "../../../dataflow/modules/modal-module";
import { handleMessageSupportThunk } from "../../../dataflow/thunks/filters-thunk";
import Colors from "../../../assets/styles/colors";
import {
  Overlay,
  Modal,
  ImageClose,
  Box,
  TitleSupport,
  Text,
  Form,
  BoxButton,
  MessageError,
} from "./styles"

const mapStateToProps = (state: any) => ({
  support: state.filter.support
});

const mapDispatchToProps = (dispatch: any) => ({
  handleMessageSupportThunk: (info: any) => dispatch(handleMessageSupportThunk(info)),
  saveModal: (info: any) => dispatch(saveModal(info))
});

interface SaveModal {
  isOpen: boolean;
  type: string;
}

interface Help {
  name: string;
  email: string;
  message: string;
}

interface SupportProps {
  saveModal: (saveModal: SaveModal) => void;
  handleMessageSupportThunk: (help: Help) => void;
}


const Support: FC<SupportProps> = ({ saveModal, handleMessageSupportThunk }) => {
  const [error, setError] = useState(false);
  const help: Help = {
    name: "",
    email: "",
    message: ""
  }

  const closeModal = () => {
    saveModal({
      isOpen: false,
      type: "support"
    });
  };

  const handleChange = (field: any, ev: any) => {
    help[field as keyof Help] = ev.target.value;
    setError(false);
  };

  const openModalSucess = () => {
    saveModal({
      isOpen: true,
      type: "sucess"
    });
  };

  const handleSubmit = (ev: any) => {
    ev.preventDefault();

    help.name = getUser();
    help.email = getEmail();

    if (help?.message === "") {
      setError(true);
    } else {
      handleMessageSupportThunk(help);
      closeModal();
      openModalSucess();
    }
  };

  const { idToken } = JSON.parse(window.localStorage.getItem("petronect_credentials") || "")
  const decodedToken: any = jwt_decode(idToken)

  const getUser = () => {
    if (idToken) {
      return decodedToken.name;
    }
    return null;
  };

  const getEmail = () => {
    if (idToken) {
      return decodedToken.email;
    }
    return "";
  };

  return (
    <Overlay onClick={closeModal}>
      <Modal onClick={(ev: any) => ev.stopPropagation()}>
        <ImageClose
          src={CloseModalIcon}
          alt="close-icon"
          onClick={closeModal}
        />
        <Box>
          <TitleSupport>Suporte Petronect</TitleSupport>
          <Text>
            Mande uma mensagem! Nossa equipe entrará em contato com você assim
            que possível.
          </Text>
        </Box>
        <Form onSubmit={handleSubmit}>
          <Textarea
            placeholder="Deixe sua mensagem aqui"
            onChange={(ev: any) => handleChange("message", ev)}
            labelText="Mensagem"
          />
          {error && (
            <MessageError>Preencha o campo acima para enviar</MessageError>
          )}
          <BoxButton>
            <Index
              background={Colors.blue6}
              fontWeight="bold"
              width="100%"
              height="3.5rem"
            >
              Enviar mensagem
            </Index>
          </BoxButton>
        </Form>
      </Modal>
    </Overlay>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Support);
