import React, { FC, ReactNode } from "react";
import { List } from "./styles";

interface ListItemProps {
  handleClick?: () => void;
  handleHover?: () => void;
  handlenoHover?: () => void;
  children: ReactNode;
}

const ListItem: FC<ListItemProps> = ({ handleClick, handleHover, handlenoHover, children }) => {
  return (
    <List
      onClick={handleClick}
      onMouseEnter={handleHover}
      onMouseLeave={handlenoHover}
    >
      {children}
    </List>
  );
};

export default ListItem;
