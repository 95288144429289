export const formatCNPJ = (cnpj: string | null) => {
    if (cnpj === null) {
        return ""; 
    }
    const digitsOnly = cnpj.replace(/\D/g, '');
    
    const formattedCNPJ = digitsOnly.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5'
    );
  
    return formattedCNPJ;
};