import { replaceAll } from "../../../util/devUtilities";

const handleMoneyWhenReceiving = (value: string) => {
    let newValue;

    if (value.includes('.')) {
        newValue = replaceAll(parseFloat(value.split('.')[0]).toLocaleString('en-US'), ',', '.') + "," + value.split('.')[1];
    } else {
        newValue = replaceAll(parseFloat(value).toLocaleString('en-US'), ',', '.') + ',00';
    }

    return newValue;
}

// handleDecimalWhenReceiving
// handleDecimalWhenSend

export {
    handleMoneyWhenReceiving
}