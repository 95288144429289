/* eslint-disable array-callback-return */
import React, { FC, useEffect, useState } from "react";
import * as d3 from "d3";
import styled from "styled-components";
import plus from "../../../assets/icons/common/plus.svg";
import less from "../../../assets/icons/common/less.svg";
import InfoModal from "./InfoModal";
import { formatDateWithoutTimezone } from "../../../util/format-value";
import Colors from "../../../assets/styles/colors";
import { eventCategory, logEvent } from "../../../util/analytics/index";
import Tooltip from "../../../components/common-components/Tooltip";
import HelpImg from "../../../assets/icons/common/help.svg";
import { Link } from "react-router-dom";

interface StylesProps {
  width?: any;
  top?: any;
  x1?: any;
  image?: any;
}

const Container = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  max-width: ${(props: StylesProps) => props.width}px;
  overflow-y: hidden;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const WrapHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${(props: StylesProps) => props.width}px;
  padding: 0 0.5rem;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

const Title = styled.h2`
  margin: 1rem 0 1rem 1rem;
  letter-spacing: 0;
  color: ${Colors.blue6};
  font: 700 1rem "Eurostile", "Open sans", sans-serif;

  @media (max-width: 1200px) {
    margin: 0;
  }

  @media screen and (max-width: 480px) {
    margin: 0.8rem 0;
  }
`;

const WrapDownoad = styled.span`
  display: flex;
  cursor: pointer;

  img {
    margin-left: 0.45rem;
  }
  a {
    display: none;
  }
`;

const WrapTitle = styled.div`
  display: flex;
  align-items: center;

`;
const TooltipContainer = styled.div`
 position: absolute;
 top: 0px;
 left: 330px;
 z-index: 99;

 @media screen and (max-width: 480px) {
  top: 25px;
  left: -5px;
  }
`;

const TextDownload = styled.p`
  margin: 0;
  font: 300 0.75rem "Open Sans";
  color: ${Colors.gray6};
`;

const Label = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: ${(props: StylesProps) => props.top}px;
  left: ${(props: StylesProps) => props.x1}px;
  width: ${(props: StylesProps) => props.width}px;
  height: 25px;
  background-color: ${Colors.white2};
  transform: translateyScale(-25px);

  text-align: center;
  line-height: 25px;
  font-size: 10px;
  color: ${Colors.gray8};

  &:not(:last-of-type) {
    border-right: 1px solid ${Colors.gray1};
  }

  &:first-of-type {
    border-bottom-left-radius: 5px;
  }
  &:last-of-type {
    border-bottom-right-radius: 5px;
  }
`;

const HelpButton = styled(Link)`
  width: 20px;
  height: 20px;
  background: none;
  background-image: url(${(props: StylesProps) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  cursor: pointer;
  margin-left: 10px;
`;

interface CoefficientChartProps {
  data: any;
  width: any;
  height: any;
}

const CoefficientChart: FC<CoefficientChartProps> = ({
  data,
  width,
  height
}) => {
  let svg: any = null;
  const margin = { top: 20, right: 30, bottom: 30, left: 45 };
  const [isOpen, setIsOpen] = useState(true)
  const [selectedNode, setSelectedNode] = useState<any>()
  const [labels, setLabels] = useState<any>();
  const [isTooltip, setIsTooltip] = useState(false);

  let responsiveWidth: any = undefined;
  let xScale: any = undefined;
  let yScale: any = undefined;

  const itensWithoutDecline = data.filter(
    (item: any) => item.statusProposal.toLowerCase().trim() !== "declínio"
  );

  const _data: any = itensWithoutDecline.map((item: any, index: any) => {
    const value = parseInt(item.priceDiff, 10);
    let y = value;
    if (y > 1000) {
      y = 1000;
    }
    if (y < -1000) {
      y = -1000;
    }

    return {
      ...item,
      name: item.id,
      value,
      y,
      x: index,
      date: new Date(item.openTime),
    };
  });

  useEffect(() => {
    updateResponsiveWidth();
    setupScales();
    setupGraph();
  }, [])


  const updateResponsiveWidth = () => {
    // 433, 378, 328, 273
    switch (true) {
      // 480px - 433 with padding
      case width >= 379 && width <= 433:
        responsiveWidth = 1740;
        break;
      // 425px - 378 with padding
      case width >= 329 && width <= 378:
        responsiveWidth = 1520;
        break;
      // 375px - 328 with padding
      case width >= 274 && width <= 328:
        responsiveWidth = 1320;
        break;
      // 320px - 273 with padding
      case width <= 273:
        responsiveWidth = 1100;
        break;
      default:
        responsiveWidth = width;
    }
  }

  const handleClick = (e: any) => {
    e.preventDefault();
    setIsOpen(!isOpen);
    logEvent(
      eventCategory.buttonClick,
      "click exibição gráfico competitividade de preços",
      `${isOpen ? "Mostrar menos" : "Mostrar mais"}`
    );
  };

  const formatValue = (num: any) => {
    if (num >= 1000000) {
      return `${Math.sign(num) * Number((Math.abs(num) / 1000000).toFixed(1))}m`;
    }
    if (num >= 1000 || num >= 1) {
      return `${Math.sign(num) * Number((Math.abs(num) / 1000).toFixed(1))}k`;
    }
    return num;
  };

  const renderTooltip = () => {
    if (selectedNode) {
      let totalWon = 0;
      let totalLost = 0;
      if (
        selectedNode?.judgementType?.toLowerCase() === "julgamento por item" ||
        selectedNode?.judgementType?.toLowerCase() === "julgamento por lote"
      ) {
        totalWon = selectedNode?.Items?.filter((item: any) => item?.winner).reduce(
          (totalItem: any, item: any) => {
            return totalItem + item.total;
          },
          0
        );

        totalLost = selectedNode?.Items?.filter((item: any) => !item?.winner).reduce(
          (totalItem: any, item: any) => {
            return totalItem + item?.total;
          },
          0
        );
      }
      return (
        <InfoModal
          //  top={selectedNode.y}
          //  left={selectedNode.x}
          //  text={`${selectedNode.value}%`}
          description={{
            ...selectedNode,
            top: selectedNode?.y,
            left: selectedNode?.x,
            text: `${selectedNode?.value}% - ${selectedNode?.description}`,
            total: formatValue(selectedNode?.total),
            competitor: formatValue(selectedNode?.competitorPrice),
            myPrice: formatValue(selectedNode?.myPrice),
            won: selectedNode?.winner,
            totalWon,
            totalLost,
          }}
        />
      );
    }
  };

  const renderCaption = () => {
    if (labels && height) {
      return labels.map((label: any, i: any) => (
        <Label
          x1={label.x1}
          width={label.size}
          key={i}
          top={height - margin.top}
        >
          {label.label}
        </Label>
      ));
    }
  };

  const renderBackground = (g: any, zero: any, topY: any, bottomY: any) => {
    g.append("rect")
      .attr("fill", Colors.pink2)
      .attr("x", margin.left)
      .attr("y", margin.top)
      .attr("width", responsiveWidth - margin.left)
      .attr("height", zero - topY);

    g.append("rect")
      .attr("fill", Colors.green7)
      .attr("x", margin.left)
      .attr("y", zero)
      .attr("width", responsiveWidth - margin.left)
      .attr("height", bottomY - zero);
  };

  const setupScales = () => {
    const _xScale: any = d3
      .scaleLinear()
      .domain([0, _data.length])
      //.nice()
      .range([margin.left, responsiveWidth - margin.right]);

    const _yScale: any = d3
      .scaleLinear()
      .domain(d3.extent(_data, (d: any) => d.y) as any)
      .nice()
      .range([height - margin.bottom, margin.top]);

    xScale = _xScale;
    yScale = _yScale;
  };

  const length = (path: any) => {
    const _d3: any = d3
    return _d3.create("svg:path").attr("d", path)?.node().getTotalLength();
  };

  const drawLine = () => {
    const _svg: any = d3.select(svg);
    const line: any = d3
      .line()
      .curve(d3.curveCatmullRom.alpha(0.5))
      .x((d: any) => xScale(d.x))
      .y((d: any) => yScale(d.y));
    const l = length(line(_data));

    _svg
      .append("path")
      .datum(_data)
      .attr("fill", "none")
      .attr("stroke", Colors.green5)
      .attr("stroke-width", 2.5)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round")
      .attr("stroke-dasharray", `0,${l}`)
      .attr("d", line)
      .attr("stroke-dasharray", `${l},${l}`);
  };

  const setupGraph = () => {
    const _width = responsiveWidth;

    const halo = (text: any) => {
      text?.select(() => {
        // return parentNode.insertBefore(cloneNode(true))
      })
        ?.attr("fill", "none")
        ?.attr("stroke", "white")
        ?.attr("stroke-width", 4)
        ?.attr("stroke-linejoin", "round");
    }

    const xAxis = (g: any) =>
      g.attr("transform", `translate(0,${height - margin.bottom})`).call(() => {
        const _labels = [];
        const labelArray: any = [];

        let lastMonth = -1;

        _data.map((item: any, i: any) => {
          const date = new Date(item.openTime);
          const month = date.getMonth() + 1;

          if (month === 1 && lastMonth === 12) {
            lastMonth = -1;
          }

          if (month > lastMonth) {
            labelArray.push({
              label: formatDateWithoutTimezone(date, "MM/yyyy"),
              xPos: xScale(item.x),
            });
          }

          if (i === _data.length - 1) {
            labelArray.push({
              label: "end",
              xPos: xScale(item.x),
            });
          }
          lastMonth = month;
        });

        for (let i = 0; i < labelArray.length; i++) {
          if (i < labelArray.length - 1) {
            _labels.push({
              label: labelArray[i].label,
              x1: labelArray[i].xPos,
              x2: labelArray[i + 1].xPos,
              size: labelArray[i + 1].xPos - labelArray[i].xPos,
            });
          }
        }

        setLabels(_labels);
      });

    const yAxis = (g: any) =>
      g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(yScale).tickFormat((d) => d.toString() + "%"))
        .call((g: any) => g.select(".domain").remove())
        .call((g: any) => {
          g.selectAll(".tick line")
            .clone()
            .attr("x2", _width)
            .attr("stroke-opacity", 0.4)
            .attr("stroke", Colors.gray2);
        })
        .call((g: any) => {
          g.select(".tick:last-of-type text")
            .clone()
            .attr("x", 4)
            .attr("text-anchor", "start")
            .attr("font-weight", "bold")
            .attr("fill", "black")
            .text(_data.y)
            .call(halo);
        });

    const _svg: any = d3.select(svg);

    _svg.append("g").call((g: any) => {
      const zero = yScale(0);
      const bottomY = yScale(yScale.domain()[0]);
      const topY = yScale(yScale.domain()[1]);
      renderBackground(g, zero, topY, bottomY);
    });

    _svg.append("g").call((d: any) => {
      xAxis(d);
    });

    _svg.append("g").call(yAxis);

    drawLine();

    _svg
      .append("g")
      .attr("fill", Colors.white)
      .attr("stroke", Colors.green5)
      .attr("stroke-width", 2)
      .selectAll("circle")
      .data(_data)
      .join("circle")
      .attr("fill", Colors.white)
      .attr("cx", (d: any) => xScale(d.x))
      .attr("cy", (d: any) => yScale(d.y))
      .attr("r", 3)
      .attr("cursor", "pointer")
      .on("mouseenter", (d: any) => {
        const _d3: any = d3;
        const item: any = {
          ...d,
          x: _d3?.event?.x,
          y: _d3?.event?.y,
        };
        setSelectedNode(item);
      })
      .on("mouseleave", () => {
        setSelectedNode(undefined);
      });
  };

  return (
    <>
      <Container
        width={width}
      // height={height}
      >
        <WrapHeader width={width}>
          <WrapTitle>
            <Title>Coeficiente Competitividade de Preços</Title>
            <HelpButton
              onMouseOver={() => setIsTooltip(true)}
              onMouseLeave={() => { setIsTooltip(false) }}
              onClick={() => {
                logEvent(
                  eventCategory.iconClick,
                  "click HelpImg",
                  "Icone de ajuda gráfico Coeficiente Competitividade"
                );
                setIsTooltip(value => !value)
              }}
              image={HelpImg}
              to={'/meu-desempenho'}
            />
            {isTooltip && (
              <TooltipContainer>
                <Tooltip
                  onMouseLeave={() => { setIsTooltip(false) }}
                  width={'100%'}
                  paragraphs={[
                    "Função: (seu preço / preço do concorrente - 1)",
                  ]}
                />
              </TooltipContainer>
            )}
          </WrapTitle>





          <WrapDownoad onClick={handleClick}>
            <TextDownload>
              Mostrar {isOpen ? "menos" : "mais"}
            </TextDownload>
            <img src={isOpen ? less : plus} alt="Download Icon" />
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          </WrapDownoad>
        </WrapHeader>
        <div
          id="canvas"
          style={{
            position: "relative",
            margin: "1rem auto 0",
            padding: 0,
            width: `${responsiveWidth}px`,
            minHeight: `${height + 25}px`,
            maxHeight: `${height + 25}px`,
            border: `2px solid ${Colors.gray1}`,
            borderRadius: "15px",
            overflow: "hidden",
            display: isOpen ? "block" : "none",
          }}
        >
          <svg
            width={responsiveWidth - margin.right}
            height={height}
            ref={(node: any) => (svg = node)}
          ></svg>
          {renderCaption()}
        </div>
      </Container>
      {renderTooltip()}
    </>
  );
}

export default CoefficientChart;
