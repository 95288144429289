import styled from "styled-components";
import Colors from "../../assets/styles/colors";

interface StylesProps {
  width?: any;
}

const ContainerPage = styled.div`
  width: 100%;
  height: 100%;
  overflow:auto; 
  @media only screen and (max-width: 600px) {
    padding-bottom: 60px;
  }
`;

const Container = styled.div`
  padding: 78px 50px 50px 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  @media (max-width: 500px) {
    padding: 68px 10px 10px 10px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  color: ${Colors.blue6};
  margin-bottom: 20px;
`;


const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 20px 20px;

  @media (max-width: 768px) {
    grid-template-columns: auto;
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: auto;
  }
`;

const Image = styled.img`
  width: ${(props: StylesProps) => props.width || '90%'};
`;

export {
  ContainerPage,
  Container,
  Title,
  ContentContainer,
  Image
}