import React, { FC } from "react";
import { Box, InputSearch, Icon } from "./styles";
import SearchIcon from "../../../../assets/icons/common/search-icon.svg";

export interface FilterSearchProps{
  width?:string;
  height?:string;
  placeholder?:string;
  icon?:any;
  onChange?:(e:any)=>void;
  handleKey?:(e:any)=>void;
  borderBotttomLeftRadius?:any;
  borderBottomRightRadius?:any;
  borderTopRightRadius?:string;
  borderTopLeftRadius?:string;
  value?:string;
  onClick?:(e:any)=>void;
  zIndex?:string;
  margin?:string;
  positionMobile?:string;
  isBoxFilter?:any;
  displayMobile?:any;
  search?:boolean;
  handleAddTag?:(e:any)=>void;
  type?:any;
}


const FilterSearch:FC<FilterSearchProps> = ({
  width,
  height,
  placeholder,
  icon,
  onChange,
  handleKey,
  borderBotttomLeftRadius,
  borderBottomRightRadius,
  borderTopRightRadius,
  borderTopLeftRadius,
  value,
  onClick,
  margin,
  isBoxFilter,
  displayMobile,
  search,
  handleAddTag,
  type

}) => {
  return (
    <Box width={width || "400px"}>
      <InputSearch
        type={"text"}
        width={width}
        height={height}
        placeholder={placeholder}  
        onChange={onChange}
        onKeyUp={handleKey}
        borderBotttomLeftRadius={borderBotttomLeftRadius}
        borderBottomRightRadius={borderBottomRightRadius}
        borderTopRightRadius={borderTopRightRadius}
        borderTopLeftRadius={borderTopLeftRadius}
        value={value}
        onClick={onClick}
        margin={margin}
        isBoxFilter={isBoxFilter}
        displayMobile={displayMobile}
      />

      <Icon smallIcon={search} onClick={handleAddTag}>
        <img src={icon || SearchIcon} alt={"icon"} />
      </Icon>
    </Box>
  );
};

export default FilterSearch;
