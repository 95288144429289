import styled from "styled-components";
import enableHeartIcon from "../../../../assets/icons/common/heartEnableIcon.svg";
import disableHeartIcon from "../../../../assets/icons/common/heartDisableIcon.svg";
import Colors from "../../../../assets/styles/colors";
import { StylesProps } from "../../../../util/types";

export const FavoriteButtonStyled = styled.button`
  background-image: url(${(props:StylesProps) => props.isFavorite ? enableHeartIcon : disableHeartIcon});
  width: 22px;
  height: 18.7px;
  background-position: center;
  padding: 15px;
  margin: 0px;
  background-color: ${Colors.white};
  border-style: none;
  color: ${Colors.white};
  background-repeat: no-repeat;
  cursor: pointer;

  :focus {
    outline: none;
  }
`;
