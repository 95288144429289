import React, { FC } from "react";
import Logo from "../../../../components/layout-components/Logo/Logo";
import styled from "styled-components";
import background from "./card4.png"
import Colors from "../../../../assets/styles/colors";

const SlideCardLiveContainer = styled.div`
    width: 90%;
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    background-image: url(${background});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    flex-wrap: nowrap;
`;

const LogoContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
width:30%;
min-width: 30%;
padding-bottom: 0px;
`

const HighlightText = styled.h4`
    color: ${Colors.blue10};
    text-align: start;
    margin-left: 45px;
// width:50%;
`

const Hr = styled.div`
  border-top: ${Colors.blue10} solid 2px;
  width: 50%;
  margin-bottom:30px;
  margin-top:20px;
`;

const CardFour: FC = () => {
    return (
        <SlideCardLiveContainer >
            {/* <LogoContainer>
                <Logo width="30%" widthMobile="20%" />
            </LogoContainer>
            <Hr />

            <HighlightText>
                {`Mais agilidade e eficiência para o seu negócio`}
            </HighlightText> */}
            <HighlightText>
                {`Conheça mais da Minha Petronect`}
            </HighlightText>
            <iframe 
                width="853" 
                height="480" 
                src="https://www.youtube.com/embed/68lLYrIjZKM"
                title="T1|E1 – Minha Petronect com você| O que é a Minha Petronect, FAQ e Avaliação das estrelas" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowFullScreen>
            </iframe>
            
        </SlideCardLiveContainer>
    )
}

export default CardFour;