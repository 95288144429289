import React, { FC } from "react";
import { BannerWarning, LoginRightSide } from "./styles";
import banner_pne_warning from "../../../assets/img/src/pages/Auth/banner_minha_pne_warning_v2.png";
import Help from "../../../components/common-components/Help/Help";
import CarouselLogin from "../components/CarouselLogin/CarouselLogin";

const RightSideContent: FC = () => {
  const warning = false; //Flag used to show a warning banner insted of the carrousel

  return (
    <LoginRightSide>
      {warning ? (
        <BannerWarning src={banner_pne_warning} alt="Warning" />
      ) : (
        <CarouselLogin />
      )}
      <Help layoutStyle={"dark"} />
    </LoginRightSide>
  );
};

export default RightSideContent;
