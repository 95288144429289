import React, { FC, useState } from "react";
import ActionButton from "./ActionButton/ActionButton";
import styles from "./Help.module.css";

interface HelpProps {
  layoutStyle: string;
}

const Help: FC<HelpProps> = ({ layoutStyle }) => {
  const [isShowing, setIsShowing] = useState(false);

  const mustShowTheContainer = () => {
    const status = isShowing;
    setIsShowing(!status);
  }

  const buttonHide = {
    opacity: "0",
    transition: "opacity 0.5s",
  };

  const buttonShow = {
    opacity: "1",
    transition: "opacity 0.5s",
  };

  return (
    <>
      <ActionButton
        layoutStyle={layoutStyle}
        title={"Suporte"}
        customStyle={!isShowing ? buttonShow : buttonHide}
        // titleCustomStyle={!isShowing ? buttonShow : buttonHide}
        handleClick={() => mustShowTheContainer()}
      />
      <div
        className={
          isShowing
            ? `${styles.helpContainer} ${styles.showContainer}`
            : `${styles.helpContainer} ${styles.hideContainer}`
        }
      >
        <button
          className={`${styles.headerContainer}`}
          onClick={() => mustShowTheContainer()}
        >
          <div className={`${styles.minimizeTrigger}`}></div>
          <p className={`${styles.headerContainerParagraph}`}>
            Precisa de ajuda?
          </p>
        </button>

        <div className={styles.contentContainer}>
          <p>
            Para obter ajuda de nosso suporte, entre em contato através dos
            seguintes números:
          </p>
          <ul className={styles.listPhones}>
            <li>
              <strong>4020-9876</strong>: Capitais, regiões metropolitanas e
              ligações originadas de telefone celular
            </li>
            <li>
              <strong>0800 282 8484</strong>: Demais regiões
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Help;
