import React, { FC } from "react";
import LogoWhite from "../../../assets/img/logos/logo-petronect-white.png";
import LogoBlue from "../../../assets/img/logos/LogoPNE.png";
import { ImageLogo } from "./styles";

interface LogoProps {
  type?: string;
  height?: string;
  width?: string;
  widthMobile?: string;
  margin?: string;
  marginMobile?: string;
  minWidthMobile?: string;
  paddingMobile?: string;
  minWidth?: string;
  marginTop?: string;
}

const Logo: FC<LogoProps> = ({
  type,
  height,
  width,
  widthMobile,
  margin,
  marginMobile,
  minWidthMobile,
  paddingMobile,
  minWidth,
  marginTop
}) => {
  return (
    <ImageLogo
      src={type === "white" ? LogoWhite : LogoBlue}
      height={height}
      width={width}
      widthMobile={widthMobile}
      margin={margin}
      marginMobile={marginMobile}
      minWidthMobile={minWidthMobile}
      paddingMobile={paddingMobile}
      minWidth={minWidth}
    />
  );
}

export default Logo;