import React, { FC } from "react";
import "./cellDescription.css";
import partNumberIcon from "../../../assets/icons/common/part_number_icon.png";
import { Link } from "react-router-dom";
import FavoriteButton from "./FavoriteButton";
import RatingProposal from "./RatingProposal";
import Colors from "../../../assets/styles/colors";
import { eventCategory, logEvent } from "../../../util/analytics/index";
import {
  ProposalCell
} from "./styles"



const setProposalStatusColorOnCss = (proposalStatus: string) => {
  switch (proposalStatus) {
    case "DONE":
      return Colors.green3;

    case "DECLINED":
      return Colors.orange4;

    case "NOT_DONE":
    default:
      return Colors.yellow1;
  }
};

interface CellDescriptionProps {
  proposal: any;
}

const cellDescription: FC<CellDescriptionProps> = ({ proposal }) => {

  return (
    <Link
      onClick={() => {
        logEvent(
          eventCategory.modalOpen,
          `click detalhes oportunidade match analítico`,
          `${proposal?.description}`
        );
      }}

      className="proposalCard"
      to={{
        pathname: "/match-analitico/" + proposal?.id,
        state: proposal,
      }}
    >
      <ProposalCell
        color={setProposalStatusColorOnCss(proposal?.proposalStatus)}
      ></ProposalCell>
      <div className="proposalInfoContainer">
        <div className="ratingAndFavoriteContainer">
          <div className="ratingContainer">
            <p>Avaliação da Oportunidade</p>
            <RatingProposal rating={proposal?.rating} />
          </div>

          <div className="favoriteContainer">
            <p>Favoritar</p>
            <FavoriteButton
              proposal={proposal}
            />
          </div>
        </div>
        <div>
          <p className="greyCellText">{proposal?.description}</p>
        </div>
        <div className="cellFooterInfoContainer">
          <div className="cellInfoParagraphs">
            <p className="greyCellTextInfo infoCellItens">
              {/* check alt property bellow */}
              <img className="cellIcons" src={partNumberIcon} alt="icon" />
              {proposal?.companyDocumentNumber
                ? proposal?.companyDocumentNumber
                : " CNPJ não fornecido"}
            </p>
            <p className="greyCellTextInfo infoCellItens">
              Data fim {proposal?.closingDate}
            </p>
            <p className="greyCellTextInfo infoCellItens">
              ID. {proposal?.opportunityNumber}
            </p>
          </div>

          <div className="cellInfoItens">
            <p className="greyCellItemText infoCellItens">
              {proposal?.items?.length}
            </p>
            <p className="greyCellText infoCellItens">Itens</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default cellDescription;
