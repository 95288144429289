import React, { FC } from "react";
import coming_soon from "../../assets/img/maintenance/coming_soon.png";
import {Overlay, TourContainer, TourTitlePrimary, ComingSoonImage, TourText } from "./styles";
import { useHistory } from "react-router-dom";

const ComingSoon:FC = () => {
  const history = useHistory();
  const feature = history.location.pathname.split("/")[2];
  let featureName = "";
  let featureDescription = "";

  if (feature === "match-analitico") {
    featureName = "Match Analítico";
    featureDescription = `Em breve disponibilizaremos um algoritmo de aprendizado para pré-filtrar e priorizar as oportunidades do tipo 
    dispensa para as quais você foi convidado, possibilitando ainda mais a eficiência no processo de análise ao indicarmos as 
    oportunidades mais apropriadas para você, com base nas suas últimas participações.`;
  }

  if (feature === "meu-desempenho") {
    featureName = "Meu Desempenho";
    featureDescription = `Em breve você poderá ter acesso ao seu histórico de participação em oportunidades, 
    com análise dos preços ofertados por você versus os menores preços ofertados por outros participantes, 
    simplificando a análise de precificação e concorrência.`;
  }

  return (
    <Overlay>
      <TourContainer>
        <ComingSoonImage src={coming_soon} alt="Maintenance Page" />
        <TourTitlePrimary>{featureName}</TourTitlePrimary>
        <TourText>{featureDescription}</TourText>
      </TourContainer>
    </Overlay>
  );
};

export default ComingSoon;
