import styled from "styled-components";
import { FilterSearchProps } from ".";
import Colors from "../../../../assets/styles/colors";
import { StylesProps } from "../../../../util/types";

const Box = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: ${(props: FilterSearchProps) => props.width};
  height: 33px;
  margin-right: 1rem;

  @media (max-width: 768px) {
    margin: 0.25rem;
    margin-bottom: 0;
  }
`;

const InputSearch = styled.input`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
  font: 400 .9rem Eurostile;
  border: 1px solid ${Colors.blue6};
  border-radius: 16px;
  padding: 0 1rem;
  padding-bottom: .25rem;
  outline: none;
  color: ${Colors.gray6};
  border-bottom-left-radius: ${(props: FilterSearchProps) =>
    props.borderBotttomLeftRadius};
  border-bottom-right-radius: ${(props: FilterSearchProps) =>
    props.borderBottomRightRadius};
  border-top-left-radius: ${(props: FilterSearchProps) => props.borderTopLeftRadius};
  border-top-right-radius: ${(props: FilterSearchProps) =>
    props.borderTopRightRadius};
  margin: ${(props) => props.margin};

  @media(max-width: 768px){
      width: ${(props:any) => props.widthMobile};
  }

  @media(max-width: 490px){
      position: ${(props: FilterSearchProps) => props.isBoxFilter && "absolute"};
      // left: ${(props: FilterSearchProps) => (props.isBoxFilter ? "41vw" : 0)};
      z-index: ${(props: FilterSearchProps) => props.isBoxFilter && "2"};
      display: ${(props: FilterSearchProps) => props.displayMobile};
  }

  @media(max-width: 375px){
      left: ${(props: FilterSearchProps) => (props.isBoxFilter ? "46vw" : 0)};
  }

  &::placeholder {
      color: ${Colors.blue6};
  }
`;

const Icon = styled.div`
  position: absolute;
  top: ${(props: StylesProps) => (props.smallIcon ? "auto" : ".35rem")};
  right: 0.85rem;
  background: ${Colors.white};
  cursor: pointer;
  background: none;
  border: none;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  @media (max-width: 590px) {
    right: ${(props: any) => (props.smallIcon ? ".5rem" : ".85rem")};
  }
`;

export { Box, InputSearch, Icon };
