const Colors = {
  white: "#FFFFFF",
  white2: "#FAFAFA",
  gray1: "#DBDBDB",
  gray2: "#D0D0D0",
  gray3: "#B2B0B0",
  gray4: "#A5A1A1",
  gray5: "#989898",
  gray6: "#8C8C8C",
  gray7: "#757575",
  gray8: "#505050",
  gray9: "#EBEBEB",
  black: "#000000",
  blue1: "#DBE9F1",
  blue2: "#01B0B7",
  blue3: "#879BA8",
  blue4: "#1592E6",
  blue5: "#245CC5",
  blue6: "#116DA0",
  blue7: "#23438E",
  blue8: "#115680",
  blue9: "#DCE9F1",
  blue10: "#003d91",
  orange1: "#DE8F33",
  orange2: "#FFA43E",
  orange3: "#9D6527",
  orange4: "#EF6436",
  orange5: "#ff8c05",
  yellow: "#D7E65D",
  yellow1: "#FDE590",
  green1: "#7FBA4C",
  green2: "#52BA44",
  green3: "#A9E04F",
  green4: "#009D00",
  green5: "#5FC47B",
  green6: "#CAF108",
  green7: "#E5F6EA",
  green8: "#7D9B00",
  green9: "#1bc11c",
  purple: "#8A5BD6",
  purple1: "#6009ed",
  pink: "#D65B85",
  pink2: "#F2DEDE",
  red: "#E6252B",
  red1: "#F76B6B",
  red2: "#D53B40",
};

export default Colors;

/**
 * Levantamento de cores utilizadas no projeto e ordenação das mesmas
 *  As cores a frente da primeira de cada linha devem ser excluidas e substituidas pela inicial
#FAFAFA,#F7F7F7,#FFFFFF,#EEEEEE,#EAEAEA,
#DBDBDB,#CECECE,#C0C0C0,#e6e6e6,#EBEBEB,
#D0D0D0,#C6C6C6,#C1C1C1,#ccc,
#B2B0B0,#acacac,
#A5A1A1,
#989898,#959595,#9A9A9A,#9F9F9F,#929292,#909090,
#8C8C8C,#8B969D,#817F7F,#808080
#757575,#6F6F6F,#707070,
#505050,#404040,#393939,
#000000,
----------------
#DBE9F1,
#01B0B7,
#879BA8,#acacac,
----------------
#DE8F33,
#FFA43E,
#9D6527,
#EF6436,
----------------
#D7E65D,
#FDE590,
----------------
#52BA44,
#A9E04F,
#7FBA4C,
#CAF108,
#E5F6EA,
#5FC47B,#6EC987,#7ACC90,#6fca88,
----------------
#8A5BD6,
----------------
#D65B85,
#F2DEDE,
----------------
#F76B6B,#FF5F5F,
#E6252B,#FF0000, 
#D53B40,#CB2B2B,
----------------
#1592E6,
#2A7CA8,
#116DA0,#116EA0,#116696,#246797,#256992,#2971A1,#026992,
#115680,#1C5781,
#245CC5,
#23438E
 * 
 */
