import React from 'react';
import { Title, Description, Text, List, ListItem, ResetFilterButton, ResetFilterIcon } from './styles';
import CloseIcon from "../../../../assets/icons/common/close-white.svg";
import CommonModal from '../../../../components/common-components/CommonModal';
import { formatCNPJ } from '../../../../util/formatCNPJ';
import useConfirmationModal from '../../hook/useConfirmationModal';

interface ApiIntegrationResponse {
    integrationId: string;
    tokenLastCharacters: string;
    document: {
        number: string;
    };
}
interface Props {
    cnpjs: ApiIntegrationResponse[];
    handleDeleteIntegration: (integrationId: string) => void; 
}

const ListCNPJs: React.FC<Props> = ({ cnpjs, handleDeleteIntegration }) => {
    const { showConfirmModal, setShowConfirmModal, integrationIdToDelete, cnpjToDelete, openConfirmModal, confirmUnlinking } = useConfirmationModal();

    return (
        cnpjs.length > 0 ? (
            <div style={{ maxWidth: '262.5px' }}>
                <Title>CNPJs Integrados</Title>
                <Description>
                    Para desvincular o acesso ao CNPJ, clique abaixo no botão &nbsp;
                    <ResetFilterButton cursor={'default'} padding={'0px'} opacity={'1'}>
                        <ResetFilterIcon src={CloseIcon} alt={"Ilustração de desvincular CNPJ"} />
                    </ResetFilterButton>
                    &nbsp;.
                </Description>
                <List>
                    {cnpjs.map((eachItem, index) => (
                        <ListItem key={index}>
                            <Text>{eachItem.document.number}</Text>
                            <ResetFilterButton onClick={() => openConfirmModal(eachItem.integrationId, eachItem.document.number)} title="Desvincular CNPJ">
                                <ResetFilterIcon src={CloseIcon} alt={"Desvincular CNPJ"} />
                            </ResetFilterButton>
                        </ListItem>
                    ))}
                </List>

                {showConfirmModal && (
                    <CommonModal
                        title="Desvincular CNPJ"
                        confirmText="Confirmar"
                        callbackConfirm={() => confirmUnlinking(handleDeleteIntegration)}
                        callbackCloseModal={() => setShowConfirmModal(false)}>
                        <Text>Tem certeza que deseja desvincular o CNPJ <b>{formatCNPJ(cnpjToDelete)}</b> ?</Text>
                    </CommonModal>
                )}
            </div>
        ) : null
    );
};

export default ListCNPJs;
