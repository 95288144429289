import React, { FC } from "react";
import closedImg from "../../../../assets/icons/common/close-blue.svg";
import Button from "../../../common-components/Button";
import Colors from "../../../../assets/styles/colors";
import {
  Overlay,
  Modal,
  CloseContainer,
  CloseButton,
  CloseImage,
  Title,
  Text,
  Box
} from "./styles";

interface DeleteFilterProps {
  closeModal: () => void;
  selectedFilter: any;
  handleConfirm: () => void;
}


const DeleteFilter: FC<DeleteFilterProps> = ({ closeModal, selectedFilter, handleConfirm }) => {
  return (
    <Overlay onClick={closeModal}>
      <Modal onClick={(ev) => ev.stopPropagation()}>
        <CloseContainer onClick={closeModal}>
          <CloseButton>
            <CloseImage src={closedImg} />
          </CloseButton>
        </CloseContainer>
        <Title>Excluir filtro</Title>
        <Text>Após ser excluido, um filtro não pode ser recuperado.</Text>
        <Text>
          Você deseja excluir o filtro{" "}
          <strong>{selectedFilter.name}</strong> permanentemente?
        </Text>
        <Box>
          <Button
            width={"50%"}
            widthMobile={"100%"}
            height={"56px"}
            color={Colors.blue6}
            textDecoration={"underline"}
            fontWeight={"400"}
            onClick={closeModal}
          >
            Cancelar
          </Button>
          <Button
            background={Colors.blue6}
            width={"50%"}
            widthMobile={"100%"}
            height={"56px"}
            fontWeight={"400"}
            onClick={handleConfirm}
          >
            Confirmar
          </Button>
        </Box>
      </Modal>
    </Overlay>
  );
};

export default DeleteFilter;
