import React, { FC } from "react";
import "./ProposalDescription.css";

interface ProposalItemProps {
  item: any;
}

const proposalItem: FC<ProposalItemProps> = ({ item }) => {
  function formatData() {
    let dateObj = new Date(item.deliveryDate);
    let res =
      dateObj.getDate() +
      1 +
      "/" +
      (dateObj.getMonth() + 1) +
      "/" +
      dateObj.getFullYear();
    return res;
  }

  return (
    <div className="itemContainer">
      <div>
        <div className="descriptionTextTitle"> Descrição</div>
        <div className="descriptiontext">{item.description}</div>

        <div className="descriptionTextTitle"> Quantidade</div>
        <div className="descriptiontext">{item.quantity}</div>

        <div className="descriptionTextTitle"> Data de entrega</div>
        <div className="descriptiontext">{formatData()}</div>
      </div>
    </div>
  );
};

export default proposalItem;
