import styled from "styled-components";
import Colors from "../../../assets/styles/colors";
import { StylesProps } from "../../../util/types";

export const InputForm = styled.input`
  width: 100%;
  height: ${(props:StylesProps) => (props.message ? "6rem" : "3.5rem")};
  outline: none;
  margin: ${(props:StylesProps) => props.margin};
  font: 400 1rem "Open Sans";
  border: ${(props:StylesProps) =>
    props.error ? `1px solid ${Colors.red2}` : `1px solid ${Colors.green1}`};
  padding: 1.25rem calc(1rem - 2px);
  color: ${Colors.gray8};
  background: ${Colors.white} 0% 0% no-repeat padding-box;
  border-radius: 4px;

  ::placeholder {
    font: 400 1rem "Open Sans", sans-serif;
    letter-spacing: 0;
    color: ${Colors.gray5};
    align-self: flex-start;

    @media (max-width: 425px) {
    font-size: 0.8rem;
  }
  }
`;

export const Label = styled.label`
  font: 500 0.75rem "Eurostile", "Open Sans", sans-serif;
  margin: 0.5rem 0 0.5rem 1rem;
  letter-spacing: 0;
  color: ${Colors.green1};
`;

export const BoxError = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 6px;
  
`;
export const TextError = styled.p`
  font: 500 0.9rem "Eurostile", "Open Sans", sans serif;
  color: ${Colors.red2};
  line-height: 18px;
  text-align: center;
  margin: 0 10px;
  
  @media (max-width: 960px) {
    margin: 0.1rem 0;
  }
  @media (max-width: 425px) {
    font-size: 0.8rem;
  }
`;
