import React, { FC } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Colors from "../../../assets/styles/colors"

const mapStateToProps = (state: any) => ({
  dataSetRange: state.opportunities.dataSetRange
});

interface StylesProps {
  top?: any;
  left?: any;
}

const Container = styled.div`
  position: fixed;
  top: ${(props: StylesProps) => props.top}px;
  left: ${(props: StylesProps) => props.left}px;
  width: 200px;
  padding: 1rem;
  background: ${Colors.white2} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px ${Colors.black}29;
  border: 0.5px solid ${Colors.gray1};
  border-radius: 8px;
  pointer-events: none;
  z-index: 1;
`;

const Title = styled.p`
  margin: 0;
  letter-spacing: 0;
  color: ${props => props.color};
  cursor: pointer;
  font: 700 0.625rem "Eurostile";
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

const Box = styled.span`
  width: 50%;
  margin-top: 0.25rem;
`;

const InfoLabel = styled.p`
  margin: 0.25rem 0;
  color: ${Colors.gray8};
  font: 500 0.625rem "Eurostile";
`;

const InfoContent = styled.p`
  margin: 0.25rem 0;
  color: ${(props: any) => props.color};
  font: 700 0.625rem "Open Sans";
`;

interface BarInfoProps {
  description: any
}

const BarInfo: FC<BarInfoProps> = ({ description }) => {

  const { top, left, modalInfo } = description;

  return (
    <Container top={top} left={left}>
      <Title>{modalInfo?.month}</Title>
      <Content>
        <Box>
          <InfoLabel>Vencido</InfoLabel>
          <InfoContent color={Colors.green5}>{modalInfo.revenues}</InfoContent>
        </Box>
        <Box>
          <InfoLabel>Maior Preço</InfoLabel>
          <InfoContent color={Colors.red}>{modalInfo.biggestPrice}</InfoContent>
        </Box>
        <Box>
          <InfoLabel>Desclassificado</InfoLabel>
          <InfoContent color={Colors.orange5}>{modalInfo.disqualified}</InfoContent>
        </Box>
        <Box>
          <InfoLabel>Declinado</InfoLabel>
          <InfoContent color={Colors.purple1}>{modalInfo.declined}</InfoContent>
        </Box>
      </Content>      
    </Container>
  );
}
export default connect(mapStateToProps)(BarInfo);
