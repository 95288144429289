import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import DeleteCell from "../../../../assets/icons/common/delete-icon.svg";
import Colors from "../../../../assets/styles/colors"

interface StylesProps {
  background?: any;
  margin?: any;
  order?: any;
}

const Cell = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  margin-right: 0.25rem;
  padding: 0.3rem 0.4rem;
  border-radius: 10px;
  min-width: 3rem;
  color: ${Colors.gray8};
  word-break: break-all;
  margin: ${(props: StylesProps) => props.margin};
  order: ${(props: StylesProps) => props.order};
  background: ${(props) => props.background};
`;

const Icon = styled.img`
  width: 13px;
  opacity: 1;
  cursor: pointer;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: center;
`;

const Title = styled.p`
  margin: 0;
  margin-bottom: 3px;
  font-size: 0.95rem;
  margin-right: 0.2rem;
`;

interface IndexProps {
  canRemoverWord?: any;
  background?: any;
  margin?: any;
  order?: any;
  handleEdit?: () => void;
  children?: ReactNode;
  onClick?: () => void;
}

const Index: FC<IndexProps> = ({
  canRemoverWord,
  background,
  margin,
  order,
  handleEdit,
  children,
  onClick
}) => {
  const showXToRemoveWord = canRemoverWord;
  return (
    <Cell
      background={background || `${Colors.blue2}30`}
      margin={margin}
      order={order}
      onClick={handleEdit}
    >
      <Title>{children}</Title>
      {showXToRemoveWord ? (
        <Icon src={DeleteCell} onClick={onClick} />
      ) : null}
    </Cell>
  );
};

export default Index;
