import { format } from "date-fns";
// import { sub } from "date-fns";
const months:string[] = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez",
];

const getMonthLabel = (month:any, year:any) => {
  if (!isNaN(month) && !isNaN(year)) {
    return `${months[month]}/${format(new Date(year, month, 1), "yy")}`;
  }
};

export const getMonthTransactions = (opportunities:any, timeline:any, dataSetRange:any) => {
  const data:any = [];
  const relativeMonths = [];
  const lastMonth = new Date(dataSetRange.date).getMonth();
  let currentMonth = lastMonth;
  let currentYear = new Date(dataSetRange.date).getFullYear();

  if (!dataSetRange.lastYearActive) {
    for (let i = 0; i < 12; i += 1) {
      relativeMonths.push(getMonthLabel(currentMonth, currentYear));

      currentMonth = currentMonth - 1;

      if (currentMonth < 0) {
        currentMonth = 11;
        currentYear = currentYear - 1;
      }
    }
    relativeMonths.reverse();
  } else {
    for (let i = 0; i < months.length; i += 1) {
      relativeMonths.push(getMonthLabel(i, currentYear));
    }
  }

  // eslint-disable-next-line array-callback-return
  relativeMonths.map((monthName, index) => {
    const currentOpportunities = opportunities.filter((op:any) => {
      const monthNumber = new Date(op.openTime).getMonth();
      const yearNumber = new Date(op.openTime).getFullYear();

      return getMonthLabel(monthNumber, yearNumber) === monthName;
    });

    // const limiteIndex = relativeMonths.length - timeline.label;
    // const monthlyFilter = relativeMonths.filter(
    //   (item, index) => index >= limiteIndex
    // );

    const revenue = currentOpportunities.reduce((total:any, item:any) => {
      if (item.totalEarned && item.winner) {
        return total + item.totalEarned;
      }
      return total;
    }, 0);

    /* currentOpportunities.filter(item => item.winner).reduce((total, item) => {
      if (item.judgementType === 'Julgamento por Total') {
        return total + item.myPrice;
      } else {
        return item.Items.filter(item => item.winner).reduce((totalItem, item) => {
          return totalItem + item.myPrice;
        }, 0);
      }
    }, 0); */

    const losses = currentOpportunities.reduce((total:any, item:any) => {      
      if (!item.winner) {
        return total + item.total;
      }
      return total + 0;
    }, 0);

    const biggestPrice = currentOpportunities.reduce((total:any, item:any) => {   
      if (item.statusProposal === "Cotação" &&
      item.statusQualification === "Classificado" &&
      !item.winner) {
        return total + item.total;      }

      return total + 0;
    }, 0);

    const disqualified = currentOpportunities.reduce((total:any, item:any) => {   
      if ( item.statusProposal === "Cotação" &&
      item.statusQualification === "Desclassificado") {
        return total + item.total;      }

      return total + 0;
    }, 0);

    const declined = currentOpportunities.reduce((total:any, item:any) => {   
      if ( item.statusProposal === "Declínio") {
        return total + item.total;      }

      return total + 0;
    }, 0);



    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const currentMonth =
      currentOpportunities.length > 0
        ? new Date(currentOpportunities[0].openTime).getFullYear()
        : "";

    data.push({
      month: `${monthName}`,
      revenue,
      losses,
      biggestPrice,
      disqualified,
      declined,
      value: revenue + losses,
    });
  });

  return data;
};

export const getMonthBubbles = (opportunities:any, timeline:any, dataSetRange:any) => {
  const data:any = [];
  const relativeMonths = [];
  const lastMonth = new Date(dataSetRange.date).getMonth();
  let currentMonth = lastMonth;

  for (let i = 0; i < 12; i += 1) {
    relativeMonths.push(months[currentMonth]);

    currentMonth = currentMonth - 1;

    if (currentMonth < 0) {
      currentMonth = 11;
    }
  }

  // eslint-disable-next-line array-callback-return
  relativeMonths.reverse().map((monthName, index) => {
    const list = opportunities.filter((op:any) => {
      const monthNumber = new Date(op.openTime).getMonth();

      return months[monthNumber] === monthName;
    });

    data.push({
      monthNumber: index,
      month: monthName,
      list,
    });
  });

  return data;
};
