import React, { FC, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Redirect, withRouter } from "react-router-dom";
import { LayoutProvider } from "../context/LayoutProvider";
import { clearCurrentIntegrationProfile } from "../dataflow/thunks/integration-profiles";
import ReactGA from "react-ga4";
import { decodedToken } from "../util/devUtilities";

const Private:FC = (props: any) => {

  const [state, setState] = useState<any>({
    validated: false
  })

  useEffect(() => {
    getAuth();
    ReactGA.send({ hitType: "pageview", page: props.path });
  }, [])

  useEffect(() => {
    getAuth();
    ReactGA.send({ hitType: "pageview", page: props.path });
  }, [props.location.key])

  const getToken = () => {
    const credentials = window.localStorage.getItem("petronect_credentials");
    if (credentials) {
      const token: any = decodedToken('accessToken');
      const isExpired = token.exp < Date.now() / 1000;
      return !isExpired;
    }

    return false;
  };

  const getAuth = async () => {
    try {
      if (getToken()) {
        setState({
          isAuthenticated: true,
          validated: true
        });
      } else {
        props.clearCurrentIntegrationProfile();
        setState({
          isAuthenticated: false,
          validated: true
        });
        window.localStorage.removeItem("petronect_credentialss");
      }
    } catch (err) {
      props.clearCurrentIntegrationProfile();
      window.localStorage.removeItem("petronect_credentials");
      setState({
        isAuthenticated: false,
        validated: true
      });
    }
  };

  const renderRoute = () => {
    const { component: Component, user, ...rest } = props;
    if (props.location.pathname === "/") {
      return <Redirect to="/match-relevancia" />;
    }

    return (
      <LayoutProvider>
        <Route {...rest} component={Component} />
      </LayoutProvider>
    );
  };

  const { validated, isAuthenticated } = state;
  return (
    <Fragment>
      {validated && isAuthenticated && renderRoute()}
      {validated && !isAuthenticated && <Redirect to="/login" />}
    </Fragment>
  );
}

const mapStateToProps = (state: any) => ({
  data: state.auth.data
});

const mapDispatchToProps = (dispatch: any) => ({
  clearCurrentIntegrationProfile: () =>
    dispatch(clearCurrentIntegrationProfile())
});

export default withRouter<any,any>(
  connect(mapStateToProps, mapDispatchToProps)(Private)
);
