import React,{FC, ReactNode} from "react";
import ReactScrollbar from "react-scrollbar";
import {Container} from "./styles";

export interface IndexProps {
  full:any;
  maxHeight:string;
  scrollWidth?:string
  children:ReactNode;
  scrollbar?:any;
} 



const Index: FC<IndexProps> = ({ full, maxHeight, scrollWidth,children,scrollbar, ...props }) => (
  <Container full={full} maxHeight={maxHeight} scrollWidth={scrollWidth}>
    <ReactScrollbar
      speed={0.8}
      horizontal={false}
      smoothScrolling
      stopScrollPropagation
      ref={scrollbar}
      {...props}
    >
      {children}
    </ReactScrollbar>
  </Container>
);

export default Index;
