import React, { FC } from "react";
import { TextDescription, TextBold, Text } from "../../../components/common-components/Accordion/styles";
import { Image } from "../styles";
import FilterNotification01 from "../../../assets/img/faq/filterNotification1.png";
import FilterNotification02 from "../../../assets/img/faq/filterNotification2.png";
import FilterNotification03 from "../../../assets/img/faq/filterNotification3.png";

const FilterNotification: FC = () => {
    return (
        <>
            <TextDescription>
                <Text>
                    {`É possível realizar a gestão dos filtros salvando o mesmo para utilizações posteriores em `}
                </Text>
                <TextBold>{`"salvar filtros"`}</TextBold>
                <Text>{`. Para isso é importante ir em `}</Text>
                <TextBold>{`"Meus Filtros" > "Gerenciar Filtros"`}</TextBold>
                <Text>{`, e você conseguirá visualizar todos os filtros salvos.`}</Text>
            </TextDescription>
            <TextDescription>
                <Image src={FilterNotification01} alt="Imagem" />
            </TextDescription>
            <TextDescription>
                <Text>{`Lembrando que o filtro necessita ter ao menos 1 palavra e que não é possível inserir caracteres especiais como: `}</Text>
                <TextBold>{`“ç”, “ã”`}</TextBold>
                <Text>{` no título do filtro.`}</Text>
            </TextDescription>
            <TextDescription>
                <Image src={FilterNotification02} alt="Imagem" />
            </TextDescription>
            <TextDescription>
                <Text>{`E mais, você contará com a opção `}</Text>
                <TextBold>{`"Notificações"`}</TextBold>
                <Text>{` que, quando ativada, faz com que o usuário receba diariamente em seu e-mail uma notificação com as oportunidades disponíveis relacionadas as palavras salvas.`}</Text>
            </TextDescription>
            <TextDescription>
                <Image src={FilterNotification03} alt="Imagem" />
            </TextDescription>
        </>
    )
}
export default FilterNotification;