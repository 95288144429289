import styled from "styled-components";
import { Form } from "@unform/web";
import Colors from "../../../assets/styles/colors";

interface StylesProps {
  center?:any;
  width?:any;
  last?:any;
}

export const CreateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-self: center;
  width: 100%;
  min-height: 80vh;
`;

export const CreateBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35%;
  background: ${Colors.white};
  box-shadow: 0px 1px 2px ${Colors.black}1a;
  border-radius: 4px;
  transition: width 0.5s, height 0.5s;
  @media (max-width: 1440px) {
    width: 50%;
  }
  @media (max-width: 960px) {
    margin: 1rem 0;
    width: 90%;
  }
  @media (max-width: 648px) {
    padding: 2rem;
    width: 100%;
  }
`;

export const RegisterForm = styled(Form)`
  width: 75%;

  @media (max-width: 500px) {
    width: 95%;
  }
`;

export const TermsText = styled.p`
  margin: 1.5rem 0 0;
  font: 400 1rem "Eurostile", "Open Sans", sans-serif;
  letter-spacing: 0;
  color: ${Colors.gray8};
  @media (max-width: 960px) {
    width: 100%;
  }
  @media (max-width: 648px) {
    margin-bottom: 1rem;
  }
  @media (max-width: 425px) {
    font-size: 0.8rem;
  }
`;

export const ButtonTerms = styled.span`
  margin: 0 1ch;
  cursor: pointer;

  strong {
    font-size: 1rem;
    @media (max-width: 425px) {
    font-size: 0.8rem;
  }
  }

  
`;

export const CreateTitle = styled.h1`
  align-self: ${(props:StylesProps) => !props.center && "flex-start"};
  margin: 0 1rem 1rem 0;
  font: 700 1.5rem "Eurostile", "Open Sans";
  letter-spacing: 0;
  color: ${Colors.blue6};

  @media (max-width: 960px) {
    font-size: 1.3rem;
  }
  @media (max-width: 425px) {
    font-size: 1rem;
  }
`;

export const InputBox = styled.span`
  position: relative;
  width: ${(props:StylesProps) => props.width && props.width};
  margin-top: ${(props:StylesProps) => props.last && ".5rem"};

  ${(props: StylesProps & { hasError?: boolean }) =>
    props.hasError &&
    `
      margin-bottom: 2rem;
    `}
  
  @media (max-width: 768px) {
    
    margin-bottom: 2rem;
  }

  @media (max-width: 960px) {
    width: 100%;
    margin-top: 2rem;
  }
`;

interface IconInputPasswordProps {
  errorType?: string;
}

export const IconInputPassword = styled.img<IconInputPasswordProps>`
  position: absolute;
  bottom: 5%;
  right: 0;
  width: 1.25rem;
  margin-right: 1rem;
  cursor: pointer;

  ${(props) =>
    props.errorType === 'Senha precisa de ao menos 6 caracteres' &&
    `
      bottom: 40%;
    `}

  ${(props) =>
    props.errorType === 'A senha precisa conter letras minúsculas, maiúsculas, números e caracteres especiais' &&
    `
      bottom: 50%;
    `}

  
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3rem;
`;

export const Loader = styled.img`
  width: 30px;
  height: 30px;
`;

export const BoxCheck = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 1rem 0px;
`;

export const TextConfirm = styled.p`
  margin: auto 0px 0.15rem 0.5rem;
  font: 400 1rem "Eurostile", "Open Sans", sans-serif;

  @media (max-width: 425px) {
    font-size: 0.8rem;
  }
`;

export const ButtonCloseTerms = styled.button`
  width: 100%;
  height: 4rem;
  color: ${Colors.white};
  background-color: ${({ disabled }) =>
    disabled ? `${Colors.black}40` : Colors.blue8};
  // cursor: pointer;
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  font: Bold 1rem "Eurostile";

  @media (max-width: 425px) {
    font-size: 0.8rem;
  }
`;
