import React, { FC, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "../../../common-components/Button";
import closedImg from "../../../../assets/icons/common/close-blue.svg";
import {
  // cleanTagsFilter,
  addOneFilter,
} from "../../../../dataflow/modules/opportunities-module";
import { setNotification } from "../../../../dataflow/modules/filter-module";
import {
  updateNotificationBellThunk,
  createMatchFilterThunk,
  editMatchFilterThunk,
  getMatchFilterThunk,
  saveFilterThunk,
  editFilterThunk,
  getFiltersThunk,
} from "../../../../dataflow/thunks/filters-thunk";
import {
  Overlay,
  FilterModal,
  Header,
  CloseContainer,
  CloseButton,
  CloseImage,
  WrapInput,
  InputBox,
  InputMessages,
  CharacterCounter,
  Label,
  Description,
  Title,
  Input,
  TextErrorBox,
  TextError,
  ContainerTagsColor,
  TagColor,
  TagBox,
} from "./styles";
import TagListItem from "../../../common-components/TagListItem/TagListItem";
import { mapTagItemsToString } from "../../../common-components/TagList/useTagListUtils";
import Colors from "../../../../assets/styles/colors";

const mapStateToProps = (state: any) => ({
  filterModal: state.filter.filterModal,
  tagsSearch: state.opportunities.tagsFilter,
  modal: state.modals.modal,
  matchFilter: state.filter.matchFilter,
  isNotification: state.filter.isNotification,
});

const mapDispatchToProps = (dispatch: any) => ({
  createMatchFilterThunk: (info: any) => dispatch(createMatchFilterThunk(info)),
  updateNotificationBellThunk: () =>
    dispatch(updateNotificationBellThunk()),
  editMatchFilterThunk: (info: any) => dispatch(editMatchFilterThunk(info)),
  // cleanTagsFilter: (info) => dispatch(cleanTagsFilter(info)),
  tagsFilter: (info: any) => dispatch(addOneFilter(info)),
  getMatchFilterThunk: () => dispatch(getMatchFilterThunk()),
  getFiltersThunk: (info: any) => dispatch(getFiltersThunk(info)),
  saveFilterThunk: (info: any) => dispatch(saveFilterThunk(info)),
  editFilterThunk: (info: any) => dispatch(editFilterThunk(info)),
  setNotification: (info: any) => dispatch(setNotification(info)),
});

interface ModalFilterProps {
  filterModal: any;
  modal: any;
  tagsSearch: any;
  isNotification: any;
  setNotification: (v: any) => void;
  updateNotificationBellThunk: () => void;
  saveFilterThunk: (v: any) => void;
  createMatchFilterThunk: (v: any) => void;
  closeModal: () => void;
  type?:any;
}

const ModalFilter: FC<ModalFilterProps> = ({
  filterModal,
  modal,
  tagsSearch,
  isNotification,
  setNotification,
  updateNotificationBellThunk,
  saveFilterThunk,
  createMatchFilterThunk,
  closeModal
}) => {

  const colors: string[] = [
    Colors.orange1,
    Colors.pink,
    Colors.green2,
    Colors.blue2,
    Colors.purple,
    Colors.yellow,
    Colors.red2,
  ];
  const [selectedColor, setSelectedColor] = useState(Colors.orange1);
  const [nameValue, setNameValue] = useState("");
  const [filters, setilters] = useState();
  const [emptyName, setEmptyName] = useState<boolean>();

  useEffect(() => {
    if (filterModal.filter) {
      setSelectedColor(filterModal.filter.color)
      setNameValue(filterModal.filter.name);
    }
    if (modal.selectedFilter) {
      setSelectedColor(modal.selectedFilter.color)
      setNameValue(modal.selectedFilter.name);
    }
  }, [])


  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.slice(0, CharacterLimit);
  
    if (!inputValue.trim()) {
      setEmptyName(false);
      setNameValue("");
      return;
    }
  
    const regexLettersNumbers = /^[a-zA-Z0-9\s]*$/;
    if (!regexLettersNumbers.test(inputValue)) {
      setEmptyName(true);
      return;
    }
  
    setEmptyName(false);
    setNameValue(inputValue);
  };

  const handleColorOption = (color: any) => {
    setSelectedColor(color);
  };

  const handleStop = (event: any) => {
    event.stopPropagation();
  };

  const deletehandleCell = (filter: any) => {
    let _filters: any =
      filters ||
      mapTagItemsToString(
        modal.type === "filter-edit" ? modal.selectedFilter.tags : tagsSearch
      );
    if (_filters.length > 0) {
      const isTagOption = typeof _filters[0] !== "string";

      if (!isTagOption) {
        _filters.splice(_filters.indexOf(filter), 1);
      } else {
        _filters = _filters.map((item: any) => item.fullObj !== filter);
      }
      setilters(_filters);
    }
  };

  const CharacterLimit = 25;

  const handleNotification = () => {
    const on = 1;

    if (isNotification === 0) {
      setNotification(on);
      updateNotificationBellThunk();
    }
  };

  const handleSave = (event: any) => {
    event.stopPropagation();
    const { selectedFilter, type, screen } = modal;

    if (!nameValue) {
      setEmptyName(true);
      return;
    }

    const tags =
      filters ||
      mapTagItemsToString(
        selectedFilter && type === "filter-edit"
          ? selectedFilter.tags
          : tagsSearch
      );

      if (tags.length === 0) {
        handleNotification();
        return;
      }

    const filter = {
      from: "match-relevancia",
      name: nameValue,
      color: selectedColor,
      tags: tags,
      query: tags,
    };

    if (type === "filter-modal") {
      if (screen) {
        saveFilterThunk(filter);
      } else {
        createMatchFilterThunk(filter);
        handleNotification();
      }
    }

    closeModal();
  };

  const renderTagCaps = () => {
    const tags =
      filters ||
      mapTagItemsToString(
        modal.type === "filter-edit" ? modal.selectedFilter.tags : tagsSearch
      );

    return tags.map((value: any, index: any) => {
      if (value !== "") {
        return (
          <TagListItem
            onClick={() => deletehandleCell(value)}
            onRemove={() => {
              deletehandleCell(value);
            }}
            selected={true}
            text={value}
            key={`${value}-${index}`}
          />
        );
      }
      return null;
    });
  };

  const renderColorOption = () => {
    return colors.map((color: any) => (
      <TagColor
        key={color}
        backgroundColor={color}
        selectedColor={selectedColor === color}
        onClick={() => handleColorOption(color)}
      />
    ));
  };

  return (
    <Overlay onClick={closeModal}>
      <FilterModal onClick={handleStop}>
        <Header>
          <Title modalTitle>
            {modal.type === "filter-edit" ? `Editar` : "Adicionar"} Filtro
          </Title>
          <CloseContainer onClick={closeModal}>
            <CloseButton>
              <CloseImage src={closedImg} />
            </CloseButton>
          </CloseContainer>
        </Header>
        <WrapInput>
          <Description>
            Você passará a receber notificações diárias de novas oportunidades
            com o filtro salvo em seu e-mail.
          </Description>
          <InputBox>
            <Label>Escolha um nome</Label>
            <Input
              placeholder={"Digite seu texto aqui"}
              onChange={handleChangeName}
              value={nameValue}
            />
            <InputMessages >
            <TextErrorBox>
              {emptyName && (
                <TextError>Por favor, digite um caractér válido.</TextError>
              )}
            </TextErrorBox>
            <CharacterCounter>
              {nameValue.length}/{CharacterLimit}
            </CharacterCounter>
            </InputMessages>
          </InputBox>
        </WrapInput>
        <TagBox>{renderTagCaps()}</TagBox>
        <Fragment>
          <Label>Escolha uma cor</Label>
          <ContainerTagsColor>{renderColorOption()}</ContainerTagsColor>
          <Button
            width="100%"
            height="3.5rem"
            color={Colors.white}
            background={Colors.blue6}
            shadow
            onClick={(e?: any) => { handleSave(e) }}
          >
            Salvar Notificação
          </Button>
        </Fragment>
      </FilterModal>
    </Overlay>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalFilter);
