import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import ListComponent from "../ListItem/ListItem";
import Item from "../ListItem/Item";
import Colors from "../../../assets/styles/colors";
import { Status } from "..";
import { maskCNPJ } from "../../../util/devUtilities";
import HelpImg from "../../../assets/icons/common/help.svg";
import { eventCategory, logEvent } from "../../../util/analytics";
import { Link } from "react-router-dom";
import { StylesProps } from "../../../util/types";
import Tooltip from "../../../components/common-components/Tooltip";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 0 1rem 3rem;
  @media (max-width: 768px) {
    flex-direction: column;
    overflow-x: hidden;
    padding: 0;
  }
`;

const Title = styled.h2`
  margin: 1rem 0 1rem 1rem;
  letter-spacing: 0;
  color: ${Colors.blue6};
  font: 700 1rem "Eurostile", "Open sans", sans-serif;

  @media (max-width: 1200px) {
    margin: 0;
  }

  @media screen and (max-width: 480px) {
    margin: 0.8rem 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const HelpButton = styled(Link)`
  width: 20px;
  height: 20px;
  background: none;
  background-image: url(${(props: StylesProps) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  cursor: pointer;
  margin-left: 10px;
`;

const Text = styled.p`
  color: ${Colors.white};
  padding-left: 10px;
  padding-right: 10px;
`;

const LinkTooltip = styled.a`
  top: 0.75rem;
  font: 800 1rem Open Sans;
  font-weight:  "500";
  text-decoration: none;
  color: ${Colors.green3};
  cursor: pointer;
`;
export interface AccessFeeItem {
    cnpj: string;
    razao_social: string;
    data_vencimento: string;
    status: string;
}

interface NoMembersProps {
    list: AccessFeeItem[];
    listCNPJ: string[];
}

const NoMembers: FC<NoMembersProps> = ({ list, listCNPJ }) => {
    const [isTooltip, setIsTooltip] = useState(false);
    const [listNoMembers, setListNoMembers] = useState<any>()
    const orderedList = list.sort((a: any, b: any) => {
        return Number(b?.data_vencimento?.replace(/-/g, "")?.slice(0, 8)) - Number(a?.data_vencimento?.replace(/-/g, "")?.slice(0, 8))
    })

    useEffect(() => {
        recentFilter()
    }, [])


    const renderListHeaderNaoSocio = () => (
        <ListComponent>
            <Item bold>CNPJ</Item>
            <Item bold>Status</Item>
        </ListComponent>
    );

    const validateDifference = () => {
        var aux: AccessFeeItem[] = [];
        let auxListCNPJ: string[] = []
        for (let i = 0; i < list.length; i++) {
            auxListCNPJ.push(list[i].cnpj)
        }
        listCNPJ.forEach(function (element, index, array) {
            if (auxListCNPJ.indexOf(element) === -1)
                aux.push({
                    cnpj: element,
                    razao_social: "",
                    data_vencimento: "",
                    status: Status.NAO_SOCIO
                });
        });
        return aux
    }

    const recentFilter = () => {
        let auxListMembers: any[] = []
        let auxListNoMembers: AccessFeeItem[] = []
        for (let i = 0; i < listCNPJ.length; i++) {
            let firstElement: any = orderedList.find(e => e.cnpj === listCNPJ[i])
            // const firstElement: any = orderedList.find(element => element.status === Status.VENCIDO)
            if (firstElement && firstElement.status !== Status.NAO_SOCIO) auxListMembers.push(firstElement)
            if (firstElement && firstElement.status === Status.NAO_SOCIO) auxListNoMembers.push(firstElement)
            if (firstElement && firstElement.status === Status.INATIVO) auxListNoMembers.push(firstElement)
            if (firstElement && firstElement.status === Status.VENCIDO) auxListNoMembers.push(firstElement)
        }
        const _auxListNoMembers = [...auxListNoMembers, ...validateDifference()]
        console.log('_auxListNoMembers', _auxListNoMembers, auxListMembers)
        setListNoMembers(_auxListNoMembers)
    }

    const linkTooltip = () => {

        return <Text>
            {"Para outras informações sobre a Taxa de Acesso "} <LinkTooltip
                href="https://www.petronect.com.br/irj/go/km/docs/pccshrcontent/Site%20Content%20(Legacy)/Portal2018/pt/modelo-cobranca.html"
                target="_blank">
                clique aqui
            </LinkTooltip>
        </Text>
    }

    return (
        <>
            {list?.length > 0 ?
                <Content>
                    {listNoMembers?.length > 0 ?
                        <div>
                            <Container>
                                <Title>CNPJs não associados a Taxa de acesso</Title>
                                <HelpButton
                                    onMouseOver={() => setIsTooltip(true)}
                                    onClick={() => {
                                        logEvent(
                                            eventCategory.iconClick,
                                            "click HelpImg",
                                            "Icone de ajuda sócio fornecedor"
                                        );
                                        setIsTooltip(value => !value)
                                    }}
                                    image={HelpImg}
                                    to={'/access-fee'}
                                />
                            </Container>
                            {isTooltip && (
                                <Tooltip
                                    onMouseLeave={() => { setIsTooltip(false) }}
                                    title="Você ainda não participa das oportunidades públicas do Sistema Petrobras?"
                                    paragraphs={[
                                        "O fornecedor que paga a taxa de acesso tem a vantagem de participar das licitações públicas do Sistema Petrobras.",
                                        "Consulte os detalhes e informações na área externa do Portal Petronect, na aba Compras e Contrações > Licitações Públicas se tem oportunidades para as suas famílias cadastradas.",
                                    ]}
                                    link={linkTooltip()}
                                    width={'100%'}
                                />
                            )}
                            {renderListHeaderNaoSocio()}
                            {listNoMembers.map((item: any, index: any) => {
                                return (
                                    <ListComponent status={Status.NAO_SOCIO} key={`${index}-${item?.cnpj}`}>
                                        <Item >{maskCNPJ(item?.cnpj)}</Item>
                                        <Item
                                            bold
                                            background={Colors.red}
                                        >
                                            {item.status}
                                        </Item>
                                    </ListComponent>
                                )
                            })}
                        </div>
                        : null}
                </Content>
                :
                null
            }
        </>
    )
}

export default NoMembers