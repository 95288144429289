import React from 'react';
import { CardWrapper } from './styles';
import ProposalCard from '../ProposalCard';

const ProposalCardsContainer = ({ items }: { items: Array<any> }) => {
    return (
        <CardWrapper>
            {
                (items && items.length > 0) &&
                    items.map((currentItem, index) => (
                        <ProposalCard 
                            key={index}
                            currentItem={currentItem}
                        />
                    ))
            }
        </CardWrapper>
    )
}

export default ProposalCardsContainer;