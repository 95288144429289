import React, { FC } from "react";
import {
  Container,
  WapperDetaisl,
  WapperIdentification,
  DetailsOportuny,
  InfoContainer,
  Infowrapper,
  InfoWrap,
  Description,
  Text,
  InfoContent,
  BoxTitle,
} from "./styles";
import OpportunityItem from "../OpportunityItem";
import Colors from "../../../../assets/styles/colors";

interface OpportunityDetailsProps {
  selectedOportunity: any;
}

const OpportunityDetails: FC<OpportunityDetailsProps> = ({ selectedOportunity }) => {

  const formatValue = (num: any) => {
    if (num >= 1000000) {
      return `${Math.sign(num) * Number((Math.abs(num) / 1000000).toFixed(1))}m`;
    }
    if (num >= 1000 || num >= 1) {
      return `${Math.sign(num) * Number((Math.abs(num) / 1000).toFixed(1))}k`;
    }
    return num;
  };

  return (
    <Container>
      <WapperIdentification>
        <Description>{selectedOportunity.description}</Description>
      </WapperIdentification>
      <WapperDetaisl>
        <DetailsOportuny>
          <InfoContainer>
            <Infowrapper>
              <InfoWrap width>
                <Text color={Colors.gray6}>Id</Text>
                <Text color={Colors.gray8} bold>
                  {selectedOportunity.id || "-"}
                </Text>
              </InfoWrap>
              <InfoWrap width>
                <Text color={Colors.gray6}> Valor Total</Text>
                <Text color={Colors.gray8} bold>
                  {formatValue(selectedOportunity.total) || "-"}
                </Text>
              </InfoWrap>
              {selectedOportunity.winner ? null : (
                <InfoWrap width>
                  <Text color={Colors.gray6}>Meu Valor Total</Text>
                  <Text color={Colors.gray8} bold>
                    {formatValue(selectedOportunity.myPrice) || "-"}
                  </Text>
                </InfoWrap>
              )}
              <InfoWrap width>
                <Text color={Colors.gray6}>Tipo de Julgamento</Text>
                <Text color={Colors.gray8} bold>
                  {selectedOportunity.judgementType || "-"}
                </Text>
              </InfoWrap>
              <InfoWrap width>
                <Text color={Colors.gray6}>Tipo de Processo</Text>
                <Text color={Colors.gray8} bold>
                  {selectedOportunity.processType || "-"}
                </Text>
              </InfoWrap>
            </Infowrapper>
          </InfoContainer>
          <InfoContent>
            <BoxTitle>
              <Text color={Colors.gray6}>Itens</Text>
            </BoxTitle>
            {selectedOportunity.quotedItems.length > 0 &&
              selectedOportunity.quotedItems.map((item: any, i: any) => (
                <OpportunityItem item={item} i={i} />
              ))}
          </InfoContent>
        </DetailsOportuny>
      </WapperDetaisl>
    </Container>
  );
};

export default OpportunityDetails;
