import styled from "styled-components";
import Colors from "../../../assets/styles/colors";
import { StylesProps } from "../../../util/types";

const InputForm = styled.input`
  width: 100%;
  height: ${(props:StylesProps) => (props.message ? "6rem" : "3.5rem")};
  outline: none;
  margin: ${(props:StylesProps) => props.margin};
  font: 400 1rem "Open Sans";
  border: ${(props:StylesProps) =>
    props.error ? `1px solid ${Colors.red2}` : `1px solid ${Colors.green1}`};
  padding: 1.25rem calc(1rem - 2px);
  color: ${Colors.gray8};
  background: ${Colors.white2} 0% 0% no-repeat padding-box;
  border-radius: 4px;

  ::placeholder {
    font: 400 1rem "Open Sans", sans-serif;
    letter-spacing: 0;
    color: ${Colors.gray5};
    align-self: flex-start;
    
    @media (max-width: 425px) {
    font-size: 0.8rem;
  }
  }
`;

const Label = styled.label`
  font: 500 0.75rem "Eurostile", "Open Sans", sans-serif;
  margin: 0.25rem 0 0.25rem 1rem;
  letter-spacing: 0;
  color: ${Colors.green1};

  p {
    color: ${Colors.green1};
    margin-left: 0.5rem;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
  }
`;

export { InputForm, Label };
