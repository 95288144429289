import React, { FC } from "react";
import { TextDescription, Text } from "../../../components/common-components/Accordion/styles";
import { Image } from "../styles";
import Feedback01 from "../../../assets/img/faq/feedback1.png";

const Feedback: FC = () => {
    return (
        <>
            <TextDescription>
                <Text>
                    {`Aqui o usuário poderá avaliar desde o atendimento até a funcionalidade da aplicação com notas de 1 a 5 estrelas, sendo 1 muito insatisfeito e 5 muito satisfeito. `}
                </Text>
            </TextDescription>
            <TextDescription>
                <Text>
                    {`O usuário poderá adicionar um comentário e o e-mail para registrarmos a sua opinião.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Text>
                    {`O feedback nos ajudará a melhorar a sua experiência com a Minha Petronect! Não deixe de avaliar, é muito importante!`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={Feedback01} alt="Imagem" />
            </TextDescription>
        </>
    )
}
export default Feedback;