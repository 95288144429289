import styled from "styled-components";
import Colors from "../../assets/styles/colors";
import { StylesProps } from "../../util/types";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
`;

const WrapHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Title = styled.h1`
  height: 4rem;
  margin: 0;
  font: 700 2rem "Eurostile";
  color: ${Colors.blue6};
`;

const WrapTopic = styled.div`
  height: 75%;
`;

const SubTitle = styled.h2`
  margin: 0;
  font: 700 1.375rem "Eurostile";
  color: ${Colors.blue6};
`;

const WrapperImg = styled.figure`
  margin: 0;
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;

  img {
    width: 70%;
  }
`;

const Text = styled.p`
  height: ${(props:StylesProps) => props.height};
  width: 80%;
  margin-bottom: 1rem;
  font: 500 1rem "Open Sans";
  color: ${Colors.gray8};
`;

export {
    Container,
    WrapHeader,
    Title,
    WrapTopic,
    SubTitle,
    WrapperImg,
    Text
}