import React, { FC } from "react";
import { TextDescription, Text } from "../../../components/common-components/Accordion/styles";
import { Image } from "../styles";
import AccessFee01 from "../../../assets/img/faq/accessFee1.png";

const AccessFee: FC = () => {
    return (
        <>
            <TextDescription>
                <Text>
                    {`A taxa de acesso garante ao usuário a participação de Licitações Públicas do Grupo Petrobras. Dentro do Painel, você conseguirá consultar a situação de seu(s) CNPJ(s). Se ele estiver como “Ativo”, significa que o processo de pagamento foi realizado corretamente e você poderá concorrer as licitações públicas. Caso esteja como “Vencido”, significa que ainda não houve o pagamento da taxa de acesso e assim, você não poderá participar das licitações públicas.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Text>
                    {`A taxa de acesso não é necessária para compras de dispensa, dando direito para participação em licitações públicas.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={AccessFee01} alt="Imagem" />
            </TextDescription>
        </>
    )
}
export default AccessFee;