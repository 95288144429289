import React, { FC } from "react";
import { Overlay, Modal, ImageClose, ImageSucess, TextSucess } from "./styles";
import { connect } from "react-redux";
import CloseModalIcon from "../../../assets/icons/common/close-icon.svg";
import SucessIcon from "../../../assets/icons/common/sucess.svg";
import { saveModal } from "../../../dataflow/modules/modal-module";

const mapDispatchToProps = (dispatch: any) => ({
  saveModal: (info: any) => dispatch(saveModal(info)),
});

interface ModalSucessProps {
  closeModal: () => void;
}

const ModalSucess: FC<ModalSucessProps> = ({ closeModal }) => {
  return (
    <Overlay onClick={closeModal}>
      <Modal>
        <ImageClose src={CloseModalIcon} onClick={closeModal} />
        <TextSucess>Mensagem enviada com sucesso</TextSucess>
        <ImageSucess src={SucessIcon} />
      </Modal>
    </Overlay>
  );
};

export default connect(null, mapDispatchToProps)(ModalSucess);
