import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Colors from "../../../assets/styles/colors";

const animation = keyframes`
0% {opacity: 0;}
20% {opacity: 1;}
27% {opacity: 1;}
43% {opacity: 0;}
100% {opacity: 0;}
`;

const SlideImage = styled.img`
  position: absolute;
  right: -30;
  bottom: 30vh;
  animation: ${animation} 9s infinite;

  &:nth-of-type(1) {
    animation-delay: 0s;
  }
  &:nth-of-type(2) {
    animation-delay: -3s;
  }

  @media (max-width: 768px) {
    width: 60%;
    bottom: 5vh;
    right: 0vw;
  }
`;

const SlideMessage = styled.span`
  position: absolute;
  width: 95%;
  margin: 0.25rem 0;
  margin-top: 3rem;
  color: ${Colors.white};
  font: 700 1.125rem "Eurostile", "Open Sans", sans serif;
  font-weight: bold;
  bottom: 16vh;
  letter-spacing: 0.18px;
  line-height: 2rem;

  animation: ${animation} 9s infinite;

  &:nth-of-type(1) {
    animation-delay: 0s;
  }
  &:nth-of-type(2) {
    animation-delay: -3s;
  }

  @media (max-width: 768px) {
    width: 35%;
    bottom: 13vh;
    left: 5vw;
    font-size: 1rem;
  }
`;

const LoginInputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;
  background: ${Colors.white};
  border-radius: 0 0 6px 6px;

  @media (max-width: 960px) {
    margin: 1rem 0;
    padding: 2rem;
    width: 85%;
    height: min-content;
    border-radius: 6px;
  }

  @media (max-width: 648px) {
    margin: 0;
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: space-evenly;
    border-radius: 6px;
  }
`;

const InputContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
`;

const LoginBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 90vh;
  border-radius: 0 0 6px 6px;
  @media (max-width: 960px) {
    width: 95%;
    height: 40vh;
    justify-content: space-evenly;
    flex-direction: row-reverse;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;

const TextVersion = styled.p`
  font: 400 1rem "Eurostile", "Open Sans", sans serif;
  color: ${Colors.gray8};
`;

const TourTitlePrimary = styled.h1`
  text-align: center;
  color: ${Colors.blue6};
  font: 32px Eurostile;
  font-weight: bold;
  letter-spacing: 0;
  margin: 20px 0 0 0;
`;

const ImageMaintenance = styled.img`
  margin: 20px 0;
`;

const TourText = styled.p`
  font: Medium 14px/18px Roboto;
  text-align: center;
  max-width: 500px;
  margin: 10px auto;
  letter-spacing: 0;
  font-size: 20px;
  color: ${Colors.blue6};
`;

const TourTextLink = styled(Link)`
  font: Medium 14px/18px Roboto;
  text-align: center;
  max-width: 500px;
  margin: 10px auto;
  letter-spacing: 0;
  font-size: 20px;
  color: ${Colors.blue6};
`;

export {
  SlideImage,
  SlideMessage,
  LoginInputBox,
  InputContainer,
  LoginBox,
  TextVersion,
  TourTitlePrimary,
  ImageMaintenance,
  TourText,
  TourTextLink
};
