import axios from "axios";

const exportCsvService = async (data: any) => {
    const { idToken } = JSON.parse(
        window?.localStorage?.getItem("petronect_credentials") || ""
    );

    const payload = { data,method: "POST" };
    await axios.post(
        `${process.env.REACT_APP_API}/export-data/upload`,
        payload,
        {
            headers: {
                Authorization: `Bearer ${idToken}`
            }
        }
    )
        // .then((v) => {
        //     console.log('teste then===>', v)
        // })
        // .catch((e) => {
        //     console.log('teste catch===>', e);
        //     return { message: "erro ao exportar arquivo", error: e }
        // });
};

export default exportCsvService;