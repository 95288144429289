import ReactGA from "react-ga4";

export const REACT_GA_TRACKING_CODE = "UA-167452861-1";

export const logEvent = (
  category: string,
  action: string,
  label?: string
  // value,
  // nonInteraction,
  // transport,
  // trackerNames
) => {

  const { userId } = window.localStorage.getItem("petronect_credentials")
    ? JSON.parse(window.localStorage.getItem("petronect_credentials") || "")
    : "";

  ReactGA.set({ user: userId || "" });
  ReactGA.event(
    {
      category,
      action,
      label,
      // value,
      // nonInteraction,
      // transport
    }
    //   trackerNames
  );
};

export const eventCategory = {
  iconClick: "icon_click",
  linkClick: "link_click",
  inputChange: "input_change",
  buttonClick: "button_click",
  tabClick: "tab_click",
  modalClose: "modal_close",
  modalOpen: "modal_open",
  checkboxChange: "checkbox_change",
  toastShow: "toast_show",
  dropdownChange: "dropdown_change",
  radioChange: "radio_change",
};
