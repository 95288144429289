import React, { FC, useEffect, useState } from "react";
import EasyProposal from "../../../pages/MatchAnalytical";
import WelcomeEasyProposal from "./WelcomeEasyProposal";
import {
  getIntegrationProfilesFromAPI,
  addNewIntegrationProfileToAPI,
  changeIntegrationStatus,
} from "../../../dataflow/thunks/integration-profiles";
import { connect } from "react-redux";
import StatusModal from "../../common-components/ModalForMessages/ModalForMessages";
import loading from "../../../assets/animations/loading.svg";
import { ScreenContainer, Loading } from "./styles"



const mapStateToProps = (state: any) => ({
  integrationProfiles: state.integrationProfiles.integrationProfiles,
  integrationStatus: state.integrationProfiles.integrationStatus,
});

const mapDispatchToProps = (dispatch: any) => ({
  // favoriteProposgetalonAPI: (proposal, isFavorite) => { dispatch(favoriteProposalonAPI(proposal, isFavorite)) }
  addNewIntegrationProfileToAPI: (newProfile: any) => {
    dispatch(addNewIntegrationProfileToAPI(newProfile));
  },
  getIntegrationProfilesFromAPI: () => {
    dispatch(getIntegrationProfilesFromAPI());
  },
  changeIntegrationStatus: (status: any) =>
    dispatch(changeIntegrationStatus(status)),
});

interface EasyProposalScreenManagerProps {
  integrationProfiles: any;
  getIntegrationProfilesFromAPI: () => void;
  integrationStatus: any;

}

const EasyProposalScreenManager: FC<EasyProposalScreenManagerProps> = ({
  integrationProfiles,
  getIntegrationProfilesFromAPI,
  integrationStatus
}) => {
  const [isUpdated, setIsUpdated] = useState(false);
  const [showScreens, setShowScreens] = useState(false);

  useEffect(() => {
    if (!integrationProfiles) {
      getIntegrationProfilesFromAPI();
      setIsUpdated(true);
    } else {
      setShowScreens(true);
    }
    //@TO-DO verify this logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isUpdated) {
      setShowScreens(true);
    }
    //@TO-DO verify this logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationProfiles]);

  const closeModal = () => {
    // changeIntegrationStatus("normal");
  };

  const messageModal = (infoReceived: any) => {
    const info = infoReceived;
    return (
      <StatusModal
        title={info.title}
        description={info.description}
        buttonTitle={"Ok"}
        closeModal={() => closeModal()}
      />
    );
  };

  // const addNewintegrationprofile = profile => {
  //   const { cnpj, token } = profile;

  //   if (cnpj === "" || cnpj === " " || token === "" || token === " ") {
  //     return;
  //   } else if (cnpj.length < 14) {
  //     props.changeIntegrationStatus("cnpjError");
  //   } else if (token.length < 117) {
  //     props.changeIntegrationStatus("tokenError");
  //   }

  //   props.addNewIntegrationProfileToAPI({ cnpj: cnpj, token: token });
  // };

  const integrationStatusFeedback = () => {
    let modalInfo = { title: "", description: "" };

    switch (integrationStatus) {
      case "successed":
        // this.resetInputFields();
        modalInfo.title = "Perfil cadastrado";
        modalInfo.description = "Seu perfil foi cadastrado com sucesso";
        return modalInfo;

      case "failed":
        modalInfo.title = "Erro ao cadastrar";
        modalInfo.description =
          "Ocorreu um erro ao cadastrar o perfil. Tente novamente.";
        return modalInfo;

      case "cnpjError":
        modalInfo.title = "CNPJ com erro";
        modalInfo.description = "Verifique se o CNPJ foi digitado corretamente";
        return modalInfo;

      case "tokenError":
        modalInfo.title = "Token com erro";
        modalInfo.description =
          "Verifique se o token foi digitado corretamente";
        return modalInfo;

      default:
        modalInfo.title = "";
        modalInfo.description = "";
        return modalInfo;
    }
  };

  return showScreens ? (
    <ScreenContainer>
      {integrationStatus !== "normal"
        ? messageModal(integrationStatusFeedback())
        : null}
      {integrationProfiles.length > 0 ? (
        <EasyProposal />
      ) : (
        <WelcomeEasyProposal />
      )}
    </ScreenContainer>
  ) : (
    <Loading>
      <img src={loading} height={50} alt="Loading..." />
    </Loading>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EasyProposalScreenManager);
