import styled from "styled-components";
import Colors from "../../../../assets/styles/colors";

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.gray8}40;
  z-index: 99;
`;

const Modal = styled.div`
  position: relative;
  padding: 1.75rem;
  padding-top: 0;
  width: 35rem;
  border: 0.5px solid ${Colors.blue8}95;
  border-radius: 8px;
  background: ${Colors.white};
  @media (max-width: 960px) {
    z-index: 2;
    padding: 1rem;
  }

  @media (max-width: 648px) {
    width: 80%;
  }
`;

const CloseContainer = styled.div`
  position: absolute;
  right: -0.7rem;
  top: -0.8rem;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid ${Colors.blue8};
  border-radius: 50%;
  background-color: ${Colors.white};

  @media (max-width: 648px) {
    top: 0.5rem;
    right: 0.5rem;
  }
`;

const CloseButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1px;
  margin-left: 1px;
  font-size: 1.2rem;
  color: ${Colors.blue8};
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;

const CloseImage = styled.img`
  width: 13px;
`;

const Title = styled.h2`
  margin: 2rem 0;
  font: 700 1rem Eurostile;
  color: ${Colors.blue8};
  font-size: 1rem;

  @media (max-width: 1024px) {
    margin: 1rem 0;
  }
`;

const Text = styled.p`
  margin: 2rem 0;
  color: ${Colors.gray8};
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 648px) {
    flex-direction: column-reverse;
  }
`;

export {
    Overlay,
    Modal,
    CloseContainer,
    CloseButton,
    CloseImage,
    Title,
    Text,
    Box
}