import React, {FC, useEffect, useState} from "react";
import AddNewProfile from "../../components/modules-components/integration/AddNewProfile";
import ManageProfile from "./ManageProfile";
import TutorialIntegrations from "./TutorialIntegrations";
import PageHeaderDescription from "../../components/common-components/PageHeaderDescription/PageHeaderDescription";
import {
  ScreenContainer,
  FlexContainer,
  ContainerWrapper
} from "./styles";
import ListCNPJs from "./components/ListCNPJ/ListCNPJs";
import useIntegrationProfiles from "./hook/useIntegrationProfiles ";

const IntegrationProfilesManager:FC = () =>  {
  const { cnpjList, handleDeleteIntegration, fetchData } = useIntegrationProfiles();

  useEffect(() => {
    fetchData();
}, []);

  const screenSelected:string = "add_new_profile_screen"

  const showCurrentScreen = ()=> {
    switch (screenSelected) {
      case "add_new_profile_screen":
        return <AddNewProfile showDescriptionText={true} />;

      case "manage_profiles_screen":
        return <ManageProfile />;

      case "tutorial":
        return (
          <>
            <TutorialIntegrations>
              <AddNewProfile showDescriptionText={false} />
            </TutorialIntegrations>
          </>
        );
    }
  }

    return (
      <>
        <ScreenContainer>
          <PageHeaderDescription
            title={"Integrações"}
            paragraphs={[
              "As integrações são utilizadas para que a Minha Petronect receba as informações do perfil do Portal Petronect e com isso tenha acesso as funcionalidades do Match Relevância, Meu Desempenho, e Mach Analítico.",
            ]}
          />
          <ContainerWrapper margin="30px 30px 30px 50px" style={{ height: "100vh" }}>
            <FlexContainer wrap="wrap" gap="32px 96px" justifyContent="flex-start">
              <ContainerWrapper>{showCurrentScreen()}</ContainerWrapper>
              <ContainerWrapper>
                <ListCNPJs
                  cnpjs={cnpjList}
                  handleDeleteIntegration={handleDeleteIntegration}
                />
              </ContainerWrapper>
            </FlexContainer>
          </ContainerWrapper>
        </ScreenContainer>
      </>
    );
  }

export default IntegrationProfilesManager;
