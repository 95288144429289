import { format } from "date-fns";

export const formatValue = (num: any) => {
  if (num >= 1000000) {
    return `${Math.sign(num) * Number((Math.abs(num) / 1000000).toFixed(1))}mi`;
  }
  if (num >= 1000 || num >= 1) {
    return `${Math.sign(num) * Number((Math.abs(num) / 1000).toFixed(1))}mil`;
  }
  return num;
};
/**
 * @param date string or Date object
 * @param formatString string like 'dd/MM/yyyy', it is used on 'format' from date-fns
 */
export const formatDateWithoutTimezone = (date: any, formatString: any) => {
  try {
    let input = new Date(date);
    return format(
      new Date(input.valueOf() + input.getTimezoneOffset() * 60 * 1000),
      formatString || "dd/MM/yyyy"
    );
  } catch (e) {
    return "Data inválida";
  }
};

export const handleDate = (currentDate: string) => {
  const DATE = new Date(currentDate),
  DAY = DATE.getDate().toString(),
  DAY_FINAL = (DAY.length === 1) ? '0' + DAY : DAY,
  MONTH = (DATE.getMonth() + 1).toString(),
  MONTH_FINAL = (MONTH.length === 1) ? '0' + MONTH : MONTH,
  YEAR_FINAL = DATE.getFullYear();

  return `${DAY_FINAL}/${MONTH_FINAL}/${YEAR_FINAL}`;
}

/** Adiciona dias a uma data e retorna a data com os dias adicionados */
export const addDaysInDate = (date: Date, days: number) => {
  date.setDate(date.getDate() + days);
  return date;
}
