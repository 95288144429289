import styled from "styled-components";

export const ListContainer = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
`;
export const TitleList = styled.span`
  font-size: 1.25rem;
  margin: auto 0.25rem;
`;
export const ChildrenContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0pxm;
`;
