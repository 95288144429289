import styled from "styled-components";

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    overflow-x: hidden;
  }
`;

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export {
    ScreenContainer,
    Loading
}