import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../assets/styles/colors";
import PageHeaderDescription from "../../components/common-components/PageHeaderDescription/PageHeaderDescription";
import { toast, ToastContainer } from "react-toastify";
import { ItemProps } from "../AccessFee/ListItem/Item";
import ModalErrorService from "./ModalErrorService";
import loader from "../../assets/icons/common/loading.svg";
import axios from "axios";
import { dateDefault } from "../../util/devUtilities";

const Form = styled.form`
  min-height: 446px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px;

  @media (min-width: 768px) {
    width: 100%;
    max-width: 600px;
    height: auto;
    padding: 30px;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  border: 1px solid ${Colors.gray3};
  margin-bottom: 15px;
  width: 100%;
  /* height: 40px; */
  border-radius: 6px;
  color: ${Colors.blue6};
  font-size: 16px;
  width: 100%;
  :focus {
    outline: 2px solid ${Colors.blue6};
  }
  ::placeholder {
    color: ${Colors.gray5};
  }
`;

const RowButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const InputButton = styled.button`
  width: auto;
  height: auto;
  padding: 0.5rem 1rem 0.5rem 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  background: ${Colors.blue6};
  color: ${Colors.white};
  :active {
    opacity: 0.5;
  }
  :focus {
    outline: none;
  }
`;

const Span = styled.span`
  margin-bottom: 3px;
  color: ${Colors.blue6};
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: ${(props: ItemProps) => (props.bold ? "600" : "350")};
`;

const Title = styled.h2`
  margin: 1rem 0 1rem 2rem;
  letter-spacing: 0;
  color: ${Colors.blue6};
  font: 700 1rem "Eurostile", "Open sans", sans-serif;

  @media (max-width: 1200px) {
    margin: 0 0 0 2rem;
  }

  @media screen and (max-width: 480px) {
    margin: 0.8rem 2rem;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3rem;
`;

const Loader = styled.img`
  width: 30px;
  height: 30px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 16px;
`;

const UploadFamilyList: FC = () => {
  const [value, setValue] = useState("");
  const [isToastShowed, setisToastShowed] = useState(false);
  const [listData, setListData] = useState(['null'])
  const [uploads, setUploads] = useState(0)
  const [isModal, setIsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdate, setLastUpdate] = useState('');
  const [fileName, setFileName] = useState('');
  const { idToken } = JSON.parse(
    window?.localStorage?.getItem("petronect_credentials") || ""
  );

  const listFamiliesUpdateService = () => {
    setIsLoading(true)
    const payload = { userId: [''], method: "GET" };
    axios.post(
      `${process.env.REACT_APP_API}/export-data/list-familys`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      }
    )
      .then((response: any) => {
        if (response && response !== null) {
          setLastUpdate(response?.data?.body?.last_update[0]?.created_at);
        }
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    listFamiliesUpdateService();
  }, [])

  const buildToast = (phrase: any) => {
    toast.success(phrase, {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setisToastShowed(true);
  };

  const handleChangeFile = (event: any) => {
    setListData(['null'])
    setUploads(0)
    if (event.target.files[0].type === 'text/csv') {
      setValue(event.target.value);
      convertToObj(event.target.files[0]);
      setFileName(event.target.value)
    }
    if (event.target.files[0].type !== 'text/csv' && !isToastShowed) {
      buildToast(
        `O arquivo ${event.target.files[0].name} 
        deve ser do tipo .csv`
      );
    }
  }

  interface ObjJson {
    COGNITO_ID?: string;
    NOME_DO_FILTRO?: string;
    FAMILIA_NUM?: string;
    DESCRIPTION?: string;
    OPORT_DISPENSA?: string;
    OPORT_LICITACAO?: string;
  }

  const convertToObj = (fileReaded: any) => {
    let reader: FileReader = new FileReader();
    reader.readAsText(fileReaded);
    reader.onload = (e) => {
      let csv: any = reader.result;
      let allTextLines = csv.split(/\r|\n|\r/);
      let lines: any = [];
      for (let i = 1; i < allTextLines.length; i++) {
        let data = allTextLines[i].split(';');

        if (data.length > 1) {
          let aux: ObjJson = {
            COGNITO_ID: data[0],
            NOME_DO_FILTRO: data[1],
            FAMILIA_NUM: data[2],
            DESCRIPTION: data[3],
            OPORT_DISPENSA: data[4],
            OPORT_LICITACAO: data[5],
          };
          lines.push(aux);
        }
      }
      setListData(lines);
    }
  }

  const uploadFiles = (files: ObjJson[]) => {
    setIsLoading(true)
    const payload = { files, method: "POST" };

    axios.post(
      `${process.env.REACT_APP_API}/export-data/list-familys`,
      { userId: [''], method: "DELETE" },
      {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      }
    )
      .then((response) => {
        axios.post(
          `${process.env.REACT_APP_API}/export-data/list-familys`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }
        )
          .then((response) => {
            setUploads((prevValue) => files.length + prevValue)
            setIsLoading(false)
            listFamiliesUpdateService();
          })
          .catch((error) => {
            setIsModal(true)
            setIsLoading(false)
          })
      })
      .catch((error) => {
        setIsModal(true)
        setIsLoading(false)
      })






  }

  const handleSubmit = (list: any[]) => {
    let aux: any[] = [];
    const rest = list.length % 1000
    let contador: number = 0;
    if (list.length < 1000 && list.length > 0) {
      return uploadFiles(list);
    }
    for (let i = 0; i < list.length; i++) {
      aux.push(list[i]);
      contador += 1;
      if (aux.length === 1000) {
        uploadFiles(aux)
        aux = [];
      }
      if (contador > list.length - rest && aux.length === rest) {
        uploadFiles(aux)
      }
    }
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PageHeaderDescription
        title={"Upload Filtros"}
        paragraphs={[
          "Upload lista de sugestões de Famílias por fitros .csv ",
        ]}
      />
      <Row>
        <Title>Upload lista de Filtros</Title>
        <Span>Última data de Upload:{' '}
          {isLoading && (
            <Loader src={loader} />
          )}
          {!isLoading && (
            <Span bold>{dateDefault(lastUpdate, '')}</Span>
          )}
        </Span>
      </Row>
      <Form >
        <Label>
          <Span>Arquivo</Span>
          <Input
            id='fileInput'
            type="file"
            value={value}
            accept={'.csv'}
            onChange={(event) => handleChangeFile(event)}
            required
          />
          {fileName.length > 0 && (
            <Span>Arquivo selecionado: <Span bold>{fileName}</Span></Span>
          )}
          {listData.length === 0 && (
            <Span>Não foram encontrados novos registros.</Span>
          )}
          {listData.length > 0 && listData[0] !== 'null' && (
            <Span><Span bold>Novos</Span> Registros encontrados: <Span bold>{listData.length}</Span></Span>
          )}
          {uploads > 0 && (
            <Span>Uploads realizados com sucesso: <Span bold>{uploads}</Span></Span>
          )}
          {isLoading && (
            <LoaderContainer>
              <Loader src={loader} />
            </LoaderContainer>
          )}
          <RowButton>
            <InputButton
              disabled={listData.length === 0 || listData[0] === 'null' || value === ""}
              id="submit"
              type="button"
              onClick={() => {
                handleSubmit(listData)
                setValue("")
              }}
            >Submeter</InputButton>
          </RowButton>
        </Label>
      </Form>
      {isModal && (
        <ModalErrorService
          closeModal={() => { setIsModal(false) }}
        />
      )}
    </>
  )
}

export default UploadFamilyList;