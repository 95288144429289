import React, { FC } from "react";
import { TextDescription, Text } from "../../../components/common-components/Accordion/styles";
import { Image } from "../styles";
import SearchField01 from "../../../assets/img/faq/searchField1.png";

const SearchField: FC = () => {
    return (
        <>
            <TextDescription>
                <Text>
                    {`No campo a seguir o usuário conseguirá buscar termos que trarão as oportunidades públicas em uma tabela para consulta. As palavras filtradas aparecerão em azul na linha “Filtros:”.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Text>
                    {`Ao clicar nas palavras em azul na linha de “Filtro:”, as palavras mais claras não estarão apresentadas na tabela. Para retornar o azul do filtro, basta clicar novamente em cima da palavra.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={SearchField01} alt="Imagem" />
            </TextDescription>
        </>
    )
}
export default SearchField;