import styled from "styled-components";
import Colors from "../../../assets/styles/colors";

interface StylesProps {
    label?: string;
    weight?: boolean;
    width?: string;
    text?: string;
    background?: string;
    rotate?: number;
    font?: boolean;
    hover?: string;
    header?: boolean;
    hideOnMobile?: boolean;
    mobileFull?: boolean;
  }

  export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15px;
`;

export const ItemsPerPageSelect = styled.select`
  border-radius: 5px;
  color: ${Colors.blue6};
  border: 1px solid ${Colors.blue6};
`;

export const ItemsPagination = styled.div<StylesProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0 10px 0 0;
    font-size: 0.875rem;
    color: ${Colors.blue6};
    font-weight: ${props => (props.weight ? "600" : "400")};
    font-family: ${props => (props.font ? "Eurostile" : "Open Sans")};
  }

  span {
    margin: 0 0 0 10px;
    font-size: 0.875rem;
    color: ${Colors.blue6};
    font-weight: ${props => (props.weight ? "600" : "400")};
    font-family: ${props => (props.font ? "Eurostile" : "Open Sans")};
  }

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;