import React, { FC } from "react";
import { TextDescription, Text, } from "../../../components/common-components/Accordion/styles";
import { Image } from "../styles";
import CoefficientGraph01 from "../../../assets/img/faq/coefficientGraph1.png";

const CoefficientGraph: FC = () => {
    return (
        <>
            <TextDescription>
                <Text>
                    {`É apresentada a razão entre o seu preço pelo preço do concorrente para medir a sua eficiência no mercado.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Text>
                    {`O ponto no gráfico representa uma oportunidade concorrida pelo CNPJ cadastrado. Os pontos na área verde representam as oportunidades vencidas e nas vermelhas as oportunidades perdidas, sendo a distância entre o ponto e a linha do 0%, a margem do seu preço para o vencedor ou do seu preço para o segundo colocado.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={CoefficientGraph01} alt="Imagem" />
            </TextDescription>
        </>
    )
}
export default CoefficientGraph;