import React, { FC } from "react";
import {
  InfoWrap,
  Text,
  BoxInfo,
} from "../OpportunityDetails/styles";
import { formatValue } from "../../../../util/format-value";
import Colors from "../../../../assets/styles/colors";

interface OpportunityItemProps {
  item: any;
  i: any;
}

const OpportunityItem: FC<OpportunityItemProps> = ({ item, i }) => {
  return (
    <>
      <BoxInfo>
        <InfoWrap>
          <Text color={Colors.gray8} bold fontSize>
            {i + 1 < 10
              ? `00${i + 1}`
              : i + 1 >= 10 && i + 1 < 100
                ? `0${i + 1}`
                : i + 1}
          </Text>
          <Text color={Colors.gray8} bold>
            {item.description || "-"}
          </Text>
        </InfoWrap>
        <InfoWrap>
          <Text color={Colors.gray8}>
            Meu Preço:{" "}
            {item.priceTotal !== 0 ? formatValue(item.priceTotal) : "-" || "-"}
          </Text>
          <Text color={Colors.gray8}>
            Preço do Competidor:{" "}
            {item.competitorPrice !== 0
              ? formatValue(item.competitorPrice)
              : "-" || "-"}
          </Text>
          {item.lot !== "?" ? (
            <Text color={Colors.gray8}>{item.lot}</Text>
          ) : (
            ""
          )}
          <Text color={Colors.gray8}>Quantidade: {item.quantity || "-"}</Text>
          <Text color={Colors.gray8}>
            Unidade de Fornecimento: {item.measurementUnity || "-"}
          </Text>
        </InfoWrap>
      </BoxInfo>
    </>
  );
};

export default OpportunityItem;
