import React, { FC, useEffect, useRef } from "react";
import { InputForm, Label } from "./style";
import { useField } from "@unform/core";

interface UnformInputProps {
  ref?: any;
  name: string;
  labelText?: string;
  onChange?: () => void;
  placeholder?: string;
  type?: string;
  margin?: string;
  innerRef?: any;
  border?: string;
  autoFocus?: any;
  message?: string;
  onSubmit?: () => void;
  required?: boolean;
  error?: any;
  autoComplete?:any;
}


const UnformInput: FC<UnformInputProps> = ({
  ref,
  name,
  labelText,
  onChange,
  placeholder,
  type,
  margin,
  innerRef,
  border,
  autoFocus,
  message,
  onSubmit,
  required,
  error,
  autoComplete
}) => {
  const inputRef = useRef(ref || null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [registerField, fieldName]);

  return (
    <>
      <Label>{labelText}</Label>
      <InputForm
        defaultValue={defaultValue}
        ref={inputRef}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        margin={margin}
        innerRef={innerRef}
        border={border}
        autoFocus={autoFocus}
        message={message}
        autoComplete={autoComplete || "none"}
        onSubmit={onSubmit}
        required={required}
        error={error}
      />
    </>
  );
}

export default UnformInput;
