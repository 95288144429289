// Libs
import axios from "axios";

import {
  saveUserFilters,
  updateUserFilter,
  deleteUserFilter,
  toggleModalFilter,
  toggleSearchModal,
  cleanUserFilters,
  cleanFilterModule,
  createMatchFilter,
  editMatchFilter,
  saveMatchFilters,
  deleteMatchFilter,
  setNotification,
  setSupport
} from "../modules/filter-module";

import {
  setLoading,
  // setSearchTags,
  cleanOpportunitiesModule
} from "../modules/opportunities-module";

import {
  applyFilterThunk,
  saveOpportunitiesThunk
} from "./opportunities-thunk";

let ENV_SUFFIX_PERFORMANCE =
  process.env.NODE_ENV === "production" ? "/desempenho/odata" : "/odata";
let ENV_SUFFIX_MATCH =
  process.env.NODE_ENV === "production"
    ? "/match-relevancia"
    : "/match-relevancia";

const getFilters = (info: any) =>
  axios({
    method: "get",
    url: `${process.env.REACT_APP_API}${ENV_SUFFIX_MATCH}/filters?from=desempenho`,
    headers: {
      Authorization: `Bearer ${info.idToken}`
    }
  });

const saveFilter = (info: any) =>
  axios({
    method: "post",
    url: `${process.env.REACT_APP_API}${ENV_SUFFIX_MATCH}/filters`,
    headers: {
      Authorization: `Bearer ${info.idToken}`
    },
    data: {
      from: "desempenho",
      // userId: info.userId,
      name: info.name,
      query: info.query,
      color: info.color,
      tags: info.tags
    }
  });

const deleteFilter = (info: any) =>
  axios({
    method: "delete",
    url: `${process.env.REACT_APP_API}${ENV_SUFFIX_PERFORMANCE}/filters/${info.id}`,
    headers: {
      Authorization: `Bearer ${info.idToken}`
    }
  });

const editFilter = (info: any) =>
  axios({
    method: "patch",
    url: `${process.env.REACT_APP_API}${ENV_SUFFIX_MATCH}/filters`,
    headers: {
      Authorization: `Bearer ${info.idToken}`
    },
    data: {
      id: info.id,
      name: info.name,
      color: info.color,
      tags: info.tags
    }
  });

const handleExpiredToken = (err: any, dispatch: any) => {
  if (err.response.status && err.response.status === 401) {
    dispatch(cleanOpportunitiesModule());
    dispatch(cleanFilterModule());

    window.localStorage.removeItem("petronect_credentials");

    // this.setState({
    //   isAuthenticated: false,
    //   validated: true
    // });

    window.location.reload();
  }
};

export const getFiltersThunk = (info: any) => async (dispatch: any, getState: any) => {
  try {
    const filters: any = [];
    const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
    );
    const { userFilters } = getState().filter;

    dispatch(setLoading(true));

    const response = await getFilters({
      idToken
    });

    // eslint-disable-next-line array-callback-return
    response.data.data.map((item: any) => {
      filters.push({
        ...item,
        isChecked: userFilters.find(
          (filter: any) => filter.id === item.id && filter.isChecked
        )
          ? true
          : false
      });
    });

    dispatch(saveUserFilters(filters));
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(saveUserFilters([]));
    dispatch(setLoading(false));
    handleExpiredToken(err, dispatch);
  }
};

export const saveFilterThunk = (info: any) => async (dispatch: any, getState: any) => {
  try {
    const { selectedFilters, searchModal } = getState().filter;
    const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
    );

    const response = await saveFilter({
      // userId,
      name: info.name,
      color: info.color,
      query: selectedFilters.join(","),
      tags: searchModal.tags.join(","),
      idToken
    });

    const query =
      selectedFilters.length === 0
        ? ""
        : ` and opportunityId in (${selectedFilters
          .map((item: any) => `"${item}"`)
          .join(",")})`;

    // dispatch(setSearchTags([]));
    dispatch(saveOpportunitiesThunk(query));

    dispatch(
      updateUserFilter({
        id: response.data.id,
        // userId: response.data.userId,
        name: info.name,
        tags: response.data.tags,
        color: response.data.color,
        query: selectedFilters.join(","),
        isChecked: true
      })
    );

    dispatch(
      toggleModalFilter({
        isOpen: false
      })
    );

    dispatch(
      toggleSearchModal({
        isOpen: false
      })
    );
  } catch (err) {
    handleExpiredToken(err, dispatch);
  }
};

export const deleteFilterThunk = (document: any, info?: any) => async (dispatch: any) => {
  try {
    const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
    );

    await deleteFilter({
      id: info.id,
      idToken
    });

    dispatch(
      deleteUserFilter({
        id: info.id
      })
    );

    dispatch(applyFilterThunk(document));
  } catch (err) {
    handleExpiredToken(err, dispatch);
  }
};

export const editFilterThunk = (info: any) => async (dispatch: any) => {
  try {
    const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
    );

    await editFilter({
      ...info,
      idToken
    });

    dispatch(updateUserFilter(info));
    dispatch(
      toggleModalFilter({
        isOpen: false
      })
    );
  } catch (err) {
    handleExpiredToken(err, dispatch);
  }
};

export const applyTemporaryFilterThunk = (info: any) => async (dispatch: any, getState: any) => {
  try {
    const { selectedFilters } = getState().filter;
    const {
      timeline: { type }
    } = getState().opportunities;

    const isLastYear = type === "last-year";

    dispatch(setLoading(true));

    const query =
      selectedFilters.length === 0
        ? ""
        : ` and opportunityId in (${selectedFilters
          .map((item: any) => `"${item}"`)
          .join(",")})`;

    dispatch(cleanUserFilters());
    dispatch(
      toggleSearchModal({
        isOpen: false
      })
    );

    if (!query) {
    }

    dispatch(saveOpportunitiesThunk(query, isLastYear));
  } catch (err) { }
};

export const createMatchFilterThunk = (info: any) => async (dispatch: any) => {
  try {
    const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
    );
    const filter = {
      from: info.from,
      // userId: userId,
      name: info.name,
      query: info.query,
      color: info.color,
      tags: info.tags
    };
    dispatch(createMatchFilter(filter));
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}${ENV_SUFFIX_MATCH}/filters`,
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      data: {
        from: info.from,
        // userId: userId,
        name: info.name,
        query: info.query.join(","),
        color: info.color,
        tags: info.tags.join(",")
      }
    });
  } catch (err) { }
};

export const editMatchFilterThunk = (info: any) => async (dispatch: any) => {
  try {
    const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
    );
    dispatch(editMatchFilter(info));
    return await axios({
      method: "patch",
      url: `${process.env.REACT_APP_API}${ENV_SUFFIX_MATCH}/filters`,
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      data: {
        ...info,
        query: info.query.join(","),
        tags: info.tags.join(",")
      }
    });
  } catch (err) { }
};

export const deleteMatchFilterThunk = (id: any) => async (dispatch: any) => {
  try {
    const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
    );
    dispatch(deleteMatchFilter(id));
    return await axios({
      method: "delete",
      url: `${process.env.REACT_APP_API}${ENV_SUFFIX_MATCH}/filters/${id}`,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
  } catch (err) { }
};

export const getMatchFilterThunk = () => async (dispatch: any) => {
  try {
    const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
    );
    let result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API}${ENV_SUFFIX_MATCH}/filters?from=match-relevancia`,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
    result = result.data.data.map((item: any) => ({
      ...item,
      query: item.query.split(","),
      tags: item.tags.split(",")
    }));
    dispatch(saveMatchFilters(result));
  } catch (err) { }
};

export const getNotificationThunk = () => async (dispatch: any) => {
  try {
    const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
    );
    let result = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API}${ENV_SUFFIX_MATCH}/notifications`,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
    dispatch(setNotification(result.data.data.enabled));
  } catch (err) { }
};

export const updateNotificationBellThunk = () => async (dispatch: any, getState: any) => {
  try {
    const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
    );
    getNotificationThunk();
    return await axios({
      method: "put",
      url: `${process.env.REACT_APP_API}${ENV_SUFFIX_MATCH}/notifications`,
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
  } catch (err) { }
};

export const handleMessageSupportThunk = (info: any) => async (dispatch: any) => {
  try {
    const { idToken } = JSON.parse(
      window?.localStorage?.getItem("petronect_credentials") || ""
    );
    dispatch(setSupport(info));
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_API}${ENV_SUFFIX_MATCH}/contact`,
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      data: info
    });
  } catch (err) { }
};
