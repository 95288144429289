import React, { FC } from "react";
import { TextDescription, Text,} from "../../../components/common-components/Accordion/styles";
import { Image } from "../styles";
import AnalyticalMatch01 from "../../../assets/img/faq/analyticalMatch2.png";
import { style } from "d3";


const AnalyticalMatch: FC = () => {
    return (
        <>
            <TextDescription>
                <Text>
                    {`Ferramenta para acesso de forma ágil às oportunidades de Dispensa no qual a empresa foi convidada. Aqui é possível verificar, na avaliação da oportunidade, uma leitura do fit que a oportunidade tem com o histórico de oportunidades da sua empresa. São listadas em quantidades de estrelas preenchidas que indicam qual pode ser mais relevante para o seu processo. `}
                </Text>
            </TextDescription>
            <TextDescription>
                <Text>
                    {`Você pode favoritar as oportunidades no ícone “coração” e aplicar o filtro “Exibir somente favoritos”.`}
                    </Text>
            </TextDescription>
            <TextDescription>
                <Text>
                    {`Também pode filtrar por palavras-chave e ordenar os campos de data fim, estrelas e quantidade de itens.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Text>
                    {`Também é possível exportar em CSV e/ou Excel.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={AnalyticalMatch01} alt="Imagem" />
            </TextDescription>
        </>
    )
}
export default AnalyticalMatch;