import styled from "styled-components";
import TagsInput from "react-tagsinput";
import { Link } from "react-router-dom";
import Colors from "../../assets/styles/colors";

interface StylesProps {
  isMobile?: boolean;
  color?: string;
  margin?: string;
  fontSize?: any;
  isSelected?: boolean;
  image?: string;
  mobile?: any;
  label?: any;
  weight?: any;
  width?: any;
  background?: any;
  rotate?: any;
  font?: any;
  hover?: any;
  inputs?: any;
  selected?: any;
  borderRadius?: any;
  menuOpen?: any;
  renderMobile?: any;
  cursor?: any;
  removeBottomBorder?:any;
  isButton?:any;
  marginRight?:any;
  checked?:any;
}

interface TimelineItemStylesProps{
  width: any;
  renderMobile?: any;
  color?: string;
  cursor?: any;
  isButton?:any;
  isSelected?: boolean;
  margin?: string;
  menuOpen?: any;
  mobile?: any;
}

const Container = styled.div`
  width: 100%;
  padding: 0px 1rem 107px;
  padding-top: 0px;
  overflow-y: auto;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.h2`
  margin: 0 0 0 1.5rem;
  font: Bold 1rem "Eurostile", "Open sans", sans-serif;
  line-height: 2rem;
  color: ${Colors.blue6};

  @media (max-width: 1200px) {
    margin: 0;
  }
`;

const FiltersContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

const FiltersBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 70%;
  align-items: center;
  font: 300 0.875rem "Eurostile", "Open Sans";
  letter-spacing: 0;
  color: ${Colors.blue6};
  z-index: 2;

  @media (max-width: 1200px) {
    max-width: 65%;
  }

  @media (max-width: 480px) {
    ${(props: StylesProps) =>
      props.isMobile &&
      `
			position: fixed;
			justify-content: flex-start;
			align-items: flex-start;
			top: 50px;
			left: 1vw;
			width: 98vw;
			height: 100%;
			padding: 0.5rem;
			max-width: none;
			background: ${Colors.white};
			border-radius: 4px 4px 0 0;
		`}
  }
`;

const FilterTitle = styled.span`
  display: inline-block;
  margin-right: 0.25rem;

  @media (max-width: 480px) {
    display: none;
  }
`;

const TagsInputContainer = styled.div`
  min-width: 250px;
  width: 80%;
  margin: 0 0.75rem;
  position: relative;

  @media (max-width: 1200px) {
    min-width: auto;
  }
`;

const Filter = styled(TagsInput)`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 0rem 0.5rem;
  background: ${Colors.white};
  box-shadow: 1px 1px 0 ${Colors.blue6}, -1px -1px 0 ${Colors.blue6},
    1px -1px 0 ${Colors.blue6}, -1px 1px 0 ${Colors.blue6};
  border-radius: ${(props: StylesProps) => props.borderRadius};

  @media (max-width: 480px) {
    display: ${(props: StylesProps) => (props.isMobile ? "flex" : "none")};
    width: 97vw;
    height: 42px;
    margin-left: -4vw;
    box-shadow: none;
    border: 1px solid ${Colors.blue6};
  }
`;

const FilterImageMobile = styled.img`
  display: none;

  @media (max-width: 480px) {
    position: absolute;
    display: ${(props: StylesProps) => (!props.isMobile ? "block" : "none")};
    top: 50%;
    right: 5px;
    width: auto;
    height: 34px;
    padding: 0.375rem;
    border: 0.5px solid ${Colors.blue6};
    border-radius: 50%;
    cursor: pointer;
    transform: translateY(-50%);
  }
`;

const CloseBox = styled.div`
  position: fixed;
  top: 0.5rem;
  right: 0.75rem;
  background: ${Colors.white};
`;

const CloseIcon = styled.img`
  width: 30px;
`;

const FilterImage = styled.img`
  position: absolute;
  top: 50%;
  right: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  transform: translateY(-50%);

  @media (max-width: 480px) {
    display: ${(props: StylesProps) => (props.isMobile ? "block" : "none")};
    right: -14vw;
  }
`;

const FilterSelector = styled.label`
  position: relative;
  display: flex;
  width: 20%;
  min-width: fit-content;
  height: 32px;
  margin-left: 0.5rem;
  padding: 0.375rem 1rem;
  cursor: pointer;
  background: ${(props: StylesProps) => props.background};
  color: ${(props: StylesProps) =>
    props.background === Colors.white ? Colors.blue6 : Colors.white};
  box-shadow: 1px 1px 0 ${Colors.blue6}, -1px -1px 0 ${Colors.blue6},
    1px -1px 0 ${Colors.blue6}, -1px 1px 0 ${Colors.blue6};
  border-radius: 16px;

  ${(props: StylesProps) =>
    props.removeBottomBorder &&
    `
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	`}

  @media (max-width: 480px) {
    display: ${(props: StylesProps) => (props.isMobile ? "none" : "flex")};
  }
`;

const FilterSelectorIcon = styled.img`
  position: absolute;
  ${"" /* top: 50%; */}
  right: 5%;
  margin-top: 0.2rem;
  width: 15px;
  height: 15px;
  ${"" /* transform: translateY(-50%); */}
  transition: transform 0.2s;
  transform: ${(props: StylesProps) => props.rotate && "rotate(180deg)"};
`;

const TimeLineContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem auto 2rem auto;

  @media (max-width: 1200px) {
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const Timeline = styled.div`
  position: relative;
  display: flex;
  width: ${(props: TimelineItemStylesProps) => props.width};
  height: 100%;
  margin: ${(props: TimelineItemStylesProps) => props.margin || "0 .25rem"};
  border: 1px solid ${Colors.gray1};
  border-radius: ${(props: TimelineItemStylesProps) =>
    props.menuOpen ? "8px 8px 0 0" : "8px"};
  font: 700 2rem sans-serif;
  background-color: ${Colors.white2};

  @media (max-width: 1200px) {
    width: calc(${(props: TimelineItemStylesProps) => props.width} - 1%);
    margin: 0;
    font-size: 1.375rem;
  }

  @media (max-width: 482px) {
    display: ${(props: TimelineItemStylesProps) => (props.mobile ? "flex" : "none")};
    width: ${(props: TimelineItemStylesProps) => props.mobile};
    margin-bottom: 0.25rem;
  }
`;

const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const SubtitleItem = styled.div`
  margin: 0px 8px;
  color: ${(props: StylesProps) => props.color};
  text-decoration: ${(props: StylesProps) =>
    !props.isSelected ? "line-through" : "none"};
`;

const TimelineItem = styled.div`
  display: ${(props: TimelineItemStylesProps) => (!props.renderMobile ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props: TimelineItemStylesProps) => props.width};
  height: 100%;
  padding: 0.5rem;
  color: ${(props: TimelineItemStylesProps) => props.color};
  cursor: ${(props: TimelineItemStylesProps) => props.cursor};
  &:hover {
    color: ${(props: TimelineItemStylesProps) =>
      props.isButton ? `${props.color}99` : null};
  }
  &:active {
    color: ${(props: TimelineItemStylesProps) =>
      props.isButton ? `${props.color}00` : null};
  }
  border: ${(props: TimelineItemStylesProps) => (props.isSelected ? "solid 1px" : "none")};
  border-radius: 8px;

  div {
    display: flex;
  }
  img {
    width: 1rem;
    margin-left: 0.5ch;
  }

  @media (max-width: 480px) {
    display: ${(props: TimelineItemStylesProps) => props.renderMobile && "flex"};
  }
`;

const TimelineItemText = styled.span`
  text-align: center;
  color: ${(props: StylesProps) => props.color};
  font: 400 0.8rem sans-serif;

  @media (max-width: 1200px) {
    font-size: 0.625rem;
  }
`;

const WrapperKeyword = styled.div`
  position: absolute;
  top: 100%;
  left: -1px;
  width: calc(100% + 2px);
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: ${Colors.white};
  border: solid ${Colors.blue6};
  border-width: 0 0.5px 0.5px 0.5px;
  align-items: flex-end;
  z-index: 2;

  @media (max-width: 480px) {
    width: 97vw;
    margin-left: calc(-4vw + 1px);
  }
`;

const WrapperOpportunities = styled.div`
  width: 100%;
  max-height: 450px;
`;

const TextNull = styled.p`
  padding-bottom: 1rem;
  font-size: 0.85rem;
  color: ${Colors.blue8};
`;

const BtnFilter = styled.button`
  margin: 0.25rem 0;
  display: flex;
  justify-content: center;
  width: 95%;
  height: 33px;
  background: ${(props: StylesProps) =>
    props.background ? Colors.blue8 : Colors.white};
  border-radius: 4px;
  box-shadow: 0px 3px 6px ${Colors.black}29;
  opacity: 1;
  font: 700 0.875rem Eurostile;
  color: ${(props: StylesProps) => (props.color ? Colors.blue6 : Colors.white)};
  border: ${(props: StylesProps) =>
    props.color ? `1px solid ${Colors.blue6}` : "none"};
  cursor: pointer;
  @media (max-width: 648px) {
    width: 100%;
  }
`;

const IconBtn = styled.img`
  width: ${(props: StylesProps) => (props.width ? "15px" : "18px")};
  margin-right: 0.35rem;
  cursor: pointer;
`;

const LabelBox = styled.label`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: calc(100% + -1px);
  height: ${(props: StylesProps) => (props.label ? "2rem" : "2.5rem")};
  border-radius: 2px;
  font: 1rem "Eurostile", "Open sans", sans-serif;
  font-weight: ${(props: StylesProps) => (props.weight ? "700" : "300")};
  color: ${(props: StylesProps) => props.color};
  ${(props: StylesProps) =>
    !props.background &&
    `
		background: ${Colors.white2};
		border-radius: 4px;
	`}
  :hover {
    background: ${(props: StylesProps) => props.hover};
  }
  @media (max-width: 480px) {
    display: ${(props: StylesProps) => props.label && "none"};
  }
`;

const Label = styled.span`
  display: inline-block;
  margin-right: 0.75rem;
  color: ${Colors.gray8};
  font: 300 0.9rem "Open Sans", sans-serif;
`;

const WrapLine = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  width: 100%;
`;

const Info = styled.p`
  width: ${(props: StylesProps) => props.width};
  margin: 0;
  font-size: ${(props: StylesProps) => props.fontSize};
  font-family: ${(props: StylesProps) =>
    props.font ? "Eurostile" : "Open Sans"};
  color: ${(props: StylesProps) => props.color};

  @media (max-width: 1200px) {
    font-size: 0.625rem;
  }

  @media (max-width: 480px) {
    display: ${(props: StylesProps) => props.label && "none"};
    width: 100%;
  }
`;

const InfoWrap = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props: StylesProps) => props.width};
  margin: 0;
  font-size: ${(props: StylesProps) => props.fontSize};
  font-family: ${(props: StylesProps) =>
    props.font ? "Eurostile" : "Open Sans"};
  color: ${(props: StylesProps) => props.color};

  @media (max-width: 1200px) {
    font-size: 0.625rem;
  }

  @media (max-width: 480px) {
    display: ${(props: StylesProps) => props.label && "none"};
    width: 100%;
  }
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  margin-left: 0.5rem;
  margin-right: ${(props: StylesProps) => props.marginRight}px;
  width: 13px;
  height: 13px;
  display: flex;
  cursor: pointer;
  background: ${(props: StylesProps) =>
    props.checked ? Colors.blue8 : Colors.white};
  border-radius: 3px;
  border: 0.5px solid ${Colors.gray6};
`;

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
`;

const Dropdown = styled.figure`
  margin: 0;
  padding: 0;
`;

const SmallMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  left: -1px;
  width: calc(100% + 2px);
  border-radius: 0 0 8px 8px;
  font: 500 0.875rem "Open Sans";
  box-shadow: 0px 3px 6px ${Colors.black}29;
  z-index: 1;
`;

const SmallMenuItem = styled.span`
  text-align: center;
  line-height: 1.375rem;
  background: ${(props: StylesProps) =>
    props.selected ? Colors.blue9 : Colors.white};
  border: 0.5px solid ${Colors.gray1};
  border-bottom: none;
  cursor: pointer;
  &:last-child {
    border-bottom: 0.5px solid ${Colors.gray1};
    border-radius: 0 0 8px 8px;
  }
  &:hover {
    background: ${(props: StylesProps) => !props.selected && Colors.white2};
  }
`;

const GraphSwitch = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  margin-top: 0.5rem;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Type = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
  cursor: pointer;
  color: ${Colors.gray8};
  font: 300 0.9rem "Open Sans", sans-serif;
  ${(props: StylesProps) =>
    props.isSelected &&
    `
		padding: .25rem .5rem;
		border-radius: 10px;
		color: ${Colors.blue8};
		font: 600 .9rem 'Open Sans', sans-serif;
    background: ${Colors.white2};
  `}
`;

const OverlayMenu = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 1;
`;

const RangeContainer = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  margin: 0.5rem 0 0.5rem auto;
  flex-flow: row wrap;
`;

const RangeLimts = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(props: StylesProps) => (props.inputs ? 0 : "1rem")};
  justify-content: ${(props: StylesProps) =>
    props.inputs ? "flex-end" : "flex-start"};

  @media screen and (max-width: 480px) {
    justify-content: flex-start;
    width: 100%;
    margin: 0.5rem 0;
    flex-flow: row wrap;
    justify-content: ${(props: StylesProps) =>
      props.inputs ? "space-between" : "flex-start"};
  }
`;

const RangeLabel = styled.span`
  display: inline-block;
  margin: 0 0.25rem;
  color: ${Colors.gray6};
  font: 400 0.9rem "Open Sans", sans-serif;
`;

const RangeValue = styled.span`
  display: inline-block;
  margin: 0 0.25rem;
  color: ${Colors.blue6};
  font: 700 0.9rem "Open Sans", sans-serif;
`;

const ApplyFilterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  padding: 0 0.5rem;
  border: 1px solid ${Colors.blue6};
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  color: ${Colors.white};
  font: 400 0.8rem "Open Sans", sans-serif;
  background: ${Colors.blue6};

  @media screen and (max-width: 480px) {
    width: calc(100% - 60px);
    height: 45px;
    margin-left: 0.25rem;
  }
`;

const ResetFilterButton = styled.button`
  position: relative;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  margin-left: 0.25rem;
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  background: ${Colors.red1};

  svg {
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 25%;
    left: 25%;
  }

  @media screen and (max-width: 480px) {
    width: 45px;
    height: 45px;
    margin-right: 0.25rem;
  }
  svg {
    top: 30%;
    left: 30%;
  }
`;

const CoefficientContainer = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  margin: 0.5rem 0 0.5rem auto;
  flex-flow: row wrap;
`;

const HelpContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const HelpButton = styled(Link)`
  width: 20px;
  height: 20px;
  background: none;
  background-image: url(${(props: StylesProps) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  cursor: pointer;
`;

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 68px 30px 30px 30px;
  overflow: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    overflow-x: hidden;
  }
`;

export {
  Container,
  Header,
  Title,
  FiltersContainer,
  FiltersBox,
  FilterTitle,
  TagsInputContainer,
  Filter,
  FilterImageMobile,
  CloseBox,
  CloseIcon,
  FilterImage,
  FilterSelector,
  FilterSelectorIcon,
  TimeLineContainer,
  RangeValue,
  Timeline,
  Subtitle,
  SubtitleItem,
  TimelineItem,
  TimelineItemText,
  WrapperKeyword,
  WrapperOpportunities,
  TextNull,
  BtnFilter,
  IconBtn,
  LabelBox,
  Label,
  WrapLine,
  Info,
  InfoWrap,
  HiddenCheckbox,
  Overlay,
  Dropdown,
  SmallMenu,
  SmallMenuItem,
  GraphSwitch,
  SwitchContainer,
  Type,
  OverlayMenu,
  RangeContainer,
  RangeLimts,
  RangeLabel,
  ApplyFilterButton,
  ResetFilterButton,
  CoefficientContainer,
  HelpContainer,
  HelpButton,
  Loading,
  ScreenContainer,
};
