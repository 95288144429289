import styled from "styled-components";
import Colors from "../../assets/styles/colors";

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px;
  padding-top: 0px;
`;

const SectionContent = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 100px;
`;

const ContainerCheckboxDropDown = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 6px 0;

  @media (max-width: 640px) {
      flex-direction: column;
      align-items: flex-start;
  }
`;

const InputTitle = styled.p`
  margin: 0.5rem;
  font: 400 1rem Eurostile;
  letter-spacing: 0.5px;
  color: ${Colors.blue6};

  @media (max-width: 640px) {
    display: none;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 6px 0;
`;

const ContainerDropDown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 250px;
  padding: 0.5rem;
  border: 0.5px solid ${Colors.blue6};
  border-top: none;
  border-radius: 0 0 4px 4px;
  background: ${Colors.white};
  overflow: hidden;
  z-index: 5;

  @media (max-width: 768px) {
    left: 0.25rem;
    width: 250px;
  }

  @media (max-width: 590px) {
    right: 0;
  }
`;

const ReverseIcon = styled.img`
  width: 17px;
  margin-top: 0px;
  margin-left: 0.25rem;
`;

const ResetFilterIcon = styled.img`
  width: 17px;
  margin: 0.2rem 0.25rem;
  cursor: pointer;
`;

const ResetFilterButton = styled.button`
  color: ${Colors.red1};
  background: ${Colors.red1};
  border: 1px solid ${Colors.red};
  border-radius: 25px;
  padding: 4px;
  margin: 0;
  opacity: .8;

  &:hover,
  &:focus {
    opacity: 1;
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export {
  SectionContainer,
  SectionContent,
  ContainerCheckboxDropDown,
  InputTitle,
  ContainerDropDown,
  FlexWrapper,
  ReverseIcon,
  ResetFilterButton,
  ResetFilterIcon,
  FlexRow
};