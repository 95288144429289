import styled from "styled-components";
import Colors from "../../../assets/styles/colors";

const Title = styled.p`
  font-size: 16px;
  color: ${Colors.blue6};
  margin-bottom: 20px;
`;
const Description = styled.p`
  font-size: 12px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0.25rem 0px;
  color: ${Colors.gray6};
`;

const ContainerOpacity = styled.div`
  position: absolute;
  z-index: 1;
  top: 0px;
  background-color: rgba(16, 16, 16, 0.7);
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalBox = styled.div`
  padding: 30px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${Colors.white};
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  margin-left: 20px;
  margin-right: 20px;
`;

const ActionButton = styled.button`
  background: ${Colors.blue6};
  color: ${Colors.white};
  font-size: 12px;
  border: none;
  border-radius: 4px;
  height: 32px;
  width: 50%;
  margin-top: 20px;
  cursor: pointer;

  :active {
    opacity: 0.5;
  }
  :focus {
    outline: none;
  }
`;
export { Title, Description, ContainerOpacity, ModalBox, ActionButton };
