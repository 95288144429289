import React, { FC } from "react";
import { TextDescription, Text,} from "../../../components/common-components/Accordion/styles";
import { Image } from "../styles";
import BubbleChart01 from "../../../assets/img/faq/bubbleChart1.png";

const BubbleChart: FC = () => {
    return (
        <>
            <TextDescription>
                <Text>
                    {`O gráfico de bolhas apresenta o histórico de oportunidades com visões por tipo de resultado.`}
                </Text>
            </TextDescription>
            <TextDescription>
                <Image src={BubbleChart01} alt="Imagem" />
            </TextDescription>
        </>
    )
}
export default BubbleChart;