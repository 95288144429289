import React, { FC, useState } from "react";
import Delete from "../../../../assets/icons/common/delete-icon.svg";
import Index from "../SearchCell";
import { Box, Header, Title, ButtonBox, ButtonLink, CellBox, ButtonRedirecQuery } from "./styles";



const changeOpenState = (isOpen: any, setIsOpen: any) => {
  setIsOpen(!isOpen);
};

interface NotificationFilterProps {
  background?: any;
  storybook?: any;
  name?: any;
  screen?: any;
  handleDelete?: () => void;
  handleSearch?: () => void;
  tags?: any;
  handleEdit?:()=>void;
  updateId?:any;
  filter?:any;
}

const NotificationFilter: FC<NotificationFilterProps> = ({
  background,
  storybook,
  name,
  screen,
  handleDelete,
  handleSearch,
  tags,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box background={background}>
      <Header
        storybook={storybook}
        background={background}
      >
        <Title>{name}</Title>
        
        <ButtonRedirecQuery to={screen} onClick={handleSearch}>
          {tags.map((tag: any) => {
            if (tag !== "") {
              return (
                <span style={{margin:".5rem 0"}}  key={tag}>
                  <Index background="#FFFFFF">
                    {tag}
                  </Index>
                </span>
              );
            } else return null;
          })}
        </ButtonRedirecQuery>
   
        <ButtonBox>
          <ButtonLink
            to={screen}
            onClick={() => {
              changeOpenState(isOpen, setIsOpen);
              handleDelete && handleDelete();
            }}
          >
            <img src={Delete} alt="delete icon"></img>
          </ButtonLink>
        </ButtonBox>

        
      </Header>
      
    </Box>
  );
};

export default NotificationFilter;
