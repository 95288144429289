import React from "react";
import { StatusBar, Card, CardContent, FlexColumn, Text, ButtonGroup, Button } from "./styles";
import { handleMoneyWhenReceiving } from "../../util/functions";
import { handleDate } from "../../../../util/format-value";

const ProposalCardDescription = ({ currentItem, decline, callbackShowProposalCardForm, callbackSendDecline }: any) => {
  const { 
    statusColor, 
    NumRequisicao, 
    Descricao, 
    DescricaoDetalhada, 
    Quantidade,
    UnidadeMedida, 
    Fabricante,
    NP,
    LocalEntrega,
    VencimentoRequisicao,
    ValorPropostaVencedora
  } = currentItem;

  return (
    <Card flexDirection="row">
      {currentItem && (<>
        <StatusBar color={statusColor} />
        <CardContent>
          <FlexColumn gapColumn="20px">
            <Text>Nº da requisição: {NumRequisicao || "-"}</Text>

            <div>
              <Text>Descrição: {Descricao || "-"}</Text>
              <Text>Detalhamento: {DescricaoDetalhada || "-"}</Text>
              <Text>Quantidade: {Quantidade || "-"}</Text>
              <Text>Unidade: {UnidadeMedida || "-"}</Text>
              <Text>Fabricante: {Fabricante || "-"}</Text>
              <Text>PartNumber: {NP || "-"}</Text>
              <Text>Local de entrega: {LocalEntrega || "-"}</Text>
              <Text>
                Data limite para apresentação de proposta: {VencimentoRequisicao ? handleDate(VencimentoRequisicao) : "-"}
              </Text>
              <Text style={{ color: "red", fontWeight: "bold" }}>
                Melhor preço: {ValorPropostaVencedora ? 
                  `R$ ${handleMoneyWhenReceiving(String(ValorPropostaVencedora))}` : "R$ 0,00"}
              </Text>
            </div>
          </FlexColumn>

          <ButtonGroup>
            <Button onClick={callbackShowProposalCardForm}>Proposta</Button>
            <Button onClick={callbackSendDecline} isDisabled={decline}>Declinar</Button>
          </ButtonGroup>
        </CardContent>
      </>)}
    </Card>
  );
}

export default ProposalCardDescription;