import React, { FC } from "react";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import {
  RangeValue,
  RangeContainer,
  RangeLimts,
  RangeLabel,
  ApplyFilterButton,
  ResetFilterButton,
} from "../../../../pages/MyPerformance/styles";
import MaskedInput from "react-text-mask";
import { formatValue } from "../../../../util/format-value";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/common/close-white.svg";
import Colors from "../../../../assets/styles/colors";
import { eventCategory, logEvent } from "../../../../util/analytics/index";

interface RangeProps {
  currentCurrency: any;
  filterRange: any;
  selectedGraph: any;
  filterRangeErrors: any;
  mediaMatch: any;
  min: any;
  max: any
  setFilterRange: ({}) => void;
  handleApplyFilter: () => void;
  handleCleanFilter: () => void;
}

const Range: FC<RangeProps> = ({
  currentCurrency,
  filterRange,
  selectedGraph,
  filterRangeErrors,
  mediaMatch,
  min,
  max,
  setFilterRange,
  handleApplyFilter,
  handleCleanFilter
}) => {

  const defaultMaskOptions = {
    prefix: currentCurrency?.cipher,
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ".",
    allowDecimal: false,
    decimalSymbol: ".",
    decimalLimit: 0, // how many digits allowed after the decimal
    integerLimit: 12, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const minCurrencyMask = createNumberMask({
    ...defaultMaskOptions,
  });

  const maxCurrencyMask = createNumberMask({
    ...defaultMaskOptions,
  });

  const styles = {
    width: mediaMatch?.matches ? "100%" : "30%",
    maxWidth: mediaMatch?.matches ? "100%" : "125px",
    margin: mediaMatch?.matches ? "0.5rem .25rem" : "0 .25rem",
    height: mediaMatch?.matches ? "45px" : "auto",
    padding: "3px",
    borderRadius: "10px",
    outline: "none",
    color: Colors.blue6,
    font: '400 .9rem "Open Sans", sans-serif',
  };

  if (selectedGraph === "bubbles") {
    return (
      <RangeContainer>
        <RangeLimts>
          <RangeLabel>Min</RangeLabel>
          <RangeValue>{formatValue(min)}</RangeValue>
          <RangeLabel>Max</RangeLabel>
          <RangeValue>{formatValue(max)}</RangeValue>
        </RangeLimts>
        <RangeLimts inputs>
          <RangeLabel>Personalizado:</RangeLabel>
          <MaskedInput
            style={{
              ...styles,
              border: filterRangeErrors?.minValue
                ? `1px solid ${Colors.red}`
                : `1px solid ${Colors.blue6}`,
            }}
            mask={minCurrencyMask}
            placeholder={`${currentCurrency?.cipher}0.00`}
            value={parseInt(filterRange?.minValue, 10)}
            onChange={(ev: any) => {
              if (ev?.target?.value) {
                setFilterRange({
                  ...filterRange,
                  minValue: Number(ev?.target?.value?.replace(/\D+/g, "")),
                });
              }
            }}
          />
          <MaskedInput
            style={{
              ...styles,
              border: filterRangeErrors?.maxValue
                ? `1px solid ${Colors.red}`
                : `1px solid ${Colors.blue6}`,
            }}
            mask={maxCurrencyMask}
            placeholder={`${currentCurrency?.cipher}0.00`}
            value={parseInt(filterRange?.maxValue, 10)}
            onChange={(ev: any) => {
              if (ev.target.value) {
                setFilterRange({
                  ...filterRange,
                  maxValue: Number(ev?.target?.value?.replace(/\D+/g, "")),
                });
              }
            }}
          />
          <ApplyFilterButton
            onClickCapture={() => {
              logEvent(
                eventCategory.buttonClick,
                "click aplicar filtro no gráfico de oportunidades",
                "Aplicar"
              );
            }}
            onClick={handleApplyFilter}
          >
            Aplicar
          </ApplyFilterButton>
          <ResetFilterButton
            onClickCapture={() => {
              logEvent(
                eventCategory.buttonClick,
                "click limpar filtro no gráfico de oportunidades",
                "CloseIcon"
              );
            }}
            onClick={handleCleanFilter}
          >
            <CloseIcon />
          </ResetFilterButton>
        </RangeLimts>
      </RangeContainer>
    );
  }
  return null;
};

export default Range;
