import styled from "styled-components";
import { Link } from "react-router-dom";
import Colors from "../../../../assets/styles/colors";

interface StylesProps {
    background?: any;
    storybook?: any;
  }
  
export const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    border-radius: 8px;
    border: solid 0.5px ${(props: StylesProps) => props.background};
    background: ${(props: StylesProps) => props.background};
    padding: .5rem;
    margin: .5rem 0;

    &:last-child {
    margin-bottom: 4rem;
  }
  `;
  
export const Header = styled.div`
    display: flex;
    align-items: center;
    width: ${(props: StylesProps) => (props.storybook ? "calc(100% - 1rem)" : "100%")};
    
    border-radius: 8px;
    
  `;
  
export const Title = styled.p`
    font: 600 1rem Eurostile;
    color: ${Colors.white};
    margin-top: 10px;
    width: 30%;
    word-break: break-all;
  `;
  
export const ButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 10%;
  `;
  
export const CellBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-left: 1rem;
  `;
  
export const ButtonLink = styled(Link)`
    display: flex;
    text-decoration: none;
    background: transparent;
    cursor: pointer;
  `;
  
export const ButtonRedirecQuery = styled(Link)`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    text-decoration: none;
    background: transparent;
    cursor: pointer;
    width: 60%;
  `;

  
