import styled from "styled-components";
import Colors from "../../../../assets/styles/colors";


interface StylesProps {
  label?:any;
  weight?:any;
  width?:any;
  text?:string;
  background?:any;
  rotate?:any
  font?:any;
  hover?:any;
  header?:any;
  hideOnMobile?:any;
  mobileFull?:any;

}

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 0.5rem 0.5rem 0.25rem;
`;

const WrapHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  margin: 1rem 0 1rem 1rem;
  letter-spacing: 0;
  color: ${Colors.blue6};
  font: 700 1rem "Eurostile", "Open sans", sans-serif;

  @media (max-width: 1200px) {
    margin: 0;
  }

  @media screen and (max-width: 480px) {
    margin: 0.8rem 0;
  }
`;

const WrapDownoad = styled.span`
  display: flex;
  cursor: pointer;

  img {
    margin-left: 0.45rem;
  }
  a {
    display: none;
  }
`;

const Label = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
  height: ${(props: StylesProps) => (props.label ? "2rem" : "2.5rem")};
  border-radius: 2px;
  font: 1rem "Eurostile", "Open sans", sans-serif;
  font-weight: ${(props: StylesProps) => (props.weight ? "700" : "300")};
  color: ${(props) => props.color};

  ${(props: StylesProps) =>
    !props.background &&
    `
		background: ${Colors.white2};
		border-radius: 4px;
	`}

  :hover {
    background: ${(props: StylesProps) => props.hover};
  }

  @media (max-width: 480px) {
    display: ${(props: StylesProps) => props.label && "none"};
    ${(props: StylesProps) => (!props.header ? "height: 80px;" : "")}
  }
`;

const WrapLine = styled.span`
  display: flex;
  width: 100%;

  @media screen and (max-width: 480px) {
    height: 100%;
    padding-left: 2%;
  }
`;

const LineContainer = styled.span`
  display: flex;
  flex-flow: row wrap;
  width: 97%;
  height: 100%;
  overflow: "auto";
`;

const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3%;
  min-width: 1.5rem;
  height: 100%;

  @media (max-width: 480px) {
    display: ${(props: StylesProps) => props.label && "none"};
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 2%;
  }
`;

const Info = styled.p`
  display: flex;
  justify-content: ${(props: StylesProps) => props.text || "flex-start"};
  width: ${(props: StylesProps) => props.width};
  margin: 0;
  font-size: 0.875rem;
  font-weight: ${(props: StylesProps) => (props.weight ? "600" : "400")};
  font-family: ${(props: StylesProps) => props.font ? "Eurostile" : "Open Sans"};
  color: ${(props) => props.color};
  white-space: nowrap;
  overflow: auto;
  text-overflow: ellipsis;
  cursor: pointer;

  @media (max-width: 1200px) {
    font-size: 0.75rem;
  };

  @media (max-width: 480px) {
    display: ${(props: StylesProps) =>
      (props.hideOnMobile && "none") || (props.mobileFull && "block")};
    width: ${(props: StylesProps) => (props.mobileFull ? "50%" : "25%")};
    line-height: 40px;
    justify-content: flex-start;
    padding-left: 2%;
    ${(props: StylesProps) => (props.mobileFull ? "padding-right: 2%;" : "")}
  };
`;

const Dropdown = styled.figure`
  position: absolute;
  right: 2ch;
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;

  img {
    transition: transform 0.2s;
    transform: ${(props: StylesProps) => props.rotate && "rotate(180deg)"};
  }

  @media (max-width: 1200px) {
    right: -2ch;
  }

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

const ItemsContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  margin-right: -5px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background: ${Colors.gray1};
  }
`;

const ButtonExportData = styled.button`
  display: flex;
  justify-content: center;
  color: white;
  background-color: ${Colors.blue6};
  box-shadow: 0px 3px 6px ${Colors.black}29;
  border: none;
  border-radius: 5px;
  text-align: center;
  height: fit-content;
  padding: 5px 2px;
  font-size: 12px;
  cursor: pointer;
  transition: 0.1s linear;
  text-decoration: none;

  &:hover {
    font-weight: 600;
    background-color: ${Colors.blue6}cc;
    box-shadow: 0 3px 15px -10px ${Colors.black};
  }

  span {
    font-weight: bold;
  }

  svg {
    margin: auto 0px;
    margin-left: 5px;
    * {
      fill: white;
    }
  }
`;

const FlexDiv = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 65% 15% 15%;
  grid-column-gap: 10px;
  align-items: baseline;
  @media (max-width: 500px) {
    grid-template-columns: 45% 25% 25%;
  }
`;

const Hr = styled.hr`
  background-color: ${Colors.gray2};
  height: 1px;
  border: none;
`;

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15px;
`

const ItemsPerPageSelect = styled.select`
  border-radius: 5px;
  color: ${Colors.blue6};
  border: 1px solid ${Colors.blue6};
`;

const ItemsPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p{
    margin: 0 10px 0 0;
    font-size: 0.875rem;
    color: ${Colors.blue6};
    font-weight: ${(props: StylesProps) => (props.weight ? "600" : "400")};
    font-family: ${(props: StylesProps) => props.font ? "Eurostile" : "Open Sans"};
  }

  span {
    margin: 0 0 0 10px;
    font-size: 0.875rem;
    color: ${Colors.blue6};
    font-weight: ${(props: StylesProps) => (props.weight ? "600" : "400")};
    font-family: ${(props: StylesProps) => props.font ? "Eurostile" : "Open Sans"};
  }

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
  }
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export {
  Container,
  WrapHeader,
  Title,
  WrapDownoad,
  Label,
  WrapLine,
  LineContainer,
  Icon,
  Info,
  Dropdown,
  ItemsContainer,
  ButtonExportData,
  FlexDiv,
  Hr,
  PaginationContainer,
  ItemsPerPageSelect,
  ItemsPagination,
  PaginationWrapper
};
