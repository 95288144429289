import styled, { css } from "styled-components";
import menuHamburgerBlue from "../../../assets/icons/common/menu_hamburger_blue.svg";
import menuHamburger from "../../../assets/icons/common/menu_hamburguer.svg";
import logoMobile from "../../../assets/img/logos/logo-MPNE.png";
import LogoImg from "../../../assets/img/logos/LogoPNE.png";
import { Link } from "react-router-dom";
import Colors from "../../../assets/styles/colors";

interface StylesProps{
  isOpen?:any;
  disable?:any;
  selected?:any;
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 19%;
  height: 100%;
  background: ${Colors.white};
  border-right: rgba(0, 0, 0, 0.1) solid 1px;
  border-radius: 4px 0 0 0;
  box-shadow: 2px 0px 4px ${Colors.black}1a;
  z-index: 1;
  overflow: auto;

  @media (max-width: 1600px) {
    width: 20%;
  }

  @media (max-width: 1200px) {
    position: absolute;
    display: ${(props: StylesProps) => (props.isOpen ? "flex" : "none")};
    left: 0;
    bottom: 0;
    width: 50vw;
    padding-top: 5rem;
    z-index: 3;
    border-radius: 0;
  }

  @media (max-width: 480px) {
    display: ${(props: StylesProps) => (props.isOpen ? "flex" : "none")};
    width: 85vw;
  }
`;

const NavBar = styled.div`
  width: 100%;

  @media (max-width: 375px) {
    width: 100%;
    height: 100%;
  }
`;

const BoxLogo = styled.div`
  display: ${(props: StylesProps) => (!props.isOpen ? "flex" : "none")};
  justify-content: center;
  width: 80%;
  margin: 0rem auto;
`;

const ImgLogo = styled.img`
  width: 80%;
`;

const HeaderMobile = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  width: calc(100% + 1rem);
  height: 5rem;
  margin: 0 -0.5rem 0.2rem -0.5rem;
  justify-content: space-between;

  @media screen and (min-width: 1201px) {
    display: none;
  }

  @media screen and (max-width: 490px) {
    height: 4rem;
    background-color: ${Colors.white};
    align-items: center;
  }
`;

const Username = styled.p`
  @media screen and (min-width: 481px) {
    display: none;
  }
  color: ${Colors.blue8};
  margin-right: 1rem;
`;

const WrapperTablet = styled.div`
  position: fixed;
  display: flex;
  top: 1.5rem;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex-direction: ${(props: StylesProps) => (props.isOpen ? "row" : "column")};
  width: auto;
  padding: ${(props: StylesProps) =>
    props.isOpen ? "1rem 0.7rem 1rem" : "0 0.7rem 1rem"};
  cursor: pointer;
  z-index: 4;

  @media screen and (max-width: 480px) {
    top: 1.35rem;
    padding: ${(props: StylesProps) =>
      props.isOpen ? "1rem 1.75rem 1rem" : "0 0.7rem 1rem"};
  }
`;

const ImageMenuTablet = styled.img`
  display: flex;
  content: url(${(props: StylesProps) => props.isOpen ? menuHamburgerBlue : menuHamburger});
  @media screen and (max-width: 480px) {
    content: url(${menuHamburgerBlue});
  }
`;

const MenuTabletText = styled.p`
  margin: ${(props: StylesProps) =>
    props.isOpen ? "0 0.5rem" : "0.5rem auto"};
  font-size: 0.85rem;
  color: ${(props: StylesProps) =>
    props.isOpen ? Colors.blue8 : Colors.white};
  text-transform: uppercase;

  @media screen and (max-width: 480px) {
    display: ${(props: StylesProps) => (props.isOpen ? "initial" : "none")};
  }
`;

const WrapperLogoMobile = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;

  @media screen and (max-width: 480px) {
    width: 100%;
    padding-left: 3rem;
    justify-content: center;
  }
`;

const LogoMobile = styled.img`
  display: none;
  max-height: 100%;

  @media (max-width: 1200px) {
    display: flex;
    content: url(${logoMobile});
    padding: 15px;
  }

  @media (max-width: 480px) {
    content: url(${LogoImg});
    padding: 0;
  }
`;

const BoxMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const MenuList = styled.ul`
  width: 96%;
  list-style: none;
  padding: 1rem 0;
  margin-bottom: 0px;
`;

const IconSideBar = styled.img`
  width: 24px;
  height: 24px;
  padding-right: 0.5rem;
  z-index: 2;
`;

const MenuItem = styled(Link)`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.6rem 1rem;
  font-size: 0.9rem;
  color: ${Colors.gray8}35;
  background: transparent;
  pointer-events: ${(props: StylesProps) => props.disable && "none"};
  text-decoration: none;
`;

const WrapperInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-bottom: 1rem;
  padding-top: 0.25rem;
  border-top: solid ${Colors.gray9} 1px;
  flex-direction: column;
`;

const Contentinfo = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextVercao = styled.p`
  color: ${Colors.green1};
  font: 400 1rem "Eurostile", "Open Sans", sans serif;
  margin: 0;
`;

const TextVersionNumber = styled.p`
  font: 400 0.8rem "Eurostile", "Open Sans", sans serif;
  color: ${Colors.gray8};
`;

const BoxInfo = styled.div`
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Representative = styled.p`
  font: 700 0.8rem "Open Sans", sans-serif;
  color: ${Colors.gray8};
  font-weight: 800;
`;

const Button = styled.button`
  width: 75px;
  height: 25px;
  background: ${Colors.blue1};
  border-radius: 10px;
  border-style: none;
  color: ${Colors.blue6};
  cursor: pointer;
  font: 400 0.8rem "Open Sans", sans-serif;
`;

const Overlay = styled.div`
  display: none;
  @media (max-width: 1200px) {
    position: absolute;
    display: ${(props: StylesProps) => (props.isOpen ? "flex" : "none")};
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${Colors.black}10;
    z-index: 3;
  }
`;

const OptionSupport = styled.span`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  padding-left: 1rem;
  font-family: Eurostile;
  color: ${Colors.blue6};
  border-radius: ${(props: StylesProps) =>
    props.isOpen ? "4px 0 0 0" : "4px 0 0 4px"};
  background: ${(props: StylesProps) => props.isOpen && `${Colors.blue6}20`};
  cursor: pointer;
  font-weight: ${(props: StylesProps) => (props.isOpen ? "600" : "400")};

  :hover {
    background: ${Colors.blue6}20;
    font-weight: bold;
  }

  img {
    width: 15px;
    margin-right: 0.6rem;
  }
`;

const UserInfo = styled.div`
  padding-left: 24px;
  button {
    background: none;
    border: none;
    cursor: pointer;
    font: normal 0.8rem "Open Sans", sans-serif;
    color: ${Colors.blue6};
  }
  ul {
    list-style: none;
    max-height: 15vh;
    overflow-y: scroll;
  }
`;

const WelcomeText = styled.div`
  color: ${Colors.blue6};
  font-size: 0.8rem;
  display: block;
  padding-left: 6px;
  margin-top: 4px;
  padding-right: 10px;
`;

const CurrentProfile = styled.span`
  color: ${Colors.blue6};
  font-size: 0.8rem;
  display: block;
  padding-left: 6px;
  margin: 4px 0 8px;
  font-weight: bold;
  cursor: pointer;
`;

const ProfilesList = styled.ul`
  border-top: 1px solid rgba(2, 105, 146, 0.3);
  border-bottom: 1px solid rgba(2, 105, 146, 0.3);
  padding: 5px 10px 10px 0;
  margin-right: 30px;
  @media (max-width: 1300px) {
    margin-right: 0px;
  }

  li {
    margin-top: 4px;
  }
`;
const ProfilesListItem = styled.li`
  margin-top: 4px;
  ${(props: StylesProps) =>
    props.selected &&
    css`
      span {
        font-weight: bold;
      }
    `}
`;

const RadioProfilesListItem = styled.button`
padding-left: 0px;
padding-right: 0px;
`

export {
  Container,
  NavBar,
  BoxLogo,
  ImgLogo,
  HeaderMobile,
  Username,
  WrapperTablet,
  ImageMenuTablet,
  MenuTabletText,
  WrapperLogoMobile,
  LogoMobile,
  BoxMenu,
  MenuList,
  IconSideBar,
  MenuItem,
  WrapperInfo,
  Contentinfo,
  TextVercao,
  TextVersionNumber,
  BoxInfo,
  Representative,
  Button,
  Overlay,
  OptionSupport,
  UserInfo,
  WelcomeText,
  CurrentProfile,
  ProfilesList,
  ProfilesListItem,
  RadioProfilesListItem
};
