import styled from "styled-components";
import Colors from "../../assets/styles/colors";
import { StylesProps } from "../../util/types";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  overflow-y: scroll;

  @media (max-width: 768px) {
    flex-direction: column;
    overflow-x: hidden;
  }
`;

const TitleCount = styled.p`
  width: ${(props:StylesProps) => !props.name && "auto"};
  margin: 0.8rem 1.3rem;
  font-size: ${(props:StylesProps) => (props.name ? ".75rem" : "0.85rem")};
  padding: ${(props:StylesProps) => props.name && ".2rem 0"};
  color: ${(props) => Colors.blue6};
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  height: 34vh;
  font: 400 1.35rem Eurostile;
  color: ${Colors.blue6};

  @media (max-width: 768px) {
    text-align: center;
    font-size: 1.1rem;
  }
`;

const ButtonExportData = styled.button`
  display: flex;
  justify-content: center;
  color: white;
  background-color: ${Colors.blue6};
  box-shadow: 0px 3px 6px ${Colors.black}29;
  border: none;
  border-radius: 5px;
  text-align: center;
  height: fit-content;
  padding: 5px 2px;
  font-size: 12px;
  cursor: pointer;
  transition: 0.1s linear;
  text-decoration: none;

  &:hover {
    font-weight: 600;
    background-color: ${Colors.blue6}cc;
    box-shadow: 0 3px 15px -10px ${Colors.black};
  }

  span {
    font-weight: bold;
  }

  svg {
    margin: auto 0px;
    margin-left: 5px;
    * {
      fill: white;
    }
  }
`;

const FlexDiv = styled.div`
  display: grid;
  grid-template-columns: 65% 15% 15%;
  grid-column-gap: 10px;
  align-items: baseline;

  @media (max-width: 500px) {
    grid-template-columns: 40% 25% 27%; 
  }
`;

const TermsText = styled.p`
  margin: 0.5rem 0;
  font: 400 1rem "Eurostile", "Open Sans", sans-serif;
  letter-spacing: 0;
  color: ${Colors.gray8};
  @media (max-width: 960px) {
    width: 100%;
  }
  @media (max-width: 648px) {
    margin-bottom: 1rem;
  }
`;

const Terms = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;

  & > div > div > div > canvas {
    margin: auto;
  }
`;

export{
  Content,
  TitleCount,
  Message,
  ButtonExportData,
  FlexDiv,
  TermsText,
  Terms
}
