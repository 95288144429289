export const DescriptionMarketplace = () => {
    const styleFlexRow = (gap = '0px', marginTop = '0px') => `
        display: flex; 
        flex-wrap: wrap;
        gap: ${gap}; 
        margin-top: ${marginTop};
        align-items: center;
    `

    const styleButton = `
        background: #2478a7;
        border: 2px solid #156693;
        color: white;
        width: calc(100% + 12px);
    `

    return [`Essa é uma ferramenta de interação entre compradores e fornecedores, com o objetivo de facilitar o processo de compra de dispensa da Petrobras, seguindo as melhores práticas de conformidade.
        Aqui você encontra as oportunidades de dispensa que foi convidado para participar, de acordo com o seu cadastro de famílias na base da Petrobras. Ao clicar na oportunidade escolhida é possível
        avaliar o item e fazer o envio da proposta.`,
        `<div style="${styleFlexRow('20px', '6px')}">
            <b>Saiba mais sobre o Marketplace:</b>
            <div style="${styleFlexRow('24px')}">
                ${
                    [{ link: 'https://www.youtube.com/watch?v=E0FJDab0Xak', text: 'Live 14/09' },
                    { link: 'https://www.youtube.com/watch?v=NmO_vW2TMos', text: 'Live 19/09' },
                    { link: 'https://www.youtube.com/watch?v=osGXKvM_-f0', text: 'Live 21/09' },
                    { link: 'https://www.youtube.com/watch?v=JzjSxDCeN-s', text: 'Live 30/11' }]
                        .map(({ link, text }) => (
                            `<a href="${link}" target="_blank">
                                <button style="${styleButton}">${text}</button>
                            </a>`
                        )).join('')
                }
            </div>
        </div>`]
}