import React, { FC, useState } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { checkDeviceMobile } from "../../../util/devUtilities";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PDFProps {
  style?: any;
}

const PDF: FC<PDFProps> = ({ style }) => {
  const [pageRenderAuxArray, setPageRenderAuxArray] = useState([])
  const isMobile: boolean = checkDeviceMobile();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    renderPages(numPages);
  };

  const renderPages = (numPages:number) => {
    const auxArray: any = []
    for (let i = 0; i < numPages; i++) {
      auxArray.push(i)
    }
    setPageRenderAuxArray(auxArray)
  }

  return (
    <div style={style}>
      <Document
        file={require("./data/termos-servicos-2023.pdf")}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {pageRenderAuxArray.map((_: any, page: any) => (
          <Page scale={isMobile ? 0.6 : 1}    pageNumber={page +1} key={page}/>          
        ))}

      </Document>
    </div>
  );
}

export default PDF;
