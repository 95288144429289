import React, { FC } from "react";
import { TextArea, Label } from "./styles"

interface TextareaProps {
  labelText: string;
  onChange: (ev:any) => void;
  placeholder: string;
  margin?: string;
  border?: string;
}

const Textarea: FC<TextareaProps> = ({ labelText, onChange, placeholder, margin, border }) => {
  return (
    <Label>
      <p>{labelText}</p>
      <TextArea
        onChange={onChange}
        placeholder={placeholder}
        margin={margin}
        border={border}
      />
    </Label>
  );
};

export default Textarea;
