/* eslint-disable no-useless-computed-key */
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  GroupCards,
  Card,
  CardContent,
  SectionContainer,
  Text,
  ErrorText,
  Input,
  JustifyInput,
  FlexColumn,
  GridRow,
} from "./styles";
import { Link } from "react-router-dom";
import backButtonIcon from "../../../../assets/icons/common/back-button.svg";
import { getPropotalsById } from "../../../../dataflow/thunks/marketplace";
import { handleDate, addDaysInDate } from "../../../../util/format-value";
import { useHistory } from "react-router-dom";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { replaceAll, decodedToken } from "../../../../util/devUtilities";
import { handleMoneyWhenReceiving } from "../../util/functions";
import "./styles.css";
import ProposalCardDescription from "../../components/ProposalCardDescription";
import CommonModal from "../../../../components/common-components/CommonModal";

// {match.params.id}
const MarketplaceDescription = ({ match, location }: any) => {
  const [currentItem, _]: Array<any> = useState(location.state);
  const {
    CNPJ,
    IdRequisicaoCompra,
    NumRequisicao,
    VencimentoRequisicao,
    IdProposta,
    Declinio
  } = currentItem;
  const [currentProposals, setCurrentProposals]: any = useState([]);
  const [justifyDecline, setJustifyDecline] = useState("");
  const [showErrorJustifyDecline, setShowErrorJustifyDecline] = useState(false);
  const [showProposalDetailsCard, setShowProposalDetailsCard] = useState(false);
  const [showJustifyDeclineModal, setShowJustifyDeclineModal] = useState(false);
  const [showAlert, setShowAlert]: any = useState({
    open: false,
    message: null,
    status: null,
  });
  const history = useHistory();
  const [formErrors, setFormErros]: any = useState({
    ValorLiquido: { errorMessage: null },
    ICMS: { errorMessage: null },
    IPI: { errorMessage: null },
    PrazoEntrega: { errorMessage: null }
  });

  const defaultMaskOptions = {
    prefix: "R$",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ".",
    allowDecimal: true,
    decimalSymbol: ",",
    decimalLimit: 2,
  };

  const moneyMask = createNumberMask({
    ...defaultMaskOptions,
  });
  
  useEffect(() => {
    getPropotalsById({
      method: "GET",
      id_requisicao: IdRequisicaoCompra,
      cnpj: CNPJ,
    })
      .then((res: any) => res.data.body.listaCatalogo[0])
      .then((res) => {
        function handleDecimal(value: any) {
          const newValue = replaceAll(String(value), '.', ',');
          if (!/,/gm.test(newValue)) return value;
          
          const [_, decimal] = newValue.split(',');
          
          if (decimal.length === 1) {
            return value.toFixed(2);
          } else {
            return value;
          }
        }

        res["ValorLiquido"] = "R$" + handleMoneyWhenReceiving(String(handleDecimal(res["ValorLiquido"])));
        res["ValorTotal"] = "R$" + handleMoneyWhenReceiving(String(handleDecimal(res["ValorTotal"])));
        res["ICMS"] = handleDecimal(res["ICMS"]);
        res["IPI"] = handleDecimal(res["IPI"]);
        res['NCM'] = res['NCM'] === "undefined" || res['NCM'] === "0" ? '' : res['NCM'];

        setCurrentProposals(res);
      }).catch(console.error);
  }, []);

  function resetFormErros() {
    setFormErros(() => ({
      ["ValorLiquido"]: { errorMessage: null },
      ["ICMS"]: { errorMessage: null },
      ["IPI"]: { errorMessage: null },
      ["PrazoEntrega"]: { errorMessage: null }
    }));
  }

  const getUser = () => {
    return decodedToken('idToken').email;
  };

  const sendLaunch = () => {
    const { ValorLiquido, ICMS, IPI,  SubistituicaoTributaria, PrazoEntrega, NCM } = currentProposals;

    function handleMoney(value: string) {
      let newValue = replaceAll(replaceAll(value, "R$", ""), ".", "");

      if (/,\d+/.test(newValue)) {
        const [real, cents] = newValue.split(',');

        if (cents.length === 1) {
          newValue = `${real}.0${cents}`
        } else {
          newValue = replaceAll(newValue, ",", ".");
        }
      } else {
        if (newValue.includes(",")) {
          newValue = replaceAll(newValue, ",", ".00");
        } else {
          newValue = `${newValue}.00`;
        }
      }

      return newValue;
    }

    function handleDecimal(value: any) {
      value = replaceAll(value, '.', ',');
      if (!/,/gm.test(value)) return value;
      
      const [whole, decimal] = value.split(',');
      
      if (decimal.length === 1) {
        return `${whole}.0${decimal}`;
      } else {
        return `${whole}.${decimal}`;
      }
    }

    if (!ValorLiquido) {
      resetFormErros();
      setFormErros((prevProposals: any) => ({
        ...prevProposals,
        ["ValorLiquido"]: {
          errorMessage: "Preencha o valor unitário com frete.",
        },
      }));

      return;
    }

    if (handleMoney(String(ValorLiquido)) === '0.00') {
      resetFormErros();
      setFormErros((prevProposals: any) => ({
        ...prevProposals,
        ["ValorLiquido"]: {
          errorMessage: "Valor inserido é inválido.",
        },
      }));

      return;
    }

    if (!ICMS) {
      resetFormErros();
      setFormErros((prevProposals: any) => ({
        ...prevProposals,
        ["ICMS"]: { errorMessage: "Preencha o ICMS." },
      }));

      return;
    }

    if (!/\d/gm.test(ICMS)) {
      resetFormErros();
      setFormErros((prevProposals: any) => ({
        ...prevProposals,
        ["ICMS"]: { errorMessage: "Preencha com números." },
      }));

      return;
    }

    if (!IPI) {
      resetFormErros();
      setFormErros((prevProposals: any) => ({
        ...prevProposals,
        ["IPI"]: { errorMessage: "Preencha o IPI." },
      }));

      return;
    }

    if (!/\d/gm.test(IPI)) {
      resetFormErros();
      setFormErros((prevProposals: any) => ({
        ...prevProposals,
        ["IPI"]: { errorMessage: "Preencha com números." },
      }));

      return;
    }

    if (!PrazoEntrega) {
      resetFormErros();
      setFormErros((prevProposals: any) => ({
        ...prevProposals,
        ["PrazoEntrega"]: { errorMessage: "Preencha o prazo de entrega." },
      }));

      return;
    }

    if (!/\d/gm.test(PrazoEntrega)) {
      resetFormErros();
      setFormErros((prevProposals: any) => ({
        ...prevProposals,
        ["PrazoEntrega"]: { errorMessage: "Preencha com números." },
      }));

      return;
    }

    resetFormErros();

    let newDataVencimento = new Date(VencimentoRequisicao);
    let newData = new Date(newDataVencimento.setDate(newDataVencimento.getDate() + 60));

    getPropotalsById({
      "method": IdProposta ? "UPDATE" : "POST",
      "id_requisicao_compra": IdRequisicaoCompra,
      "nm_pedido": NumRequisicao,
      "cnpj_fornecedor": String(CNPJ),
      "valor_liquido_und": handleMoney(String(ValorLiquido)),
      "icms": ICMS,
      "ipi": IPI,
      "subistituicao_tributaria": Number(SubistituicaoTributaria),
      "valor_total": handleMoney(String(ValorLiquido)),
      "ncm": NCM,
      "prazo_entrega": PrazoEntrega,
      "vencimento_proposta": newData.toISOString().slice(0, 19).replace('T', ' '),
      "declinio": "0",
      "email_usuario":getUser(),
    })
      .then(() => {
        setShowAlert(() => ({
          open: true,
          message: "Proposta lançada com sucesso.",
          status: "success",
        }));
      })
      .catch(() => {
        setShowAlert(() => ({
          open: true,
          message: "Ocorreu uma falha ao lançar proposta.",
          status: "failed",
        }));
      });
  };

  const sendDecline = () => {
    if (!justifyDecline) {
      setShowErrorJustifyDecline(true);
      return;
    }

    setShowJustifyDeclineModal(false);
    setShowErrorJustifyDecline(false);

    getPropotalsById({
      "method": IdProposta ? "UPDATE" : "POST",
      "id_requisicao_compra": IdRequisicaoCompra,
      "nm_pedido": NumRequisicao,
      "cnpj_fornecedor": CNPJ,
      "valor_liquido_und": 0,
      "icms": 0,
      "ipi": 0,
      "subistituicao_tributaria": 0,
      "valor_total": 0,
      "ncm": 0,
      "prazo_entrega": 0,
      "vencimento_proposta": 0,
      "just_declinio": justifyDecline,
      "declinio": "1",
    })
      .then(() => {
        setShowAlert(() => ({
          open: true,
          message: "Proposta declinada com sucesso.",
          status: "success",
        }));
      })
      .catch(() => {
        setShowAlert(() => ({
          open: true,
          message: "Ocorreu uma falha ao declinar proposta.",
          status: "failed",
        }));
      });
  };

  const handleChange = (event: any) => {
    const fieldName = event.target.name;
    const value = event.target.value;

    setCurrentProposals((prevProposals: any) => ({
      ...prevProposals,
      [fieldName]: value,
    }));
  };

  const closeModal = (withRedirect: boolean = false, URLToRedirect: string = '') => {
    setShowAlert(() => ({
      open: false,
      message: null,
      status: null,
    }));

    if (withRedirect) {
      history.replace("/marketplace");
      history.push("/marketplace");
    } else {
      setShowJustifyDeclineModal(false);
      setShowErrorJustifyDecline(false);
    }
  }

  const renderInputGroup = (obj: any, key: any, title: string, inputType: string = "text", withErrorMessage: boolean = false) => {
    return (
      <>
        <Text>{title}</Text>
        <div>
          <Input
            type={inputType}
            name={key}
            value={obj && (obj[key] || obj[key] === 0) ? obj[key] : ""}
            onChange={(event) => handleChange(event)}
          />
          {withErrorMessage && (formErrors[key]["errorMessage"] && <ErrorText>{formErrors[key]["errorMessage"]}</ErrorText>)}
        </div>
      </>
    );
  };

  const renderMaskedInputGroup = (obj: any, key: any, title: string, moneyMask: any, withErrorMessage: boolean = false) => {
    return (
      <>
        <Text>{title}</Text>
        <div>
          <MaskedInput
            className="maskedInput"
            name={key}
            mask={moneyMask}
            value={obj && (obj[key] || obj[key] === 0) ? obj[key] : ""}
            onChange={(event) => handleChange(event)}
          />
          {withErrorMessage && (formErrors[key]["errorMessage"] && <ErrorText>{formErrors[key]["errorMessage"]}</ErrorText>)}
        </div>
      </>
    );
  };

  return (
    <>
      <SectionContainer>
        <Link className="backButton" to={{ pathname: "/marketplace/" }}>
          <img src={backButtonIcon} alt="Return marketplace" />
          <span className="pageTitleText">Voltar</span>
        </Link>
        
        <GroupCards>
          <ProposalCardDescription
            currentItem={currentItem}
            callbackShowProposalCardForm={() => setShowProposalDetailsCard((state) => !state)}
            callbackSendDecline={() =>{ setShowJustifyDeclineModal(true); }}
            decline={Declinio === 1}
          />
          {showProposalDetailsCard && (
            <Card flexDirection="column">
              <CardContent>
                <FlexColumn gapColumn="4px">
                  <GridRow>
                    <Text>CNPJ:</Text>
                    <Text>
                      {CNPJ || CNPJ === 0 ? CNPJ.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : null}
                    </Text>
                  </GridRow>
                  <GridRow>
                    {renderMaskedInputGroup(currentProposals, "ValorLiquido", "Valor Líquido Unitário com frete:", moneyMask, true)}
                  </GridRow>
                  <GridRow>
                    {renderInputGroup(currentProposals, "ICMS", "ICMS(%):", "number", true)}
                  </GridRow>
                  <GridRow>
                    {renderInputGroup(currentProposals, "IPI", "IPI(%):", "number", true)}
                  </GridRow>
                  <GridRow>
                    <Text>Substituição tributária:</Text>
                    <input
                      type="checkbox"
                      name="SubistituicaoTributaria"
                      onChange={(event: any) => {
                        event.target.value = currentProposals && Number(currentProposals.SubistituicaoTributaria) === 0 ? 1 : 0;
                        handleChange(event);
                      }}
                      value={currentProposals && currentProposals.SubistituicaoTributaria ? currentProposals.SubistituicaoTributaria : 0}
                      checked={currentProposals && Number(currentProposals.SubistituicaoTributaria) === 1 ? true : false}
                      style={{ width: "16px", cursor: "pointer" }}
                    />
                  </GridRow>
                  <GridRow>
                    {renderInputGroup(currentProposals, "PrazoEntrega", "Prazo de entrega (dias):",  "number",  true)}
                  </GridRow>
                  <GridRow>
                    {renderInputGroup(currentProposals, "NCM", "NCM:",  "text",  false)}
                  </GridRow>
                  <GridRow>
                    <Text>Validade da proposta:</Text>
                    <Text>
                      {VencimentoRequisicao || VencimentoRequisicao === 0
                        ? handleDate(String(addDaysInDate(new Date(VencimentoRequisicao), 60))) : null}
                    </Text>
                  </GridRow>
                </FlexColumn>

                <ButtonGroup>
                  <Button onClick={sendLaunch}>Enviar Proposta</Button>
                  <Button onClick={() => setShowProposalDetailsCard((state) => !state)}>Cancelar</Button>
                </ButtonGroup>
              </CardContent>
            </Card>
          )}
        </GroupCards>
      </SectionContainer>

      { showAlert?.open && <CommonModal 
                              title="Aviso" 
                              callbackCloseModal={() => { closeModal(true, '/marketplace')}}>
                              <p>{showAlert?.message}</p>
                            </CommonModal> }
      { showJustifyDeclineModal && <CommonModal 
                                    title="Justifique o declínio" 
                                    confirmText="Confirmar"
                                    callbackConfirm={sendDecline}
                                    callbackCloseModal={() => { closeModal(false) }}>
                                      <JustifyInput
                                        width="100%"
                                        value={justifyDecline}
                                        onChange={(event) => setJustifyDecline(event.target.value)}
                                        placeholder="Justificativa..."
                                        required
                                      />
                                      {showErrorJustifyDecline && <ErrorText>Justificativa é obrigatória.</ErrorText>}
                                    </CommonModal> }
    </>
  );
};

export default MarketplaceDescription;
