import styled from "styled-components";
import Colors from "../../../../assets/styles/colors";

interface StylesProps{
  labelNotifications?:any;
  modalTitle?:any;
  backgroundColor?:any;
  selectedColor?:any;
  alt?:any;
  last?:any;
  hasText?:any;
}

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.gray8}40;
  z-index: 99;
`;

export const FilterModal = styled.div`
  position: relative;
  padding: 1.5rem;
  padding-top: 0;
  width: 35rem;
  border: 0.5px solid ${Colors.blue8}95;
  border-radius: 8px;
  background: ${Colors.white};

  @media (max-width: 490px) {
    width: 95%;
    padding: 0.85rem;
  }
`;

export const Header = styled.div`
  position: relative;
  margin: 0 0 0.8rem 0.5rem;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const CloseContainer = styled.div`
  position: absolute;
  right: -1.75rem;
  top: -0.9rem;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid ${Colors.blue8};
  border-radius: 50%;
  background-color: ${Colors.white};

  @media (max-width: 490px) {
    right: 0.5rem;
    top: 0;
  }
`;

export const CloseButton = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: -1rem;
  margin-left: 1px;
  font-size: 1.2rem;
  color: ${Colors.blue8};
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;

export const CloseImage = styled.img`
  width: 13px;
`;

export const WrapInput = styled.div`
  margin-bottom: 1rem;
`;

export const InputBox = styled.span`
  position: relative;
  display: flex;
  flex-direction: ${(props: StylesProps) => (props.alt ? "row" : "column")};
  width: 100%;
  margin-top: ${(props) => props.last && ".5rem"};
`;

export const InputMessages = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const CharacterCounter = styled.span`
  text-align: end;
  font-size: 12px;
  color: ${Colors.blue6};
`;

export const Label = styled.label`
  font: 500 0.75rem "Eurostile";
  font-size: ${(props: StylesProps) =>
    props.labelNotifications ? ".9rem" : ".9rem"};
  margin: 0 0 0.25rem 0.5rem;
  letter-spacing: 0;
  color: ${(props: StylesProps) =>
    props.labelNotifications ? Colors.black : Colors.green1};
  @media (max-width: 648px) {
    margin: 0 0 0.25rem 1rem;
  }
`;

export const Description = styled.p`
  margin-top: 0;
  font: 400 0.9rem Open Sans;
  padding-left: 1rem;
  color: ${Colors.blue6};
  text-align: center;

  @media (max-width: 490px) {
    width: 63%;
  }
`;

export const Title = styled.h2`
  font: 700 1rem Eurostile;
  color: ${(props: StylesProps) =>
    props.modalTitle ? Colors.blue8 : Colors.white};
  font-size: ${(props) => (props.modalTitle ? "1.4rem" : "1rem")};
`;

export const Input = styled.input`
  width: 100%;
  height: 3.5rem;
  padding: 0.5rem;
  background: ${Colors.white2} 0% 0% no-repeat padding-box;
  border: 1px solid ${Colors.green1};
  border-radius: 4px;
  font: 300 1rem "Open Sans";
  outline: none;

  ::placeholder {
    font: 300 1rem "Open Sans";
    letter-spacing: 0;
    color: ${Colors.gray5};
  }
`;

export const TextErrorBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TextError = styled.p`
  font: 500 0.8rem eurostile, sans serif;
  color: ${Colors.red2};
`;

export const ContainerTagsColor = styled.div`
  margin: 1rem 0;
  height: 4rem;
  display: flex;
  justify-content: space-between;

  @media (max-width: 520px) {
    height: auto;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 90%;
    margin: 2rem auto;
  }
`;

export const TagColor = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${(props: StylesProps) => props.backgroundColor};
  cursor: pointer;
  ${(props: StylesProps) =>
    props.selectedColor &&
    `
      border: solid 4px ${Colors.gray7};
  `}

  @media(max-width: 520px) {
    margin: 0.5rem;
  }
`;

export const TagBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;
