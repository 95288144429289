import React, { FC } from "react";
import {
  ActionButtonContainer,
  ButtonTitle,
  ButtonImg,
  ActionButtonStyleDark,
} from "./styles";
import helpicon from "../helpicon.svg";
import helpiconDark from "../helpicon-dark.svg";
import Colors from "../../../../assets/styles/colors";

interface ActionButtonProps {
  layoutStyle: string;
  handleClick: () => void;
  title: string;
  customStyle: any;
}

const ActionButton: FC<ActionButtonProps> = ({
  layoutStyle,
  handleClick,
  title,
  customStyle,
}) => {
  return (
    <ActionButtonContainer>
      <ActionButtonStyleDark
        style={customStyle}
        layout={layoutStyle}
        onClick={() => handleClick()}
      >
        <ButtonImg
          src={layoutStyle === "dark" ? helpiconDark : helpicon}
          alt="help icon"
        />
      </ActionButtonStyleDark>
      <ButtonTitle color={layoutStyle === "dark" ? Colors.white : Colors.blue8}>
        {title}
      </ButtonTitle>
    </ActionButtonContainer>
  );
};

export default ActionButton;
